import {
  Column,
  FormField,
  FormSelect,
  FormTextInput,
  Row,
} from '@simplisafe/ss-react-components'
import React from 'react'

type ShippingAddressProps = {
  readonly shippingSectionTitle: string
  readonly firstNameFieldLabel: string
  readonly firstNameFieldPlaceholder: string
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
  readonly lastNameFieldLabel: string
  readonly lastNameFieldPlaceholder: string
  readonly stateFieldLabel: string
  readonly streetAddressFieldLabel: string
  readonly streetAddressFieldPlaceholder: string
  readonly locale: string
  readonly streetAddress2FieldLabel: string
  readonly streetAddress2FieldPlaceholder: string
  readonly cityFieldLabel: string
  readonly cityFieldPlaceholder: string
  readonly isMobile: boolean
  readonly postalCodeFieldLabel: string
  readonly postalCodeFieldPlaceholder: string
  readonly phoneNumberFieldLabel: string
  readonly phoneNumberFieldPlaceholder: string
};

const ShippingAddress: React.FC<ShippingAddressProps> = (
  props: ShippingAddressProps
) => {
  return (
    <div data-component={'ShippingAddress'}>
      <Row alignItems="center" equalHeightRows={true}
        gap="none">
        <Column spans={[ 12, 6, 6 ]}>
          <FormField
            hideLabel={true}
            label={props.firstNameFieldLabel}
            name="firstName"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="firstName"
              placeholder={props.firstNameFieldPlaceholder}
            />
          </FormField>
        </Column>

        <Column spans={[ 12, 6, 6 ]}>
          <FormField
            hideLabel={true}
            label={props.lastNameFieldLabel}
            name="lastName"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="lastName"
              placeholder={props.lastNameFieldPlaceholder}
            />
          </FormField>
        </Column>
      </Row>

      <Row alignItems="center" equalHeightRows={true}
        gap="none">
        <Column spans={[ 12, 6, 6 ]}>
          <FormField
            hideLabel={true}
            label={props.emailFieldLabel}
            name="email"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="email"
              placeholder={props.emailFieldPlaceholder}
            />
          </FormField>
        </Column>

        <Column spans={[ 12, 6, 6 ]}>
          <FormField
            hideLabel={true}
            label={props.phoneNumberFieldLabel}
            name="phone"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="phone"
              placeholder={props.phoneNumberFieldPlaceholder}
            />
          </FormField>
        </Column>
      </Row>

      <Row alignItems="center" equalHeightRows={true}
        gap="none">
        <Column spans={[ 12, 6, 6 ]}>
          <FormField
            hideLabel={true}
            label={props.streetAddressFieldLabel}
            name="address1"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="address1"
              placeholder={props.streetAddressFieldPlaceholder}
            />
          </FormField>
        </Column>

        <Column spans={[ 12, 6, 6 ]}>
          <FormField hideLabel={true} name="address2">
            <FormTextInput
              fullWidth={props.isMobile}
              name="address2"
              placeholder={props.streetAddress2FieldPlaceholder}
            />
          </FormField>
        </Column>
      </Row>

      <Row alignItems="stretch" equalHeightRows={true}
        gap="none">
        <Column spans={[ 12, 4, 4 ]}>
          <FormField hideLabel={true} label={props.cityFieldLabel}
            name="city">
            <FormTextInput
              fullWidth={props.isMobile}
              name="city"
              placeholder={props.cityFieldPlaceholder}
            />
          </FormField>
        </Column>
        <Column spans={[ 12, 4, 4 ]}>
          <FormField
            hideLabel={true}
            label={props.stateFieldLabel}
            name="zoneCode"
          >
            <FormSelect
              fullWidth={false}
              name="zoneCode"
              options={[
                'AK',
                'AL',
                'AR',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MS',
                'MT',
                'NC',
                'ND',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VA',
                'VI',
                'VT',
                'WA',
                'WI',
                'WV',
                'WY',
              ]}
              placeholder="Please Select"
            />
          </FormField>
        </Column>

        <Column spans={[ 12, 4, 4 ]}>
          <FormField
            hideLabel={true}
            label={props.postalCodeFieldLabel}
            name="postal"
          >
            <FormTextInput
              fullWidth={props.isMobile}
              name="postal"
              placeholder={props.postalCodeFieldPlaceholder}
            />
          </FormField>
        </Column>
      </Row>
    </div>
  )
}

export default ShippingAddress
