import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type Props = {
  readonly getAQuoteFormPrivacyPolicy?: ReactElement
}

const PrivacyPolicy: React.FC<Props> = ({ getAQuoteFormPrivacyPolicy }: Props) => {
  return (
    <FormField hideLabel={true} name='getAQuoteFormPrivacyPolicy'>
      <Text textAlignment='center' textSize='xs'>
        {getAQuoteFormPrivacyPolicy}
      </Text>
    </FormField>
  )
}

export default PrivacyPolicy
