import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Heading } from '@simplisafe/ss-react-components'
import type { HeadingProps } from '@simplisafe/ss-react-components/Heading'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import React, { FC } from 'react'

import { ContentfulHeading } from '../../../graphql'

export type HeadingComponentProps = {
  readonly data: Partial<ContentfulHeading>
}

const toHeadingProps = applySpec<HeadingProps>({
  bold: prop('bold'),
  color: path([ 'color', 'color' ]),
  element: prop('headingType'),
  headingAspect: prop('fontSizeAspect'),
  margin: prop('margin'),
  useTailwind: prop('useTailwind')
})

const HeadingComponent: FC<HeadingComponentProps> =
  ({ data }: HeadingComponentProps) => (
    <Heading {...toHeadingProps(data)}>
      {path([ 'text', 'text' ], data)}
    </Heading>
  )

export default HeadingComponent

export const query = graphql`#graphql
  fragment heading on ContentfulHeading {
    bold
    color {
      color
    }
    fontSizeAspect
    headingType
    id
    internal {
      type
    }
    text {
      text
    }
    title
    useTailwind
  }`
