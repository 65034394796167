import { getChatAppId } from '@lib/tracking/src/cookies'
import { getLiveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { window } from 'browser-monads-ts'
import React from 'react'

import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import useLiveChatTracking from '../../hooks/useLiveChatTracking'
import useScript from '../../hooks/useScript'

type LiveChatProps = {
  readonly appId: string
}

// The underlying embed code. Loads the Sprinklr LiveChat script to the document
// after defining its settings on the window object.
const LiveChatWidget = ({ appId: chatIdProp }: LiveChatProps) => {
  const chatIdCookie: string = getChatAppId()
  const chatId: string = chatIdCookie || chatIdProp || ''
  const chatScriptUrl: string = getLiveChatScriptUrl(chatId)

  window['sprChatSettings'] = window['sprChatSettings'] || {}
  window['sprChatSettings']['appId'] = chatId

  const status = useScript(chatScriptUrl)

  useLiveChatTracking(status)
  return null
}

// Wrapping code that determines if we should enable the livechat widget via
// optimizely, and if agents are available.
const LiveChat = ({ appId }: LiveChatProps)  => {
  const hasAvailableAgents: boolean = useLiveChatAvailability(appId)

  return hasAvailableAgents ? <LiveChatWidget appId={appId} /> : null
}

export default LiveChat
