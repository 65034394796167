/* eslint-disable max-lines -- legacy code */
import { useOptimizelyAffirm } from '@lib/tracking/src/optimizely'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safePath, safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { getLocalizedString } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import {
  selectActivePromoFlag,
  selectActivePromoOverrideDiscountText,
  selectDisplayMonitoringDiscount,
  selectItemFromSku,
  selectNavigationProductCategory,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SSButton } from '@simplisafe/ss-react-components'
import { AffirmPromoMessage } from '@simplisafe/ss-react-components'
import { CardItemBanner } from '@simplisafe/ss-react-components'
import { CardItemBannerProps } from '@simplisafe/ss-react-components/CardItemBanner'
import { ItemProduct } from '@simplisafe/ss-react-components/IncludedItem'
import { OfferTagProps } from '@simplisafe/ss-react-components/OfferTag'
import { window } from 'browser-monads-ts'
import { graphql, Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import contains from 'ramda/src/contains'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import isNil from 'ramda/src/isNil'
import pipe from 'ramda/src/pipe'
import React, {
  FC, ReactElement,
  ReactNode,
  useMemo
} from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulButton, ContentfulLinkAddToCart,
  ProductCardFragment
} from '../../../graphql'
import { formatDiscountDifferenceText } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount'
import useRequestPrice from '../../hooks/useRequestPrice'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import type { AffirmClient } from '../../types/affirm'
import documentFallback from '../../util/documentFallback'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import LinkAddToCart from '../LinkAddToCart'

type ContentfulCardItemBannerProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ProductCardFragment
}

const renderProductButton = (button: Pick<ContentfulButton, 'addToCartButton' | 'buttonSize' | 'text' | 'type' | 'url'>, productSku?: string) => {
  const buttonText = safeProp('text', button).getOrElse('')
  const buttonType = safeProp('type', button).getOrElse('')
  const buttonUrl = safeProp('url', button).getOrElse('')
  const buttonSize = safeProp('buttonSize', button).getOrElse('auto')
  const addToCartButton: boolean = safeProp('addToCartButton', button).getOrElse(false)
  const data: Partial<ContentfulButton | ContentfulLinkAddToCart> = {
    buttonSize,
    buttonType,
    internal: {
      contentDigest: '',
      owner: '',
      type: 'ContentfulLinkAddToCart',
    },
    productSku,
    text: buttonText,
    url: buttonUrl,
  }

  return addToCartButton === true ? (
    <LinkAddToCart data={data} />
  ) : buttonUrl ? (
    <Link
      to={buttonUrl}
    >
      <SSButton
        color={toButton(button).color}
        dataComponent="ProductButton"
        type='div'
      >
        {buttonText}
      </SSButton>
    </Link>
  ) : <div />
}

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
const toCardItemBannerProps = (data, isNoPlanDiscount, skuID?: string) => applySpec<CardItemBannerProps>({
  disclaimerText: (d) => isNoPlanDiscount ? '' : prop('disclaimerText', d),
  imagePosition: prop('imagePosition'),
  itemImage: path([ 'image', '0', 'file', 'url' ]),

  locale: always(locale),
  productButton: (data: ProductCardFragment) => safeProp('button', data)
    .map(x => renderProductButton(x, skuID))
    .orJust(<div />),
  productDescription: path([ 'productDescription', 'json' ]),
  productHighlights: path([ 'productHighlights', 'json' ]),
  showSensorList: prop('showSensorList'),
  textPosition: prop('textPosition'),
  title: prop('title')
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
})(data)

export const toSensorList = transformObject<PackageProduct, ItemProduct>({
  // @ts-expect-error TS(7006) FIXME: Parameter 'packageProduct' implicitly has an 'any'... Remove this comment to see the full error message
  productName: (packageProduct) => pipe((packageProduct) => defaultTo('')(prop('name', packageProduct)),

    getLocalizedString(locale),
    (prodName: string) => concat(prop('quantity', packageProduct).toString(), ` ${prodName}`))(packageProduct)
})

// Filter Products from the Sensor List: window decal: SSWD2, yard sign: SSYS3
const sensorFilter = [ 'SSWD2', 'SSYS3' ]
const toItemProducts = (packageVal: Package) =>
  safeProp('products', packageVal)
    .map(products =>
      products
        .filter(packageProduct => !!prop('isViewable', packageProduct) && !sensorFilter.includes(prop('sku', packageProduct)))
        .map(toSensorList)
    )

const toGatsbyImage = (imgData: ProductCardFragment['img']): ReactNode => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const altText: string = Maybe.fromNull(imgData)
    .chain(safePath([ '0', 'title' ]))
    .getOrElse('')

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const id: number = Maybe.fromNull(imgData)
    .chain(safePath([ '0', 'id' ]))
    .getOrElse(null)

  const inlineStyles = {
    height: '100%',
    position: 'absolute',
    width: '100%'
  }

  return Maybe.fromNull(imgData)
    .chain(safePath([ '0', 'fluid' ]))
    .map(
      (fluid) => (
        <Img
          alt={altText}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          fluid={fluid as FluidObject}
          imgStyle={{ objectFit: 'cover' }}
          key={id}
          style={inlineStyles}
        />
      )
    )
    .orNull()
}

const ContentfulCardItemBanner: FC<ContentfulCardItemBannerProps> =
  ({ affirmClient = window.affirm, data }: ContentfulCardItemBannerProps) => {
    const {
      optimizelyAffirmLearnMore, optimizelyAffirmVariation, optimizelyReady
    } = useOptimizelyAffirm()

    const category = useSelector(selectNavigationProductCategory)

    const isActive = React.useMemo(() =>
      safeProp('categoryFilterInclusion', data).cata(
        // eslint-disable-next-line ramda/prefer-ramda-boolean -- legacy code
        () => true,
        categories => contains(category, categories)
      ), [ data, category ]
    )
    const skuID: string = safeProp('productId', data).orJust('')
    const isPromoTopBanner = useSelector(selectTopBannerVisible)
    // For US, when the top banner promo is displayed then priority should be given to discount without plan.
    const isNoPlanDiscount = equals('en-US', process.env.LOCALE) && isPromoTopBanner
    const props = toCardItemBannerProps(data, isNoPlanDiscount, skuID)

    const _packageOrProduct = useSelector(selectItemFromSku(skuID))
    const packageProps = useMemo(() => (
      _packageOrProduct.map(item => item['@@type'] === 'package' ? toItemProducts(item) : None<readonly ItemProduct[]>())), [ _packageOrProduct ]
    ).cata<readonly ItemProduct[]>(() => [], p => p.getOrElse([]))

    // Log an error if we don't have a valid package or product. Only triggers after initial mount in order to avoid
    // logging errors before we've hydrated the redux store with product/package data from pageContext.
    useEffectAfterMount(() => {
      _packageOrProduct.forEachLeft(e => {
        logError(e)
      })
    }, [ _packageOrProduct, skuID ])

    const displayMonitoringPrice = useSelector(selectDisplayMonitoringDiscount)

    const {
      getPrice, getDiscountedPrice, getDiscountedText, getFormattedPrice, getDiscountedTextWithServicePlan
    } = useRequestPrice(skuID)
    const maybePrice = getDiscountedPrice.catchMap(() => getPrice)
    const formattedPrice = getFormattedPrice(priceDefault, true, displayMonitoringPrice)

    const priceDetails = {
      pieces: safeProp('quantity', data).orUndefined(),
      // PriceDetails type should be updated to be a ReactNode instead of ReactElement
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      price: formattedPrice as ReactElement
    }

    const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
    const discountText = displayMonitoringDiscount ? getDiscountedTextWithServicePlan : getDiscountedText

    const affirmPromoMessage = optimizelyReady && maybePrice && maybePrice.map(_price => {
      return <AffirmPromoMessage affirmClient={affirmClient}
        className={optimizelyAffirmVariation === 'variation_1' ? 'affirm-as-low-as-test' : 'affirm-as-low-as'}
        key={_price}
        onLearnMoreClick={optimizelyAffirmLearnMore}
        pageType='product'
        price={_price}  /> }).orNull()

    const taggingTextJson =
      safePath([ 'promotionalTagging', 'taggingText' ], data)
        .map(getJson)
        .getOrElse(documentFallback())

    const stringifiedTaggingText = JSON.stringify(taggingTextJson)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const modifiedTaggingText: Document = JSON.parse(stringifiedTaggingText.replace('"value":" OFF"', '"value":""' ))

    const promoFlag = useSelector(selectActivePromoFlag)
    const tagBackgroundColor = promoFlag.chain(chainProp('backgroundColor')).orUndefined()
    const tagTextColor = promoFlag.chain(chainProp('textColor')).orUndefined()

    const discountValueText = useMemo(() => _packageOrProduct.map(p => formatDiscountDifferenceText(p, true))
      .cata(
        error => JSON.stringify(error),
        val => val),
    [ _packageOrProduct ])

    const additionalInfoJson =
      safeProp('additionalInfo', data)
        .map(getJson)
        .orNull()

    // TODO we should never reach into a Document like this. It's very brittle.
    const content = path([ 'content', 0 ], additionalInfoJson)
    // TODO I'm not sure what this is doing or why, but it isn't typesafe
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const additionalInfo: ReactNode =  path([ 'content', 0, 'value' ], content)
    const rawTokens = additionalInfo && additionalInfo.toString().split(/(?:{{|}})/g)
    const textTokens = rawTokens && rawTokens.map((token: string, idx: number) =>
      token === 'legacy_discount_percent' ? <span key={`discount-${idx}`}>{discountText.orJust('')}</span> :
        token === 'legacy_discount_value' ? <span key={`discount-value-${idx}`}>{discountValueText}</span> :
          <span key={`text-${idx}`}
          >{token}</span>)

    const text = !isNil(additionalInfo) ? <div className={'placeholder-wrapper'}
      data-component={`${ContentfulCardItemBanner.name}-discountInfo`}>{textTokens}</div>
      : null

    const overrideTextMaybe =
      useSelector(selectActivePromoOverrideDiscountText)
        .chain(safeProp('listingFlag'))
        .chain(val => val)
    const hasOverrideText = !overrideTextMaybe.isNone()

    const offerTag: OfferTagProps | undefined =
      discountText
        .filter(val => !!val && doesDocumentHaveContent(taggingTextJson))
        .filter(__ => isPromoTopBanner)
        .map(val => ({
          placeholderText: displayMonitoringDiscount ? (hasOverrideText ? overrideTextMaybe.some() : val) : val,
          tagBackgroundColor,
          tagTextColor,
          taggingText: displayMonitoringDiscount ? (hasOverrideText ? modifiedTaggingText : taggingTextJson) : taggingTextJson
        }))
        .orUndefined()

    const productImage = toGatsbyImage(prop('img', data))

    return (
      <>{
        isActive &&
            <CardItemBanner
              additionalInfo={text}
              affirmPromoMessage={affirmPromoMessage}
              key={data.id}
              {...props}
              itemImage={productImage}
              offerTag={offerTag}
              priceDetails={priceDetails}
              productSensors={packageProps}
            />
      }
      </>
    )
  }

export const query = graphql`
  fragment productCard on ContentfulProductCard{
    id
    internal {
    type
    }
    productId
    imagePosition
    showSensorList
    textPosition
    title
    productDescription {
        json
    }
    productHighlights {
        json
    }
    additionalInfo {
        json
    }
    disclaimerText
    button {
        text
        type
        url
        buttonSize
        addToCartButton
    }
    categoryFilterInclusion
    img: image {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
      title
    }
    promotionalTagging {
      ...promotionalTagging
    }
    quantity
  }
`

export default ContentfulCardItemBanner
