import prop from '@simplisafe/ewok/ramda/prop'
import { TrustpilotReviewBanner } from '@simplisafe/ss-react-components'
import type { TrustpilotWidget } from '@simplisafe/ss-react-components/TrustpilotReviewBanner'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import props from 'ramda/src/props'
import React, { FC } from 'react'

import { ContentfulTrustpilotReviewsBanner } from '../../../graphql'

type DeepPartial<T> = { readonly
    [P in keyof T]?: DeepPartial<T[P]>;
}

type TrustpilotBannerProps = {
    readonly data: DeepPartial<ContentfulTrustpilotReviewsBanner>
}

const widgetProps =
    applySpec<TrustpilotWidget>({
      dataBusinessunitId: prop('dataBusinessunitId'),
      dataLocale: prop('dataLocale'),
      dataStars: prop('dataStars'),
      dataStyleHeight: prop('dataStyleHeight'),
      dataStyleWidth: prop('dataStyleWidth'),
      dataTemplateId: prop('dataTemplateId'),
      dataTheme: prop('dataTheme')
    })

const TrustpilotBanner: FC<TrustpilotBannerProps> = ({ data }: TrustpilotBannerProps) => {
  const [ mobile, desktop ] = props([ 'mobile', 'desktop' ], data)
  return (
    <TrustpilotReviewBanner
      desktopWidget={widgetProps(desktop)}
      mobileWidget={widgetProps(mobile)}
    />
  )
}

export default TrustpilotBanner

export const trustpilotBannerFragment = graphql`#graphql
  fragment trustpilotBannerFragment on ContentfulTrustpilotReviewsBanner {
    internal {
      type
    }
    id
    mobile {
    ...trustpilotWidget
    }
    desktop {
    ...trustpilotWidget
    }
  }

  fragment trustpilotWidget on ContentfulTrustpilotReviewBanner {
    dataBusinessunitId
    dataLocale
    dataStars
    dataStyleHeight
    dataStyleWidth
    dataTemplateId
    dataTheme
    id
  }
`
