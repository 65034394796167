import { Document } from '@contentful/rich-text-types'
import { Form2GUpgradeSchema } from '@simplisafe/ss-ecomm-data/leads/schema/form2GUpgradeSchema'
import {
  Column, Row, Text
} from '@simplisafe/ss-react-components'
import { Form, Formik } from 'formik'
import propOr from 'ramda/src/propOr'
import React, { ReactNode, useState } from 'react'

import states from '../../../../utils/states'
import { Form2GUpgradeFragment } from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'
import City from './form-sections/City'
import Email from './form-sections/Email'
import FirstName from './form-sections/FirstName'
import InfoModal from './form-sections/InfoModal'
import LastName from './form-sections/LastName'
import Phone from './form-sections/Phone'
import SIDNumber from './form-sections/SIDNumber'
import State from './form-sections/State'
import StreetAddress1 from './form-sections/StreetAddress1'
import StreetAddress2 from './form-sections/StreetAddress2'
import Submission from './form-sections/Submission'
import ZipCode from './form-sections/ZipCode'
import useForm2gUpgradeFragment from './useForm2gUpgradeFragment'
import useSubmit from './useSubmit'

type Form2GUpgradeProps = {
  readonly data: Form2GUpgradeFragment
}

export const Form2GUpgrade = ({ data }: Form2GUpgradeProps) => {
  const fragment = useForm2gUpgradeFragment(data)
  const [ showInfoModal, setShowInfoModal ] = useState(false)
  const handleSubmission = useSubmit()

  const description = fragment.get('description')
  const fieldsDescription = fragment.get('fieldsDescription')
  const formFields = fragment.get('formFields')
  const infoModalImage = fragment.get('infoModalImage')
  const sidNumberOverlayTitle = fragment.get('sidNumberOverlayTitle')
  const sidNumberOverlayCopy = fragment.get('sidNumberOverlayCopy')
  const submitButtonLabel = fragment.get('submitButtonLabel')
  const title = fragment.get('title')

  const initialValues = {
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    sidNumber: '',
    state: '',
    streetAddress1: '',
    streetAddress2: '',
    zipCode: ''
  }

  const getDataFor = (field: string) => {
    return formFields.filter(f => {
      return f.propName === field
    }).shift()
  }

  const formDescription: ReactNode = description
    .map((json: Document) => <ContentfulRichText key={propOr<string, string>('', 'id', data)}
      rawRichText={json} />)
    .getOrElse(<></>)

  const modalDescription: ReactNode = sidNumberOverlayCopy
    .map((json: Document) => <ContentfulRichText key={propOr<string, string>('', 'id', data)}
      rawRichText={json} />)
    .getOrElse(<></>)

  return (
    <div>
      <Row>
        <Column spans={[ 12, 12, 12 ]}>
          <Text
            textAlignment="center"
            textSize="2xl"
            useTailwind={true}
          >
            <p style={{
              fontSize: '65px',
              fontWeight: 200,
              lineHeight: '75px'
            }}>{title}</p>
          </Text>
        </Column>
      </Row>
      <Row>
        <Column spans={[ 0, 1, 2 ]} />
        <Column padding="medium" spans={[ 12, 10, 8 ]}>
          <Text textAlignment="center">
            {formDescription}
          </Text>
        </Column>
        <Column spans={[ 0, 1, 2 ]} />
      </Row>
      <Row>
        <Column spans={[ 0, 1, 2 ]}></Column>
        <Column
          backgroundColor='neutralLightGray'
          className="mb-4"
          padding="medium"
          shadow="soft"
          spans={[ 12, 10, 8 ]}
        >
          <Text className="px-4">

            <p>{fieldsDescription}</p>
          </Text>
          <Formik
            initialValues={initialValues}
            onSubmit={ (values, { setSubmitting }) => {
              handleSubmission(values, setSubmitting)
            }}
            validationSchema={Form2GUpgradeSchema}
          >
            {({ isSubmitting, status }) => (
              <Form>
                {!status && (
                  <>
                    <Row alignItems="stretch" gap="none">
                      <Column spans={[ 12, 6, 6 ]}>
                        <FirstName
                          label={propOr<string, string>('', 'label', getDataFor('firstName'))}
                          placeholder={propOr<string, string>('', 'placeholder', getDataFor('firstName'))}
                        />
                      </Column>
                      <Column spans={[ 12, 6, 6 ]}>
                        <LastName
                          label={propOr<string, string>('', 'label', getDataFor('lastName'))}
                          placeholder={propOr<string, string>('', 'placeholder', getDataFor('lastName'))}
                        />
                      </Column>
                    </Row>

                    <StreetAddress1
                      label={propOr<string, string>('', 'label', getDataFor('streetAddress1'))}
                      placeholder={propOr<string, string>('', 'placeholder', getDataFor('streetAddress1'))}
                    />

                    <StreetAddress2
                      label={propOr<string, string>('', 'label', getDataFor('streetAddress2'))}
                      placeholder={propOr<string, string>('', 'placeholder', getDataFor('streetAddress2'))}
                    />

                    <Row alignItems="stretch">
                      <Column spans={[ 12, 4, 4 ]}>
                        <City
                          label={propOr<string, string>('', 'label', getDataFor('city'))}
                          placeholder={propOr<string, string>('', 'placeholder', getDataFor('city'))}
                        />
                      </Column>
                      <Column spans={[ 6, 4, 4 ]}>
                        <ZipCode
                          label={propOr<string, string>('', 'label', getDataFor('zipCode'))}
                          placeholder={propOr<string, string>('', 'placeholder', getDataFor('zipCode'))}
                        />
                      </Column>
                      <Column spans={[ 6, 4, 4 ]}>
                        <State
                          label={propOr<string, string>('', 'label', getDataFor('state'))}
                          options={states.map(s => s.name)}
                          placeholder={propOr<string, string>('asd', 'placeholder', getDataFor('state'))}
                        />
                      </Column>
                    </Row>

                    <Phone
                      label={propOr<string, string>('', 'label', getDataFor('phonenumber'))}
                      placeholder={propOr<string, string>('', 'placeholder', getDataFor('phonenumber'))}
                    />

                    <Email
                      label={propOr<string, string>('', 'label', getDataFor('email'))}
                      placeholder={propOr<string, string>('', 'placeholder', getDataFor('email'))}
                    />

                    <SIDNumber
                      label={propOr<string, string>('', 'label', getDataFor('sidnumber'))}
                      onInfoClick={() => setShowInfoModal(true)}
                      placeholder={propOr<string, string>('', 'placeholder', getDataFor('sidnumber'))}
                    />

                    <Submission
                      disableSubmit={isSubmitting}
                      isMobile={false}
                      showErrorMessage={false}
                      submitButtonLabel={submitButtonLabel}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Column>
        <Column spans={[ 0, 1, 2 ]}></Column>
      </Row>
      <InfoModal
        description={modalDescription}
        image={infoModalImage.orUndefined()}
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        title={sidNumberOverlayTitle}
      />
    </div >
  )
}

export default Form2GUpgrade
