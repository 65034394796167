import { Header as HeaderAtom } from '@simplisafe/ss-react-components'
import React, { FC } from 'react'

import DesktopHeader from './DesktopHeader'
import { MobileOrTabletHeader } from './MobileOrTabletHeader'
import { ContentfulHeaderFragment } from './query'

export type HeaderProps = {
  readonly data: ContentfulHeaderFragment
  readonly hasProgressBar?: boolean
}

const Header: FC<HeaderProps> = ({ data, hasProgressBar }: HeaderProps) => {
  return (
    <HeaderAtom hasProgressBar={hasProgressBar} key="header">
      <MobileOrTabletHeader className='flex justify-between w-full items-center lg:hidden' data={data} />
      <DesktopHeader className="hidden justify-between items-center w-full lg:flex" data={data} />
    </HeaderAtom>
  )
}

export default Header
