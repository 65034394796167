import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import map from 'ramda/src/map'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import type { ContentfulBadgeText } from '../../../graphql'
import { toSiteColor } from '../../attributeMappings'
import getJson from '../../util/getJson'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

export type BadgeTextProps = {
  readonly data: Partial<ContentfulBadgeText>
}

export const getBadgeDiscount = (discountText: string) => ({ 'Discount Percentage': discountText.replace('%', '') })

const BadgeText = ({ data }: BadgeTextProps) => {

  /**
   * GraphQL alias don't type correctly
   * https://simplisafe.atlassian.net/browse/ECP-4325
   */
  const badgeText = getJson(prop('text', data))
  const [ showBadgeText, setShowBadgeText ] = useState(false)
  const [ placeholders, setPlaceholders ] = useState({})

  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HEADING_3]:
        (__: unknown, text) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const header3 = map(spanWrap, text as string)

          return (
            <h3 className={'h3'}>{header3}</h3>
          )
        }
    },
  }
  const floatingBadgeContent = <ContentfulRichText optionsCustom={options} rawRichText={badgeText} />

  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountText = useSelector(selectPromoDiscountText)
  const shouldShowBadge = showBadgeText && !isPromoTopBanner

  useEffect(() => {
    discountText.cata(
      () => setShowBadgeText(false),
      text => {
        setShowBadgeText(true)
        setPlaceholders(getBadgeDiscount(text))
      }
    )
  }, [ discountText ])

  return (
    shouldShowBadge ? <div style={
      {
        bottom: 0,
        position: 'absolute',
        right: 0
      }
    }
    >
      <FloatingBadge
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        backgroundColor={toSiteColor('brandPrimary')}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        borderColor={toSiteColor('brandPrimary')}
        floatingBadgeContent={floatingBadgeContent}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        textColor={toSiteColor('neutralWhite')}
      />
    </div> : null
  )
}

export default BadgeText
export const BadgeTextQuery = graphql`#graphql
      fragment badgeText on ContentfulBadgeText {
        id
    internal {
        type
      }
      title
      promoCode
      badgeText_text: text {
        json
      }
  }
      `
