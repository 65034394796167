import {
  FormField, FormRadio, Text
} from '@simplisafe/ss-react-components'
import React from 'react'

import { ContentfulRadioButton } from '../../../../graphql'

type Props = {
  readonly numLocationsFieldLabel: string
  readonly numLocationsOptions: readonly Pick<ContentfulRadioButton, 'text' | 'value'>[]
}

const NumLocations: React.FC<Props> = ({ numLocationsFieldLabel, numLocationsOptions }: Props) => {
  return (
    <div data-component="numLocations">
      <FormField hideLabel={true} label={numLocationsFieldLabel}
        name="numLocations">
        <Text fontWeight="medium">{numLocationsFieldLabel}</Text>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {numLocationsOptions &&
            numLocationsOptions.map((option, idx) => (
              <div key={idx} style={{ minWidth: 'auto' }}>
                <FormRadio label={option.text || option.value || ''} name="numLocations"
                  value={option.value || ''} />
              </div>
            ))}
        </div>
      </FormField>
    </div>
  )
}

export default NumLocations
