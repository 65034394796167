import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectActivePromoDiscountTextWithOverrides, selectActivePromoEndTime, selectLocale, selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column, Row, Text
} from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import { Link } from 'gatsby'
import { None } from 'monet'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { SimpliSafeCSSProperties } from '../../types/SimpliSafeCSSProperties'
import LeadCaptureForm, { LeadCaptureFormProps, LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import type { ActivePromoBannerType } from '.'

export type MobileMaximizedLayoutProps = {
  readonly isFormSubmitted: boolean
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly type: ActivePromoBannerType
}

const CountdownMobile: FC = () => {
  const bannerProps = useSelector(selectPromotionBanner)
  const endTime = useSelector(selectActivePromoEndTime).map(_endTime => new Date(_endTime))
  const maybeHasCountdown = bannerProps.chain(safeProp('hasCountdown'))

  return maybeHasCountdown
    .chain(hasCountdown => hasCountdown ? endTime : None<Date>())
    .cata<JSX.Element | null>(
      () => null,
      date => (
        <Column justifySelf='center' rounded='none'
          spans={[ 12 ]}>
          <Text
            inheritTextColor={true}
            textAlignment='center'
            textSize='md'
            useTailwind={true}
          >
            <Promo.Countdown endTime={date}/>
          </Text>
        </Column>
      )
    )
}

const TitleMobile: FC = () => {
  const locale = useSelector(selectLocale)
  const endTime = useSelector(selectActivePromoEndTime).map(_endTime => new Date(_endTime))
    .orUndefined()
  const bannerProps = useSelector(selectPromotionBanner)
  const bannerTitle = bannerProps.chain(chainProp('saleName')).getOrElse('')
  const hasEndDateCallout = bannerProps.chain(safeProp('hasEndDateCallout')).getOrElse(false)
  const hasSaleName = bannerProps.chain(safeProp('hasSaleName')).getOrElse(false)
  const endTimeValue = hasEndDateCallout && endTime ? endTime : undefined
  const title = hasSaleName && bannerTitle ? bannerTitle : undefined

  return ( hasSaleName && bannerTitle ) || ( hasEndDateCallout && endTime ) ? (
    <Column justifySelf='center' rounded='none'
      spans={[ 12 ]}>
      <Text
        fontWeight='medium'
        inheritTextColor={true}
        textAlignment={'center'}
        textSize={'md'}
        useTailwind={true}
      >
        <Promo.PromoTitle
          endDate={endTimeValue}
          lineBreak={true}
          locale={locale}
          title={title}
        />
      </Text>
    </Column>
  ) : null
}

const OfferMobile: FC = () => {
  const discountText = useSelector(selectActivePromoDiscountTextWithOverrides).getOrElse('')
  const bannerProps = useSelector(selectPromotionBanner)
  const lineItemLabel = bannerProps.chain(chainProp('freeGiftItemSecondaryText')).orUndefined()
  const lineItemTitle = bannerProps.chain(chainProp('freeGiftItemPrimaryText')).orUndefined()
  const offerLabel = bannerProps.chain(chainProp('discountSecondaryText')).orUndefined()
  const hasFreeGiftItem = bannerProps.chain(safeProp('hasFreeGiftItem')).getOrElse(false)
  return discountText ? (
    <Column justifySelf='center' rounded='none'
      spans={[ 12 ]}>
      <Text
        fontWeight='medium'
        inheritTextColor={true}
        textAlignment={'center'}
        textSize={'xl'}
        useTailwind={true}
      >
        <Promo.OfferText
          hasBorder={true}
          lineBreak={false}
          lineItemLabel={hasFreeGiftItem && lineItemLabel ? lineItemLabel : undefined}
          lineItemTitle={hasFreeGiftItem && lineItemTitle ? lineItemTitle : undefined}
          offerLabel={offerLabel}
          offerTitle={`${discountText} off`}
        />
      </Text>
    </Column>
  ) : null
}

type LeadGenMobileProps = Pick<LeadCaptureFormProps, 'isSubmitted' | 'onSubmit' | 'showCta'>
const LeadGenMobile: FC<LeadGenMobileProps> = ({
  isSubmitted,
  onSubmit,
  showCta,
}: LeadGenMobileProps) => {
  const bannerProps = useSelector(selectPromotionBanner)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)
  const buttonBackgroundColor = bannerProps.chain(chainProp('buttonBackgroundColor')).orUndefined()
  const buttonTextColor = bannerProps.chain(chainProp('buttonTextColor')).orUndefined()
  const buttonStyles: SimpliSafeCSSProperties = {
    '--btn-accent': buttonTextColor,
    '--btn-primary': buttonBackgroundColor,
    '--btn-primary-light': buttonBackgroundColor,
  }

  return hasForm ? (
    <Column dataComponent='MobileLeadCaptureForm' justifySelf='center'
      rounded='none' spans={[ 12 ]}>
      <Text
        inheritTextColor={true}
        textSize='sm'
        useTailwind={true}
      >
        <LeadCaptureForm
          buttonStyles={buttonStyles}
          ctaButtonHref='/home-security-shop'
          ctaButtonText='shop now'
          isSubmitted={isSubmitted}
          label='Enter your email'
          legal={<span>You may receive email offers from us in accordance with our <Link to="/privacy-policy"><strong>Privacy Policy</strong></Link>.</span>}
          onSubmit={onSubmit}
          placeholder='enter your email'
          showCta={showCta}
          submitButtonText='get offer'
          successMessage='Thank you. Your code will automatically apply at checkout.'
        />
      </Text>
    </Column>
  ) : null
}

const MobileMaximizedLayout: FC<MobileMaximizedLayoutProps> = ({
  isFormSubmitted,
  onEmailSubmit,
  type,
}: MobileMaximizedLayoutProps) => {
  return (
    <div style={{
      margin: '0 auto',
      maxWidth: '360px',
    }}>
      <Row dataComponent='MobileMaximizedLayout' inheritTextColor={true}
        rounded='none'>
        <TitleMobile />
        <CountdownMobile />
        <OfferMobile />
        <LeadGenMobile
          isSubmitted={isFormSubmitted}
          onSubmit={onEmailSubmit}
          showCta={type === 'standard'}
        />
      </Row>
    </div>
  )
}

export default MobileMaximizedLayout
