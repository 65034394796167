import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SimpleProductCard } from '@simplisafe/ss-react-components'
import { FooterTopSection } from '@simplisafe/ss-react-components'
import { graphql, navigate } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import pathOr from 'ramda/src/pathOr'
import React, {
  FC, useCallback, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulFooterTopSection, ContentfulProductCardSimple } from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import useRequestPrice from '../../hooks/useRequestPrice'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'

type ContentfulFooterTopSectionProps = {
  readonly data: Partial<ContentfulFooterTopSection>
}

// TODO remove margin-top on regular page footer that causes a gap with this component?
const FooterTopSectionComponent: FC<ContentfulFooterTopSectionProps> =
  ({ data }: ContentfulFooterTopSectionProps) => {
    const productData: Partial<ContentfulProductCardSimple> = prop('product', data) || {}
    const sku = path([ 'product', 'productId' ], data) || ''
    const product = useSelector(selectProduct(sku))
    const dispatch = useDispatch()
    const navigateUrl = path([ 'product', 'button', 'url' ], data)
    const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)
    const [ showSpinner, setShowSpinner ] = useState(true)
    const { Track, trackEvent } = useTracking()

    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

    const { getFormattedPrice } = useRequestPrice(sku)
    const price = getFormattedPrice(pricePerUnit)

    const onAddToCart = useCallback((quantity: number) => {
      const urlRedirect = (url: string) => {
        navigate(url)
      }
      setAddToCartError(null)

      const handleSuccess = () => {
        setShowSpinner(false)
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(product, trackEvent, quantity)
        navigateUrl && urlRedirect(navigateUrl)
      }
      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const skuIdWithOrNullProductError = sku ? `no product with sku ${sku} found in redux` : 'received null/empty sku'

      product.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error(`Cannot add to cart: ${skuIdWithOrNullProductError}`))
        },
        _product => {
          dispatch(IOAddToCart({
            products: [ {
              quantity,
              sku: _product.masterSku
            } ]
          }, handleFailure, handleSuccess))
        }
      )
    }, [ dispatch, product, sku, navigateUrl, trackEvent, optimizelyTrackSiteEvents ])

    const buttonData = prop('button', productData)

    const productCard = (
      <SimpleProductCard
        additionalText={safeProp('disclaimerText', productData).orUndefined()}
        buttonProps={{
          ...buttonData ? toButton(buttonData) : { children: null },
          showSpinner
        }}
        defaultQuantity={safeProp('defaultQuantity', productData).orJust(1)}
        errorMessage={addToCartError && <AddToCartError errorType={addToCartError}
          textAlign='center' />}
        img={safePath([ 'image', '0', 'fluid' ], productData)
          .map((fluid: FluidObject) =>
            <Img
              alt={pathOr<string, string>('', [ 'image', '0', 'title' ], productData)}
              fluid={fluid}
              key={pathOr<string, string>('', [ 'image', '0', 'id' ], productData)}
            />
          )
          .orNull()}
        onButtonClick={onAddToCart}
        outOfStockMessage={renderOutOfStockMessage({ product: product })}
        price={price}
        quantityChangerProps={{
          label: 'Select quantity', // TODO this shouldn't be hardcoded
          max: safeProp('maximumQuantity', productData).orJust(4),
          min: 1
        }}
        title={safeProp('title', productData).orJust('')}
      />
    )

    const footerTopSectionText = <ContentfulRichText rawRichText={getJson(prop('text', data))} />

    return (
      <Track>
        <FooterTopSection
          content={footerTopSectionText}
          icon={safePath([ 'icon', 'fluid' ], data)
            .map((fluid: FluidObject) =>
              <Img
                alt={pathOr('', [ 'icon', 'title' ], data)}
                fluid={fluid}
                key={pathOr('', [ 'icon', 'id' ], data)} />
            )
            .orNull()
          }
          productCard={productCard}
        />
      </Track>
    )
  }


export default FooterTopSectionComponent

export const query = graphql`#graphql
    fragment footerTopSectionFragment on ContentfulFooterTopSection {
        id
        internal {
          type
        }
        icon {
          id
          fluid(maxWidth: 200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        text {
          json
        }
        product {
          id
          internal {
            type
          }
          button {
              text
              type
              url
          }
          defaultQuantity
          disclaimerText
          image {
            id
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          maximumQuantity
          productId
          title
        }
    }
`
