import {
  FormRadio,
  FormSection,
  Text
} from '@simplisafe/ss-react-components'
import React, { CSSProperties } from 'react'

type ShippingOptionsProps = {
  readonly isMobile: boolean
  readonly shippingOptionsSectionTitle: string
  readonly shippingOptionsSectionText: string
  readonly shippingOptions: readonly { readonly localizedShippingName: string; readonly id: string }[]
}



const ShippingOptions: React.FC<ShippingOptionsProps> = (props: ShippingOptionsProps) => {
  const containerDesktopStyles: CSSProperties = { marginLeft: '170px' }
  const containerMobileStyles: CSSProperties = { marginLeft: '0px' }

  const containerStyles = (props.isMobile === false) ? containerDesktopStyles : containerMobileStyles

  return (
    <div data-component={'ShippingOptions'}>
      <FormSection name={props.shippingOptionsSectionTitle}>
        <div style={containerStyles}>
          <Text>{props.shippingOptionsSectionText}</Text>
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code */}
          {props.shippingOptions.map((option, i) => {
            const optionId = option.id
            const optionLabel = option.localizedShippingName
            return <FormRadio key={optionId} label={optionLabel}
              name='shippingOption' value={optionId} />
          })}
        </div>
      </FormSection>
    </div>
  )
}

export default ShippingOptions
