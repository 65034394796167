import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { RichTextWithButtons } from '@simplisafe/ss-react-components'
import { graphql, Link } from 'gatsby'
import React, { FC } from 'react'

import { ContentfulRichTextWithButtons } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import { ContentfulButtonProps } from '../ButtonComponent'
import RichText from '../RichText'

type RichTextWithButtonsComponentProps = {
  readonly data: Partial<ContentfulRichTextWithButtons>
  readonly onUrlFragmentClick?: () => void
}

export const onClickFragmentUrl = (url: string, onClick?: () => void) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  event.preventDefault()
  const scrollTimeout = onClick ? 350 : 0

  onClick && onClick()
  const button: HTMLButtonElement = event.currentTarget

  const anchorTarget = button.ownerDocument.getElementById(url.substr(1))
  // This sets a timeout because the onClick() call can cause the browser to scroll back to the original element if
  // the callback and the scrolling happen too close together
  anchorTarget && setTimeout(() => {
    anchorTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, scrollTimeout)
}

const RichTextWithButtonsComponent: FC<RichTextWithButtonsComponentProps> = ({ data, onUrlFragmentClick }: RichTextWithButtonsComponentProps) => {
  const buttonsData = prop('buttons', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulRichTextWithBu... Remove this comment to see the full error message
  const description = getDescriptionJson(data)
  const id = prop('id', data)

  return (
    <>
      <RichTextWithButtons
        buttons={buttonsData ? buttonsData.map((_button) => {
          const button = _button || {}
          const ButtonComponent = getMappedComponent<ContentfulButtonProps>(button)
          // TODO: fix type
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          const buttonUrl = prop('url', button) || ''
          // TODO: fix type
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          const isButtonComponent = path([ 'internal', 'type' ], button) === 'ContentfulButton'
          // TODO: fix type
          // @ts-expect-error TS(2339) FIXME: Property 'startsWith' does not exist on type 'neve... Remove this comment to see the full error message
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- legacy code
          const buttonHasFragmentUrl = buttonUrl.startsWith('#')

          // TODO: fix type
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          const linkText = prop('linkText', button)

          // TODO: fix type
          //TODO Ensure that the url is NOT an external url because the Gatsby <Link /> can only use internal urls.
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          const linkUrl = prop('linkUrl', button)

          return (
            <>
              { ButtonComponent && <ButtonComponent
                data={button}
                // TODO: fix type
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                key={prop('id', button)}
                // If we have a ButtonComponent and it has a hash url, we want to use its `onClick` prop to scroll to that anchor
                // tag and call the provided callback if it exists (currently used by ModalComponent to close the parent modal).
                onClick={isButtonComponent && buttonHasFragmentUrl ? onClickFragmentUrl(buttonUrl, onUrlFragmentClick) : undefined} /> }

              { !ButtonComponent && linkText && linkUrl && <Link
                // TODO: fix type
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                key={prop('id', button)}
                to={linkUrl}>
                {linkText}
              </Link> }
            </>
          )
        }) : []}

        description={<RichText json={description} />}
        key={id}
      />
    </>
  )
}

export default RichTextWithButtonsComponent

export const RichTextWithButtonsQuery = graphql`#graphql
  fragment contentfulRichTextWithButtonsFragment on ContentfulRichTextWithButtons {
    buttons {
      ... on ContentfulLink {
        id
        internal {
          type
        }
        linkSubtext
        linkText
        linkType
        linkUrl
      }

      ... on ContentfulButton {
        ...contentfulButtonFragment
      }

      ... on ContentfulLinkAddToCart {
        ...contentfulLinkAddToCart
      }
    }
    description {
      internal {
        type
      }
      json
    }
    id
    internal {
      type
    }
  }
`
