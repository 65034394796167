import { Document } from '@contentful/rich-text-types'
import { safePath, safeProp } from '@simplisafe/monda'
import { Record as ImmutableRecord  } from 'immutable'
import { None } from 'monet'
import propOr from 'ramda/src/propOr'
import { useMemo } from 'react'

import { Form2GUpgradeFragment } from '../../../graphql'
import { FluidImgProps } from '../FluidImg'

type FormField = {
  readonly label: string
  readonly placeholder: string
  readonly propName: string
}

function fn<T extends { readonly title?: string | null; readonly placeholderText?: string | null; readonly propName?: string | null } | null>(fields: readonly T[]): readonly FormField[] {
  return fields.map(field => {
    const fieldValue: FormField = {
      label: propOr<string, string>('', 'title', field),
      placeholder: propOr<string, string>('', 'placeholderText', field),
      propName: propOr<string, string>('', 'propName', field),
    }
    return fieldValue
  })
}

const emptyFormFields: readonly FormField[] = []

const useForm2gUpgradeFragment = (data: Form2GUpgradeFragment) =>
  useMemo(() => {
    const descriptionValue = safePath([ 'description', 'json' ], data)
    const fieldsDescriptionValue = propOr<string, string>('', 'fieldsDescription', data)
    const formFieldsValue: readonly FormField[] = safeProp('formFields', data)
      .map(fn)
      .getOrElse([])
    const infoModalImageValue = safeProp('sidNumberOverlayImage', data)
    const sidNumberOverlayCopyValue = safePath([ 'sidNumberOverlayCopy', 'json' ], data)
    const sidNumberOverlayTitleValue = propOr<string, string>('', 'sidNumberOverlayTitle', data)
    const submitButton = safeProp('submitButton', data).orUndefined()
    const submitButtonLabelValue = propOr<string, string>('', 'text', submitButton)
    const titleValue = propOr<string, string>('', 'title', data)

    return ImmutableRecord({
      // Defines the shape of the object and defaults.
      description: None<Document>(),
      fieldsDescription: '',
      formFields: emptyFormFields,
      infoModalImage: None<FluidImgProps>(),
      sidNumberOverlayCopy: None<Document>(),
      sidNumberOverlayTitle: '',
      submitButtonErrorMessage: '',
      submitButtonLabel: '',
      title: '',
      type: 'Form2GUpgradeFragment',
    })({
      // Redefines the object with values to apply over the defaults.
      description: descriptionValue,
      fieldsDescription: fieldsDescriptionValue,
      formFields: formFieldsValue,
      // @ts-expect-error
      infoModalImage: infoModalImageValue,
      sidNumberOverlayCopy: sidNumberOverlayCopyValue,
      sidNumberOverlayTitle: sidNumberOverlayTitleValue,
      submitButtonLabel: submitButtonLabelValue,
      title: titleValue,
      type: 'Form2GUpgradeFragment',
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

export default useForm2gUpgradeFragment
