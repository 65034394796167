import {
  FormField, FormTextInput, Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly emailFieldLabel: string
  readonly emailFieldPlaceholder: string
}

const Email: React.FC<Props> = ({ emailFieldLabel, emailFieldPlaceholder }: Props) => {
  return (
    <div className="my-7" data-component={'Email'}>
      <FormField hideLabel={true} label={emailFieldLabel}
        name="email">
        <Text fontWeight="medium">{emailFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          id="email"
          name="email"
          placeholder={emailFieldPlaceholder} />
      </FormField>
    </div>
  )
}

export default Email
