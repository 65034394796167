import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { VerticalScrollCarousel } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { ContentfulCarouselVerticalScrollAndBanner, ContentfulTwoColumn } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { TwoColumnBannerProps } from '../TwoColumnBanner'

export type VerticalScrollCarouselComponentProps = {
  readonly data: Partial<ContentfulCarouselVerticalScrollAndBanner>
}

const renderComponent = (data: ContentfulTwoColumn) => {
  const Component = getMappedComponent<TwoColumnBannerProps>(data)

  return Component ? (
    <Component
      data={data}
      key={prop('id', data)}
    />
  ) : null
}

const VerticalScrollCarouselComponent: FC<VerticalScrollCarouselComponentProps> = ({ data }: VerticalScrollCarouselComponentProps) => {
  const slides = propOr<readonly ContentfulTwoColumn[], readonly ContentfulTwoColumn[]>([], 'bannerVerticalCarousel', data).filter(isNotNil)
  const staticBanner = prop('bannerStatic', data)

  return (
    <VerticalScrollCarousel
      // id is prefixed to make sure it doesn't start with a number, otherwise the scrolling breaks
      id={`id-${prop('id', data)}`}
      staticBanner={staticBanner ? renderComponent(staticBanner) : null}
    >
      {slides.map(renderComponent)}
    </VerticalScrollCarousel>
  )
}

export default VerticalScrollCarouselComponent

export const query = graphql`#graphql
  fragment carouselVerticalScrollFragment on ContentfulCarouselVerticalScrollAndBanner {
    id
    internal {
      type
    }
    bannerVerticalCarousel {
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
    }
    bannerStatic {
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
    }
  }
`
