import { safeProp } from '@simplisafe/monda'
import { IncludedItem as IncludedItems } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { IncludedItemsFragment } from '../../../graphql'
import IncludedItemComponent from '../IncludedItemComponent'

export type IncludedItemsComponentProps = {
  readonly data: IncludedItemsFragment
}

const IncludedItemsComponent: FC<IncludedItemsComponentProps> = ({ data }: IncludedItemsComponentProps) => {

  const includedItems = safeProp('includedItems', data)
    .cata<readonly(JSX.Element | null)[] | null>(
      () => null,
    items => items.map((item): JSX.Element | null =>
      item ? <IncludedItemComponent
        data={item}
        key={safeProp('id', data).getOrElse('')}
      /> : null
    )
    )

  return includedItems ? <IncludedItems
    includedItems={includedItems}
    includedItemsAccentedTitleText={data.accentedHeadlineSection || ''}
    includedItemsTitle={safeProp('headline', data).getOrElse('')}
    key={safeProp('id', data).getOrElse('')}
    numColumnsDesktop={safeProp('numColumnsDesktop', data).cata(() => undefined, x => x)}
    numColumnsMobile={safeProp('numColumnsMobile', data).cata(() => undefined, x => x)}
    numColumnsTablet={safeProp('numColumnsTablet', data).cata(() => undefined, x => x)}
  /> : null
}

export default IncludedItemsComponent

export const IncludedItemsQuery = graphql`#graphql
  fragment includedItems on ContentfulIncludedItems {
    headline
    accentedHeadlineSection
    id 
    includedItems {
      ... on ContentfulIncludedItem {
        ...includedItem
      }
    }
    internal {
      type
    }
    numColumnsMobile,
    numColumnsTablet,
    numColumnsDesktop
  }
`
