import { FixedObject } from 'gatsby-image'
import React, { FC } from 'react'

import FixedImg from '../FixedImg'

type AdditionalTextIconProps = {
  readonly title: string
  readonly smallIconFixed?: FixedObject
  readonly className?: string
}

const AdditionalTextIcon: FC<AdditionalTextIconProps> = ({
  title, smallIconFixed, className
}) => (
  title && smallIconFixed ?
    <FixedImg
      alt={title}
      className={className}
      fixed={smallIconFixed} />
    : null
)

export default AdditionalTextIcon
