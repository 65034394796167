import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import React from 'react'

import { ContentfulButtonFragmentFragment } from '../../../graphql'
import { ContentfulButtonProps } from '../ButtonComponent'
import Button from '../ButtonComponent'


/**
 * Renders PartnerClaimNowClickTargetComponent applies specific styling for mobile
 * @see https://simplisafe.atlassian.net/browse/ECP-4422
 * @param props
 * @returns
 */
export const PartnerClaimNowClickTargetComponent = (props: ContentfulButtonProps) => {
  const isMobile = !useMediaQuery('TabletAndUp')
  return <Button {...props} style={ isMobile ? {
    paddingLeft: 'revert',
    paddingRight: 'revert',
    whiteSpace: 'nowrap',
    width: '100%'
  } : {}}/>
}

/**
 * Check if we should map a contentful button component to  PartnerClaimNowClickTargetComponent
 * @param contentfulComponent
 * @returns PartnerClaimNowClickTargetComponent or false
 */
export function mapToPartnerClaimNowClickTargetComponent(contentfulComponent: Partial<ContentfulButtonFragmentFragment>){
  if (contentfulComponent.internal?.type === 'ContentfulButton' && contentfulComponent.buttonText === 'Claim Now'){
    return PartnerClaimNowClickTargetComponent
  } else {
    return false
  }

}
