import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath } from '@simplisafe/monda'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { SmallBannerItem } from '@simplisafe/ss-react-components'
import { DefaultBanner } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulDefaultBanner, ContentfulIconWithText } from '../../../graphql'
import getJson from '../../util/getJson'
import FluidImg from '../FluidImg'
import RichText from '../RichText'


type DefaultBannerComponentProps = {
  readonly data: ContentfulDefaultBanner
}

type ContentfulIcon = ContentfulIconWithText | undefined;

const renderDefaultBanner = (bannerDetails: readonly (ContentfulIcon)[]) => {
  return bannerDetails.map((bannerdata, i) => (
    <SmallBannerItem
      icon={renderImgIcon(bannerdata)}
      isDefaultBanner={true}
      key={`bannerId-${i}`}
      text={<RichText json={getJson(prop('childContentfulIconWithTextTextRichTextNode', bannerdata))} />}
    />
  ))
}

const renderImgIcon = (bannerData: ContentfulIcon) => {
  return <FluidImg alt={path([ 'icon', 'title' ], bannerData)}
    // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
    fluid={path([ 'icon', 'fluid' ], bannerData)}
    style={{ width: '75px' }}/>
}

const DefaultBannerComponent: FC<DefaultBannerComponentProps> =
  ({ data }: DefaultBannerComponentProps) => {
    const bannerDetails = propOr<readonly ContentfulIconWithText[], readonly ContentfulIconWithText[]>([], 'sectionContent', data).filter(isNotNil)
    const desktopShowInHeader = safePath([ 'desktopShowInHeader' ], data).orJust(false)
    const isPromoTopBanner = useSelector(selectTopBannerVisible)

    return Maybe.fromNull(bannerDetails)
      // Filter out an empty array.
      .filter(bannerProp => !isEmpty(bannerProp))
      // If null or undefined, always return null.
      .cata(
        () => null,
        bannerProp => {
          return ifElse(
            equals(true),
            always(
              <DefaultBanner
                banner={renderDefaultBanner(bannerProp)}
                showVerticalBanner={true}
              />
            ),
            () => null
          )(!isPromoTopBanner && desktopShowInHeader)
        }
      )
  }

export const query = graphql`
  fragment defaultBannerFragment on ContentfulDefaultBanner {
    id
    desktopShowInHeader
    mobileCarouselDuration
    sectionContent {
      textAlignment
      textPosition
      title
      iconPosition
      icon {
        file {
          contentType
          fileName
          url
        }
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      childContentfulIconWithTextTextRichTextNode {
        json
        nodeType
      }
    }
  }
`

export default DefaultBannerComponent
