
import prop from '@simplisafe/ewok/ramda/prop'
import { IconDropDown } from '@simplisafe/ss-react-components'
import { Profile } from '@simplisafe/ss-react-components/icons'
import React, { FC, ReactElement } from 'react'

import { linkToListItem } from './helpers'
import { ContentfulAccountNavFragment, } from './query'

export const HeaderAccountNav: FC<{ readonly item: ContentfulAccountNavFragment }> = ({ item }): ReactElement => {
  const login = prop('loginLink', item)
  const register = prop('registerLink', item)
  return <IconDropDown a11yLabel="account menu"
    key={prop('id', item)}
    listItem={[ linkToListItem(login), linkToListItem(register) ].filter(Boolean)}
    srcIcon={<Profile />}
  />
}
