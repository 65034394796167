import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

  type SimpleTextProps = {
    readonly label: string
    readonly placeholder: string
    readonly name: string
    readonly type: string
  }

const SimpleText: React.FC<SimpleTextProps> = (props: SimpleTextProps) => {
  const {
    name,
    placeholder,
    label,
    type
  } = props

  return (
    <div data-component={'SimpleText'}>
      <FormField hideLabel={true} label={label}
        name={name}>
        <Text fontWeight='medium'>{label}</Text>
        <FormTextInput
          fullWidth={true}
          id={name}
          name={name}
          placeholder={placeholder}
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"text" | ... Remove this comment to see the full error message
          type={type}
        />
      </FormField>
    </div>
  )
}

export default SimpleText
