"use strict";
exports.__esModule = true;
exports.parseFluidImg = exports.fluidImgSchema = void 0;
var zod_1 = require("zod");
exports.fluidImgSchema = zod_1.z.object({
    aspectRatio: zod_1.z.number(),
    base64: zod_1.z.string().optional(),
    media: zod_1.z.string().optional(),
    sizes: zod_1.z.string(),
    src: zod_1.z.string(),
    srcSet: zod_1.z.string(),
    srcSetWebp: zod_1.z.string().optional(),
    srcWebp: zod_1.z.string().optional(),
    tracedSVG: zod_1.z.string().optional()
});
/**
* Parse raw a raw contentful FixedObject and remove null/undefiend values.
*/
var parseFluidImg = function (fluid) {
    return exports.fluidImgSchema.parse(fluid);
};
exports.parseFluidImg = parseFluidImg;
