import { FluidObject } from 'gatsby-image'
import { isEmpty } from 'ramda'
import path from 'ramda/src/path'

import { ContentfulHeroBannerColumn } from '../../../graphql'

const emptyImage = {
  aspectRatio: 1,
  sizes: '',
  src: '',
  srcSet: ''
}

export const useHeroColumnImage = (column: ContentfulHeroBannerColumn) => {
  const imageMobile = path<FluidObject>([ 'imageMobile', 'fluid' ], column)
  const imageTablet = path<FluidObject>([ 'imageTablet', 'fluid' ], column)
  const imageDesktop = path<FluidObject>([ 'image', 'fluid' ], column)

  const images = [
    imageDesktop || imageTablet || imageMobile,
    imageTablet ? {
      ...imageTablet,
      media: '(max-width: 1239px)'
    } : null,
    imageMobile ? {
      ...imageMobile,
      media: '(max-width: 767px)'
    } : null
  ].filter((el): el is FluidObject => !!el)

  return { images: !isEmpty(images) ? images : emptyImage }
}
