import {
  FormField,
  FormTextInput,
  Text,
} from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly phoneFieldLabel: string
  readonly phoneFieldPlaceholder: string
};

const Phone: React.FC<Props> = ({ phoneFieldLabel, phoneFieldPlaceholder }: Props) => {
  return (
    <div className="my-7" data-component={'Phone'}>
      <FormField hideLabel={true} label={phoneFieldLabel}
        name="phone">
        <Text fontWeight="medium">{phoneFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          id="phone"
          name="phone"
          placeholder={phoneFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Phone
