import { visitorIdAtAt } from '@lib/tracking/src/atat'
import { useOptimizelyActivateExperiment, userAttributes } from '@lib/tracking/src/optimizely'
import { OptimizelyContext } from '@optimizely/react-sdk'
import prop from '@simplisafe/ewok/ramda/prop'
import { liftSelectProduct, selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import T from 'ramda/src/T'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

// Optimizely Experiment and Feature IDs.
const UK_PRICING_EXPERIMENT_ID = 'uk_prices_test'
const US_PRICING_EXPERIMENT_ID = 'us_prices_test'
const UK_PRICING_FEATURE_ID = 'uk_product_prices'
const US_PRICING_FEATURE_ID = 'us_product_prices'

const getExperimentId = cond([
  [ equals('en-GB'), always(UK_PRICING_EXPERIMENT_ID) ],
  [ equals('en-US'), always(US_PRICING_EXPERIMENT_ID) ],
  [ T, always('') ]
])

const getFeatureId = cond([
  [ equals('en-GB'), always(UK_PRICING_FEATURE_ID) ],
  [ equals('en-US'), always(US_PRICING_FEATURE_ID) ],
  [ T, always('') ]
])

const usePriceVariation = (sku: string) => {
  // Get the UK or US optimizely experiment and feature pricing.
  const siteLocale = useSelector(selectLocale)
  const experimentId = getExperimentId(siteLocale)
  const featureId = getFeatureId(siteLocale)

  // Retrieve the product from redux, and conditionally override its price.
  const product = useSelector(liftSelectProduct(
    // Filter out empty strings.
    Maybe.of(sku).filter(x => !isEmpty(x))
  ))
  const userId = visitorIdAtAt()
  const attributes = userAttributes()

  const { optimizely } = useContext(OptimizelyContext)
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  // Activate the experiment and retrieve this variation's variables.
  return Maybe.fromNull(optimizely).cata(
    () => product,
    _optimizely => {
      optimizelyActivateExperiment({ experimentId })
      const isFeatureEnabled = _optimizely.isFeatureEnabled(featureId, userId, attributes)
      const variables = _optimizely.getFeatureVariables(featureId, userId, attributes)
      const optimizelyPrice = prop(sku, variables)
      const hasOptimizelyPrice = !!optimizelyPrice

      return isFeatureEnabled && hasOptimizelyPrice ? product.map(p => ({
        ...p,
        price: Number(optimizelyPrice)
      })) : product
    }
  )
}

export default usePriceVariation
