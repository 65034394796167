import React, { FC } from 'react'

import ReviewBlock from './ReviewBlock'
import { ReviewGridComponentProps } from './types'

const ContentfulReviewGridComponent: FC<ReviewGridComponentProps> = ({ data }) => {
  const {
    rightContent, leftContent, rowOrderParity
  } = data
  const dataComponent = 'ReviewGrid'
  return (
    <section className="grid gap-4 md:grid-cols-2" data-component={dataComponent}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'Maybe<Maybe<ContentfulIconWithText>[]> | und... Remove this comment to see the full error message */}
      <ReviewBlock className={rowOrderParity ? 'order-1 md:order-first' : ''} content={leftContent}
        key='review_block_1' />
      {/* @ts-expect-error TS(2322) FIXME: Type 'Maybe<Maybe<ContentfulIconWithText>[]> | und... Remove this comment to see the full error message */}
      <ReviewBlock content={rightContent} key='review_block_2' />
    </section>
  )
}

export default React.memo(ContentfulReviewGridComponent)
