import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import type { SmallTextSectionProps } from '@simplisafe/ss-react-components/SmallTextSection'
import { graphql } from 'gatsby'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import trim from 'ramda/src/trim'
import React, { FC } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulSmallTextSection } from '../../../graphql'
import { TrackEvent } from '../../util/analytics'
import {
  nullToUndefined, strToCamelCase, toFirstCharLower
} from '../../util/helper'
import { PageProps } from '../Page'


// getClickableBannerEvent bannerClickEvent are temporary solutions
// to capture click tracking events for bofu optimizely experiments:
// All | Home, BMS, Shop | Affirm Banner
// All | Cart | Cart Confidence Bar Banners

export const getClickableBannerEvent = (contentfulId: string) => {
  const clickableBanners = {
    '0% Affirm banner': 'clickAffirmBanner',
    'Affirm card content': 'clickConfidenceBarBanner',
    'Buy now Affirm Banner': 'clickAffirmBanner',
    'Customer Service Card': 'clickConfidenceBarBanner',
    'Try It Test It Card': 'clickConfidenceBarBanner'
  }
  const map = new Map(Object.entries(clickableBanners))
  return map.get(contentfulId) || ''
}

export const bannerClickEvent = (trackEvent: TrackEvent, contentfulId: string) => {
  trackEvent({
    action: 'click-banner',
    category: 'banner',
    event: getClickableBannerEvent(contentfulId),
    label: contentfulId
  })
}

type ContentfulSmallTextSectionProps = {
  readonly id: string
  readonly data: Partial<ContentfulSmallTextSection>
  readonly pageContext: PageProps['pageContext']
}

export const toUiTheme =
  (data: Partial<ContentfulSmallTextSection>) =>
    safeProp('uiTheme', data)
      .map(toFirstCharLower)
      .map(split(':'))
      .map(x => x.map(trim))
      .map(join(''))
      .orUndefined()

const toBackgroundColor =
  (data: Partial<ContentfulSmallTextSection>) =>
    safeProp('backgroundColor', data)
      .map(strToCamelCase)
      .getOrElse('none')

const toShadowBorder =
      (data: Partial<ContentfulSmallTextSection>) =>
        safeProp('shadowBorder', data)
          .map(strToCamelCase)
          .orUndefined()

const totextColor = pipe(path([ 'textColor', 'color' ]), nullToUndefined)
const toSmallTextSectionData = (data: Partial<ContentfulSmallTextSection>, showInMobile: boolean): SmallTextSectionProps => {
  return applySpec<SmallTextSectionProps>({
    backgroundColor: toBackgroundColor,
    borderLine: pipe(prop('borderLine'), nullToUndefined),
    description: ifElse(
      equals(true), always(path( [ 'descriptionMobile', 'json' ])), always(path( [ 'description', 'json' ]))
    )(showInMobile && !isNil(prop('descriptionMobile', data))),
    listStyle: pipe(prop('listStyle'), nullToUndefined),
    otherDeviceVisibility: pipe(prop('otherDeviceVisibility'), nullToUndefined),
    shadowBorder: toShadowBorder,
    sidePadding: pipe(prop('sidePadding'), nullToUndefined),
    textColor: totextColor,
    theme: toUiTheme,
    verticalPadding: pipe(prop('verticalPadding'), nullToUndefined)
  })(data)
}

const SmallTextSections: FC<ContentfulSmallTextSectionProps> =
  ({ data, pageContext }: ContentfulSmallTextSectionProps) => {
    const { trackEvent } = useTracking()
    const showInMobile = !useMediaQuery('TabletAndUp')
    const contentfulTitle = prop('title', data) || ''
    const clickEvent: string = getClickableBannerEvent(contentfulTitle)

    const props = {
      ...toSmallTextSectionData(data, showInMobile),
      pageContext
    }
    return (
      !isEmpty(clickEvent) ?
        <div onClick={() => { bannerClickEvent(trackEvent, contentfulTitle) }}>
          <SmallTextSection {...props} />
        </div>
        : <SmallTextSection {...props} />
    )
  }

export default SmallTextSections

export const query = graphql`#graphql
    fragment smallTextSectionFragment on ContentfulSmallTextSection {
        id
        internal {
          type
        }
        title
        description {
          json
        }
        descriptionMobile {
          json
        }
        uiTheme
        backgroundColor
        textColor {
          color
        }
        otherDeviceVisibility
        sidePadding,
        verticalPadding,
        listStyle
        height
        borderLine
        shadowBorder
    }
`
