import { Document } from '@contentful/rich-text-types'
import localizeCent from '@simplisafe/ewok/currency-utils/localizeCent'
import { Maybe } from 'monet'

import { formatDisplayPrice } from '../commercetools/price'
import { locale } from '../commercetools/utils'
import documentFallback from './documentFallback'

export const regexPricePerDay = /(?:{{price_per_day}})/g
export const regexPricePerMonth = /(?:{{price_per_month}})/g

export const replaceTokensWithPrice = (text: string, price: number): string =>

  text.replace(regexPricePerDay, `${localizeCent(locale, price)}/day`)
    .replace(regexPricePerMonth, `${formatDisplayPrice(price).orUndefined()} per month`)

export const getTokenizedJson = (json: Document, price: number): Document => {
  const jsonStringified = Maybe.fromNull(json)
    .chain(json => Maybe.of(JSON.stringify(json)))
    .getOrElse('')
  const tokenizedString = replaceTokensWithPrice(jsonStringified, price)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  return documentFallback(JSON.parse(tokenizedString))
}
