"use strict";
exports.__esModule = true;
exports.parseRichText = exports.documentSchema = void 0;
var rich_text_types_1 = require("@contentful/rich-text-types");
var zod_1 = require("zod");
var nodeSchema = zod_1.z.object({
    data: zod_1.z.record(zod_1.z.string(), zod_1.z.any()),
    nodeType: zod_1.z.string()
});
var markSchema = zod_1.z.object({ type: zod_1.z.string() });
var textSchema = nodeSchema.extend({
    marks: zod_1.z.array(markSchema),
    nodeType: zod_1.z.literal('text'),
    value: zod_1.z.string()
});
var inlinesEnum = zod_1.z.nativeEnum(rich_text_types_1.INLINES);
var inlineSchema = zod_1.z.lazy(function () { return nodeSchema.extend({
    content: zod_1.z.array(zod_1.z.union([textSchema, inlineSchema])),
    nodeType: inlinesEnum
}); });
var blocksEnum = zod_1.z.nativeEnum(rich_text_types_1.BLOCKS);
var blockSchema = zod_1.z.lazy(function () { return nodeSchema.extend({
    content: zod_1.z.array(zod_1.z.union([blockSchema, inlineSchema, textSchema])),
    nodeType: blocksEnum
}); });
var topLevelBlockEnum = zod_1.z["enum"]([
    rich_text_types_1.BLOCKS.PARAGRAPH,
    rich_text_types_1.BLOCKS.HEADING_1,
    rich_text_types_1.BLOCKS.HEADING_2,
    rich_text_types_1.BLOCKS.HEADING_3,
    rich_text_types_1.BLOCKS.HEADING_4,
    rich_text_types_1.BLOCKS.HEADING_5,
    rich_text_types_1.BLOCKS.HEADING_6,
    rich_text_types_1.BLOCKS.OL_LIST,
    rich_text_types_1.BLOCKS.UL_LIST,
    rich_text_types_1.BLOCKS.HR,
    rich_text_types_1.BLOCKS.QUOTE,
    rich_text_types_1.BLOCKS.EMBEDDED_ENTRY,
    rich_text_types_1.BLOCKS.EMBEDDED_ASSET,
]);
// this was supposed to extend blockSchema but it introduces type ambiguity since blockSchema is lazy
var topLevelBlockSchema = nodeSchema.extend({
    content: zod_1.z.array(zod_1.z.union([blockSchema, inlineSchema, textSchema])),
    nodeType: topLevelBlockEnum
});
exports.documentSchema = nodeSchema.extend({
    content: zod_1.z.array(topLevelBlockSchema),
    nodeType: zod_1.z.literal(rich_text_types_1.BLOCKS.DOCUMENT)
});
var parseRichText = function (richText) { return exports.documentSchema.parse(richText); };
exports.parseRichText = parseRichText;
