import { FormField, Heading } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type ContactFormDescriptionProps = {
  readonly contactFormDescription?: ReactElement
  readonly formTitle?: string
}

const Description: React.FC<ContactFormDescriptionProps> = (props: ContactFormDescriptionProps) => {
  const formTitle = props.formTitle
  return (
    <div data-component={'Description'}>
      <FormField hideLabel={true} name='contactFormDescription'>
        {
          formTitle &&
          <Heading
            element='h2'
            headingAspect='h3'
            margin='extraSmall'
            useTailwind>
            {formTitle}
          </Heading>
        }
        {props.contactFormDescription}
      </FormField>
    </div>
  )
}

export default Description
