import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type StreetAddress2Props = {
  readonly label: string
  readonly placeholder: string
}

const StreetAddres2: React.FC<StreetAddress2Props> = (props: StreetAddress2Props) => {
  return (
    <div data-component={'StreetAddress2'}>
      <FormField hideLabel={true} name='streetAddress2'>
        <Text fontWeight='medium'>{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name='streetAddress2'
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default StreetAddres2
