import prop from '@simplisafe/ewok/ramda/prop'
import { RichText } from '@simplisafe/ss-react-components'
import { BlogArticle } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { ContentfulBlogArticle } from '../../../graphql'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import FacebookPostComponent from '../FacebookPostComponent'
import { PageProps } from '../Page'


type BlogArticleComponentProps = {
  readonly data: Partial<ContentfulBlogArticle>
  readonly pageContext: PageProps['pageContext']
}
const BlogArticleComponent: FC<BlogArticleComponentProps> = ({ data, pageContext }: BlogArticleComponentProps) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulBlogArticle>' ... Remove this comment to see the full error message
  const description = getDescriptionJson(data)
  const lastPostedText = getJson(prop('lastPostedText', data))
  const title = prop('title', data)


  const content = <RichText json={description} />

  const lastPostedContent = <RichText json={lastPostedText} />

  return doesDocumentHaveContent(description) && doesDocumentHaveContent(lastPostedText) && title ? (
    <>
      <BlogArticle
        content={content}
        lastPostedContent={lastPostedContent}
        title={title}
      />
      <FacebookPostComponent description={description}
        pageContext={pageContext}></FacebookPostComponent>
    </>
  ) : null
}
export default BlogArticleComponent
export const BlogArticleQuery = graphql`#graphql
  fragment contentfulBlogArticle on ContentfulBlogArticle {
    description {
      json
    }
    id
    internal {
      type
    }
    lastPostedText {
      json
    }
    title
  }
`
