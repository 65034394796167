import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { FixedObject } from 'gatsby-image'
import { Either } from 'monet'
import { T } from 'ramda'
import propOr from 'ramda/src/propOr'

import { AddExtraSensorsFragment } from '../../../graphql'
import { AddExtraSensorsDefaultProps } from './types'

export const getProductId = (data: AddExtraSensorsFragment) => safeProp('productId', data)
export const getIsSellable = (product: Either<Error, Product>): boolean =>
  product.cata(T, (p) =>
    prop('isSellable', p) ? true : false
  )

export const getIsOnStock = (product: Either<Error, Product>): boolean => product.cata(T, p => prop('isOnStock', p) ? true : false)
export const getHeaderText = (data: AddExtraSensorsFragment) => prop('headerText', data)
export const getCartUpdateText = (data: AddExtraSensorsFragment) => path([ 'cartUpdatedText', 'text', 'text' ], data)
export const getMaximumQuantity = (data: AddExtraSensorsFragment) => propOr<number, number>(0, 'maximumQuantity', data)
export const getOutOfStockButtonText = (data: AddExtraSensorsFragment) =>  prop('outOfStockButtonText', data)
export const getOutOfStockText = (data: AddExtraSensorsFragment) => prop('outOfStockText', data)
export const getDefaultQuantity = (data: AddExtraSensorsFragment) => propOr<number, number>(0, 'defaultQuantity', data)
export const getAdditionalIconTitle = (data: AddExtraSensorsFragment) => path([ 'note', 'icon', 'title' ], data)
export const getAdditionalIconFixedProp = (data: AddExtraSensorsFragment): FixedObject | undefined => {
  const icon = path([ 'note', 'icon', 'smallIconFixed' ], data)

  // This validation was added to convert GatsbyContentfulFixed_WithWebp_NoBase64Fragment to FixedObject
  return icon ? {
    ...icon,
    srcSetWebp: prop('srcSetWebp', icon),
    srcWebp: prop('srcWebp', icon)
  } : undefined
}

export const getDefaultProps = (data: AddExtraSensorsFragment) => transformObject<AddExtraSensorsFragment, AddExtraSensorsDefaultProps>({
  additionalIconFixedProp: getAdditionalIconFixedProp,
  additionalIconTitle: getAdditionalIconTitle,
  cartUpdatedText: getCartUpdateText,
  defaultQuantity: getDefaultQuantity,
  headerText: getHeaderText,
  maximumQuantity: getMaximumQuantity,
  outOfStockButtonText: getOutOfStockButtonText,
  outOfStockText: getOutOfStockText,
  sku: getProductId
})(data)
