import {
  FormCheckbox,
  FormSection,
  FormSelect,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React, { CSSProperties } from 'react'

type CustomerServiceProps = {
  readonly additionDetailsFieldLabel: string
  readonly additionalDetailsFieldPlaceholder: string
  readonly epsilonAbacusOptInLabel: string
  readonly foundInfoThrough: readonly string[]
  readonly foundInfoThroughPlaceholder: string
  readonly isMobile: boolean
  readonly locale: string
  readonly sourceFieldLabel: string
  readonly sourceSectionText: string
  readonly sourceSectionTitle: string
}

const CustomerService: React.FC<CustomerServiceProps> = (props: CustomerServiceProps) => {

  const textClassName: string = props.isMobile ? 'screenReaderText' : ''

  const containerDesktopStyles: CSSProperties = { marginLeft: '170px' }
  const containerMobileStyles: CSSProperties = { marginLeft: '0px' }

  const desktopGridStyles: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    gap: '20px',
    marginBottom:'12px',
    marginTop:'12px'
  }
  const mobileGridStyles: CSSProperties = { display: 'block', }

  const gridStyles = (props.isMobile === false) ? desktopGridStyles : mobileGridStyles
  const containerStyles = (props.isMobile === false) ? containerDesktopStyles : containerMobileStyles


  return (
    <div data-component={'CustomerService'}>
      <FormSection name={props.sourceSectionTitle}>
        <div style={containerStyles}>
          {!props.isMobile ? <Text>{props.sourceSectionText}</Text> : null}
          <div style={gridStyles}>
            {!props.isMobile ?
              <FormSelect
                label={props.sourceFieldLabel}
                name='foundInfoThrough' options={props.foundInfoThrough}
                placeholder={props.foundInfoThroughPlaceholder}/>
              :
              <FormSelect
                classNameLabel='screenReaderText'
                fullWidth={props.isMobile}
                label={props.sourceFieldLabel}
                name='foundInfoThrough' options={props.foundInfoThrough}
                placeholder={props.foundInfoThroughPlaceholder}/>}
          </div>
          <div style={gridStyles}>
            {<Text className={textClassName} fontWeight='medium'>
              <label htmlFor={'additionalFoundInfoThrough'}>{props.additionDetailsFieldLabel}</label>
            </Text>}
            <FormTextInput fullWidth={props.isMobile} id='additionalFoundInfoThrough'
              name='additionalFoundInfoThrough' placeholder={props.additionalDetailsFieldPlaceholder}/>
          </div>
          {props.locale && props.locale === 'en-GB' ?
            <div style={{ marginTop: '15px' }}>
              <FormCheckbox
                label={props.epsilonAbacusOptInLabel}
                name='epsilonAbacusOptIn'
              />
            </div>

            : null }
        </div>
      </FormSection>
    </div>
  )
}

export default CustomerService

