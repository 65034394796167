import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Modal } from '@simplisafe/ss-react-components'
import { ModalStyle } from '@simplisafe/ss-react-components/Modal'
import { graphql } from 'gatsby'
import { Maybe } from 'monet/dist/monet'
import React, {
  FC,
  ReactNode,
  useState
} from 'react'

import { ContentfulButtonContentfulSmallTextSectionUnion, ModalFragmentFragment } from '../../../graphql'
import { toModalSize } from '../../attributeMappings'
import { ContentfulComponent, getMappedComponent } from '../../componentMappings'
import { mapToPartnerClaimNowClickTargetComponent } from '../PartnerClaimNowClickTargetComponent'

export type ModalComponentProps = {
  /* Click Target and Model Content data from Contentful, Click target field is optional in Contentful */
  // Once other components using ModalComponent are changed to not use Partial<> types anymore,
  // this should be changed to just ModalFragmentFragment
  readonly data?: Omit<ModalFragmentFragment, 'clickTarget' | 'id' | 'internal'> & {
    readonly internal?: ModalFragmentFragment['internal']
    readonly id?: ModalFragmentFragment['id']
    readonly clickTarget?: ContentfulButtonContentfulSmallTextSectionUnion | ModalFragmentFragment['clickTarget']
  }
  /* Click target component can be passed in here, instead of setting it in Contentful */
  readonly clickTarget?: ReactNode
  /** Allows default styles on the clickTarget to be overridden. */
  readonly clickTargetStyles?: React.CSSProperties
  /* Modal content component can be passed in here, instead of setting it in Contentful */
  readonly modalContent?: ReactNode
  /** custom style for modal */
  readonly style?: ModalStyle
  /* External click to be called when the button is clicked */
  readonly onClick?: () => void
  /* Size of modal */
  readonly size?: 'large' | 'medium' | 'small'
}

const externalClick = (onClick?: () => void) => { onClick && onClick() }

const isRichTextWithButtons = <T extends ContentfulComponent>(data: T) => !!data && path([ 'internal', 'type' ], data) === 'ContentfulRichTextWithButtons'

type ClickTargetComponentProps = {
  readonly data: ContentfulButtonContentfulSmallTextSectionUnion | ModalFragmentFragment['clickTarget']
  readonly disabled: boolean
}

type ContentComponentProps = {
  readonly data: ModalFragmentFragment['modalContent']
  readonly closeModal: () => void
  readonly onUrlFragmentClick?: () => void
  readonly columnType?: string
  readonly onSuccess?: () => void
}

const ModalComponent: FC<ModalComponentProps> = ({
  data: dataProp,
  clickTarget,
  clickTargetStyles,
  modalContent: modalContentProp,
  style,
  onClick,
  size
}: ModalComponentProps) => {
  const data = dataProp || {}
  const clickTargetContent = prop('clickTarget', data)
  const modalContent = prop('modalContent', data)
  const [ isOpen, setIsOpen ] = useState(false)
  const ClickTargetComponent = clickTargetContent && (mapToPartnerClaimNowClickTargetComponent(clickTargetContent) || getMappedComponent<ClickTargetComponentProps>(clickTargetContent))
  const disableClickTargetOnSuccess = prop('disableClickTargetOnSuccess', data)
  const [ clickTargetDisabled, setClickTargetDisabled ] = useState<boolean>(false)

  const ContentComponent = modalContent && getMappedComponent<ContentComponentProps>(modalContent)
  const clickTargetIsSet = !!(ClickTargetComponent || clickTarget)
  const modalContentIsSet = !!(ContentComponent || modalContentProp)
  const modalSize = size ? toModalSize(size) : toModalSize(prop('modalSize', data))
  const closeModal = () => setIsOpen(false)

  const handleSuccess = () => { disableClickTargetOnSuccess && setClickTargetDisabled(true) }

  return (clickTargetIsSet && modalContentIsSet) ? (
    //TODO apply click event to ClickTargetComponent
    <>
      <span
        className="clickTarget"
        data-component="click-target"
        onClick={(e) => {
          e.preventDefault()
          externalClick(onClick)
          !clickTargetDisabled && setIsOpen(true)
        }}
        style={{
          alignItems: 'center',
          display: 'inline-flex',
          ...clickTargetStyles
        }}>
        {/* @ts-expect-error TS(2604) FIXME: JSX element type 'ClickTargetComponent' does not h... Remove this comment to see the full error message */}
        {clickTarget ? clickTarget : <ClickTargetComponent data={clickTargetContent} disabled={clickTargetDisabled} />}
      </span>
      <Modal
        appElementId='___gatsby'
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={modalSize}
        style={style}
      >
        {modalContentProp ? modalContentProp : (
          // @ts-expect-error TS(2604) FIXME: JSX element type 'ContentComponent' does not have ... Remove this comment to see the full error message
          <ContentComponent
            closeModal={closeModal}
            columnType='none'
            data={modalContent}
            // Prop is specific to RichTextWithButtonsComponent
            onSuccess={handleSuccess}
            onUrlFragmentClick={Maybe.fromNull(modalContent)
              .filter(isRichTextWithButtons)
              .map(() => closeModal)
              .orUndefined()}
          />
        )}
      </Modal>
    </>
  ) : null
}

export default ModalComponent

export const query = graphql`#graphql
  fragment modalFragment on ContentfulModal {
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulModalSensorAdditionToSystem {
        ...addExtraSensors
      }
      ... on ContentfulRichTextWithButtons {
        ...contentfulRichTextWithButtonsFragment
      }
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
      ... on ContentfulPartnerCaptureForm {
        ...contentfulPartnerCaptureForm
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulBanner {
        ...contentfulBanner
      }
      ... on ContentfulPdpPackageSensorEdit {
        ...contentfulPackageSensorEditFragment
      }
    }
    modalSize
    disableClickTargetOnSuccess
  }

  # this fragment has been made to support a scenario where adding modalFragment to
  # contentfulTwoColumnFragment resulted in a recursive graphql error since 
  # modalFragment has twoColumnBanner as potential content in contentful
  fragment secondaryModalFragment on ContentfulModal {
    clickTarget {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    id
    internal {
      type
    }
    modalContent {
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
    }
    modalSize
  }
`
