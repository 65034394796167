import React, { FC, HTMLAttributes } from 'react'

import { shouldCardSpan } from './helpers'
import ReviewCard from './ReviewCard'
import { ReviewBlockProps } from './types'

const ReviewBlock: FC<HTMLAttributes<HTMLDivElement> & ReviewBlockProps> = ({ content = [], className = '' }) => {
  return (
    <div className={`grid grid-cols-2 gap-4 ${className}`}>
      {content.map((card, index, cards) => {
        return (
          <ReviewCard
            className={shouldCardSpan(index, cards.length) ? 'col-span-2' : ''}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulAsset> | undefined' is not a... Remove this comment to see the full error message
            icon={card.icon}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
            iconPosition={card.iconPosition}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
            iconSize={card.iconSize}
            key={`review_card_${card.id}`}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulIconWithTextTextRichTextNode... Remove this comment to see the full error message
            text={card.text}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
            textPosition={card.textPosition}
            // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
            title={card.title} />

        )
      })}
    </div>
  )
}

export default ReviewBlock
