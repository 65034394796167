import { INLINES } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import { RichText as _RichText } from '@simplisafe/ss-react-components'
import type { RichTextProps as _RichTextProps } from '@simplisafe/ss-react-components/RichText'
import pathOr from 'ramda/src/pathOr'
import React, { FC } from 'react'

import {
  renderAssetHyperlink, renderEmbeddedEntry, renderModalVideoHyperlink,
  renderSectionId
} from './embeddedAssets'

export type RichTextProps = _RichTextProps

const options: _RichTextProps['options'] = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = pathOr<string, string>('', [ 'data', 'uri' ], node)
      const title = pathOr<string, string>('', [ '0' ], children || {})

      return uri.includes('youtube') ? renderModalVideoHyperlink(title, uri, children) : <a className='link neutralBlackTextColor'
        href={uri}>{children}</a>
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return renderAssetHyperlink(node, children)
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      const referenceId = path([ 'data', 'target', 'fields', 'referenceId' ], node) as string
      return referenceId ? renderSectionId(node, referenceId) : renderEmbeddedEntry(node)
    },
  }
}

/**
 * @deprecated Use `ContentfulRichText` instead.
 *
 * This component should not be added to, changed, or used for anything.
 */
const RichText: FC<RichTextProps> = (props: RichTextProps) => (

  <_RichText
    options={options}
    textColor='neutralBlack'
    {...props}
  />
)


export default RichText
