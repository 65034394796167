import { OptimizelyContext } from '@optimizely/react-sdk'
import { Maybe } from 'monet'
import { useContext } from 'react'

export const useExperimentVariation = (experimentId: string) => {
  const { optimizely } = useContext(OptimizelyContext)

  return Maybe.fromNull(optimizely)
    .chain(_optimizely => Maybe.fromNull(_optimizely.activate(experimentId)))
    .getOrElse('')
}
