import prop from '@simplisafe/ewok/ramda/prop'
import { safePath } from '@simplisafe/monda'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { RichText, SSButton } from '@simplisafe/ss-react-components'
import { SensorProductBanner } from '@simplisafe/ss-react-components'
import { ButtonColor } from '@simplisafe/ss-react-components/SSButton'
import { graphql, Link } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulProductPageHeroSensor } from '../../../graphql'
import { renderOutOfStockMessage  } from '../../commercetools/outOfStock'
import getDescriptionJson from '../../util/getDescriptionJson'
import { leadingSlashIt, strToCamelCase } from '../../util/helper'
import FluidImg from '../FluidImg'
import ModalComponent from '../ModalComponent'

export type SensorProductBannerComponentProps = {
  readonly data: Partial<ContentfulProductPageHeroSensor>
}

const SensorProductBannerComponent: FC<SensorProductBannerComponentProps> = ({ data }: SensorProductBannerComponentProps) => {
  // TODO: fix type
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const image = prop('media', data)
  const addSensorToCartModal = prop('addSensorToCartModal', data)
  const buttonUrl = leadingSlashIt(pathOr<string, string>('', [ 'button', 'url' ], data))
  const buttonColor = strToCamelCase(pathOr('', [ 'button', 'type' ], data))
  const buttonText = pathOr<ReactNode, ReactNode>('', [ 'button', 'buttonText' ], data)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const sku = safePath([ 'modalContent', 'productId' ], addSensorToCartModal).getOrElse('')
  const product = useSelector(selectProduct(sku))
  const isOnStock = product.cata(T, p => prop('isOnStock', p) ? true : false)
  const isSellable = product.cata(T, p => prop('isSellable', p) ? true : false)
  const outOfStockBannerText = prop('outOfStockBannerText', data)

  const notSellableText: ReactNode = (!isSellable || !isOnStock) && outOfStockBannerText ? <p className="paragraph textSizeXS font-medium mt-0.5">{outOfStockBannerText}</p> : null
  return (
    <SensorProductBanner
      button={
        <Link
          to={buttonUrl}
        >
          <SSButton
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            color={buttonColor as ButtonColor}
            type='div'>
            {buttonText}
          </SSButton>
        </Link>
      }
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulProductPageHer... Remove this comment to see the full error message

      description={<><RichText json={getDescriptionJson(data)} />{notSellableText}</>}
      headerText={propOr<string, string>('', 'headerText', data)}
      image={<FluidImg
        alt={`${propOr('', 'title', image)} ${propOr('', 'description', image)}`}
        fluid={propOr<Partial<FluidObject> | undefined, Partial<FluidObject> | undefined>(undefined, 'fluid', image)}
        style={{
          height: '100%',
          width: '100%'
        }}
      />}
      outOfStockMessage={isSellable && renderOutOfStockMessage({ product: product })}
      popupButton={addSensorToCartModal && <ModalComponent data={addSensorToCartModal} />}
      productName={propOr<string, string>('', 'sensorName', data)}
    />
  )
}

export default SensorProductBannerComponent

export const query = graphql`#graphql
  fragment sensorProductBannerFragment on ContentfulProductPageHeroSensor {
    id
    internal {
      type
    }
    button {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
    description {
      json
    }
    headerText
    media: image {
      description
      fluid(maxWidth: 700, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    addSensorToCartModal {
      ... on ContentfulModal {
        ...modalFragment
      }
    }
    outOfStockBannerText
    title
    sensorName
  }
`
