import { TrackingData } from '@simplisafe/ecomm-ts-types'
import { SSButton } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import React, { FC, useCallback } from 'react'

import { trackShopNowEvent } from '../../util/analytics'
import { handleLinkClick } from './helpers'
import { ContentfulButtonFragment } from './query'

type HeaderButtonProps = {
  readonly id: ContentfulButtonFragment['id']
  readonly text: ContentfulButtonFragment['text']
  readonly url: ContentfulButtonFragment['url']
  readonly trackEvent: (_data: Partial<TrackingData>) => void
};

export const HeaderButton: FC<HeaderButtonProps> = ({
  id, text, url, trackEvent
}) => {
  const buttonUrl = url ?? ''
  const buttonText = text ?? ''

  const clickHandler = useCallback(() => {
    handleLinkClick(buttonText, trackEvent)
    trackShopNowEvent('header-shop-btn')(trackEvent)
  }, [ buttonText, trackEvent ])

  return (
    <Link key={id}
      onClick={clickHandler}
      role="button"
      style={{
        textDecoration: 'none',
        whiteSpace: 'nowrap'
      }}
      to={buttonUrl}>
      <SSButton id={id} type='div'> {text} </SSButton>
    </Link>
  )
}
