import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  HeaderDropdown as HeaderDropdownAtom,
  HeaderDropdownItem as HeaderDropdownItemAtom,
  HeaderDropdownMultipleItems,
} from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Maybe } from 'monet'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React, { FC } from 'react'

import { handleLinkClick } from './helpers'
import {
  ContentfulMenuItemsFragment,
  NavItem,
} from './query'

type HeaderDropdownItemProps = {
  readonly item?: NavItem
  readonly showImage: boolean
  readonly isMobile: boolean
  readonly trackEvent: (_trackingData: Partial<TrackingData>) => void
};

export const HeaderDropdownItem: FC<HeaderDropdownItemProps> = ({
  item, showImage, isMobile, trackEvent
}) => {
  const { domain } = LOCALE_INFO
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const contentsToGroup = item?.['contentsToGroup']
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const groupingType = item?.['groupingTypeDesktop']
  // @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  const renderLink = (link: NavItem, trackEvent) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const linkText = link?.['linkText'] ?? ''
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const linkUrl = link?.['linkUrl'] ?? ''
    const id = prop('id', link)
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const linkSubtext = link?.['linkSubtext']
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const linkImage = link?.['linkImage']

    return <Link
      key={id}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
      onClick={() => linkUrl && handleLinkClick(linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      to={linkUrl}>
      {showImage && <HeaderDropdownItemAtom
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        image={linkImage && linkImage?.fluid && <Img
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
          fluid={linkImage?.fluid}
          loading="eager"
          style={{
            display: 'block',
            height: '100%',
            objectFit: 'contain',
            width: '100%'
          }}
        />}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        label={linkText}
        labelPosition={equals(domain, 'us') && !isMobile ? 'right' : 'bottom'}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        subText={linkSubtext}
      />}
      {!showImage && linkText}
    </Link>
  }

  return Maybe.fromFalsy(contentsToGroup)
    .map(contents => (
      <HeaderDropdownMultipleItems key={prop('id', item)}
        position={Maybe.fromNull(groupingType)
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/consistent-type-assertions -- legacy code, legacy code */
          .map(g => toLower(g) as 'column' | 'row')
          .orUndefined()}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call -- legacy code, legacy code */}{/* @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type. */}
        {contents.map((link) => renderLink(link, trackEvent))}
      </HeaderDropdownMultipleItems>
    ))
    // @ts-expect-error TS(2345) FIXME: Argument of type 'NavItem | undefined' is not assi... Remove this comment to see the full error message
    .getOrElse(renderLink(item, trackEvent))
}

export type HeaderDropdown = {
  readonly dropDown: ContentfulMenuItemsFragment
  readonly showImage: boolean
  readonly isMobile: boolean
  readonly trackEvent: (_trackingData: Partial<TrackingData>) => void
};

export const HeaderDropdown: FC<HeaderDropdown> = ({
  dropDown, showImage, isMobile, trackEvent
}) => {
  const id = prop('id', dropDown)
  const linkText = propOr<string, string>('', 'linkText', dropDown)
  const subNav = prop('subNav', dropDown)

  return (
    <HeaderDropdownAtom id={id}
      key={id}
      label={linkText}>
      {subNav && subNav.map(item => <HeaderDropdownItem isMobile={isMobile} item={item}
        key={item.id} showImage={showImage}
        trackEvent={trackEvent} />)}
    </HeaderDropdownAtom>
  )
}
