import { Modal } from '@simplisafe/ss-react-components'
import { LookingForUsSiteModal } from '@simplisafe/ss-react-components'
import { get, set } from 'local-storage'
import React, {
  FC,
  useState
} from 'react'

import { locale } from '../../commercetools/utils'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import getDescriptionJson from '../../util/getDescriptionJson'
import RichText from '../RichText'

const geoRedirectKey = 'geoRedirectSuggested'

type CountryRedirectModal = {
  readonly data: SmallTextSectionRedirect
  readonly language?: string
}

const CountryRedirectModal: FC<CountryRedirectModal> = ({
  data,

  language = locale,
}: CountryRedirectModal) => {

  // Safari returns 'en' for 'en-us'
  const getBrowserLanguage = (browserLanguage: string) => browserLanguage === 'en' ? 'en-us' : browserLanguage
  const showRedirectModal = (typeof navigator !== 'undefined' && language.toLowerCase() !== getBrowserLanguage(navigator.language.toLowerCase()) && !get(geoRedirectKey))

  const [ isRedirectModalVisible, setRedirectModalVisibility ] = useState(showRedirectModal)
  /*
    redirect modal needs to show only once to the user
    if a user is set to see the redirect modal, save a flag in localstorage,
    so that the modal doesn't show again when a user goes back to the site
   */
  const handleClose = () => {
    setRedirectModalVisibility(false)
    set(geoRedirectKey, true)
  }


  const modalContent = <RichText json={getDescriptionJson(data)} />

  return isRedirectModalVisible ? (
    <Modal
      appElementId='___gatsby'
      isOpen={isRedirectModalVisible}
      onRequestClose={handleClose}>
      {/* TODO rename modal to remove "US" reference to be more generic */}
      <LookingForUsSiteModal content={modalContent} />
    </Modal>
  ) : null
}

export default CountryRedirectModal
