import prop from '@simplisafe/ewok/ramda/prop'
import { Column } from '@simplisafe/ss-react-components'
import defaultTo from 'ramda/src/defaultTo'
import React, { FC } from 'react'

import FluidImg from '../FluidImg'
import { FluidImage } from './types'

type ImageColumnProps = {
    readonly image: FluidImage
    readonly positionMobile?: string
    readonly imageObjectFit?: string
}

const ImageColumn: FC<ImageColumnProps> =
    ({
      image, imageObjectFit, positionMobile
    }: ImageColumnProps) => {
      const imageFirstRow = (positionMobile === 'Bottom' ? false : true)
      const imgStyle = { 'objectFit': defaultTo('contain')(imageObjectFit)  }
      const fluid = prop('fluid', image)
      return (
        <Column
          firstRow={imageFirstRow}
          key={image.id}
          spans={[ 12, 6 ]}>
          <FluidImg
            alt={image.title}
            // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
            fluid={fluid}
            imgStyle={imgStyle}
            style={{ height: '100%' }}
          />
        </Column>
      )
    }

export default ImageColumn
