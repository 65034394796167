import { Address, ShippingInfo } from '@commercetools/platform-sdk'
import { useOptimizelyCheckoutCartCollapse } from '@lib/tracking/src/optimizely'
import { TrackingData } from '@simplisafe/ecomm-ts-types/tracking'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { getCartDetails, ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart, selectHiddenProductSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import { BannerError, BannerLoading } from '@simplisafe/ss-react-components'
import { OrderSummary } from '@simplisafe/ss-react-components'
import { OrderSummaryProps, ShippingDetailsProps } from '@simplisafe/ss-react-components/OrderSummary'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import applySpec from 'ramda/src/applySpec'
import isNil from 'ramda/src/isNil'
import React, {
  FC, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { CheckoutOrderDetailsFragment } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import { trackPaymentPageView, trackShippingPageView } from '../../util/analytics'
import {
  getCartDiscountCode, getCartDiscountValue, getShippingInfoPrice, toItemList
} from '../CartDetailsComponent/transformLineItem'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'

type CheckoutOrderDetailProps = {
  readonly data: CheckoutOrderDetailsFragment
}

// TODO this needs to be exported and unit tested
// TODO this needs a type for the object being passed to the functin this returns
const toShippingAddressData = applySpec<ShippingDetailsProps>({
  addressLine1: prop('streetName'),
  addressLine2: prop('additionalStreetInfo'),
  city: prop('city'),
  email: prop('email'),
  firstName: prop('firstName'),
  lastName: prop('lastName'),
  phoneNumber: prop('phone'),
  postalCode: prop('postalCode'),
  state: prop('state'),
})

const toOrderSummaryProps = applySpec<OrderSummaryProps>({
  priceCalculationSection: path([ 'priceCalculationSection', 'json' ]),
  promoCodeSection:path([ 'promoCodeSection', 'json' ]),
  section2Title:prop('section2Title'),
  titleHeader:prop('title')
})

export const getShipppingLabel = (shippingInfoPrice: Maybe<number>, shippingInfo: Maybe<ShippingInfo>) =>
  shippingInfoPrice.map(val =>
    val > 0
      ? shippingInfo.map(info => `${prop('shippingMethodName', info)}:`).getOrElse('')
      // TODO get this from CTFL
      : 'Free Shipping:'
  )

export const handleShippingPageView = (
  cart: RemoteData<ImmutableCart>,
  trackEvent: (_trackingData: Partial<TrackingData>) => void,
  setHasViewedShippingPage: React.Dispatch<React.SetStateAction<boolean>>,
  setHasViewedPaymentPage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  cart.forEach(response => {
    setHasViewedShippingPage(true)
    setHasViewedPaymentPage(false)
    const itemList = response.lineItems
    trackShippingPageView(itemList)(trackEvent)
  })
}

export const handlePaymentPageView = (
  cart: RemoteData<ImmutableCart>,
  trackEvent: (_trackingData: Partial<TrackingData>) => void,
  setHasViewedShippingPage: React.Dispatch<React.SetStateAction<boolean>>,
  setHasViewedPaymentPage: React.Dispatch<React.SetStateAction<boolean>>
) => {
  cart.forEach(response => {
    setHasViewedShippingPage(false)
    setHasViewedPaymentPage(true)
    const itemList = response.lineItems
    trackPaymentPageView(itemList)(trackEvent)
  })
}

const CheckoutOrderDetails: FC<CheckoutOrderDetailProps> =
  ({ data }: CheckoutOrderDetailProps) => {
    const propsOrderSummary = toOrderSummaryProps(data)
    const collapseOnDesktop = prop('collapseCart', useOptimizelyCheckoutCartCollapse())
    const cart: RemoteData<ImmutableCart> = useSelector(selectCart)
    const hiddenProductSkus = useSelector(selectHiddenProductSkus)

    const { Track, trackEvent } = useTracking()
    const [ hasViewedShippingPage, setHasViewedShippingPage ] = useState(false)
    const [ hasViewedPaymentPage, setHasViewedPaymentPage ] = useState(false)

    useEffect(() => {

      const checkShippingPage = () => {
        (!hasViewedShippingPage && window.location.pathname === '/cart/checkout') && handleShippingPageView(cart, trackEvent, setHasViewedShippingPage, setHasViewedPaymentPage)
      }

      const checkPaymentPage = () => {
        (!hasViewedPaymentPage && window.location.pathname === '/payment-page') && handlePaymentPageView(cart, trackEvent, setHasViewedShippingPage, setHasViewedPaymentPage)
      }

      checkShippingPage()
      checkPaymentPage()

    }, [ cart, hasViewedPaymentPage, hasViewedShippingPage, trackEvent ])

    const cartLoadingMsg = safeProp('cartLoadingMessage', data)
      .map(data => <RichTextWithOptionsComponent data={data}
        key={data.id} />)
      .orNull()
    const cartErrorMsg = safeProp('cartRetrievalErrorMessage', data)
      .map(data => <RichTextWithOptionsComponent data={data}
        key={data.id} />)
      .orNull()

    const linkText = safeProp('seePackageDetailLinkText', data)
      .orUndefined()

    const section1Title = safeProp('section1Title', data).getOrElse('')

    return (
      <Track>
        <div>
          {
            cart.cata(
              () => <BannerLoading>{cartLoadingMsg}</BannerLoading>,
              () => <BannerError height='responsive'>{cartErrorMsg}</BannerError>,
              // If the cart is empty, there's logic elsewhere to redirect the user to the /cart page
              () => <BannerLoading>{cartLoadingMsg}</BannerLoading>,
              (x: ImmutableCart) => {
                const itemList = toItemList(getCartDetails(hiddenProductSkus)(x.lineItems))(undefined, undefined, linkText).getOrElse([])
                const total = prop('totalPrice', x)
                const shippingAddress = toShippingAddressData(x.shippingAddress.map<Partial<Address>>(addr => addr).orJust({}))
                const shippingSectionTitle = safeProp('addressLine1', shippingAddress)
                  .cata(
                    () => '',
                    () => section1Title
                  )
                const subTotal = prop('subTotal', x)
                const taxedPrice = prop('taxedPrice', x).getOrElse(0)
                const discountValue = getCartDiscountValue(x)
                const discountCode = getCartDiscountCode(x)

                // FYI getShippingInfoPrice comes back as a Just, even if it's 0
                const shippingInfoPrice = getShippingInfoPrice(x)

                const shippingPrice = shippingInfoPrice.chain(formatDisplayPrice)

                const shippingLabel = getShipppingLabel(shippingInfoPrice, x.shippingInfo)

                const priceCal = {
                  checkoutShippingValue: shippingPrice.getOrElse(''),
                  shippingLabel: shippingLabel.getOrElse(''),
                  subtotal: formatDisplayPrice(subTotal).getOrElse(''),
                  total: formatDisplayPrice(total).getOrElse(''),
                  vAT: formatDisplayPrice(taxedPrice).getOrElse('')
                }
                const discPriceCal = {
                  couponCode:  Maybe.fromUndefined(discountCode).getOrElse(''),
                  discountLabel: `${discountCode} Discount Applied:`, //TODO use placeholder from contentul
                  discountValue: Maybe.fromNull(discountValue).getOrElse('')
                }

                return (
                  <OrderSummary
                    {... propsOrderSummary}
                    collapseOnDesktop={collapseOnDesktop}
                    itemList={itemList}
                    priceCalculation={{
                      ...priceCal,
                      ...(!isNil(discountValue) && discPriceCal)
                    }}
                    section1Title={shippingSectionTitle}
                    shippingDetails={shippingAddress}
                  />
                )
              },
            )
          }
        </div>
      </Track>
    )
  }

export const query = graphql`
  fragment checkoutOrderDetails on ContentfulCheckoutOrderDetails{
    id
    internal {
      type
    }
    priceCalculationSection {
      json
    }
    promoCodeSection {
      json
    }
    title
    section1Title
    section2Title
    seePackageDetailLinkText
    cartLoadingMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    cartRetrievalErrorMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
  }
`
export default CheckoutOrderDetails
