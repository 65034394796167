import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { safePath } from '@simplisafe/monda'
import {
  IconWithText, SmallTextSection, Text
} from '@simplisafe/ss-react-components'
import { Info } from '@simplisafe/ss-react-components/icons'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { FC, ReactNode } from 'react'

import { IconWithTextFragment } from '../../../graphql'
import {
  toIconPositionValue, toIconSizeValue, toIconVerticalAlignmentValue, toTextAlignmentValue
} from '../../attributeMappings'
import getJson from '../../util/getJson'
import ModalComponent from '../ModalComponent'

type IconWithTextContainerProps = {
  readonly data: IconWithTextFragment
}
type ImageData = IconWithTextFragment['icon'] | null

const IconWithTextComponent: FC<IconWithTextContainerProps> =
  ({ data }: IconWithTextContainerProps) => {
    const icon = prop('icon', data)

    const iconPosition = propOr('Left', 'iconPosition', data)
    const iconSize = propOr('medium', 'iconSize', data)
    const verticalAlignment = propOr('middle', 'iconVerticalAlignment', data)
    const textAlignment = propOr('Left', 'textAlignment', data)
    const modalContent = prop('modalContent', data)
    const disclaimer = propOr<string, string>('', 'disclaimerText', data)

    const toImg = (imgData: ImageData): ReactNode =>
      Maybe.fromNull(imgData)
        .chain(safePath([ 'file', 'url' ]))
        .cata(
          () => null,
          (fileUrl: string) => <img
            alt={prop('title', imgData)}
            key={fileUrl}
            src={fileUrl} />
        )

    const toText =
      safeProp('text', data)
        .map(getJson)
        .map(json => <SmallTextSection description={json}
          key={prop('id', data)}/>)
        .orNull()

    return (
      <IconWithText
        icon={toImg(icon)}
        iconPosition={toIconPositionValue(iconPosition)}
        iconSize={toIconSizeValue(iconSize)}
        iconVerticalAlignment={toIconVerticalAlignmentValue(verticalAlignment)}
        text={
          <div style={{ display:'flex' }}>
            <div>
              {toText}
              {disclaimer &&
                <Text display='inline-flex'
                  fontWeight='medium'
                  textColor='neutralDarkGray'
                  textSize='xs'
                >
                  {disclaimer}
                </Text>
              }
            </div>
            {modalContent && <ModalComponent clickTarget={<Info forceButtonMode={true}
              titleA11y="show more info"/>}
            data={modalContent}/>
            }
          </div>
        }
        textAlignment={toTextAlignmentValue(textAlignment)}
      />
    )
  }

export default IconWithTextComponent

export const IconWithTextQuery = graphql`#graphql
  fragment iconWithText on ContentfulIconWithText {
    ...iconWithTextInteranls
    disclaimerText
    icon {
      fixed(width: 188, height: 189) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      id
      description
      file {
        url
      }
      title
    }
    modalContent {
      ... on ContentfulModal {
        internal {
          type
        }
        # TODO : using specific queries since facing errors while using the fragment, need to fix as soon as possible
        # error showing : Cannot spread fragment "modalFragment" within itself via 
        # contentfulTwoColumnFragment, __twoColumnBannerBasic, popupButtonFragment, addExtraSensorsFragment, contentfulIconWithTextFragment.
        modalContent {
          ... on ContentfulRichTextWithOptions {
            ...richTextWithOptions
          }
          ... on ContentfulTwoColumn {
            ...contentfulTwoColumnInteranls
            backgroundColor
            padding
            leftContent {
              ... on ContentfulSmallTextSection {
                ...smallTextSectionFragment
              }
            }
            rightContent {
              ... on ContentfulGroupSection {
                ...groupSectionInteranls
                headerText
                contentsToGroup {
                  ... on ContentfulIconWithText {
                    ...iconWithTextInteranls
                    icon {
                      fluid(maxWidth: 300) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                        title
                      }
                      text {
                        json
                    }
                    iconObjectFit
                    textPosition
                    title
                  }
                }
              }
            }
          }
        }
        modalSize
      }
    }
  }

  fragment iconWithTextInteranls on ContentfulIconWithText {
    id
    iconPosition
    iconSize
    iconVerticalAlignment
    internal {
      type
    }
    textAlignment
    text {
      json
    }
  }
`
