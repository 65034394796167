import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { GetAQuoteFormSchema } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSchema'
import { GetAQuoteFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSubmission'
import { getAQuoteFormSubmit } from '@simplisafe/ss-ecomm-data/commercetools/getAQuoteFormSubmission'
//import type { PartnerWithUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsForm'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import {
  Form, Formik, FormikHelpers
} from 'formik'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { ContentfulRadioButton, GetAQuoteFormFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import Company from './form-sections/Company'
import Email from './form-sections/Email'
import Name from './form-sections/Name'
import NumLocations from './form-sections/NumLocations'
import Phone from './form-sections/Phone'
import PrivacyPolicy from './form-sections/PrivacyPolicy'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'
import Title from './form-sections/Title'

export type FormValues = {
  readonly name: string
  readonly company: string
  readonly email: string
  readonly phone: string
  readonly numLocations: string
}

const initialValues: FormValues = {
  company: '',
  email: '',
  name: '',
  numLocations: '',
  phone: ''
}

type GetAQuoteFormProps = {
  readonly data: GetAQuoteFormFragment
}

type OptionType = readonly Pick<ContentfulRadioButton, 'text' | 'value'>[]

const GetAQuoteForm = ({ data }: GetAQuoteFormProps) => {

  const getAQuoteFormPrivacyPolicy = safeProp('privacyPolicy', data)
    .map(getJson)
    .map((json: Document) => <SmallTextSection description={json} key={prop('id', data)} />)
    .orUndefined()

  const getAQuoteFormsuccessMessageDescription = safeProp('successMessageDescription', data)
    .map(getJson)
    .map((json: Document) => <SmallTextSection description={json} key={prop('id', data)}
      verticalPadding={'small'} />)
    .orUndefined()

  const onSubmit = async (formData: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const nameParts = formData.name.split(' ')
    const firstName = nameParts[0]
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : ' '
    const submitForm: GetAQuoteFormSubmitBody = {
      businessType: propOr<string, string>('-', 'businessType', data),
      companyName: formData.company,
      email: formData.email,
      firstName: firstName,
      lastName: lastName,
      numLocations: formData.numLocations,
      phone: formData.phone,
      source: propOr<string, string>('Business Owner', 'source', data)
    }
    setSubmitting(true)

    const handleSuccess = () =>
    {
      setSubmitting(false)
      setStatus({ message: 'success' })
    }

    const handleFailure = (error: Error) => {
      setSubmitting(false)
      logError(error)
    }

    getAQuoteFormSubmit(submitForm)(handleFailure)(handleSuccess)
  }

  return (
    <div
      data-component={'GetAQuoteForm'}
      style={{
        backgroundColor: 'rgb(246, 246, 245)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px',
        margin: '0',
        padding: '24px 32px'
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={GetAQuoteFormSchema}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {!status && (
              <>
                <Title formTitle={propOr<string, string>('', 'formTitle', data)} />
                <Name
                  nameFieldLabel={propOr<string, string>('', 'nameFieldLabel', data)}
                  nameFieldPlaceholder={propOr<string, string>('', 'nameFieldPlaceholder', data)}
                />
                <Company
                  companyFieldLabel={propOr<string, string>('', 'companyFieldLabel', data)}
                  companyFieldPlaceholder={propOr<string, string>('', 'companyFieldPlaceholder', data)}
                />
                <Email
                  emailFieldLabel={propOr<string, string>('', 'emailFieldLabel', data)}
                  emailFieldPlaceholder={propOr<string, string>('', 'emailFieldPlaceholder', data)}
                />
                <Phone
                  phoneFieldLabel={propOr<string, string>('', 'phoneFieldLabel', data)}
                  phoneFieldPlaceholder={propOr<string, string>('', 'phoneFieldPlaceholder', data)}
                />
                <NumLocations
                  numLocationsFieldLabel={propOr<string, string>('', 'numLocationsFieldLabel', data)}
                  numLocationsOptions={propOr<OptionType, OptionType>([], 'numLocationsOptions', data)}
                />
                <Submission submitButtonDisabled={isSubmitting} submitButtonLabel={propOr<string, string>('', 'submitButtonLabel', data)} />
                <PrivacyPolicy getAQuoteFormPrivacyPolicy={getAQuoteFormPrivacyPolicy} />
              </>
            )}
            {status && status.message && (
              <SuccessMessage
                successMessageDescription={getAQuoteFormsuccessMessageDescription}
                successMessageTitle={propOr<string, string>('', 'successMessageTitle', data)}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default GetAQuoteForm

export const query = graphql`#graphql
  fragment getAQuoteForm on ContentfulGetaQuoteForm {
    businessType
    id
    internal {
      type
    }
    entryTitle
    formTitle
    nameFieldLabel
    nameFieldPlaceholder
    emailFieldLabel
    emailFieldPlaceholder
    companyFieldLabel
    companyFieldPlaceholder
    phoneFieldLabel
    phoneFieldPlaceholder
    numLocationsFieldLabel
    numLocationsOptions {
      text
      value
    }
    privacyPolicy {
      json
    }
    submitButtonLabel
    source
    successMessageTitle
    successMessageDescription {
      json
    }
  }
`
