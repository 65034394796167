import { visitorIdAtAt } from '@lib/tracking/src/atat'
import {
  COOKIE_FCP_ORDER_ID, COOKIE_LEAD_DATA, COOKIE_PURCHASED_CART_ID
} from '@lib/tracking/src/cookies'
import { useFacebookTrackProductPurchase } from '@lib/tracking/src/facebook'
import {
  OptimizelyPurchaseEvent,
  useOptimizelyTrackProductPurchase, useOptimizelyTrackSiteEvents
} from '@lib/tracking/src/optimizely'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import { safeProp } from '@simplisafe/monda'
import { selectCartShippingAddress } from '@simplisafe/ss-ecomm-data/cart/select'
import { getCartDetails } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/leads/capture'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { handleBrazeTrackingEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import { createHash } from 'crypto'
import { Maybe } from 'monet'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'

import { trackPaymentConfirmation, trackTransactionEvent } from '../../util/analytics/purchase'
import { toTrackCartItemsList } from '../CartDetailsComponent/transformLineItem'
import { PaymentState } from './usePayment'

const cookies = new Cookies()
const AFFIRM_SELECTED = 'affirm'

const handleFailure = (error: Error) => logError(error)

const handleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
  cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
  handleBrazeTrackingEvent(value.orUndefined())
}

const leadCapture = (email: string) => {
  const leadGenParams: LeadGenCaptureParams = {
    email,
    source: 'purchase',
    sourceType: 'purchase'
  }
  leadGenCapture(leadGenParams)(handleFailure)(handleSuccess)
}

/**
 * Tracks various purchase events upon payment completion.
 */
const useTrackPurchaseComplete = (paymentState: PaymentState, selectedPaymentOption: TrackingData['selectedPaymentOption'], trackEvent: (_data: TrackingData) => void) => {
  const cart = useSelector(selectCart)
  const shippingAddress = useSelector(selectCartShippingAddress)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const optimizelyTrackProductPurchase = useOptimizelyTrackProductPurchase()
  const facebookTrackProductPurchase = useFacebookTrackProductPurchase()
  const visitorToken = visitorIdAtAt() || ''
  const facebookEventId = visitorToken + Date.now()

  useEffect(() => {
    cart.forEach(
      /**
       * Check if the Payment Complete flag is
       * true and send some tracking data out
       */
      _cart => Maybe.fromFalsy(paymentState === 'complete').forEach(() => {
        const email = _cart.shippingAddress.chain(safeProp('email')).orJust('')

        leadCapture(email)

        safeProp('id', _cart)
          .forEach(cartId => cookies.set(COOKIE_PURCHASED_CART_ID, cartId, cookiesOption))

        const orderId = cookies.get<string>(COOKIE_FCP_ORDER_ID)
        const cartTotal = safeProp('totalPrice', _cart).getOrElse(0)
        const systemInOrder = _cart.isThereAnySecurity

        trackPaymentConfirmation(trackEvent, _cart, systemInOrder, orderId, cartTotal, email, selectedPaymentOption, shippingAddress)

        trackTransactionEvent(trackEvent, _cart, selectedPaymentOption)

        // Optimizely events
        optimizelyTrackSiteEvents({
          eventKey: 'track_revenue',
          revenue: Number(cartTotal)
        })

        selectedPaymentOption === AFFIRM_SELECTED && optimizelyTrackSiteEvents({
          eventKey: 'affirm_payment_revenue',
          revenue: Number(cartTotal)
        })

        const cartItems = toTrackCartItemsList(getCartDetails([])(_cart.get('lineItems')))

        cartItems.forEach((cartItem: OptimizelyPurchaseEvent) => optimizelyTrackProductPurchase( cartItem ))
        // Facebook events
        const hash = createHash('sha256')
        const hashData = hash.update(email.toLowerCase())
        const emailHash = hashData.digest('hex')

        facebookTrackProductPurchase({
          contents: cartItems,
          email: emailHash,
          eventId: facebookEventId,
          orderId: orderId,
          value: cartTotal
        })

      })
    )
  }, [ cart, paymentState, trackEvent, selectedPaymentOption, optimizelyTrackProductPurchase, optimizelyTrackSiteEvents, shippingAddress, facebookTrackProductPurchase ])
}

export default useTrackPurchaseComplete
