import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type FirstNameProps = {
  readonly label: string
  readonly placeholder: string
}

const FirstName: React.FC<FirstNameProps> = (props: FirstNameProps) => {
  return (
    <div data-component={'firstName'}>
      <FormField hideLabel={true} name='firstName'>
        <Text fontWeight='medium'>{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name='firstName'
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default FirstName
