import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Column, Row } from '@simplisafe/ss-react-components'
import { graphql, PageProps } from 'gatsby'
import { Maybe } from 'monet'
import React from 'react'

import { ContentfulPlaceholder } from '../../../graphql'

type PlaceholderComponentProps = {
  readonly data: Partial<ContentfulPlaceholder>
  readonly pageContext: PageProps['pageContext'] & { readonly iframeConfig: Record<string, string> }
}

const IFRAME_HEIGHT = '830px'

const PlaceholderComponent = ({ data, pageContext }: PlaceholderComponentProps) => {
  const placeholderType = prop('type', data)
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const iframeUrl = path([ 'iframeConfig', placeholderType ], pageContext)

  // @ts-expect-error TS(2345) FIXME: Argument of type '(url: string) => JSX.Element' is... Remove this comment to see the full error message
  return Maybe.fromFalsy(iframeUrl).map((url: string) => <Row alignItems='center'
    key={url}>
    <Column shadow='soft'>
      <iframe
        frameBorder="0"
        height={IFRAME_HEIGHT}
        src={url}
        width='100%' />
    </Column>
  </Row>)
    .orNull()
}

export const query = graphql`#graphql
fragment placeholderFragment on ContentfulPlaceholder {
    internal {
        type
    }
    label
    type
}
`
export default PlaceholderComponent
