import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Text } from '@simplisafe/ss-react-components'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import T from 'ramda/src/T'
import React, {
  FC, ReactNode, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import styled from 'styled-components'

/**
 * The styles in this file are dependent on the en-US Chase payment form styles.
 * Various error messages are absolutely positioned to appear near the corresponding
 * field in the Chase iframe (see payment-form-us.css).
 */

// This value lines up with the screen size at which the US payment form changes layout,
// rather than the common values used across the rest of the site
const screenSizeMedium = '607px'

const PaymentErrorMessages = styled.div`
  left: 9px;
  position: absolute;
  right: 0;
  top: 69px;
  @media (min-width: ${screenSizeMedium}) {
    left: 220px;
  }
`

const ChaseError = styled.p`
  position: absolute;
`

const NameError = styled(ChaseError)`
  top: 0px;
  @media (min-width: ${screenSizeMedium}) {
    top: -20px;
  }
`

const CVVError = styled(ChaseError)`
  top: 236px;
  @media (min-width: ${screenSizeMedium}) {
    top: 148px;
  }
`

const CardNumberError = styled(ChaseError)`
  top: 158px;
  @media (min-width: ${screenSizeMedium}) {
    top: 92px;
  }
`

const CardTypeError = styled(ChaseError)`
  top: 79px;
  @media (min-width: ${screenSizeMedium}) {
    top: 36px;
  }
`

const CardExpiryError = styled(ChaseError)`
  top: 320px;
  @media (min-width: ${screenSizeMedium}) {
    top: 210px;
  }
`

const AddressError = styled(ChaseError)`
  top: 399px;
  @media (min-width: ${screenSizeMedium}) {
    top: 266px;
  }
`

const CityError = styled(ChaseError)`
  top: 542px;
  @media (min-width: ${screenSizeMedium}) {
    top: 382px;
  }
`

const ZipError = styled(ChaseError)`
  top: 687px;
  @media (min-width: ${screenSizeMedium}) {
    left: 160px;
    top: 440px;
  }
`

export const errorMessages = {
  billingAddress: 'Billing Address field is required.',
  cardCVV: 'You have entered an invalid CVV number.',
  cardName: 'Name on Card is required.',
  cardNumber: 'You have entered an invalid credit card number.',
  cardType: 'Credit card type is invalid',
  city: 'City field is required.',
  expiryDate: 'The credit card you entered has expired.',
  paymentDeclined: 'Payment declined or some other error has occurred',
  postalCodeInvalid: 'You have entered an invalid postal code.',
  postalCodeMissing: 'Postal Code field is required.'
}

const chaseErrorClass = 'cc-error-chase'

/**
 * Renders an error message based on the given Chase error code. These error
 * messages (and possibly class names) are also referenced in GTM for tracking purposes.
 * TODO switch to ts-pattern when frontend is on TS4
 * @private Exported for unit tests.
 */
export const renderErrorMessage = (code: string) => cond<string, ReactNode>([
  [ equals('200'), _code =>
    <NameError className={`${chaseErrorClass} cc-name-error`} key={_code}>
      {errorMessages.cardName}
    </NameError>
  ],
  [ _code => [ '310', '315', '357' ].includes(_code), _code =>
    <CardNumberError className={`${chaseErrorClass} cc-number-error`} key={_code}>
      {errorMessages.cardNumber}
    </CardNumberError>
  ],
  [ equals('320'), _code =>
    <CardTypeError className={`${chaseErrorClass} cc-type-error`} key={_code}>
      {errorMessages.cardType}
    </CardTypeError>
  ],
  [ _code => [ '350', '355' ].includes(_code), _code =>
    <CVVError className={`${chaseErrorClass} cc-cvv-error`} key={_code}>
      {errorMessages.cardCVV}
    </CVVError>
  ],
  [ equals('360'), _code =>
    <CardNumberError className={`${chaseErrorClass} cc-number-error`} key={_code}>
      {errorMessages.paymentDeclined}
    </CardNumberError>
  ],
  [ equals('370'), _code =>
    <CardExpiryError className={`${chaseErrorClass} cc-expiry-error`} key={_code}>
      {errorMessages.expiryDate}
    </CardExpiryError>
  ],
  [ equals('500'), _code =>
    <AddressError className={`${chaseErrorClass} cc-billing-address-error`} key={_code}>
      {errorMessages.billingAddress}
    </AddressError>
  ],
  [ equals('510'), _code =>
    <CityError className={`${chaseErrorClass} cc-city-error`} key={_code}>
      {errorMessages.city}
    </CityError>
  ],
  [ equals('530'), _code =>
    <ZipError className={`${chaseErrorClass} cc-zip-error`} key={_code}>
      {errorMessages.postalCodeMissing}
    </ZipError>
  ],
  [ equals('531'), _code =>
    <ZipError className={`${chaseErrorClass} cc-zip-error`} key={_code}>
      {errorMessages.postalCodeInvalid}
    </ZipError>
  ],
  [ equals('100'), () => {
    logError(Error('payment-callback: Error 100 - Merchant Identifier left blank or not valid. The transaction was not processed.'))
    return null
  } ],
  [ T, _code => {
    logError(Error(`payment-callback: Error ${_code} - Unhandled chase error code.`))
    return null
  } ]
])(code)

type ChaseErrorMessagesProps = {
  readonly errorCodes?: readonly string[]
}

/**
 * Renders and tracks error messages from Chase for the payment form.
 */
const ChaseErrorMessages: FC<ChaseErrorMessagesProps> = ({ errorCodes = [] }: ChaseErrorMessagesProps) => {
  const { trackEvent } = useTracking()
  const [ isVisible, setVisible ] = useState(true)
  const locale = useSelector(selectLocale)
  // en-GB error messages are rendered in the iframe itself; we render them ourselves for en-US
  const shouldRenderErrorMessages = !isEmpty(errorCodes) && isVisible && locale === 'en-US'

  useEffect(() => {
    errorCodes.forEach(code => {
      trackEvent({
        errorID: code,
        event: 'paymentFormCreError'
      })
    })
  }, [ errorCodes, trackEvent ])

  useEffect(() => {
    // add 1s delay when error codes change so gtm.elementVisibility can be re-triggered (for subsequent form error validation)
    setVisible(false)
    setTimeout(() => setVisible(true), 1000)
  }, [ errorCodes ])

  return (
    shouldRenderErrorMessages ? (
      <Text textColor='darkOrange' textSize='xs'>
        { /* Note: the id on this container element is used by GTM */ }
        <PaymentErrorMessages id='payment-error-messages'>
          { errorCodes.map(renderErrorMessage) }
        </PaymentErrorMessages>
      </Text>
    ) : null
  )
}

export default ChaseErrorMessages
