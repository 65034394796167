import { safeProp } from '@simplisafe/monda'
import { selectPromoDiscountText, selectPromoWithMonitoringDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectActivePromoOverrideDiscountText,
  selectDisplayMonitoringDiscount,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column,
  OfferTag,
  Row
} from '@simplisafe/ss-react-components'
import path from 'ramda/src/path'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulPromotionalTagging } from '../../../graphql'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'

export type PromoTagProps = {
  readonly data: ContentfulPromotionalTagging
}

const PromoTag: FC<PromoTagProps> = ({ data }) => {
  const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
  const discountText = useSelector(selectPromoDiscountText)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountWithMonitoringText = useSelector(selectPromoWithMonitoringDiscountText)
  const taggingText = path<string>([ 'taggingText', 'json' ], data)
  const isPartnerAirline = getValueFromPartnerCookie('partnerGroup') === 'airlines'

  const placeholderText = displayMonitoringDiscount ? discountWithMonitoringText : discountText

  const overrideTextMaybe = useSelector(selectActivePromoOverrideDiscountText)
    .chain(safeProp('listingFlag'))
    .chain(val => val)
  const hasOverrideText = !overrideTextMaybe.isNone()

  return (
    placeholderText.isSome() && isPromoTopBanner && taggingText && !isPartnerAirline ? (
      <div className='lg:-mt-16'>
        <Row alignItems='center' padding='small'>
          <Column padding='medium' spans={[ 12, 12, 12 ]}>
            {/* Hacky way to solve an alignment issue in the shop page ECP-4524 */}
            <OfferTag
              borderColor={path<string>([ 'textColor' ], data)}
              placeholderText={displayMonitoringDiscount ? (hasOverrideText ? overrideTextMaybe.some() : placeholderText.getOrElse('')) : placeholderText.getOrElse('')}
              tagBackgroundColor={path<string>([ 'tagBackgroundColor' ], data)}
              tagTextColor={path<string>([ 'textColor' ], data)}
              taggingText={taggingText}
            />
          </Column>
        </Row>
      </div>
    ) : null
  )
}

export default PromoTag
