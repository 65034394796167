import { getChatAppId } from '@lib/tracking/src/cookies'
import { useDecision } from '@optimizely/react-sdk'
import { requestLiveChatAvailability } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { useEffect, useState } from 'react'

const useLiveChatAvailability = (chatIdProp: string) => {
  const [ isLiveChatEnabled ] = useDecision('livechat')
  const [ hasAvailableAgents, setHasAvailableAgents ] = useState(false)
  const chatId = getChatAppId() || chatIdProp

  useEffect(() => {
    const handleFailure = () => setHasAvailableAgents(false)
    const handleSuccess = (response: boolean) => response === true ? setHasAvailableAgents(true) : handleFailure()

    // Only make the request to sprinklr if Optimizely feature flag is enabled
    // and chat id is not an empty string.
    const shouldRequest = isLiveChatEnabled.enabled && !!chatId
    shouldRequest && requestLiveChatAvailability(chatId)(handleFailure)(handleSuccess)
  }, [ isLiveChatEnabled.enabled, chatId ])

  return isLiveChatEnabled.enabled && hasAvailableAgents ? true : false
}

export default useLiveChatAvailability
