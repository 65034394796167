import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

import InfoButton from './InfoButton'

type SIDNumberProps = {
  readonly label: string
  readonly placeholder: string
  readonly onInfoClick: () => void
}

const SIDNumber: React.FC<SIDNumberProps> = ({
  label,
  placeholder,
  onInfoClick
}: SIDNumberProps) => {
  return (
    <div data-component={'SIDNumber'}>
      <FormField hideLabel={true} name='sidNumber'>
        <div style={{
          alignItems: 'center',
          display: 'flex',
        }}>
          <Text fontWeight='medium'>
            {label}
          </Text>
          <InfoButton onClick={onInfoClick} />
        </div>
        <FormTextInput
          fullWidth={true}
          name='sidNumber'
          placeholder={placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default SIDNumber
