import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { SSButton } from '@simplisafe/ss-react-components'
import { ChevronDownSm } from '@simplisafe/ss-react-components/icons'
import { MinWidthType, SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { graphql, Link } from 'gatsby'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import propOr from 'ramda/src/propOr'
import React, {
  CSSProperties, FC, MouseEventHandler
} from 'react'

import { ContentfulAsset, ContentfulButton } from '../../../graphql'
import { toButton } from '../../util/helper'
import FluidImg from '../FluidImg'

export type ContentfulButtonProps = {
  //todo data should be using a fragment here instead of a partial
  readonly data: Partial<ContentfulButton> & { readonly buttonText?: string }
  readonly disabled?: boolean
  readonly onClick?: MouseEventHandler<HTMLElement>
  readonly style?: CSSProperties
}

const imgStyle: CSSProperties = {
  height: '15px',
  marginLeft: '15px',
  width: '15px'
}

const toGatsbyImg = (data: ContentfulAsset) => {
  const fluid = prop('fluid', data)
  const title = prop('title', data)
  const key = prop('id', data)
  return <FluidImg alt={title}
    // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
    fluid={fluid}
    imgStyle={{
      marginLeft: '0',
      objectFit: 'contain'
    }}
    key={key}
    style={imgStyle}
  />
}

export const isHashUrl = (url?: string): boolean => !!url && url.startsWith('#')
export const isLocalUrl = (url?: string): boolean => !!url && url.startsWith('/')

const ButtonComponent: FC<ContentfulButtonProps> =
  ({
    data, disabled, onClick, style
  }: ContentfulButtonProps) => {
    const buttonText = prop('buttonText', data)
    const disabledText = prop('disabledText', data)
    const button: SSButtonProps = toButton({
      ...data,
      buttonText: disabled ? disabledText || buttonText : buttonText
    })
    // added this validation to prevent testing errors
    const url = prop('url', data)
    const isLinkExternal = prop('isLinkExternal', data)
    const isHash = isHashUrl(url)
    const isLocal = isLocalUrl(url)

    /**
     * Local links should be wrapped with Gatsby links, so its child should be a div
     * Links should render as anchor links unless they're a hash link, which renders as a button instead
     * undefined type defaults to a button as specified by SSButton.
     * https://github.com/simplisafe/ss-react-components/blob/240d6c072e2374d183faab850e75aa295ac9f839/src/atoms/SSButton/index.tsx#L122
     */
    const buttonProp: SSButtonProps = {
      ...button,
      type: url ? isLocal ? 'div' : isHash ? undefined : 'link' : undefined
    }

    const buttonSize = propOr<MinWidthType, MinWidthType>('auto', 'buttonSize', data)
    const icon = safeProp('icon', data)
      .chain(iconData =>
        safePath([ 'file', 'contentType' ], iconData)
          .map(ifElse(
            equals('image/svg+xml'),
            //todo should check the icon type
            () => <span style={imgStyle}><ChevronDownSm data-component="svg" /></span>,
            () => toGatsbyImg(iconData)
          )))
      .orNull()

    const ssButton = (
      <SSButton
        {...buttonProp}
        disabled={disabled}
        minWidth={buttonSize}
        onClick={onClick}
        style={style}
      >
        {buttonProp.children}
        {icon}
      </SSButton>
    )

    function renderButton(isLocal: boolean, url: string | undefined, useAnchor: boolean | undefined){
      if (isLocal && url){
        return useAnchor ? <a href={url}>{ssButton}</a> : <Link data-component="link" to={url}>{ssButton}</Link>
      } else {
        return ssButton
      }
    }

    return renderButton(isLocal, url, isLinkExternal)
  }

export default ButtonComponent

export const query = graphql`#graphql
    fragment contentfulButtonFragment on ContentfulButton {
        id
        internal {
          type
        }
        addToCartButton
        buttonText: text
        buttonSize
        type
        url
        icon {
          id
          title
          file {
            url
            contentType
          }
          fluid(maxWidth: 20) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        disabledText
        isLinkExternal
    }
`
