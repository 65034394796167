import { COOKIE_LEAD_DATA, cookies } from '@lib/tracking/src/cookies'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import findFirstRightValue from '@simplisafe/ewok/monet-utils/findFirstRight'
import { safeProp } from '@simplisafe/monda'
import type { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  selectActivePromoCode, selectActivePromoCodeWithMonitoring, selectActivePromoLoading, selectLocale,
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { brazeTrackPromoView, handleBrazeTrackingEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import {
  Column, Modal, Row, SSButton, Text
} from '@simplisafe/ss-react-components'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { Link } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { get, set } from 'local-storage'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulButton, ContentfulModalPromoPopup } from '../../../graphql'
import { toButtonTypeValue } from '../../attributeMappings'
import { trackSubmitLeadEvent } from '../../util/analytics'
import { toButton } from '../../util/helper'
import FluidImg from '../FluidImg'
import LeadCaptureForm, { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'

const promoPopupClosedKey = 'promoPopupClosed'

const leadSourceI18N: Record<Locale, string> = {
  'en-GB': 'uk_promo_banner_popup',
  'en-US': 'us_promo_banner_popup'
}

type ModalPromoPopupProps = {
  readonly data: ContentfulModalPromoPopup
}

const ModalPromoPopupComponent: FC<ModalPromoPopupProps> = ({ data }: ModalPromoPopupProps) => {
  const locale = useSelector(selectLocale)
  const [ isOpen, setIsOpen ] = useState(!get(promoPopupClosedKey))
  const [ isFormSubmitted, setIsFormSubmitted ] = useState(false)

  const dismissButton = safeProp('dismissButton', data)
  const promoTitle = propOr<string, string>('', 'promoTitle', data)
  const termsAndConditions = propOr<string, string>('', 'termsAndConditions', data)
  const evergreenOnly = propOr<boolean, boolean>(true, 'evergreenOnly', data)

  const isActivePromoLoading = useSelector(selectActivePromoLoading)
  const activePromo = useSelector(selectActivePromoCode)
  const activePromoWithMonitoring = useSelector(selectActivePromoCodeWithMonitoring)
  const hasActivePromo: boolean = findFirstRightValue([ activePromo, activePromoWithMonitoring ]).isJust()

  const closeModal = () => {
    setIsOpen(false)
    // Save flag in localStorage to make sure this promo popup is not displayed more than once
    set(promoPopupClosedKey, true)
  }

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (email, onFailure) => {
    const handleLeadCaptureFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      onFailure('We\'ve encountered an error. Please try again later.')
    }

    const handleLeadCaptureSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      setIsFormSubmitted(true)
      set(promoPopupClosedKey, true)
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      brazeTrackPromoView()
      handleBrazeTrackingEvent(value.orUndefined())
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackEvent({ event: 'submit' })
      trackSubmitLeadEvent(trackEvent)
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      source: `${leadSourceI18N[locale]}_${getDeviceType().toLowerCase()}`
    }

    leadGenCapture(leadGenParams)(handleLeadCaptureFailure)(handleLeadCaptureSuccess)
  }

  const renderPromoImage = (data: ContentfulModalPromoPopup) => {
    return safeProp('promoImage', data).map(
      (promoImage) =>
        <FluidImg
          alt={propOr<string, string>('', 'title', promoImage)}
          fluid={propOr<Partial<FluidObject> | undefined, Partial<FluidObject> | undefined>(undefined, 'fluid', promoImage)}
          imgStyle={{ objectFit: 'contain', }}
          key="img"
          style={{
            maxHeight: '367px',
            maxWidth: '283px'
          }}
        />
    )
      .orNull()
  }

  const renderButton = (
    button: Maybe<ContentfulButton>,
    buttonType: SSButtonProps['type'],
    onClick?: SSButtonProps['onClick']
  ) => {
    return button.map(
      (props) => {
        const buttonProps = {
          ...toButton(props),
          className: '!p-0',
          onClick,
          textColor: 'white',
          type: toButtonTypeValue(buttonType)
        }
        return <SSButton key="button" {...buttonProps} />
      }).orNull()
  }

  const renderTitle = () => (
    promoTitle && <Text className="mt-10 md:mt-7 mb-6" useTailwind={true}><h3 className="text-white capitalize">{promoTitle}</h3></Text>
  )

  return (!evergreenOnly || (!isActivePromoLoading && !hasActivePromo)) && isOpen ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      size="medium"
      style={{
        content: {
          backgroundColor:'var(--neutral-black)',
          color:'white',
          width: '640px'
        }
      }}
    >
      <Row alignItems="stretch" gap="none"
        rounded="none">
        <Column rounded="none" spans={[ 6 ]}>
          {renderPromoImage(data)}
        </Column>
        <Column className="p-5 md:p-0 md:pr-10" spans={[ 6, 0 ]}>
          {renderTitle()}
        </Column>
        <Column className="p-5 md:p-0 md:pr-9 md:pl-1" spans={[ 12, 6 ]}>
          <div className="hidden md:inline">{renderTitle()}</div>
          <LeadCaptureForm
            buttonStyles={{
              padding: '1rem',
              textTransform: 'capitalize'
            }}
            compactInput={false}
            ctaButtonHref='/home-security-shop'
            ctaButtonText='shop now'
            isSubmitted={isFormSubmitted}
            label='Enter your email'
            legal={<span>By clicking &apos;submit&apos; you accept our <Link className="!text-white" to="/privacy-policy">Privacy Policy</Link>.</span>}
            onSubmit={handleEmailSubmit}
            placeholder='enter your email'
            showCta={true}
            style='stack'
            submitButtonText='get offer'
            successMessage='Thank you. Your code will automatically apply at checkout.'
          />
          {!isFormSubmitted && renderButton(dismissButton, 'button', closeModal)}
        </Column>
      </Row>

      {termsAndConditions && (
        <div className="p-6 pt-0 md:pt-6">
          <Text textAlignment="center" useTailwind={true}><small className="text-white">{termsAndConditions}</small></Text>
        </div>
      )}
    </Modal>
  ) : null
}

export default ModalPromoPopupComponent
