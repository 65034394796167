import {
  useCallback,
  useEffect,
  useState
} from 'react'

export const getScrollPercentage = (element: Element | null) => {
  return (element === null)
    ? NaN
    : Math.round((element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100)
}

const useScrollPosition = () => {
  const [ scrollPercentage, setScrollPercentage ] = useState(NaN)
  const [ hasGoneDown20Percent, setHasGoneDown20Percent ] = useState(false)
  const [ hasGoneDownAndUp, setHasGoneDownAndUp ] = useState(false)

  const handleScroll = useCallback((e: Event) => {
    // @ts-expect-error TS(2339) FIXME: Property 'scrollingElement' does not exist on type... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    const newPercentage = getScrollPercentage(e?.target?.scrollingElement)
    setScrollPercentage(newPercentage)
    newPercentage > 20 && setHasGoneDown20Percent(true)
    hasGoneDown20Percent && (newPercentage < scrollPercentage) && setHasGoneDownAndUp(true)
  }, [ scrollPercentage, hasGoneDown20Percent ])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ handleScroll ])

  return {
    handleScroll,
    hasGoneDown20Percent,
    hasGoneDownAndUp,
    scrollPercentage,
  }
}

export default useScrollPosition
