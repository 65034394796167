/* eslint-disable max-lines -- legacy code */
import { Document } from '@contentful/rich-text-types'
import deriveHtmlId from '@simplisafe/ewok/contentful-utils/deriveHtmlId'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectPromoDiscountText, selectPromoWithMonitoringDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectDisplayMonitoringDiscount, selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column,
  HiddenAnchor,
  OfferTag,
  Row
} from '@simplisafe/ss-react-components'
import type { Spans } from '@simplisafe/ss-react-components/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import T from 'ramda/src/T'
import toLower from 'ramda/src/toLower'
import zip from 'ramda/src/zip'
import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import {
  SitePadding, toGapValue, toPaddingValue
} from '../../attributeMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import { nullToUndefined } from '../../util/helper'
import { toAlignItemsValue } from '../TwoColumnBanner'
import FileColumn from './FileColumn'
import ImageColumn from './ImageColumn'
import TextColumn from './TextColumn'
import {
  BannerProps,
  File,
  FluidImage,
  MediaItem,
  PairSpan,
  SimpleBannerComponentProps
} from './types'


const bannerProps = (data: Partial<SimpleBannerComponentProps>, isMobile: boolean) => {
  return applySpec<BannerProps>({
    alignItems: prop('alignItems'),
    backgroundColor: prop('backgroundColor'),
    columnPadding: prop('columnPadding'),
    gapSize: prop('gapSize'),
    height: prop('height'),
    listStyle: prop('listStyle'),
    media: prop('mediaItems'),
    mediaMobile: prop('mediaMobileItems'),
    mediaObjectFit: prop('mediaObjectFit'),
    mediaObjectFitMobile: prop('mediaObjectFitMobile'),
    mediaPosition: prop('mediaPosition'),
    mediaPositionMobile: prop('mediaPositionMobile'),
    rowPadding: prop('rowPadding'),
    text: (data) => ifElse(equals(true), always(path([ 'descriptionMobile', 'json' ], data)), always(getDescriptionJson(data)))(isMobile && !isNil(path([ 'descriptionMobile', 'json' ], data))),
    textAlignment: prop('textAlignment'),
    textColor: (data) => ifElse(equals(true), always(path([ 'mobileTextColor', 'color' ], data)), always(path([ 'textColor', 'color' ], data)))(isMobile && !isNil(path([ 'mobileTextColor', 'color' ], data))),
    textId: path([ 'description', 'id' ]),
    textPosition: prop('textPosition'),
    textPositionMobile: prop('textPositionMobile'),
  })(data)
}

export const renderTextColumn = ({
  columnPadding, listStyle, text, textAlignment, textId, textColor, spans = [ 12, 6 ]
}: Partial<BannerProps>, responsiveContentLayout?: string, isMobile?: boolean) =>
  <TextColumn
    columnCount={ !isMobile && responsiveContentLayout === '2-Column' ? 2 : 1 }
    id={textId}
    listStyle={listStyle}
    padding={columnPadding}
    spans={spans}
    text={text}
    textAlignment={textAlignment}
    textColor={textColor}
  />

const renderImage = (image: FluidImage, mediaPositionMobile?: string, imageObjectFit?: string) =>
  <ImageColumn
    image={image}
    imageObjectFit={imageObjectFit}
    key={image.id}
    positionMobile={mediaPositionMobile}
  />

export const renderFile = (file: File, imageObjectFit?: string) =>
  <FileColumn
    file={file}
    imageObjectFit={imageObjectFit}
    key={file.id}
  />

export const renderMedia = (media?: readonly MediaItem[], mediaPositionMobile?: string, imageObjectFit?: string): ReactNode => Maybe.fromFalsy(media).map(map(
  (x: MediaItem) =>
    x.fluid
      ? renderImage(x, mediaPositionMobile, imageObjectFit)
      : renderFile(x, imageObjectFit)
))
  .orNull()

export const renderTwoColumnLayout = ({
  columnPadding,
  listStyle,
  media,
  mediaMobile,
  mediaPosition,
  mediaPositionMobile,
  text,
  textAlignment,
  textColor,
  textId
}: Partial<BannerProps>, isMobile: boolean, imageObjectFit?: string) =>
{
  const isMediaMobile = !isNil(mediaMobile) && isMobile
  const mediaData = ifElse(equals(true), always( mediaMobile ), always( media ) )(isMediaMobile)
  const renderLeftText = (!isMobile && mediaPosition === 'Right' || isMobile && mediaPositionMobile === 'Bottom')
  const renderRightText = (!isMobile && mediaPosition === 'Left' || isMobile && mediaPositionMobile === 'Top')
  return (
    <>
      {renderLeftText && renderTextColumn({
        columnPadding,
        listStyle,
        text,
        textAlignment,
        textColor,
        textId
      })}
      {renderMedia(mediaData, mediaPositionMobile, imageObjectFit)}
      {renderRightText && renderTextColumn({
        columnPadding,
        listStyle,
        text,
        textAlignment,
        textColor,
        textId
      })}
    </>
  ) }

// I'm not sure what all of these positions are supposed to look like yet
// TODO handle Top-Left, Top-Center, Top-Right, Center-Left, Bottom-Left, Bottom-Center, Bottom-Right
export const createSpacingColumns = (textPosition?: string, textPositionMobile?: string, responsiveContentLayout?: string) => {
  const mobileSpacing: Spans = cond<string, Spans>([
    [ equals('Center-Left'), () => [ 0, 7 ] ],
    [ equals('Center-Right'), () => [ 6, 6 ] ],
    [ equals('Center'), () => [ 0, 12 ] ],
    [ T, () => [ 0, 12 ] ]
  ])(textPositionMobile || '')

  const desktopSpacing: Spans = responsiveContentLayout === '2-Column' ? [ 0, 8 ] : cond<string, Spans>([
    [ equals('Center-Left'), () => [ 0, 6 ] ],
    [ equals('Center-Right'), () => [ 6, 6 ] ],
    [ equals('Center'), () => [ 2, 8 ] ],
    [ T, () => [ 0, 6 ] ]
  ])(textPosition || '')

  return zip(mobileSpacing, desktopSpacing)
}

const renderPromotionalOfferTag = (offerText?: Document, offerTagBackground?: string, offerTextColor?: string, placeholderText?: string, borderColor?: string) => {
  const columnSpans: readonly PairSpan[] = createSpacingColumns('Center')
  return (
    <>
      <Column
        key='offerTextCol1'
        spans={columnSpans[0]} />
      <Column
        key='offerTextCol2'
        spans={columnSpans[1]}>
        <OfferTag
          borderColor={borderColor}
          placeholderText={placeholderText}
          tagBackgroundColor={offerTagBackground}
          tagTextColor={offerTextColor}
          taggingText={offerText}
        />
      </Column>
      <Column
        key='offerTextCol3'
        spans={columnSpans[0]} />
    </>
  )
}

const renderTextOverBackground = ({
  columnPadding, listStyle, text, textAlignment, textColor, textId, textPosition, textPositionMobile
}: Partial<BannerProps>, responsiveContentLayout?: string, isMobile?: boolean) => {
  const columnSpans: readonly PairSpan[] = createSpacingColumns(textPosition, textPositionMobile, responsiveContentLayout)
  const padding: SitePadding = toPaddingValue(columnPadding)
  return (
    <>
      <Column
        key={`${text}-txt-bg`}
        padding={padding}
        spans={columnSpans[0]}
      />
      {renderTextColumn({
        columnPadding: columnPadding,
        listStyle,
        spans: columnSpans[1],
        text,
        textAlignment,
        textColor: textColor,
        textId
      }, responsiveContentLayout, isMobile)}
    </>
  ) }

const SimpleBannerComponent: FC<SimpleBannerComponentProps> =
 ({
   id,
   data: _data,
 }: SimpleBannerComponentProps) => {
   const isMobile = !useMediaQuery('TabletAndUp')
   const data = bannerProps(_data, isMobile)
   const backgroundColor = data.backgroundColor
   const isBackgroundMediaPosition = data.mediaPosition === 'Background'
   const isBackgroundMediaPositionMobile = data.mediaPositionMobile === 'Background'
   const hasBackgroundImage = isMobile && !!data.mediaPositionMobile ? isBackgroundMediaPositionMobile : isBackgroundMediaPosition
   const isMobileMediaPresent = isMobile && !isNil(data.mediaMobile)
   const currentMedia = ifElse(equals(true), always(data.mediaMobile), always( data.media))(isMobileMediaPresent)
   const isMobileObjectFitPresent = isMobile && !isNil(data.mediaObjectFitMobile)
   const imageObjectFit = pipe(ifElse(equals(true), always( data.mediaObjectFitMobile), always( data.mediaObjectFit)), defaultTo('contain'), toLower)(isMobileObjectFitPresent)
   const backgroundProps =
    hasBackgroundImage
      ? {
        BackgroundComponent: BackgroundImage,
        backgroundComponentProps:{
          alt:currentMedia[0].title,
          fluid: currentMedia[0].fluid,
          style: { 'backgroundSize': imageObjectFit }
        }
      }
      : {}

   // To determine whether to display discount text with or without monitoring
   const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
   const discountText = useSelector(selectPromoDiscountText)
   const discountWithMonitoringText = useSelector(selectPromoWithMonitoringDiscountText)
   const placeholderText = displayMonitoringDiscount ? discountWithMonitoringText : discountText

   const isPromoTopBanner = useSelector(selectTopBannerVisible)
   const taggingText = path([ 'promotionalTagging', 'taggingText' ], _data)
   const offerText = taggingText && getJson(taggingText)

   // TODO: Decide what to do with these during/post brand refresh. These don't follow the promo flag colors controlled in the CTFL Promotion entry.
   const offerBackGroundColor = '#00000000'
   const offerTextColor = '#FFFFFF'
   const borderColor = '#FFFFFF'

   const responsiveContentLayout = defaultTo('')(path([ 'responsiveContentLayout' ], _data))
   const textSize = defaultTo('md', prop('textSize', _data))

   const initialColor = prop('textColor', data)
   const textColor = initialColor ?? (hasBackgroundImage ? 'neutralWhite' : 'default')

   const htmlId = deriveHtmlId({
     ..._data,
     title: nullToUndefined(_data.title)
   })
   // To identify whether offer tag need to be shown or not.
   const isShowOfferTag = !!(hasBackgroundImage && isPromoTopBanner && offerText && placeholderText.isSome())

   return (
     <>
       <HiddenAnchor id={htmlId} />
       <Row
         // TODO: fix type
         // @ts-expect-error TS(2345) FIXME: Argument of type 'string | Document | Spans | read... Remove this comment to see the full error message
         alignItems={toAlignItemsValue(prop('alignItems', data))}
         backgroundColor={backgroundColor}
         gap={toGapValue(prop('gapSize', data))}
         height={defaultTo('standard', prop('height', data))}
         key={id}
         padding={toPaddingValue(prop('rowPadding', data))}
         textColor={textColor}
         // TODO: fix type
         // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"md" | "s... Remove this comment to see the full error message
         textSize={textSize}
         {...backgroundProps}>
         {isShowOfferTag && <Column key='promotionalOffer'>{renderPromotionalOfferTag(offerText, offerBackGroundColor, offerTextColor, placeholderText.getOrElse(''), borderColor)}</Column>}
         {
           hasBackgroundImage
             ? renderTextOverBackground(data, responsiveContentLayout, isMobile)
             : renderTwoColumnLayout(data, isMobile, imageObjectFit)
         }
       </Row>
     </>
   )
 }

export default SimpleBannerComponent


/**
 * The exported query here can contain a fragment that becomes
 * available as part of the main page component query.
 *
 * This allows us to keep the query definitions in the same
 * place as the component code. This will keep it easy to maintain
 * the component and query.
*/
export const contentfulBannerFragment = graphql`#graphql
    fragment contentfulBanner on ContentfulBanner {
        alignItems
        columnPadding
        gapSize
        height
        id
        internal {
            type
        }
        title
        rowPadding
        textAlignment
        mobileTextColor {
          color
        }
        textColor {
          color
        }
        backgroundColor
        textPosition
        textPositionMobile
        listStyle
        mediaPosition
        mediaItems: media {
          id
          title
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
        mediaPositionMobile
        descriptionMobile{
          json
          id
        }
        description {
          json
          id
        }
        textSize
        mediaMobileItems: mediaMobile {
          id
          title
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
        mediaObjectFitMobile
        mediaObjectFit
        mobileColumnSplitPercentage
        tabletColumnSplitPercentage
        desktopColumnSplitPercentage
        promotionalTagging {
          ...promotionalTagging
        }
        responsiveContentLayout
    }
`
