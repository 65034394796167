import type { Block, Inline } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import { safeFind, safeProp } from '@simplisafe/monda'
import { selectMonthsOfServiceDisplay, selectPackagesDiscountDisplayText } from '@simplisafe/ss-ecomm-data/redux/select'
import assoc from 'ramda/src/assoc'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import propOr from 'ramda/src/propOr'
import React, { cloneElement, FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulHeading } from '../../../graphql'
import { ComponentMappedProps } from '../../componentMappings'
import { returnNextBusinessDay } from '../../util/businessDays'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import ButtonComponent from '../ButtonComponent'
import HeadingComponent from '../HeadingComponent'

export function getContentType<T>(obj: T): string {
  // TODO obj needs a type
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const sys = path([ 'data', 'target', 'sys', 'contentType', 'sys' ], obj)
  // TODO obj needs a type
  // @ts-expect-error TS(2322) FIXME: Type '"" | Exclude<Exclude<T[keyof T], null>[keyof... Remove this comment to see the full error message
  return prop('id', sys) || ''
}

export function getFieldType<T>(obj: T): string {
  // TODO obj needs a type
  // @ts-expect-error TS(2322) FIXME: Type '"" | Exclude<T[keyof T], null>' is not assig... Remove this comment to see the full error message
  return path([ 'data', 'target', 'fields', 'type' ], obj) || ''
}

// Can be used when we need to display a number of free months only (example: "free month", "2 free months", "3 free months")
const MonthOfFreeService = () => {
  const monthsOfFreeService = useSelector(selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer()))
  return (
    <>{monthsOfFreeService}</>
  )
}




// Can be used in a Service plan description text (example: "2 months are", "month is")
const MonthOfServiceText = () => {
  const monthsOfService = useSelector(selectMonthsOfServiceDisplay(false, isPartnerUpgradePromoOffer()))
  return (
    <>{monthsOfService}</>
  )
}

type DiscountPercentageProps = {
  readonly placeholders?: Record<string, string | null>
};

const DiscountPercentage = (props: DiscountPercentageProps) => {
  const discountPercentage = useSelector(selectPackagesDiscountDisplayText).orUndefined()
  const placeholderText = path([ 'placeholders', 'Discount Percentage' ], props)

  return isNilOrEmpty(placeholderText) ? <>{discountPercentage}</> : <>{placeholderText}</>
}

type DiscountValueProps = {
  readonly placeholders?: Record<string, string | null>
};

const DiscountValue = (props: DiscountValueProps) => {
  const placeholderText = path([ 'placeholders', 'Discount Value' ], props)

  return isNilOrEmpty(placeholderText) ? null : <>{placeholderText}</>
}

// Map which content needs to render based on placeholder type
const placeholderTypeRenderMaps: readonly {
  readonly placeholder: string
  readonly renderComponent: JSX.Element
}[] = [
  {
    placeholder: 'Months of Free Service Description',
    renderComponent: <MonthOfServiceText />
  },
  {
    placeholder: 'Months of Free Service',
    renderComponent: <MonthOfFreeService />
  },
  {
    placeholder: 'Discount Percentage',
    renderComponent: <DiscountPercentage />
  },
  {
    placeholder: 'Discount Value',
    renderComponent: <DiscountValue />
  },
  {
    placeholder: 'Shipping Estimate Date',
    renderComponent: <span>{returnNextBusinessDay()}</span>
  }
]

const renderPlaceholderContent = (node: Block | Inline, placeholders?: Record<string, string | null>) => {
  const fieldType = getFieldType(node)

  return safeFind((entry) => entry.placeholder === fieldType, placeholderTypeRenderMaps)
    .chain(safeProp('renderComponent'))
    .map((renderComponent) => cloneElement(renderComponent, { placeholders }))
    .orNull()
}

const embeddedEntries: Record<string, FC<ComponentMappedProps>> = {
  // @ts-expect-error TS(2322) FIXME: Type 'FC<ContentfulButtonProps>' is not assignable... Remove this comment to see the full error message
  'button': ButtonComponent,
  'heading': (content) => {
    const data = propOr<Partial<ContentfulHeading>, Partial<ContentfulHeading>>({}, 'data', content)
    const text = { text: prop('text', data) }

    const finalData = assoc('text', text, data)

    return <HeadingComponent data={finalData} />
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
export const renderEmbeddedBlockComponent = (node) => {
  const fieldType = getContentType(node)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const data = path([ 'data', 'target', 'fields' ], node)
  const EmbeddedComponent = embeddedEntries[fieldType]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  return EmbeddedComponent ? <EmbeddedComponent data={data} /> : null
}

/**
 * Renders embedded entry in ContentfulRichText
 */
export const renderEmbeddedEntry = (node: Block | Inline, placeholders?: Record<string, string | null>) => {
  const contentType = getContentType(node)

  return ifElse(
    equals('placeholder'),
    () => renderPlaceholderContent(node, placeholders),
    () => null
  )(contentType)
}
