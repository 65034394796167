import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import applySpec from 'ramda/src/applySpec'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import React from 'react'

import { CarouselFragment } from '../../../graphql'
import { toSiteColor } from '../../attributeMappings'
import getJson from '../../util/getJson'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

export const toFloatingBadge = (contentfulCarousel: CarouselFragment, placeholders: Record<string, string>) => {
  const floatingBadgeRawText = getJson(path([ 'promoBadge', 'text' ], contentfulCarousel))
  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HEADING_3]:
        (__: unknown, text) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const header3 = map(spanWrap, text as string)

          return (
            <h3 className={'h3'}>{header3}</h3>
          )
        }
    },
  }
  return !isEmpty(placeholders) ? applySpec({
    backgroundColor: () => toSiteColor('brandPrimary'),
    borderColor: () => toSiteColor('brandPrimary'),
    floatingBadgeContent: () => <ContentfulRichText optionsCustom={options} rawRichText={floatingBadgeRawText} />,
    linkUrl: prop('discountBadgeUrl'),
    textColor: () => toSiteColor('neutralWhite'),
  })(contentfulCarousel) : null
}
