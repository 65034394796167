import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import transformObject from '@simplisafe/ewok/transformObject'
import type { UpdateAddressBody, UpdateCartAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  buildBillingAddressUpdateAction,
  buildCustomFieldUpdateAction, buildOrderExternalReferencesUpdateAction, buildShippingAddressUpdateAction
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import isEmpty from 'ramda/src/isEmpty'

import type {
  CheckoutFormAddress, CheckoutFormData, CheckoutFormObject
} from './lib'

const getAddress = prop('address')
const getForms = prop('forms')
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
const getEmail = prop('EMAIL')

export const toAddressData =
    (formAddress: CheckoutFormAddress): UpdateAddressBody => {
      const address = transformObject<CheckoutFormAddress, UpdateAddressBody['address']>({
        additionalStreetInfo: form => prop('streetAddress2', form),
        city: form => prop('city', form),
        country: always(LOCALE_INFO['country']),
        email: form => prop('email', form),
        firstName: form => prop('firstName', form),
        lastName: form => prop('lastName', form),
        phone: form => prop('phoneNumber', form),
        postalCode: form => prop('postalCode', form),
        region: form => prop('county', form),
        state: form => prop('state', form),
        streetName: form => prop('streetAddress1', form)
      })(formAddress)
      return { address }
    }

/**
 * This cleans up the Form data coming form the checkout
 * form into an object with key/value pairs
 */
export const convertFormArrayToObject =
    (formData: CheckoutFormData): CheckoutFormObject | undefined => {
      const forms = getForms(formData)
      return forms && forms.length > 0
        ? Object.assign(...forms)
        : undefined
    }

export const propOrString =
  <T extends Record<string, string | undefined>, U extends string & keyof T>(key: U, obj: T): string => {
    const value: string | undefined = prop(key, obj)
    return value || ''
  }

/**
 * Creates cart actions based on formData
 */
export const buildAddressUpdateActions =
    (formData: CheckoutFormData, cartHasCustom: boolean, shippingMethodId?: string): ReadonlyArray<UpdateCartAction> => {
      return Maybe.fromUndefined(convertFormArrayToObject(formData))
        .map(form => {
          const address: CheckoutFormObject['SHIPPING ADDRESS'] = prop('SHIPPING ADDRESS', form)

          const email: string = path([ 'EMAIL', 'email' ], form) || ''

          // TODO the titles in contentful should not be how we get these values
          const customerServiceInfo =
               prop('CUSTOMER SERVICE INFO', form)
               // TODO: fix type
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            || prop('How\'d you hear about us?', form)
            || {}

          const shippingAddressBody = toAddressData({
            ...address,
            email
          })

          // These have to default to and empty string.
          // An undefined value will attempt to delete the fields, which will cause an error if they are not already defined on the cart.
          // TODO: fix type
          const leadOther: string = propOrString('additionalFoundInfoThrough', customerServiceInfo)
          // TODO: fix type
          const leadSource: string = propOrString('foundInfoThrough', customerServiceInfo)

          const hasLeadInfo = !isEmpty(leadSource || leadOther)
          const hasAddress = !!address
          const shippingAddress = getAddress(shippingAddressBody)
          const isSameBillingAddress = hasAddress && !!prop('sameAddress', address)

          const customFieldAction =
        // TODO it seems like `cartHasCustom` will always be true, so we should investigate what
        // is expected here and verify it works
        cartHasCustom
          ? [ buildCustomFieldUpdateAction({
            name: 'leadOther',
            value: leadOther
          }),
          buildCustomFieldUpdateAction({
            name: 'leadSource',
            value: leadSource
          }) ]
          : [ buildOrderExternalReferencesUpdateAction({
            leadOther,
            leadSource
          }) ]

          const actions: ReadonlyArray<UpdateCartAction | undefined> = [
            hasAddress
              ? buildShippingAddressUpdateAction(shippingAddress)
              : undefined,
            isSameBillingAddress
              ? buildBillingAddressUpdateAction(shippingAddress)
              : undefined,
            shippingMethodId && isNotEmpty(shippingMethodId)
              ? {
                action: 'setShippingMethod',
                shippingMethod: {
                  id: shippingMethodId,
                  typeId: 'shipping-method'
                }
              }
              : undefined
          ]

          const allActions = hasLeadInfo ? actions.concat(customFieldAction) : actions
          return allActions.filter((action): action is UpdateCartAction => !!action)
        })
        .getOrElse([])
    }
