import type { Document } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import { FacebookPost } from '@simplisafe/ss-react-components'
import { FacebookPostProps } from '@simplisafe/ss-react-components/FacebookPost'
import defaultTo from 'ramda/src/defaultTo'
import filter from 'ramda/src/filter'
import isEmpty from 'ramda/src/isEmpty'
import pathEq from 'ramda/src/pathEq'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import { PageProps } from '../Page'

export type FacebookPostComponentProps = {
  readonly pageContext: PageProps['pageContext']
  readonly description: Document
}

const isFBPlaceholderPresent = ( description: Document) => {
  // TODO we should not be reaching into the contents of contentfuls document like this
  // @ts-expect-error TS(2352) FIXME: Conversion of type 'TopLevelBlock[]' to type 'read... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const descriptionArray = path([ 'content' ], description) as readonly []
  const contentTypePath = [ 'data', 'target', 'sys', 'contentType', 'sys', 'id' ]
  const contentTextPath = [ '0', 'data', 'target', 'fields', 'type' ]
  const filterCondForPlaceholder = pathEq(contentTypePath, 'placeholder')
  const placeholderOutput = filter(filterCondForPlaceholder, descriptionArray)
  return !isEmpty(placeholderOutput) && pathEq(contentTextPath, 'Facebook Comment')(placeholderOutput)
}

const FacebookPostComponent: FC<FacebookPostComponentProps> =
  ({  pageContext, description }: FacebookPostComponentProps) => {
    const config: FacebookPostProps = {
      numOfPosts:5,
      orderBy:'time',
      width:'100%'
    }
    // @ts-expect-error TS(2322) FIXME: Type 'defaultTo_general_11<string, string | boolea... Remove this comment to see the full error message
    const sdkUrl: string = defaultTo('')(path([ 'fbConfig', 'sdkUrl' ], pageContext))
    // @ts-expect-error TS(2322) FIXME: Type 'defaultTo_general_11<string, string | boolea... Remove this comment to see the full error message
    const appId: string = defaultTo('')(path([ 'fbConfig', 'appId' ], pageContext))
    const isFBPostAvailable = !isEmpty(sdkUrl) && !isEmpty(appId) && isFBPlaceholderPresent(description)
    return isFBPostAvailable ? (
      <>
        <Helmet>
          <meta content={appId}
            property="fb:app_id" />
          <script async
            crossOrigin="anonymous"
            defer
            src={sdkUrl}>
          </script>
        </Helmet>
        <FacebookPost {...config}></FacebookPost>
      </>
    ) : null
  }

export default FacebookPostComponent
