import localizeCent from '@simplisafe/ewok/currency-utils/localizeCent'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectMonthsOfServiceDisplay } from '@simplisafe/ss-ecomm-data/redux/select'
import isEmpty from 'ramda/src/isEmpty'
import pathOr from 'ramda/src/pathOr'
import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  ContentfulLinkAddToCart, ContentfulProductPlan, ContentfulRichTextWithOptions
} from '../../../graphql'
import { renderPriceWithTemplate } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import usePriceVariation from '../../hooks/usePriceVariation'
import getDescriptionJson from '../../util/getDescriptionJson'
import { isPartnerUpgradePromoOffer } from '../../util/partnerCookie'
import { getAddPlanButton, getMonitoringConfirmModal } from '.'

export type PlanButtonProps = {
  readonly data: Partial<ContentfulProductPlan | ContentfulRichTextWithOptions>
  readonly titlePlan: string
}

const PlanButton: FC<PlanButtonProps> = ({
  data,
  titlePlan,
}: PlanButtonProps) => {
  const { Track, trackEvent } = useTracking()
  // TODO: fix the type for data
  // productId does not exist on data
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const productSku = prop('productId', data) || ''
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  const product = usePriceVariation(productSku)
  const monthsOfFreeService: string = useSelector(selectMonthsOfServiceDisplay(true, isPartnerUpgradePromoOffer()))

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulProductPlan | ... Remove this comment to see the full error message
  const json = getDescriptionJson(data)
  // TODO we shouldn't be reaching inside the json from contentful, this is very brittle and will break
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const description: ReactNode = path([ 'content', 0, 'content', 0, 'value' ], json)
  const rawTokens = description && description.toString().split(/(?:{{|}})/g)
  const textTokens = rawTokens && rawTokens.map((token: string, idx: number) =>
    token === 'price_per_day' ? <span key={`price-per-day-${idx}`}>{
      renderPriceWithTemplate({

        customFormatPrice: (price) => localizeCent(locale, price),
        product,
        // Todo: Maybe add this argument back into renderPriceWithTemplate?
        // showCustomFormatPrice: true,
        showDiscountedPrice: false,
        template: '{price}/day'
      })
    }</span> :
      token === 'price_per_month' ? <span key={`price-per-month-${idx}`}>{
        renderPriceWithTemplate({
          product,
          showDiscountedPrice: false,
          template: '{price} per month'
        })
      }</span> :
        <span key={`text-${idx}`}
        >{token}</span>)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const button: Partial<ContentfulLinkAddToCart> =
  // TODO: fix type for data
  // button does not exist on data
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    prop('button', data) || {}
  const renderButtonType: string = pathOr('', [ 'button', 'internal', 'type' ], data)
  const richText = textTokens ? (
    <div className='breakLine defaultTextColor'>
      <p className="paragraph">
        {textTokens}
      </p>
    </div>
  ) : null

  return (
    <Track>
      {richText}
      { renderButtonType !== 'ContentfulModal' ? !isEmpty(button) ? getAddPlanButton(button) : null : getMonitoringConfirmModal(button, trackEvent, titlePlan, monthsOfFreeService)}
    </Track>
  )
}

export default PlanButton
