import { Divider, withLegacyDataProp } from '@lib/components'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { PageProps } from 'gatsby'

import { Internal } from '../graphql'
import AccessoriesCardComponent from './components/AccessoriesCardComponent'
import AccordionItemComponent from './components/AccordionItemComponent'
import AddExtraSensorsComponent from './components/AddExtraSensorsComponent'
import AlarmSensorsComponent from './components/AlarmSensorsComponent'
import AuthenticationComponent from './components/AuthenticationComponent'
import BadgeText from './components/BadgeText'
import BannerTextComponent from './components/BannerTextComponent'
import BlogArticleComponent from './components/BlogArticleComponent'
import BlogArticleListComponent from './components/BlogArticleListComponent'
import BmsHeroItem from './components/BmsHeroItem'
import BmsSensorGroup from './components/BmsSensorGroup'
import BmsSensor from './components/BmsSensorGroup/BmsSensor'
import ButtonComponent from './components/ButtonComponent'
import CardKitComponent from './components/CardKitComponent'
import CardShopComponent from './components/CardShopComponent'
import CarouselComponent from './components/CarouselComponent'
import CarouselFeaturesComponent from './components/CarouselFeaturesComponent'
import CartDetailsComponent from './components/CartDetailsComponent'
import CartSummaryComponent from './components/CartSummaryComponent'
import CheckoutForm from './components/CheckoutForm'
import CheckoutOrderDetails from './components/CheckoutOrderDetails'
import CompanyReviewBannerComponent from './components/CompanyReviewBannerComponent'
import ComparisonTableRowComponent from './components/ComparisonTableRowComponent'
import ConditionalContent from './components/ConditionalContent'
import ContactUsForm from './components/ContactUsForm'
import ContentfulCardItemBanner from './components/ContentfulCardItemBanner'
import ContentfulComparisonBanner from './components/ContentfulComparisonBanner'
import ContentfulLocationGridComponent from './components/ContentfulCrimeLocationGrid'
import ContentfulFormComponent from './components/ContentfulFormComponent'
import ContentfulOverlayBanner from './components/ContentfulOverlayBanner'
import ContentfulPaymentFormComponent from './components/ContentfulPaymentFormComponent'
import ContentfulReviewGridComponent from './components/ContentfulReviewGridComponent'
import ContentfulVariationContainerComponent from './components/ContentfulVariationContainerComponent'
import ContentSelectorComponent from './components/ContentSelectorComponent'
import DeviceVariationsComponent from './components/DeviceVariationsComponent'
import DynamicPackageFloorplanBannerComponent from './components/DynamicPackageFloorplanBannerComponent'
import DynamicPackageHeroBannerWrapper from './components/DynamicPackageHeroBannerComponent/DynamicPackageHeroBannerWrapper'
import EditPackageSensorComponent from './components/EditPackageSensorComponent'
import ExpandableComponent from './components/ExpandableComponent'
import ExpandableMonitoringPlanComponent from './components/ExpandableMonitoringPlanComponent'
import FloatingBadgeComponent from './components/FloatingBadgeComponent'
import FloorPlan from './components/FloorPlan'
import FooterTopSectionComponent from './components/FooterTopSectionComponent'
import Form2GUpgrade from './components/Form2GUpgrade'
import GetAQuoteForm from './components/GetAQuoteForm'
import GroupSection from './components/GroupSection'
import HeadingComponent from './components/HeadingComponent'
import HowItWorksInteractiveHouse from './components/HowItWorksInteractiveHouse'
import IconWithTextComponent from './components/IconWithTextComponent'
import ImageGalleryComponent from './components/ImageGalleryComponent'
import ImageWithArtDirection from './components/ImageWithArtDirection'
import ImageWithFloatingBadgeComponent from './components/ImageWithFloatingBadgeComponent'
import ImageWithFocalPoint from './components/ImageWithFocalPoint'
import ImageWithOverlay from './components/ImageWithOverlay'
import IncludedItemComponent from './components/IncludedItemComponent'
import IncludedItemsComponent from './components/IncludedItemsComponent'
import ItemContainerComponent from './components/ItemContainerComponent'
import LegalContainer from './components/LegalContainer'
import LinkAddToCart from './components/LinkAddToCart'
import CommunicationsContent from './components/LiveChat/CommunicationsContent'
import LiveChatTrigger from './components/LiveChat/LiveChatTrigger'
import SmsBlock from './components/LiveChat/SmsBlock'
import ContentfulLocationBannerComponent from './components/LocationBannerComponent'
import Media from './components/Media'
import MeetTheSystemComponent from './components/MeetTheSystemComponent'
import ModalComponent from './components/ModalComponent'
import ModalExitIntentComponent from './components/ModalExitIntentComponent'
import ModalPromoPopupComponent from './components/ModalPromoPopupComponent'
import PageAnchorNavigationComponent from './components/PageAnchorNavigationComponent'
import PartnerCaptureForm from './components/PartnerCaptureForm'
import PartnerForm from './components/PartnerOrderIntentForm'
import PartnerTemplateComponent from './components/PartnerTemplateComponent'
import PartnerWithUsForm from './components/PartnerWithUsForm'
import PaymentConfirmationContainerComponentProps from './components/PaymentConfirmationContainerComponent'
import PaymentFormErrorMessage from './components/PaymentFormErrorMessage'
import PerfectSystemBannerComponent from './components/PerfectSystemBannerComponent'
import PhoneCTAComponent from './components/PhoneCTAComponent'
import PlaceholderComponent from './components/PlaceholderComponent'
import PlanComparisonTableRows from './components/PlanComparisonTableRows'
import PopupButton from './components/PopupButtonComponent'
import PressKitGridComponent from './components/PressKitGridComponent'
import ProductPageHero from './components/ProductPageHero'
import ProductPlanComponent from './components/ProductPlanComponent'
import QuoteWizardWrapper from './components/QuoteWizardWrapper'
import ReferAFriendPlaceholderComponent from './components/ReferAFriendPlaceholderComponent'
import ResponsiveContainerComponent from './components/ResponsiveContainerComponent'
import RichTextWithButtonsComponent from './components/RichTextWithButtonsComponent'
import RichTextWithOptionsComponent from './components/RichTextWithOptionsComponent'
import SaveMySystemModalComponent from './components/SaveMySystemModalComponent'
import SensorProductBannerComponent from './components/SensorProductBannerComponent'
import SimpleBannerComponent from './components/SimpleBannerComponent'
import SmallTextSection from './components/SmallTextSection'
import TabNavigationComponent from './components/TabNavigationComponent'
import TestimonialCardComponent from './components/TestimonialCardComponent'
import TrustpilotBanner from './components/TrustpilotBanner'
import TwoColumnBanner from './components/TwoColumnBanner'
import UnsubscribePageContents from './components/UnsubscribePageContents'
import VerticalScrollCarouselComponent from './components/VerticalScrollCarouselComponent'

// A shared mapping of Contentful content types to component to be rendered
const componentMappings = {
  ContentfulAccordion: AccordionItemComponent,
  ContentfulAdditionalInfoBanner: ContentfulOverlayBanner,
  ContentfulAlarmSensors: AlarmSensorsComponent,
  ContentfulAuthentication: AuthenticationComponent,
  ContentfulBadgeText: BadgeText,
  ContentfulBanner: SimpleBannerComponent,
  ContentfulBannerText: BannerTextComponent,
  ContentfulBlogArticle: BlogArticleComponent,
  ContentfulBlogArticleList: BlogArticleListComponent,
  ContentfulBmsHeroItem: BmsHeroItem,
  ContentfulBmsMiniCart: CartSummaryComponent,
  ContentfulBmsSensorGroup: BmsSensorGroup,
  ContentfulBmsSensors: BmsSensor,
  ContentfulButton: ButtonComponent,
  ContentfulCardKit: CardKitComponent,
  ContentfulCardShop: CardShopComponent,
  ContentfulCarousel: CarouselComponent,
  ContentfulCarouselFeatures: CarouselFeaturesComponent,
  ContentfulCarouselVerticalScrollAndBanner: VerticalScrollCarouselComponent,
  ContentfulCartDetails: CartDetailsComponent,
  ContentfulCategoryList: TabNavigationComponent,
  ContentfulCheckoutForm: CheckoutForm,
  ContentfulCheckoutOrderDetails: CheckoutOrderDetails,
  ContentfulCommunicationsContent: CommunicationsContent,
  ContentfulCompanyReviewBanner: CompanyReviewBannerComponent,
  ContentfulComparisonBanner: ContentfulComparisonBanner,
  ContentfulComparisonTableRow: ComparisonTableRowComponent,
  ContentfulConditionalContent: ConditionalContent,
  ContentfulContactUsForm: ContactUsForm,
  ContentfulContentSelector: ContentSelectorComponent,
  ContentfulCrimeLocationBannerWithAddressField: ContentfulLocationBannerComponent,
  ContentfulCrimeLocationGrid: ContentfulLocationGridComponent,
  ContentfulDeviceVariations: DeviceVariationsComponent,
  ContentfulDivider: withLegacyDataProp(Divider),
  ContentfulDynamicPackageFloorplanBanner: DynamicPackageFloorplanBannerComponent,
  ContentfulDynamicPackageHeroBanner: DynamicPackageHeroBannerWrapper,
  ContentfulExpandable: ExpandableComponent,
  ContentfulExpandableMonitoringPlan: ExpandableMonitoringPlanComponent,
  ContentfulFindYourPerfectSystem: QuoteWizardWrapper,
  ContentfulFloatingBadge: FloatingBadgeComponent,
  ContentfulFloorPlan: FloorPlan,
  ContentfulFooterTopSection: FooterTopSectionComponent,
  ContentfulForm2GUpgrade: Form2GUpgrade,
  ContentfulGetaQuoteForm: GetAQuoteForm,
  ContentfulGroupSection: GroupSection,
  ContentfulHeading: HeadingComponent,
  ContentfulHowItWorksInteractiveHouse: HowItWorksInteractiveHouse,
  ContentfulIconWithText: IconWithTextComponent,
  ContentfulImage: Media,
  ContentfulImageGallery: ImageGalleryComponent,
  ContentfulImageWithArtDirection: ImageWithArtDirection,
  ContentfulImageWithFloatingBadge: ImageWithFloatingBadgeComponent,
  ContentfulImageWithFocalPoint: ImageWithFocalPoint,
  ContentfulImageWithOverlay: ImageWithOverlay,
  ContentfulIncludedItem: IncludedItemComponent,
  ContentfulIncludedItems: IncludedItemsComponent,
  ContentfulLegal: LegalContainer,
  ContentfulLinkAddToCart: LinkAddToCart,
  ContentfulLiveChatTrigger: LiveChatTrigger,
  ContentfulLoginforgetPassword: ContentfulFormComponent,
  ContentfulMeetTheSystem: MeetTheSystemComponent,
  ContentfulModal: ModalComponent,
  ContentfulModalExitIntent: ModalExitIntentComponent,
  ContentfulModalPromoPopup: ModalPromoPopupComponent,
  ContentfulModalSaveYourSystem: SaveMySystemModalComponent,
  ContentfulModalSensorAdditionToSystem: AddExtraSensorsComponent,
  ContentfulPageAnchorNavigation: PageAnchorNavigationComponent,
  ContentfulPartnerCaptureForm: PartnerCaptureForm,
  ContentfulPartnerForm: PartnerForm,
  ContentfulPartnerTemplate: PartnerTemplateComponent,
  ContentfulPartnerWithUsForm: PartnerWithUsForm,
  ContentfulPaymentConfirmationContainer: PaymentConfirmationContainerComponentProps,
  ContentfulPaymentForm: ContentfulPaymentFormComponent,
  ContentfulPaymentFormErrorMessage: PaymentFormErrorMessage,
  ContentfulPdpPackageSensorEdit: EditPackageSensorComponent,
  ContentfulPhoneCta: PhoneCTAComponent,
  ContentfulPlaceholder: PlaceholderComponent,
  ContentfulPlanComparisonTable: PlanComparisonTableRows,
  ContentfulPopupButton: PopupButton,
  // typo is in contentful
  ContentfulPrefectSystemBanner: PerfectSystemBannerComponent,
  ContentfulPressKitGrid: PressKitGridComponent,
  ContentfulProduct: ItemContainerComponent,
  ContentfulProductCard: ContentfulCardItemBanner,
  ContentfulProductCardAccessories: AccessoriesCardComponent,
  ContentfulProductPageHeroSensor: SensorProductBannerComponent,
  ContentfulProductPageHeroV2: ProductPageHero,
  ContentfulProductPlan: ProductPlanComponent,
  ContentfulReferAFriendPlaceholder: ReferAFriendPlaceholderComponent,
  ContentfulResponsiveContainer: ResponsiveContainerComponent,
  ContentfulReviewGrid: ContentfulReviewGridComponent,
  ContentfulRichTextWithButtons: RichTextWithButtonsComponent,
  ContentfulRichTextWithOptions: RichTextWithOptionsComponent,
  ContentfulSmallTextSection: SmallTextSection,
  ContentfulSmsBlock: SmsBlock,
  ContentfulTestimonialCard: TestimonialCardComponent,
  ContentfulTrustpilotReviewsBanner: TrustpilotBanner,
  ContentfulTwoColumn: TwoColumnBanner,
  ContentfulUnsubscribePageContents: UnsubscribePageContents,
  ContentfulVariationContainer: ContentfulVariationContainerComponent
}

// TODO: Update all mapped components to use this interface so they have a consistent set of standard props defined in one place
export type ComponentMappedProps = {
  readonly data?: ContentfulComponent
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

export type ContentfulComponent = {
  readonly id?: string
  readonly internal?: Partial<Internal>
}

type ComponentMappings = typeof componentMappings
type ComponentKeys = keyof ComponentMappings

function isComponentKey(key: ComponentKeys | string | undefined): key is ComponentKeys {
  const keys = Object.keys(componentMappings)
  return keys.includes(key || '')
}

export function getMappedComponent<Props extends ComponentMappedProps>(contentfulComponent: ContentfulComponent): React.FC<Props>
export function getMappedComponent<T>(contentfulComponent: ContentfulComponent): React.FC<T>
export function getMappedComponent(contentfulComponent: ContentfulComponent) {
  const internalType = path([ 'internal', 'type' ], contentfulComponent)
  return isComponentKey(internalType) ? prop(internalType, componentMappings) : null
}
