import { selectActivePromoEndTime, selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column, Row, Text
} from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React from 'react'
import { useSelector } from 'react-redux'

const getEndsTextTag = (text: string) =>
  <span style={{
    color: 'var(--promo-secondary-text)',
    textTransform: 'uppercase'
  }}>{text}</span>

// Intended to show on the /cart page for mobile, tablet, desktop.
const CartLayout = () => {
  const endTime = useSelector(selectActivePromoEndTime)
  const locale = useSelector(selectLocale)
  const endsSoonText = Promo.soon(locale)

  const endsTextMarkup = endTime
    .map(dateString => new Date(dateString))
    .cata(
      () => getEndsTextTag(endsSoonText),
      dateInstance => {
        const isEndsSoonText = Promo.isExpired(dateInstance) || Promo.isMoreThan7DaysAway(dateInstance)
        return isEndsSoonText ? getEndsTextTag(endsSoonText) : <><span>by</span> {getEndsTextTag(Promo.relativeEndTime(dateInstance, locale))}</>
      }
    )

  return (
    <Row dataComponent='CartLayout' inheritTextColor={true}
      rounded='none'>
      <Column alignSelf='center' rounded='none'>
        <Text
          inheritTextColor={true}
          textAlignment='center'
          textSize='lg'
          useTailwind={true}
        >
          <strong>Checkout {endsTextMarkup} for this offer</strong>
        </Text>
      </Column>
    </Row>
  )
}

export default CartLayout
