import { FormField, Text } from '@simplisafe/ss-react-components'
import React, { ReactElement } from 'react'

type Props = {
  readonly successMessageDescription?: ReactElement
  readonly successMessageTitle?: string
}

const SuccessMessage: React.FC<Props> = ({
  successMessageDescription,
  successMessageTitle,
}: Props) => {
  return (
    <FormField hideLabel={true} name="partnerWithUsSuccessMessage">
      <div
        className="m-auto text-center">
        {successMessageTitle && (
          <Text fontWeight="light">
            <h3 className="text-center">{successMessageTitle}</h3>
          </Text>
        )}
        {successMessageDescription}
      </div>
    </FormField>
  )
}

export default SuccessMessage
