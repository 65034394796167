/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable ramda/prefer-ramda-boolean */
/* eslint-disable ramda/reduce-simplification */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines */
// @ts-nocheck
/*
  NOTE: This component has a lot of shared and/or copied functionality with DynamicPackageHeroBannerComponent.
  As such, when making changes in one, consider whether the same changes need to be made in the other one.
  TODO combine these two components into a single one to handle dual responsibility
*/
/**
 *
 *
 *
 *
 *
 * TODO
 * WARNING
 * This file has many issues that need to be fixed and needs to be rewritten.
 * Please do not make any changes to this file until this warning is removed.
 *
 *  - Josh
 *
 *
 *
 */
import {
  Document, INLINES, Node
} from '@contentful/rich-text-types'
import { getMonitoringGiftItems, getNonMonitoringGiftItems, } from '@lib/tracking/src/cookies'
import { useOptimizelyAffirm, useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import { ProductBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { getLocalizedString, Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import {
  initializeMiniCart, MiniCartLineItem, setMiniCartLineItem
} from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import { GiftItemContainer } from '@simplisafe/ss-ecomm-data/prices/service'
import {
  selectActivePromoFlag,
  selectActivePromoOverrideDiscountText,
  selectLocale,
  selectMiniCartLineItems,
  selectPackage,
  selectProduct,
  selectProducts,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Price, SSInput } from '@simplisafe/ss-react-components'
import { AffirmPromoMessage, IncludedItemProduct } from '@simplisafe/ss-react-components'
import { ItemContainer } from '@simplisafe/ss-react-components'
import { AdditionalOptionItemsProps, AdditionalOptionItemType } from '@simplisafe/ss-react-components/AdditionalOptionItems'
import { CardBadgeProps } from '@simplisafe/ss-react-components/CardBadge'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Caution, Info } from '@simplisafe/ss-react-components/icons'
import { IncludedItemProps, ItemProduct } from '@simplisafe/ss-react-components/IncludedItem'
import { ItemContainerProps } from '@simplisafe/ss-react-components/ItemContainer'
import { ItemDetailProps } from '@simplisafe/ss-react-components/ItemDetail'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { window } from 'browser-monads-ts'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import {
  Either,
  Just, Maybe, None
} from 'monet'
import add from 'ramda/src/add'
import always from 'ramda/src/always'
// TODO replace applySpec with transformObject
import applySpec from 'ramda/src/applySpec'
import concat from 'ramda/src/concat'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import pathEq from 'ramda/src/pathEq'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import reduce from 'ramda/src/reduce'
import subtract from 'ramda/src/subtract'
import sum from 'ramda/src/sum'
import T from 'ramda/src/T'
import unless from 'ramda/src/unless'
import React, {
  FC,
  useCallback, useEffect, useMemo, useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import {
  BmsSensorsFragment,
  ContentfulGuaranteeBadge, ContentfulImage, ContentfulModal, ContentfulPdpCarouselImage, ContentfulPriceOption, ContentfulProductDetail, ContentfulRichTextWithOptions, ContentfulSystemComponent,
  ProductFragmentFragment
} from '../../../graphql'
import {
  componentsNotInStock,   CoreComponentsData, renderCoreComponentsNotInStockMsg, renderOutOfStockMessage, systemCoreComponents
} from '../../commercetools/outOfStock'
import {
  formatDisplayPrice, formatPercentage, getProductFinalPrice
} from '../../commercetools/price'
import { PackageDiscountKey } from '../../commercetools/utils'
import {
  ContentfulComponent,
  getMappedComponent
} from '../../componentMappings'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import usePriceVariation from '../../hooks/usePriceVariation'
import useRequestPrice from '../../hooks/useRequestPrice'
import { PriceProvider } from '../../providers/PriceProvider'
import type { AffirmClient } from '../../types/affirm'
import { trackAddToCartEvent, trackAddToCartPackageWithExtrasEvent } from '../../util/analytics/addToCart'
import { trackPackageDetailEvent } from '../../util/analytics/details'
import documentFallback from '../../util/documentFallback'
import { findFirstJust, toButton } from '../../util/helper'
import { getTokenizedJson } from '../../util/monitoring-price-placeholders'
import { renderComponentFromData } from '../../util/render'
import BmsSensor from '../BmsSensorGroup/BmsSensor'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { getFieldType, renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'
import EditPackageSensorComponent from '../EditPackageSensorComponent'
import ModalComponent from '../ModalComponent'
import RichText from '../RichText'

// These values need to line up with CTFL; see the Product Type field in the priceOption/"Pick Your Price Toggle" content type
export const withPlanKey = 'With Plan'
export const noPlanKey = 'No Plan'
export const refurbKey = 'Refurb'
export const ss2UpgradeKey = 'SS2 Upgrade'

// TODO: move this to ecomm-data
// this could be a generic function that takes in a Maybe
// we could also change selectProduct to take in a Maybe<string> instead of a string
// This all seems weird anyway since the entire product plan sku should be in a different component that doesn't render if it isn't needed
export const selectProductPlanById =
  (productPlan: Maybe<string>) =>
    (state: ImmutableState) => {
      return productPlan
        .chain(id => isNotEmpty(id) ? Just(id) : None<string>())
        .chain(id => selectProduct(id)(state).toMaybe())
    }

export type ItemContainerComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: ProductFragmentFragment
}

type PdpIncludedItem = ItemProduct & {
  readonly sku: string
}

const US_LOCALE = 'en-US'
const GTM_TOOLTIP_CATEGORY = 'package-details'
const GTM_TOOLTIP_ACTION = 'tooltip'
const GTM_TOOLTIP_EVENT = 'package-detail-tooltip'

// TODO move this into it's own component
export const OutOfStock = ({
  coreComponetsNotInStockList,
  skuID
}: {
  readonly coreComponetsNotInStockList: readonly CoreComponentsData[]
  readonly skuID: string
}) => {
  const product = useSelector(selectProduct(skuID))
  const outOfStockMessage = renderOutOfStockMessage({
    coreComponentsNotInStock: coreComponetsNotInStockList,
    includedInPackage: true,
    lowStockTemplate: true,
    product: product
  })

  return outOfStockMessage ? (
    <>
      <Caution />
      <span>
        {outOfStockMessage}
      </span>
    </>
  ) : null
}

const toProductList = (locale: Locale) => transformObject<PackageProduct, PdpIncludedItem>({
  productName: (x) =>
    pipe(val => prop('name', val),
      getLocalizedString(locale),
      (prodName: string) => concat(prop('quantity', x).toString(), ` ${prodName}`))(x),
  sku: (x) => prop('sku', x) || '' // TODO this empty sku isn't great, but it'll take more work to refactor this
})

const toBadgeIcon = (x) => (
  [ <Img
    alt={prop('title', x)}
    fluid={prop('fluid', x)}
    key={prop('id', x)}
    style={{
      margin: '0 auto',
      overflow: 'inherit'
    }}
  /> ]
)

const toCardBadge = transformObject<ContentfulGuaranteeBadge, CardBadgeProps>({
  cardImage: pipe(path([ 'images', '1' ]), toBadgeIcon),
  description: (val) =>
    safePath([ 'description', 'description' ], val)
      .getOrElse(''),
  image: pipe(path([ 'images', '0' ]), toBadgeIcon),
  title: (val) =>
    safePath([ 'title', 'title' ], val)
      .getOrElse(''),
})

const toProductImage = (img, isMobile, isDesktop) => {
  // TODO this needs to be done in react components, not here
  // TODO also move it out of DynamicPackageHeroBannerComponent
  const getHeight = isMobile ? {
    height: '340px',
    minWidth: '380px'
  } : isDesktop ? {
    height: '600px',
    width: '100%'
  } : {
    height: '100%',
    width: '100%'
  }
  return (<Img
    alt={'todo'}
    fluid={prop('fluid', img)}
    key={prop('id', img)}
    style={{ ...getHeight }}
  />)
}

const toolTipModalTrackEvent = (productName, trackEvent) => {
  trackEvent({
    action: GTM_TOOLTIP_ACTION,
    category: GTM_TOOLTIP_CATEGORY,
    event: GTM_TOOLTIP_EVENT,
    label: productName
  })
}

const toIncludedItemsProps = (childData, includedItemsPopup, setSelectedIncludedItemSku, coreComponetsNotInStockList, refurbishedPackage: boolean, trackEvent, modalProp) => {
  const modalContentIncludedItemsLinkContent = prop('modalContent', modalProp)

  return transformObject<ContentfulProductDetail, IncludedItemProps>({
    includedItems: data => childData.map(item => {
      const modalData = defaultTo({})(includedItemsPopup && includedItemsPopup.find(pathEq([ 'systemComponent', 'sku' ], item.sku)))
      const modalContent: ContentfulComponent = defaultTo({})(prop('modalContent', modalData))
      const ModalComponent = getMappedComponent(modalContent)
      const productSku: string = safeProp('sku', item).getOrElse(item.productName)
      const hiddenItems = path([ 'hiddenItems', 0, 'products' ], data)
      const isHiddenItem = Maybe.fromNull(hiddenItems)
        .chain(safeFind<ContentfulSystemComponent>(propEq('sku', item.sku)))
      const isFreeItem: boolean = safeProp('isFreeItem', item).getOrElse(false)

      return isHiddenItem
        .cata(() => <IncludedItemProduct
          {...item}
          isFreeItem={isFreeItem}
          // override SKU to avoid dupe key when free item matches pkg component
          key={isFreeItem ? `FREE-${productSku}` : productSku}
          modalContent={ModalComponent && <ModalComponent data={modalContent} />}
          modalType='link'
          onClick={() => toolTipModalTrackEvent(prop('productName', item), trackEvent)}
          onHoverEnd={() => setSelectedIncludedItemSku(None())}
          onHoverStart={() => setSelectedIncludedItemSku(item.sku)}
          outOfStockMessage={(isNotEmpty(productSku) && !refurbishedPackage) && <OutOfStock coreComponetsNotInStockList={coreComponetsNotInStockList}
            skuID={productSku} />}
        />, always(null))
    }),
    includedItemsLink: path([ 'customizedSystem', 'description', 'json' ]),
    includedItemsLinkContent: () =>
      Maybe.fromNull(modalProp)
        .map(modalData => <ModalComponent data={modalData}
          key={modalData.id}
          modalContent={<EditPackageSensorComponent childList={childData.map(applySpec({
            quantity: d => safeProp('productName', d)
              .map(name => name.match(/\d/g).join(''))
              .map(Number)
              .orJust(0),
            sku: prop('sku')
          }))}
          data={modalContentIncludedItemsLinkContent} />}
          style={{ content: { padding: '30px' } }} />)
        .orUndefined(),
    includedItemsTitle: (data) => safeProp('includedItemsTitle', data).getOrElse(''),
    numColumnsDesktop: () => 3,
    numColumnsMobile: () => 2,
    numColumnsTablet: () => 3,
  })
}

//TODO This need to be handled in contentful
const modalStyle = { content: { padding: '30px' } }

const toModalComponent = (modalContent: ContentfulModal) =>
  <ModalComponent
    data={modalContent}
    style={modalStyle} />

const formatAbsolute = (val) => formatDisplayPrice(
  val,
  {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }
)

export const getDiscountValueBasedKey =
  (data: Package) =>
    (key: string) =>
      key === withPlanKey
        ? {
          absoluteDiscount: data.absoluteDiscountWithServicePlan,
          formattedDiscountString: findFirstJust([ data.absoluteDiscountWithServicePlan.chain(formatAbsolute), data.relativeDiscountWithServicePlan.map(formatPercentage) ]),
          relativeDiscount: data.relativeDiscountWithServicePlan,
        }
        : {
          absoluteDiscount: data.absoluteDiscount,
          formattedDiscountString: findFirstJust([ data.absoluteDiscount.chain(formatAbsolute), data.relativeDiscount.map(formatPercentage) ]),
          relativeDiscount: data.relativeDiscount
        }

export function getDiscount(data: Package) {
  return function <T extends { readonly productType?: string | null }>(x: T) {
    return pipe(
      (y: T) => prop('productType', y),
      defaultTo(''),
      getDiscountValueBasedKey(data),
    )(x)
  }
}

export const getPackageDiscountValues =
  (p?: Package) =>
    Maybe.fromUndefined(p)
      .map(d => getDiscountValueBasedKey(d)(noPlanKey))

const filterDetailsModalData = (modal: ContentfulModal, planPrice: number) => {
  return safeProp('modalContent', modal)
    .map((richTextWithOptions: ContentfulRichTextWithOptions) => {
      const filteredJson = safePath([ 'richText', 'json' ], richTextWithOptions)
        .map((json: Document) => getTokenizedJson(json, planPrice))
        .getOrElse(documentFallback())

      return {
        ...richTextWithOptions,
        richText: { json: filteredJson }
      }
    })
    .orUndefined()
}

const getDetailsModal = (priceOption: ContentfulPriceOption, planPrice: number) => {
  return safeProp('detailsModal', priceOption)
    .map(data => ({
      ...data,
      modalContent: filterDetailsModalData(data, planPrice)
    }))
    .map(data => toModalComponent(data))
    .orUndefined()
}

// Renderes service plan description in the Price Options Box
const getServicePlanDescription = (priceOption: ContentfulPriceOption, planPrice: number) => {
  const serviceDescriptionContent = safePath( [ 'description', 'json' ], priceOption)
    .map(json => getTokenizedJson(json, planPrice))
    .getOrElse(documentFallback())

  return (
    <ContentfulRichText rawRichText={serviceDescriptionContent} />
  )
}

// Renderes tab header for a service plan in the Price Options Box
const getServicePlanTabHeader = (priceOption: ContentfulPriceOption, withPlanKey: string, discountText: string | undefined, discountTextWithMonitoring: string | undefined) => {
  const productWithPlanKey: boolean = prop('productType', priceOption) === withPlanKey
  const hasPromo = productWithPlanKey ? !!discountTextWithMonitoring : !!discountText
  const discountValue = productWithPlanKey ? discountTextWithMonitoring : discountText
  const titlePath: string = hasPromo ? 'titleDiscountText' : 'productName'
  const title: Document = path([ titlePath, 'json' ], priceOption) || documentFallback()

  const options: Options = {
    renderNode: {
      // Inlines
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const fieldType: string = getFieldType(node)
        return ifElse(
          equals('Discount Percentage'),
          () => <>{discountValue}</>,
          () => renderEmbeddedEntry(node)
        )(fieldType)
      }
    }
  }

  return (
    <ContentfulRichText
      optionsCustom={options}
      rawRichText={title}
    />
  )
}

export const toAdditionalOptionItems = (_data: Package | undefined, discountText: string | undefined, discountTextWithMonitoring: string | undefined, planPrice: number, isRefurb?: boolean, overrideTextMaybe: Maybe<string>, hasOverrideText: boolean) => {
  // TODO replace with transformObject
  return applySpec<AdditionalOptionItemType>({
    content: (spec: ContentfulPriceOption) => getServicePlanDescription(spec, planPrice),
    detailsModal: (spec: ContentfulPriceOption) => getDetailsModal(spec, planPrice),
    discount: v => {
      const productType = prop('productType', v)
      return (!isRefurb && productType === withPlanKey) ? ( hasOverrideText ? overrideTextMaybe.some() : discountTextWithMonitoring ) : discountText
    },
    inlineTitle: v => safeProp('titleProductName', v).getOrElse(''),
    planSkuId: prop('productSku'),
    sensorListDiscountText: v => safeProp('sensorListDiscountText', v).getOrElse(''),
    skuId: prop('productType'),
    titleContent: (spec: ContentfulPriceOption) => getServicePlanTabHeader(spec, withPlanKey, discountText, ( hasOverrideText ? overrideTextMaybe.some() : discountTextWithMonitoring ))
  })
}

export const getRichTextPriceOptionsTitle =
  (data?: Package, _isRefurb?: boolean) =>
    (document: ProductFragmentFragment['rightSideContainer']): Maybe<Document> =>
      getPackageDiscountValues(data)
        .chain(__ => safePath([ 'toggleBoxesHeadline', 'json' ], document))
        .orUndefined()

export const toAdditionalOptionItem =
  (onChange: (__plan: string, __planSkuId?: string) => void, data: Package | undefined, isRefurb: boolean | undefined, discountText: string | undefined, discountTextWithMonitoring: string | undefined, planPrice: number, overrideTextMaybe: Maybe<string>, hasOverrideText: boolean) =>
    transformObject<ProductFragmentFragment['rightSideContainer'], AdditionalOptionItemsProps>({
      additionalOptionItems: v => Maybe.fromFalsy(v)
        .chain(safeProp('priceOptions'))
        .map(x => x.map(toAdditionalOptionItems(data, discountText, discountTextWithMonitoring, planPrice, isRefurb, overrideTextMaybe, hasOverrideText)))
        .just(),
      onClick: () => onChange,
      priceOptionsTitle: v => Maybe.fromFalsy(v)
        .chain(safeProp('priceOptionsTitle'))
        .getOrElse(''),
      richTextPriceOptionsTitle: getRichTextPriceOptionsTitle(data, isRefurb),
    })

export const getPlanText =
  (key: string) =>
    (data /** TODO fix this any type */) =>
      safeProp('priceOptions', data)
        .chain(safeFind((x: ContentfulPriceOption) => propEq('productType', key)(x)))
        .chain<Document>(safePath([ 'savingDetails', 'json' ]))
        .orUndefined()

export const getDiscountKey =
  cond([
    [ equals(withPlanKey), always<PackageDiscountKey>('discountedPriceWithServicePlan') ],
    [ equals(refurbKey), always<PackageDiscountKey>('discountedPriceWithServicePlan') ],
    [ T, always<PackageDiscountKey>('discountedPrice') ]
  ])

// TODO connect this to real data
export const safeDiscountPrice = (_a, _b) => None<number>()
// (key: PackageDiscountKey, _package: Package) =>
//   safeProp(key, _package)
//     .chain(discountPrice => Maybe.isInstance(discountPrice) ? discountPrice : None<number>())

const defaultPrice = 0

const getAdditionalItem = (proSetupCheckbox, customLayoutField, proSetupModal, setChecked, isChecked, trackEvent) => always(proSetupCheckbox && <><SSInput
  checked={isChecked}
  defaultChecked={prop('defaultChecked', proSetupCheckbox)}
  disabled={false}
  id={prop('propName', proSetupCheckbox)}
  label={<><RichText
    json={prop('json', customLayoutField) as Document}
    textColor='neutralMediumGray'
    textSize='xs'
  />
  {<ModalComponent
    clickTarget={<Info forceButtonMode={true}
      titleA11y="show more info" />}
    data={proSetupModal}
    onClick={() => { toolTipModalTrackEvent('pro setup help', trackEvent) }}
    style={modalStyle} />}
  </>}
  name={prop('propName', proSetupCheckbox)}
  onChange={() => {
    setChecked(!isChecked)
  }}
  placeholder={prop('placeholderText', proSetupCheckbox)}
  type={prop('type', proSetupCheckbox)}
  value={prop('value', proSetupCheckbox)}
/>

</>)


// TODO: This function takes in way too many arguments and is overly complicated
export const toItemDetail =
  (
    onClick: (url: string) => void,
    childData,
    onChange: (plan: string, planSkuId?: string) => void,
    showSpinner: boolean,
    rightSideData,
    includedItemsPopup,
    setSelectedIncludedItemSku,
    isProSetUpChecked,
    setProSetUpCheckbox,
    coreComponetsNotInStockList,
    trackEvent,
    isRefurb,
    totalQuantityProduct,
    _package,
    planPrice,
    overrideToggleTextMaybe,
    hasOverrideToggleText,
    cartIsLoading
  ) => {
    const proSetupCheckbox = prop('proSetupCheckbox', rightSideData)
    const customLayoutField = prop('customLayoutField', proSetupCheckbox)
    const proSetupModal = prop('proSetupModal', rightSideData)
    const modalProp = prop('customizedSystem', rightSideData)
    const addtionalOption = prop('priceOptions', rightSideData)
    const productQuantity = safeProp('productQuantity', rightSideData).map(data => toRichTextProductQuantity(data as ContentfulRichTextWithOptions, totalQuantityProduct))
      .orNull()

    return applySpec<ItemDetailProps>({
      additionalItem: getAdditionalItem(proSetupCheckbox, customLayoutField, proSetupModal, setProSetUpCheckbox, isProSetUpChecked, trackEvent),
      additionalOptionItemsProps: addtionalOption && toAdditionalOptionItem(onChange, _package, isRefurb, undefined, undefined, planPrice, overrideToggleTextMaybe, hasOverrideToggleText),
      buttonProps: (x): SSButtonProps => ({
        ...pipe(prop('button'), toButton)(x),
        isLoading: cartIsLoading,
        showSpinner: showSpinner || cartIsLoading
      }),
      description: path([ 'description', 'json' ]),
      descriptionWithOptions: data => renderComponentFromData(prop('descriptionRichTextWithOptions', data)),
      includedItemProps: toIncludedItemsProps(childData, includedItemsPopup, setSelectedIncludedItemSku, coreComponetsNotInStockList, isRefurb, trackEvent, modalProp),
      onClickButton: (x) => () => onClick(path([ 'button', 'url' ], x)),
      productQuantity: always(productQuantity),
      productTitle: prop('productTitle')
    })(rightSideData)
  }

const toBmsSensor = (sensor: BmsSensorsFragment, pkg?: Package) => <BmsSensor data={sensor} key={sensor.id}
  pkg={pkg} />

const toItemContainer = ({
  data, showSpinner, pkg
}: { readonly data: ProductFragmentFragment; readonly showSpinner: boolean; readonly pkg?: Package }) => {
  // TODO replace applySpec with transformObject
  return applySpec<ItemContainerProps>({
    cardBadge: (val) => safePath([ 'leftSideContainer', 'guaranteeBadge' ], val)
      .map(toCardBadge)
      .orUndefined(),
    checkOutButtonFooter: (value): SSButtonProps => ({
      ...pipe(path([ 'leftSideContainer', 'extraSensors', 'button' ]), toButton)(value),
      showSpinner
    }),
    linkText: path([ 'leftSideContainer', 'sensorLinkText', 'json' ]),
    priceCalculation: path([ 'leftSideContainer', 'extraSensors', 'priceCalculation', 'json' ]),
    sensors: pipe(path([ 'leftSideContainer', 'extraSensors', 'sensor' ]), unless(isNil, map((sensor: BmsSensorsFragment) => toBmsSensor(sensor, pkg))), defaultTo(<></>))
  })(data)
}

const toItemProducts = (locale: Locale) => (packageVal: Package) =>
  safeProp('products', packageVal)
    .map(products =>
      products
        .filter(packageProduct => !!prop('isViewable', packageProduct))
        .map(toProductList(locale))
    )
const totalQuantity = (packageVal: Package) =>
  safeProp('products', packageVal)
    .map(products =>
      products
        .filter(packageProduct => !!prop('isViewable', packageProduct))
        .map(item => {
          return prop('quantity', item)
        })
    )
const toProductBody = transformObject<MiniCartLineItem, ProductBody>({
  quantity: prop('quantity'),
  sku: prop('sku')
})

const multipliedPrice = (x: MiniCartLineItem): number => x.quantity * getProductFinalPrice(x)

const getExtraPrice =
  (itemList: readonly MiniCartLineItem[]) =>
    Maybe.fromUndefined(itemList)
      .map(x => reduce(add, 0, map(multipliedPrice)(x)))

// export const isShowPromotionalTag =
//   (discountValue: string | undefined, _package: Package): boolean =>
//     (isNotEmpty(discountValue) || !(isNotEmpty(_package.discountService)  || isNotEmpty(_package.discount)))

// TODO connect this to real data
// export const isShowPromotionalTag = (_a, _b) => false

export const getAddToCartRedirectUrl = (locale: Locale) => (originalUrl: string, radioKey: string) => {
  // TODO Need to move this logic/url to CTFL
  const isUS = locale === US_LOCALE
  const isNoPlan = radioKey !== withPlanKey && radioKey !== ss2UpgradeKey
  return isUS && isNoPlan ? '/choose-monitoring' : originalUrl
}

export const maybeAddMonitoringToProducts = (locale: Locale) => (products: ReadonlyArray<ProductBody>, radioKey: string) =>
  ifElse(equals(true), always(ifElse(
    equals(withPlanKey),
    always(products.concat([ {
      quantity: 1,
      // TODO Need to move value to CTFL
      sku: 'SSEDSM2__4867366'
    } ])),
    always(products)
  )(radioKey)),
  always(products))(locale === US_LOCALE)

export const isRefurbishedPageContent = (priceOptions) => {
  const priceOption = priceOptions.filter(option => {
    return option.productType === refurbKey
  })
  return priceOption.length !== 0 ? true : false
}

const toRichTextProductQuantity = (data: ContentfulRichTextWithOptions, total: number) =>
  <RichText json={path([ 'richText', 'json' ], data)}
    options={{
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (_node: Node) =>
          <>{total}</>
      }
    }} />

const ItemContainerComponent: FC<ItemContainerComponentProps> =
  ({ affirmClient = window.affirm, data }: ItemContainerComponentProps) => {
    const locale = useSelector(selectLocale)
    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
    const {
      optimizelyAffirmLearnMore, optimizelyAffirmVariation, optimizelyReady
    } = useOptimizelyAffirm()
    const cartIsLoading = useSelector(selectCartLoading)
    const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)

    const isMobile = !useMediaQuery('TabletAndUp')
    const isPromoTopBanner = useSelector(selectTopBannerVisible)
    const isDesktop = useMediaQuery('DesktopAndUp')

    const skuID = path([ 'rightSideContainer', 'productId' ], data) || ''
    const plaPackageProduct: boolean = skuID.includes('sscs3-pla-')
    const priceOptions: ReadonlyArray<Record<string, unknown>> = safePath([ 'rightSideContainer', 'priceOptions', ], data).getOrElse([ {} ])
    const isRefurb = isRefurbishedPageContent(priceOptions)

    // TODO should this default to the first priceOptions' productType instead of always withPlanKey?
    const defaultPlanKey: string = plaPackageProduct ? noPlanKey : withPlanKey
    const [ radioKey, setRadioKey ] = useState(defaultPlanKey)

    const _package: Either<Error, Package> = useSelector(selectPackage(skuID))
    const itemList = useSelector(selectMiniCartLineItems)
    const product = useSelector(selectProduct(skuID as string))
    const selectState = useSelector((state: ImmutableState) => state)
    const [ shouldTrackEventsDetails, setShouldTrackEventDetails ] = useState<boolean>(true)
    const dispatch = useDispatch()
    const productValue = useMemo(() => product.cata(() => undefined, p => p), [ product ])
    const packageValue = useMemo(() => _package.cata(() => undefined, p => p), [ _package ])
    const packageProps = useMemo(() => _package.map(toItemProducts(locale)), [ locale, _package ])
    const baseProductPrice = useMemo(() => _package.cata(() => 0, prop('price')), [ _package ])
    const promoFlag = useSelector(selectActivePromoFlag)
    const tagBackgroundColor = promoFlag.chain(chainProp('backgroundColor')).orUndefined()
    const tagTextColor = promoFlag.chain(chainProp('textColor')).orUndefined()
    const taggingText = path([ 'leftSideContainer', 'promoCode', 'taggingText', 'json' ], data) as Document
    const stringifiedTaggingText = JSON.stringify(taggingText || '')
    const modifiedTaggingText = JSON.parse(stringifiedTaggingText.replace('"value":" OFF"', '"value":""' ))
    const formattedPrice = formatDisplayPrice(baseProductPrice).orJust('')

    const {
      getPrice,
      getDiscountedPrice,
      getDiscountedPriceWithServicePlan,
      getDiscountedText: promoDiscountText,
      getDiscountedTextWithServicePlan: promoWithMonitoringDiscountText
    } = useRequestPrice(skuID)

    const originalPrice = getPrice
      .chain(formatDisplayPrice)
      .orUndefined()

    const isNoPlanSelected = radioKey !== withPlanKey
    const selectedDiscountedPrice = isNoPlanSelected || isRefurb ? getDiscountedPrice : getDiscountedPriceWithServicePlan

    const formattedSelectedDiscountedPrice = selectedDiscountedPrice
      .chain(formatDisplayPrice)
      .orUndefined()

    const hasDiscount = selectedDiscountedPrice.map(_discountedPrice => getPrice.cata(() => false, _price => _discountedPrice !== _price)).orJust(false)
    const price = <Price discountedPrice={hasDiscount ? formattedSelectedDiscountedPrice : undefined} regularPrice={originalPrice} />

    const imageWithProductId = path([ 'leftSideContainer', 'imageGallery', 'imagesWithProductId' ], data) as ReadonlyArray<ContentfulImage | ContentfulPdpCarouselImage>
    const [ selectedIncludedItemSku, setSelectedIncludedItemSku ] = useState<Maybe<string>>(None())
    const selectedImageIndex = imageWithProductId && Math.max(0, imageWithProductId.findIndex(i => {
      const skuCarouselImage = path([ 'systemComponent', 'sku' ], i)
      return skuCarouselImage === selectedIncludedItemSku
    }))
    const productTitle: string = safePath([ 'rightSideContainer', 'productTitle' ], data).getOrElse('')
    const totalQuantityProduct = packageValue && sum(totalQuantity(packageValue).cata(() => [ 0 ], p => p))
    const [ showSpinner, setShowSpinner ] = useState(cartIsLoading)

    const { Track, trackEvent } = useTracking()
    const rightSideData = prop('rightSideContainer', data)

    const discountValue =
      radioKey === withPlanKey
        ? promoWithMonitoringDiscountText
        : promoDiscountText

    // TODO the pro-setup logic needs to live in it's own component
    // This component is way to big and tries to do way to much and needs to be split up into many smaller components

    const proSetupId: Maybe<string> = safePath([ 'rightSideContainer', 'proSetupId' ], data)

    const proSetupProduct = useSelector((state: ImmutableState) => {
      return proSetupId.chain(id => selectProduct(id)(state).toMaybe())
    })

    /** Does MiniCartLineItems contain a sku for pro setup?  */
    const isProSetUpChecked = useMemo(() => {
      return proSetupId
        .chain(id => safeFind(propEq('masterSku', id), itemList)).isSome()
    }, [ itemList, proSetupId ])

    const setProSetUpCheckbox = (checked: boolean) => {
      proSetupProduct.forEach(product => {
        dispatch(setMiniCartLineItem({
          ...product,
          checked: true,
          quantity: checked ? 1 : 0
        }))
      })
    }

    const productServicePlan: Maybe<string> = safePath([ 'priceOptions', '0', 'productSku' ], rightSideData)

    const [ productPlan, setProductPlan ] = useState(productServicePlan)

    const planProduct = usePriceVariation(productPlan.getOrElse(''))
    const planProductPrice = planProduct.cata(
      () => 0,
      value => prop('price', value)
    )

    const trackPlanToggleEvent = (trackEvent, plan: string) => {
      trackEvent({
        action: 'package-details',
        category: 'monitoring-toggle',
        event: 'onChange',
        label: ifElse(equals(noPlanKey), always('no-plan'), always('add-plan'))(plan)
      })
    }

    // TODO: planSkuId should just be Maybe<string>
    const onPlanChange = useCallback((plan: string, planSkuId?: string) => {
      setRadioKey(plan)
      setProductPlan(Maybe.fromNull(planSkuId))
    }, [])

    // TODO this hook is way to large
    // TODO split this up into smaller tested funtions

    const onAddToCart = useCallback((url: string) => {
      setAddToCartError(null)

      const itemListWithPackage = itemList.map(toProductBody)
        .concat([ {
          quantity: 1,
          sku: skuID
        } ])

      const products =
        productPlan.cata(
          () => itemListWithPackage,
          sku => itemListWithPackage.concat([ {
            quantity: 1,
            sku
          } ]))

      const urlRedirect = (url: string) => {
        // TODO Need to move the logic in CTFL
        const addtionalOption = Maybe.fromNull(rightSideData).chain(safeProp('priceOptions'))
          .orUndefined()
        const urlRedirect = addtionalOption ? getAddToCartRedirectUrl(locale)(url, radioKey) : '/choose-monitoring'
        navigate(urlRedirect, { state: { packageSku: _package.right().sku } })
      }

      const handleSuccess = () => {
        dispatch(initializeMiniCart({}))
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartPackageWithExtrasEvent(
          _package.toMaybe(),
          product.toMaybe(),
          itemList,
          false,
          trackEvent,
          selectState,
          radioKey === withPlanKey
        )
        trackAddToCartEvent(planProduct, trackEvent, 1)
        setShowSpinner(false)
        url && urlRedirect(url)
      }

      const handleFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const skuIdWithOrNullProductError = skuID
        ? `no product with sku ${skuID} found in redux`
        : 'received null/empty sku'

      _package.cata(
        () => {
          setAddToCartError('unrecoverable')
          setShowSpinner(false)
          logError(Error(`Cannot add to cart: ${skuIdWithOrNullProductError}`))
        },
        () => dispatch(IOAddToCart({
          discountCode: isRefurb ? 'SIMPLI25REFURB' : undefined,
          products
        }, handleFailure, handleSuccess))
      )
    },
    // with this many dependencies, *maybe* this callback is doing too much
    [
      itemList,
      skuID,
      productPlan,
      _package,
      product,
      rightSideData,
      locale,
      radioKey,
      isRefurb,
      dispatch,
      optimizelyTrackSiteEvents,
      trackEvent,
      selectState,
      planProduct
    ])

    const coreComponetsProducts = useSelector(selectProducts(systemCoreComponents))
    const coreComponetsNotInStockList: readonly CoreComponentsData[] = componentsNotInStock(coreComponetsProducts)

    const overrideTextMaybe =
      useSelector(selectActivePromoOverrideDiscountText)
        .chain(safeProp('productFlag'))
        .chain(val => val)
    const hasOverrideText = !overrideTextMaybe.isNone()

    const overrideSummaryTextMaybe =
      useSelector(selectActivePromoOverrideDiscountText)
        .chain(safeProp('discountSummary'))
        .chain(val => val)
    const hasOverrideSummaryText = !overrideSummaryTextMaybe.isNone()

    const overrideToggleTextMaybe =
    useSelector(selectActivePromoOverrideDiscountText)
      .chain(safeProp('toggleBox'))
      .chain(val => val)
    const hasOverrideToggleText = !overrideToggleTextMaybe.isNone()

    const withMonitoringGiftItem = getMonitoringGiftItems()
    const withoutMonitoringGiftItem = getNonMonitoringGiftItems()

    const applicableGiftItem = useMemo(() => {
      const freeGiftItems: GiftItemContainer = {
        withMonitoring: withMonitoringGiftItem || null,
        withoutMonitoring: withoutMonitoringGiftItem || null
      }
      const freeGiftsExist = (withMonitoringGiftItem || withoutMonitoringGiftItem)
      const monitoringPlan = radioKey === withPlanKey ? 'withMonitoring' : 'withoutMonitoring'
      // first need to ensure freeGiftItems and at least one relevant field exist
      return freeGiftsExist
      // then check that a gift item exists for that plan before returning its title
        ? freeGiftItems[monitoringPlan] ?
          {
            isFreeItem: true,
            productName: `1 FREE ${freeGiftItems[monitoringPlan].title}`,
            sku: freeGiftItems[monitoringPlan].sku
          } : null
        : null
    }, [ withMonitoringGiftItem, withoutMonitoringGiftItem, radioKey ])

    const includedItemsPopup = path([ 'rightSideContainer', 'includedItemsPopup' ], data)
    // TODO extracting the data out at this stage seems premature
    const itemDetailProps =
      toItemDetail(onAddToCart,
        packageProps.cata(
          () => [],
          p => {
            const packages = p.getOrElse([])
            return applicableGiftItem ? [ ...packages, applicableGiftItem ] : packages
          }
        ),
        onPlanChange,
        showSpinner,
        rightSideData,
        includedItemsPopup,
        setSelectedIncludedItemSku,
        isProSetUpChecked,
        setProSetUpCheckbox,
        coreComponetsNotInStockList,
        trackEvent,
        isRefurb,
        totalQuantityProduct,
        _package.cata(() => undefined, i => i),
        planProductPrice,
        overrideToggleTextMaybe,
        hasOverrideToggleText,
        cartIsLoading
      )

    const mounted = useRef(false)
    useEffect(() => {
      !mounted.current ? mounted.current = true : trackPlanToggleEvent(trackEvent, radioKey)
    }, [ trackEvent, radioKey ])


    // TODO move this logic into ecomm-data
    const [ personalizePrices, setPersonalizePrices ] = useState(None<number>())
    // useCallback is only for user interactions or other outside into
    const getPriceProps = useCallback(
      (_packageVal: Package) => {

        const rawRegularPrice = safeProp('price', _packageVal)
        const addExtrasPrice = (p: number) => add(p, personalizePrices.orJust(0))

        const discountedPrice = selectedDiscountedPrice
          .map(addExtrasPrice)
          .chain(formatDisplayPrice)
          .orUndefined()

        //don't format discount price as a string, returns as a number instead
        const rawdiscountedPrice = selectedDiscountedPrice
          .orElse(rawRegularPrice)
          .map(addExtrasPrice)
          .getOrElse(_packageVal.price)

        const discountValuePrice = selectedDiscountedPrice
          .chain(_discountedPrice =>
            rawRegularPrice
              .map(subtract(_discountedPrice)))
          .map(formatDisplayPrice)
          .orUndefined()

        // TODO move this out into it's own function and unit test it
        const placeholderText = cond([
          [ equals(withPlanKey), always(
            selectedDiscountedPrice.ap(rawRegularPrice.map(p => subtract(p)))
          ) ],
          [ equals(refurbKey), always(
            safeDiscountPrice('discountedPriceWithServicePlan', _packageVal)
              .map(addExtrasPrice)) ],
          [ equals(noPlanKey), always(
            safeDiscountPrice('discountedPriceWithServicePlan', _packageVal)
              .map(addExtrasPrice)) ]
        ])(radioKey)
          .chain(formatDisplayPrice)
          .orUndefined()

        // TODO move this out into it's own function and unit test it
        const additionalOptionItemsProps =
          Maybe.fromNull(rightSideData)
            .chain(safeProp('priceOptions'))
            .map(__ => toAdditionalOptionItem(onPlanChange, _packageVal, isRefurb, promoDiscountText.orUndefined(), promoWithMonitoringDiscountText.orUndefined(), planProductPrice, overrideToggleTextMaybe, hasOverrideToggleText)(rightSideData))
            .orUndefined()

        // additionalData.discount is the problem, it's pulling from redux and not from the package

        // TODO move this out into it's own function and unit test it
        const additionalData = Maybe.fromUndefined(additionalOptionItemsProps)
          .chain(safeProp('additionalOptionItems'))
          .chain(safeFind<AdditionalOptionItemType>(propEq('skuId', radioKey)))
          .orUndefined()

        const discountText = discountValue
          .map(defaultDiscount => {
            const service = prop('sensorListDiscountText', defaultTo({})(additionalData))
            const summaryText = (!isRefurb && radioKey === withPlanKey ) ? (hasOverrideSummaryText ? overrideSummaryTextMaybe.some() : defaultDiscount) : defaultDiscount
            return `+ ${summaryText} ${service}:`
          })
          .orUndefined()

        const affirmPromoMessage = optimizelyReady && <AffirmPromoMessage affirmClient={affirmClient}
          className={optimizelyAffirmVariation === 'variation_1' ? 'affirm-as-low-as-test' : 'affirm-as-low-as'}
          onLearnMoreClick={optimizelyAffirmLearnMore}
          pageType={'product'}
          price={rawdiscountedPrice} /> || <></>

        // if one of core components is out of stock, add delayed shipping message to all packages except the refurbished packages
        const showDelayedShippingMessage: boolean = (!isRefurbishedPageContent(priceOptions) && coreComponetsNotInStockList.length > 0)
        // TODO split this up into smaller functions that are each tested
        const itemDetail = additionalOptionItemsProps ? {
          ...itemDetailProps,
          additionalOptionItemsProps,
          affirmPromoMessage,
          // TODO Reintroduce the planText when safeDiscountPrice is reimplemented
          interactivePlan: placeholderText, // placeholderText && getPlanText(radioKey)(rightSideData),
          outOfStockMessage: showDelayedShippingMessage ? renderCoreComponentsNotInStockMsg(coreComponetsNotInStockList) : undefined,
          placeholderText,
          price
        } : {
          ...itemDetailProps,
          affirmPromoMessage,
          placeholderText,
          price
        }

        const offerTag =
          discountValue
            .filter(val => !!val && !!taggingText)
            .map(val => ({
              placeholderText: (!isRefurb && radioKey === withPlanKey ) ? ( hasOverrideText ? overrideTextMaybe.some() : val ) : val,
              tagBackgroundColor,
              tagTextColor,
              taggingText: (radioKey === withPlanKey) ? hasOverrideText ? modifiedTaggingText : taggingText : taggingText,
            }))
            .orUndefined()

        return ({
          discountAppliedPrice: discountValuePrice,
          discountText: discountText,
          extrasPrice: formatDisplayPrice(personalizePrices.getOrElse(defaultPrice)).orJust(''),
          itemDetail: itemDetail,
          offerTag: !plaPackageProduct ? offerTag : undefined,
          systemTotalPrice: discountedPrice
        })
      }
      // we want to ignore _package changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , [ personalizePrices, radioKey, onPlanChange, rightSideData, isPromoTopBanner, taggingText, tagBackgroundColor, itemDetailProps.additionalOptionItemsProps, discountValue ])

    useEffect(() => {
      const trackEvents = (packageValue: Package, productValue: Product) => {
        trackPackageDetailEvent(productValue, packageValue, false, trackEvent, selectState, radioKey === withPlanKey)
        setShouldTrackEventDetails(false)
      }

      !!(productValue && packageValue && selectState && shouldTrackEventsDetails)
        && trackEvents(packageValue, productValue)
    }, [ productValue, packageValue, radioKey, selectState, shouldTrackEventsDetails, trackEvent ])

    useEffect(() => {
      const prices = getExtraPrice(itemList)
      setPersonalizePrices(prices)
    }, [ itemList ])

    const skus = safeProp('leftSideContainer', data)
      .chain(safeProp('extraSensors'))
      .chain(safeProp('sensor'))
      .map(sensors => sensors.filter((sensor): sensor is BmsSensorsFragment => !!sensor && 'productId' in sensor))
      .map(sensors => sensors.map(prop('productId')))
      .orJust([])
      .filter(isNotNil)

    // TODO This 1 component is doing a lot
    // We need to split this up into smaller components
    // It should be the carousel, the system details, the monitoring choice, a component for each custom item (map over an array), and the subtotal/add to cart section
    // This woould make it a lot easier to make changes or find bugs
    const images = path([ 'leftSideContainer', 'imageGallery', 'images' ], data) as ReadonlyArray<ContentfulImage>
    const imageProps = !isNil(imageWithProductId) ? imageWithProductId : images
    const toImage = imageProps.map((image) =>
      Maybe.fromUndefined('imageItem' in image ? prop('imageItem', image) : prop('image', image))
        .orElse(Just(image))
        .map((i) => toProductImage(i, isMobile, isDesktop))
        .some()
    )

    return (
      <Track>
        <PriceProvider skus={skus}>
          <div data-component="ItemContainerComponent">
            <ItemContainer
              {...toItemContainer({
                data,
                pkg: _package.fold<Package | undefined>(() => undefined, identity),
                showSpinner
              })}
              checkOutClick={() => {
                const url = path([ 'leftSideContainer', 'extraSensors', 'button', 'url' ])(data) as string // TODO remove "as string"
                onAddToCart(url)
              }}
              errorMessage={addToCartError && <AddToCartError errorType={addToCartError} />}
              itemDetail={itemDetailProps}
              packagePrice={formattedPrice}
              {..._package.cata(
                () => ({}), // TODO should this really be empty?
                (_packageVal) => getPriceProps(_packageVal))
              }
              images={toImage}
              selectedImageIndex={selectedImageIndex}
            />
          </div>
        </PriceProvider>
      </Track>
    )
  }

export const query = graphql`
  fragment productFragment on ContentfulProduct{
    id
    internal {
      type
    }
    leftSideContainer {
      promoCode {
        ...promotionalTagging
      }
      guaranteeBadge {
        description {
          description
        }
        images {
          id
          title
          fluid(maxWidth: 511) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title {
          title
        }
      }
      imageGallery {
        images {
          fluid(maxWidth: 833) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          id
        }
        imagesWithProductId {
          ... on ContentfulImage {
            ...contentfulImage
          }
          ... on ContentfulPdpCarouselImage {
            image {
              fluid(maxWidth: 833) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              id
            }
            systemComponent {
              displayName
              sku
            }
          }
        }
      }
      sensorLinkText {
        json
      }
      extraSensors {
        sensor {
          ... on ContentfulBmsSensors {
            ...bmsSensors
          }
          ... on ContentfulVariationContainer {
            ...variationContainer
          }
        }
        priceCalculation {
          json
        }
        button {
          text
          type
          url
        }
      }
    }
    rightSideContainer {
      id
      productId
      productTitle
      description {
        json
      }
      productQuantity{
        ... on ContentfulRichTextWithOptions {
          ...richTextWithOptions
        }
      }
      includedItemsTitle
      priceOptionsTitle
      proSetupId
      proSetupCheckbox{
        answerOptions
        characterType
        checked
        customLayoutField{
          json
        }
        defaultChecked
        maximumCharacter
        placeholderText
        propName
        title
        type
      }
      proSetupModal{
        ... on ContentfulModal {
          ...modalFragment
        }
      }
      priceOptions {
        title
        description {
          json
        }
        productSku
        detailsModal {
          ... on ContentfulModal {
            ...modalFragment
          }
        }
        savingDetails {
          json
        }
        productType
        titleDiscountText {
          json
        }
        titleProductName
        sensorListDiscountText
        productName {
          json
        }
      }
      toggleBoxesHeadline {
        json
      }
      button {
        text
        type
        url
      }
      customizedSystem {
        ... on ContentfulSmallTextSection {
          id
          description {
            json
          }
        }
        ... on ContentfulModal {
          ...modalFragment
        }
      }
      includedItemsPopup {
        systemComponent {
          sku
        }
        modalContent {
          ... on ContentfulBanner {
            ...contentfulBanner
          }
        }
      }
      descriptionRichTextWithOptions {
        ... on ContentfulRichTextWithOptions {
          ...richTextWithOptions
        }
      }
      hiddenItems {
        ... on ContentfulSystemCollection {
          id
          products {
            sku
          }
        }
      }
    }
  }
`

export default ItemContainerComponent
