import { Document } from '@contentful/rich-text-types'
import deriveHtmlId from '@simplisafe/ewok/contentful-utils/deriveHtmlId'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  Column,
  HiddenAnchor,
  ProductCard,
  RichText,
  Row
} from '@simplisafe/ss-react-components'
import type { Spans } from '@simplisafe/ss-react-components/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from 'react'

import type { ContentfulFloorPlan, ContentfulProductCardFloorPlan } from '../../../graphql'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getDescriptionJson from '../../util/getDescriptionJson'
import { nullToUndefined } from '../../util/helper'

export type FloorPlanProps = {
  readonly data: ContentfulFloorPlan
}

const renderProductCards = (
  data: ContentfulFloorPlan,
  index: number,
  setIndex: Dispatch<SetStateAction<number>>
) => {
  const productCardData = prop('productCards', data)
  return productCardData ? (
    // @ts-expect-error TS(2345) FIXME: Argument of type '(card: ContentfulProductCardFloo... Remove this comment to see the full error message
    productCardData.map((card: ContentfulProductCardFloorPlan, idx: number) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulProductCardFloorPlan' ... Remove this comment to see the full error message
      const descriptionData = getDescriptionJson(card)
      const descriptionRichText: ReactNode =
         doesDocumentHaveContent(descriptionData)

           ? <RichText json={descriptionData} />
           : null

      const fluidImageData = path([ 'productImage', 'fluid' ], card)
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      const cardImage = fluidImageData ? <Img fluid={fluidImageData}
        style={{ width: '130px', }} /> : null
      return descriptionRichText
        ? ( <Column key={idx}
          spans={[ 12, 6, 3 ]}>
          <div data-test={idx}
            onClick={() => setIndex(idx)}
            onMouseEnter={() => setIndex(idx)}>
            <ProductCard description={descriptionRichText}
              img={cardImage}
              isActive={index === idx}/>
          </div>
        </Column>
        ) : null
    })
  ) : null
}

const renderTopSection = (
  isDesktop: boolean,
  isTabletAndUp: boolean,
  sectionDescription: Document,
  selectedFloorPlanImageFluid: FluidObject
) => {
  const spans: Spans = [ 12, 12, 6 ]
  return (
    isDesktop ?
      (<Row>
        <Column spans={spans}>

          <div style={{ paddingLeft: '64px', }}> <RichText json={sectionDescription}/></div>

        </Column>
        <Column spans={spans}>
          <div style={{ paddingRight: '64px' }}><Img fluid={selectedFloorPlanImageFluid} /></div>
        </Column>
      </Row>)
      :
      (<Row>
        {isTabletAndUp ?
          <>
            <Column spans={spans}>
              <div style={{
                paddingLeft: '40px',
                paddingRight: '40px'
              }}><Img fluid={selectedFloorPlanImageFluid} /></div>
            </Column>
            <Column spans={spans}>
              <div style={{
                paddingLeft: '80px',
                paddingRight: '80px'

              }}> <RichText json={sectionDescription}/></div>
            </Column> </> :
          <>
            <Column spans={spans}>
              <div style={{
                paddingLeft: '0px',
                paddingRight: '0px'
              }}><Img fluid={selectedFloorPlanImageFluid} /></div>
            </Column>
            <Column spans={spans}>
              <div style={{
                paddingLeft: '16px',
                paddingRight: '16px'

              }}> <RichText json={sectionDescription}/></div>
            </Column>
          </>
        }
      </Row>)
  )
}

const FloorPlan = ({ data }: FloorPlanProps) => {
  const [ index, setIndex ] = useState<number>(0)
  const isDesktopOnly = useMediaQuery('DesktopAndUp')
  const isTabletAndUp = useMediaQuery('TabletAndUp')

  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulFloorPlan' is not assi... Remove this comment to see the full error message
  const sectionDescription = getDescriptionJson(data)
  const listOFfloorPlanImages = prop('floorPlanImages', data)
  // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
  const selectedFloorPlanImageFluid: FluidObject = listOFfloorPlanImages ? prop('fluid', listOFfloorPlanImages[index]) : undefined

  const htmlId = deriveHtmlId({
    ...data,
    title: nullToUndefined(data.title)
  })
  // padding left and right desktop 64px , Tablet 80px  and mobile 16px

  const paddingValue = isDesktopOnly ? '64px' : isTabletAndUp ? '80px' : '16px'
  return ( sectionDescription && selectedFloorPlanImageFluid ) ? (
    <div key={data.id}>
      <HiddenAnchor id={htmlId} />
      {renderTopSection(isDesktopOnly, isTabletAndUp, sectionDescription, selectedFloorPlanImageFluid)}
      <div style={{
        paddingBottom: '50px',
        paddingLeft: paddingValue,
        paddingRight: paddingValue,
        paddingTop: '50px'
      }}>
        <Row alignItems={'stretch'}>
          {renderProductCards(data, index, setIndex)}
        </Row>
      </div>
    </div>
  ) : null
}

export default FloorPlan
export const FloorPlanFragment = graphql`#graphql
  fragment floorPlan on ContentfulFloorPlan {
    id
    internal {
      type
    }
    description {
      json
    }
    floorPlanImages {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    title
    productCards {
      productImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      title
      productCardHeader
      description {
        json
      }
    }
  }
`
