import propOr from '@simplisafe/ewok/ramda/propOr'
import { Modal, Text } from '@simplisafe/ss-react-components'
import React, { ReactNode } from 'react'

import FluidImg, { FluidImgProps } from '../../FluidImg'

type InfoModalProps = {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly title: string
  readonly description: ReactNode
  readonly image?: FluidImgProps
}

const InfoModal = ({
  isOpen,
  onClose,
  title,
  description,
  image
}: InfoModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      size={'small'}
      style={{
        content: {
          background: 'white',
          color: 'black',
        },
        overlay: { background: 'accentBlueGray' },
      }}
    >
      <div className="p-16">
        <Text textAlignment="center">
          <p style={{
            fontSize: '36px',
            fontWeight: 200,
            lineHeight: '44px',
          }}>
            {title}
          </p>
        </Text>
        <Text className="mb-8 font-thin">
          {description}
        </Text>
        {image && <FluidImg
          alt={`${propOr('', 'title', image)}`}
          fluid={propOr({}, 'fluid', image)}
          imgStyle={{ objectFit: 'contain' }}
          style={{
            height: '100%',
            width: '100%',
          }}
        />}
      </div>
    </Modal>
  )
}

export default InfoModal
