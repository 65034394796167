import { userAttributes } from '@lib/tracking/src/optimizely'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import {
  PriceData, Prices, requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { Maybe } from 'monet'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getRawDiscountedPrice, getRawPrice } from '../providers/PriceProvider'
import {
  getDiscountedTextHelper, getDiscountedTextWithServicePlanHelper, getFormattedPriceHelper,
  getRawDiscountedPriceWithServicePlan
} from '../providers/PriceProvider/PriceProvider'
import { getValueFromPartnerCookie } from '../util/partnerCookie'
import useOptimizelyParams from './useOptimizelyParams'

const useRequestPrice = (sku: string) => {
  const [ isMounted, setIsMounted ] = useState(true)
  const [ prices, setPrices ] = useState<Prices>({})
  const [ isLoading, setLoading ] = useState(true)

  const maybeCustomerGroup = useSelector(selectCustomerGroupKey)
  const partnerCookie = getValueFromPartnerCookie('partnerGroup') || undefined
  const customerGroup = maybeCustomerGroup.orUndefined() || partnerCookie

  const optimizelyParams = useOptimizelyParams()

  const fallback = useSelector(selectItemsFromSkus([ sku ]))

  useEffect(() => {
    const attributes = userAttributes()
    isMounted && requestPrices([ sku ], attributes, customerGroup, optimizelyParams)(() => {
      setLoading(false)
    })((response: Maybe<Record<string, PriceData>>) => {
      response.forEach((newPrices: Record<string, PriceData>) => setPrices(newPrices))
      setLoading(false)
      setTimeout(() => {
        window.prerenderReady = true
      }, 3000)
    })

    return () => setIsMounted(false)
  }, [ sku, isMounted, customerGroup, optimizelyParams ])

  return {
    getDiscountedPrice: getRawDiscountedPrice(prices, fallback, isLoading)(sku),
    getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(prices, fallback, isLoading)(sku),
    getDiscountedText: getDiscountedTextHelper(prices, fallback, isLoading)(sku),
    getDiscountedTextWithServicePlan: getDiscountedTextWithServicePlanHelper(prices, fallback, isLoading)(sku),
    getFormattedPrice: getFormattedPriceHelper(prices, fallback, isLoading)(sku),
    getPrice: getRawPrice(prices, fallback, isLoading)(sku)
  }
}

export default useRequestPrice
