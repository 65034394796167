import isArray from '@simplisafe/ewok/ramda-adjunct/isArray'
import { safeFind } from '@simplisafe/monda'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import compose from 'ramda/src/compose'
import filter from 'ramda/src/filter'
import ifElse from 'ramda/src/ifElse'
import pathEq from 'ramda/src/pathEq'
import propEq from 'ramda/src/propEq'

import {
  ContentfulDefaultBanner, ContentfulFloatingBar, ContentfulHeaderPartner, ContentfulHeaderWithProgressBar,   ContentfulModalPromoPopup, ContentfulTrustpilotReviewsBanner, ContentfulVariationContainer,
  FloatingPromoWidgetFragment
} from '../../../graphql'
import { ContentfulHeaderFragment } from '../Header/query'
import { PageLayoutComponent } from './types'

export const getPageLayoutComponent = (components: readonly PageLayoutComponent[], contentType: string): Maybe<PageLayoutComponent> => ifElse(
  isArray,
  safeFind<PageLayoutComponent>(propEq('__typename', contentType)),
  always(None<PageLayoutComponent>())
)(components)

/**
 * Will return the first ContentfulVariationContainer found in the components argument, whose variations are of type contentType. Thus enabling the ability
 * to run more than one experiment in the Web Config layout components.
 */
const getVariationContainerComponent = (components: readonly PageLayoutComponent[], contentType: string): Maybe<ContentfulVariationContainer> => ifElse(
  isArray,
  compose(
    safeFind<PageLayoutComponent>(pathEq([ 'variations', '0', '__typename' ], contentType)),
    filter(propEq('__typename', 'ContentfulVariationContainer'))
  ),
  always(None<ContentfulVariationContainer>())
)(components)

export function getPageLayoutComponents(components: readonly PageLayoutComponent[]) {
  const headerProgressBar: Maybe<ContentfulHeaderWithProgressBar> = getPageLayoutComponent(components, 'ContentfulHeaderWithProgressBar')
  const headerPartner: Maybe<ContentfulHeaderPartner> = getPageLayoutComponent(components, 'ContentfulHeaderPartner')
  const simpleFooter = getPageLayoutComponent(components, 'ContentfulSmallTextSection')
  // TODO fix this type
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const contentfulHeader = getPageLayoutComponent(components, 'ContentfulHeader') as Maybe<ContentfulHeaderFragment>
  const contentfulDefaultBanner: Maybe<ContentfulDefaultBanner> = getPageLayoutComponent(components, 'ContentfulDefaultBanner')
  const contentfulHeaderVariationContainer = getVariationContainerComponent(components, 'ContentfulHeader')
  const contentfulFloatingPromoWidget: Maybe<FloatingPromoWidgetFragment> = getPageLayoutComponent(components, 'ContentfulFloatingPromoWidget')
  const popupWizard: Maybe<ContentfulFloatingBar> = getPageLayoutComponent(components, 'ContentfulFloatingBar')
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const contentfulFooter: Maybe<ContentfulFooterFragment> = getPageLayoutComponent(components, 'ContentfulFooter')
  const modalExitIntent = getPageLayoutComponent(components, 'ContentfulModalExitIntent')
  const modalPromoPopup: Maybe<ContentfulModalPromoPopup> = getPageLayoutComponent(components, 'ContentfulModalPromoPopup')
  const contentfulModalPromoPopupVariationContainer = getVariationContainerComponent(components, 'ContentfulModalPromoPopup')
  const authentication = getPageLayoutComponent(components, 'ContentfulAuthentication')
  const trustpilotBanner: Maybe<ContentfulTrustpilotReviewsBanner> = getPageLayoutComponent(components, 'ContentfulTrustpilotReviewsBanner')

  return {
    authentication,
    contentfulDefaultBanner,
    contentfulFloatingPromoWidget,
    contentfulFooter,
    contentfulHeader,
    contentfulHeaderVariationContainer,
    contentfulModalPromoPopupVariationContainer,
    headerPartner,
    headerProgressBar,
    modalExitIntent,
    modalPromoPopup,
    popupWizard,
    simpleFooter,
    trustpilotBanner
  }
}
