import { getLeadData } from '@lib/tracking/src/cookies'
import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import {
  leadGenSubscribe, LeadGenSubscriptionParams, leadGenUnsub
} from '@simplisafe/ss-ecomm-data/leads/subscription'
import {
  FormCheckbox,
  FormField,
  FormTextInput,
  RichText, SSButton,
  Text
} from '@simplisafe/ss-react-components'
import { UnsubscribeForm } from '@simplisafe/ss-react-components'
import {
  Form, Formik, FormikHandlers, FormikHelpers
} from 'formik'
import propOr from 'ramda/src/propOr'
import React, {
  Dispatch,
  FC,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  object,
  string,
} from 'yup'

import { UnsubscribePageContentsFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import { strToCamelCase } from '../../util/helper'
import { verifyButtonColor } from '../../util/verifyButtonColor'

export type UnsubscribePageContentsProps = {
  readonly data: UnsubscribePageContentsFragment
}

type FormPayload = {
  readonly emailAddress: string
  readonly subscribeCheckbox: boolean
}

const UnsubscribePageContents: FC<UnsubscribePageContentsProps> = ({ data }: UnsubscribePageContentsProps) => {
  const [ wasSuccessfullySubmitted, setWasSuccessfullySubmitted ] = useState(false)

  const emailLabel = safeProp('emailFieldLabel', data).getOrElse('Email Address')

  const schema = object().shape({
    emailAddress: string().label('Email Address')
      .trim()
      .email()
      .required(propOr<string, string>('', 'emptyStateErrorMessage', data))
  })

  // use useState instead of Formik status for type-safety
  const [ submitStatus, setSubmitStatus ]  = useState<'error' | 'success' | undefined>()

  const emailInputRef = useRef<HTMLInputElement>(null)

  // "Instance variable" to figure out, inside Formik's onSubmit, if we clicked subscribe or unsubscribe button to submit the form
  const unsubClicked = useRef<boolean>(true)

  useEffect(() => {
    emailInputRef.current && emailInputRef.current.focus()
  }, [])

  const handleSubmitFailure = (setSubmitting: FormikHelpers<Dispatch<boolean>>['setSubmitting']) => () => {
    setSubmitStatus('error')
    setSubmitting(false)
  }

  const handleUnsubSubmitSuccess = (
    setSubmitting: FormikHelpers<Dispatch<boolean>>['setSubmitting'],
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
    setFieldTouched: FormikHelpers<unknown>['setFieldTouched']
  ) => () => {
    setWasSuccessfullySubmitted(true)
    setFieldValue('emailAddress', '')
    setFieldTouched('emailAddress', false)
    setSubmitting(false)
    setSubmitStatus('success')
  }

  const handleSubscribeSubmitSuccess = (setSubmitting: FormikHelpers<Dispatch<boolean>>['setSubmitting']) => () => {
    setWasSuccessfullySubmitted(true)
    setSubmitting(false)
    setSubmitStatus('success')
  }

  const handleUnsubscribeSubmit = (handleSubmit: FormikHandlers['handleSubmit']) => () => {
    setWasSuccessfullySubmitted(false)
    handleSubmit()
    unsubClicked.current = true
  }

  const handleSubscribeSubmit = (handleSubmit: FormikHandlers['handleSubmit']) => () => {
    setWasSuccessfullySubmitted(false)
    handleSubmit()
    unsubClicked.current = false
  }

  const emailAddressInput =
    <FormField hideLabel={true} label={emailLabel}
      name="emailAddress">
      <Text fontWeight="medium">{emailLabel}</Text>
      <FormTextInput
        fullWidth={true}
        id="emailAddress"
        name="emailAddress"
        onKeyDown={() => { unsubClicked.current = true }}
        placeholder={safeProp('emailFieldPlaceholder', data).getOrElse('enter email')}
        ref={emailInputRef}
        type="text"
      />
    </FormField>

  const subscribeCheckbox =
    <FormCheckbox
      label={safeProp('subscribeCheckboxLabel', data).getOrElse('Subscribe to marketing emails and offers from SimpliSafe')}
      name="subscribeCheckbox"
    />

  const unsubscribeButton = (handleSubmit: FormikHandlers['handleSubmit'], disabled: boolean) =>
    <SSButton
      color={
        safeProp('unsubscribeButton', data)
          .chain(safeProp('type'))
          .map(type => verifyButtonColor(strToCamelCase(type)))
          .getOrElse('primary')
      }
      disabled={disabled}
      onClick={handleUnsubscribeSubmit(handleSubmit)}
    >
      {path([ 'unsubscribeButton', 'text' ], data)}
    </SSButton>

  const subscribeButton = (handleSubmit: FormikHandlers['handleSubmit'], disabled: boolean, subscribeCheckbox: boolean) =>
    <SSButton
      color={
        safeProp('subscribeButton', data)
          .chain(safeProp('type'))
          .map(type => verifyButtonColor(strToCamelCase(type)))
          .getOrElse('primary')
      }
      disabled={disabled}
      onClick={() => subscribeCheckbox && handleSubscribeSubmit(handleSubmit)()}
    >
      {path([ 'subscribeButton', 'text' ], data)}
    </SSButton>

  const successContent = safeProp('successMessage', data)
  const successBanner = wasSuccessfullySubmitted && successContent.map((content) => <RichText json={getJson(content)} key={'banner'} />).orNull()

  const needHelpPromptText = safeProp('needHelpPrompt', data)
  const needHelpPrompt = needHelpPromptText.map((text) => <RichText json={getJson(text)} key={'prompt'} />).orNull()

  return (
    <Formik
      initialValues={{
        emailAddress: propOr<string, string>('', 'email', getLeadData()),
        subscribeCheckbox: false
      }}
      onSubmit={
        (
          { emailAddress, subscribeCheckbox }: FormPayload,
          {
            setSubmitting, setFieldValue, setFieldTouched
          }
        ) => {
          const leadGenSubscriptionParams: LeadGenSubscriptionParams = { email: emailAddress.trim() }
          const failureCB = handleSubmitFailure(setSubmitting)
          const unsubSuccessCB = handleUnsubSubmitSuccess(setSubmitting, setFieldValue, setFieldTouched)
          const subSuccessCB = handleSubscribeSubmitSuccess(setSubmitting)

          unsubClicked.current && leadGenUnsub(leadGenSubscriptionParams)(failureCB)(unsubSuccessCB)
          !unsubClicked.current && subscribeCheckbox && leadGenSubscribe(leadGenSubscriptionParams)(failureCB)(subSuccessCB)
        }
      }
      validationSchema={schema}
    >
      {({
        handleSubmit, isSubmitting, values
      }) =>
        <Form>
          <UnsubscribeForm
            emailAddressInput={emailAddressInput}
            emailAddressPrompt={safeProp('emailPrompt', data).getOrElse('')}
            needHelpLabel={safeProp('needHelpLabel', data).getOrElse('')}
            needHelpPrompt={needHelpPrompt}
            pageTitle={safeProp('pageTitle', data).getOrElse('')}
            subscribeButton={subscribeButton(handleSubmit, isSubmitting, values.subscribeCheckbox)}
            subscribeCheckbox={subscribeCheckbox}
            subscriptionsHeader={safeProp('subscribeHeader', data).getOrElse('')}
            subscriptionsPrompt={safeProp('subscribePrompt', data).chain(safeProp('subscribePrompt'))
              .getOrElse('')}
            successBanner={successBanner}
            unsubscribeButton={unsubscribeButton(handleSubmit, isSubmitting)}
            unsubscribeFinePrint={safeProp('unsubscribeFinePrint', data).chain(safeProp('unsubscribeFinePrint'))
              .getOrElse('')
            }
            unsubscribeHeader={safeProp('unsubscribeHeader', data).getOrElse('')}
            unsubscribePrompt={safeProp('unsubscribePrompt', data).chain(safeProp('unsubscribePrompt'))
              .getOrElse('')}
          />
          <input className="hidden" type="submit" />
          { !isSubmitting && submitStatus === 'error' && <Text className='mt-4' textColor='errorRed'>{propOr<string, string>('', 'genericErrorMessage', data)}</Text> }
        </Form>
      }
    </Formik>
  )
}

export default UnsubscribePageContents
