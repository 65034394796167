/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/* istanbul ignore file */
// @ts-nocheck
/**
 * 
 * 
 * This file is being deprecated and should not be changed. 
 * 
 * Type checking and linting have been disabled.
 * 
 * Please do not make any changes.
 * 
 * You have been warned.
 * 
 * 
 */
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import {
  clearCartError, getMatchingShippingRate
} from '@simplisafe/ss-ecomm-data/cart'
import {
  getMatchingShippingPrice, IOSetShippingMethod, IOUpdateCart, IOUpdateDiscountCodeToCart
  , LOCAL_STORAGE_CARTID
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import {
  buildShippingAddressUpdateAction,
  commercetoolsGetShippingMethods,
  ImmutableCart,
  ShippingMethod,
  ShippingMethodPagedQueryResponse
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { PartnerWithUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsForm'
import { formatCTPriceToNumber } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { IOSubmitPartnerContactForm } from '@simplisafe/ss-ecomm-data/deprecated/partnerWithUs/partnerWithUsForm'
import {
  leadGenCapture,
  LeadGenCaptureParams,
  LeadGenCaptureResponse,
} from '@simplisafe/ss-ecomm-data/leads/capture'
import { leadGenUnsub } from '@simplisafe/ss-ecomm-data/leads/subscription'
import { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import {
  selectActivePromoCode,
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import {
  cookiesOption,
  fetchUserCheckoutDataWithPopup,
  fetchUserInfoData,
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { handleBrazeTrackingEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import type { CartLineItemProps } from '@simplisafe/ss-react-components/CartLineItem'
import { Form } from '@simplisafe/ss-react-components'
import { FormProps } from '@simplisafe/ss-react-components/Form'
import { fork } from 'fluture'
import { graphql, navigate } from 'gatsby'
import { get, set } from 'local-storage'
import {
  Fail, Just, Maybe, Validation
} from 'monet'
import always from 'ramda/src/always'
import and from 'ramda/src/and'
import append from 'ramda/src/append'
import applySpec from 'ramda/src/applySpec'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import find from 'ramda/src/find'
import findIndex from 'ramda/src/findIndex'
import has from 'ramda/src/has'
import head from 'ramda/src/head'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
import not from 'ramda/src/not'
import or from 'ramda/src/or'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import reduce from 'ramda/src/reduce'
import reject from 'ramda/src/reject'
import split from 'ramda/src/split'
import tail from 'ramda/src/tail'
import unless from 'ramda/src/unless'
import values from 'ramda/src/values'
import when from 'ramda/src/when'
import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react'
import {
  ErrorOption, FieldName, FieldValues
} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { ThunkDispatch } from 'redux-thunk'
import Cookies from 'universal-cookie'
import { v4 } from 'uuid'

import { ContentfulLoginforgetPassword as ContentfulLoginforgetPassword, ContentfulRadioButton } from '../../../graphql'
import { formatDisplayPrice, toMaybeOrNone } from '../../commercetools/price'
import {
  COOKIE_DRUPAL_UID,
  COOKIE_LEAD_DATA,
} from '@lib/tracking/src/cookies'
import {
  OptimizelyEvent,
  useOptimizelyTrackSiteEvents
} from '@lib/tracking/src/optimizely'
import { trackEpsilonAbacusOptIn, trackSubmitLeadEvent } from '../../util/analytics'
import { getCartDiscountCode } from '../CartDetailsComponent/transformLineItem'
import { USER_DISCOUNT_CODE } from '../GlobalPromotionalComponent'
import { buildAddressUpdateActions, propOrString } from './actionBuilders'
import {
  BUSINESS_TYPE, deviceIdStorageKey, EPSILON_ABACUS_PROP_NAME, KEY_AUTHENTICATED, OFFER_PROP_NAME, PHONE, SOURCE, TITLE_COUPONS,
  TITLE_EMAIL, TITLE_HOW_HEAR_INFO,
  TITLE_SHIPPING_ADDRESS, TITLE_SHIPPING_OPTIONS, TYPE_ERROR
} from './const'
import { CheckoutFormData } from './lib'

const cookies = new Cookies()

// todo move phone and postal code validation to ewok

const phoneRegExArray = [ '^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|',
  '((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$' ]
const phoneNumberRegexp = phoneRegExArray.join('')

// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
const ukPostalCodeRegex = '^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$'

const validationPatternForUK = {
  'phoneNumber': new RegExp(phoneNumberRegexp),
  'postalCode': new RegExp(ukPostalCodeRegex, 'i')
}

//validate against guam zip codes by checking if value starts with 969
const validationPatternForUS = {
  'phoneNumber':/^([(]?\d{3}[)]?[(\s).-]?\d{3}[\s.-]?\d{4})$/,
  'postalCode': /^(?!969)[0-9]{5}(?:-[0-9]{4})?$/
}

type PartnerFormProps = {
  readonly name: string
  readonly company: string
  readonly emailAddress: string
}

export type FormComponentProps = {
  readonly data: Partial<ContentfulLoginforgetPassword>
}

// Defines data for each radio button in the Shipping Methods field.
export type ShippingOption = Pick<ContentfulRadioButton, 'text' | 'value'> & { readonly defaultChecked?: boolean }

export const toForm = applySpec<FormProps>({
  button: prop('button'),
  formInput: prop('formInput'),
  formTheme: prop('formTheme'),
  navigationLink: prop('navigationLink'),
  successMessageDescription: prop('successMessageDescription'),
  successMessageTitle: prop('successMessageTitle'),
  title: prop('title')
})

const getForms = prop('forms')
const getShippingAddress = prop(TITLE_SHIPPING_ADDRESS)
const getEmail = prop('EMAIL')

// TODO: fix type
const getEmailFromForm: (formData: Record<string, unknown>) => string = pipe(getForms, find(pipe(getEmail, isNil, not)), getEmail)

//TODO This needs to be refactored the value must dynamic from contentful in the feature
const listPropInputField = [
  {
    propName: 'email',
    title: 'EMAIL',
  },
  {
    propName: 'firstName',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'lastName',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'streetAddress1',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'streetAddress2',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'city',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'state',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'postalCode',
    title: TITLE_SHIPPING_ADDRESS,
  },
  {
    propName: 'phoneNumber',
    title: TITLE_SHIPPING_ADDRESS,
  }
]

const getFormPath =
  (formTitle: string, data) => {
    const formInputIndex = findIndex(propEq('title', formTitle))(prop('formInput', data))
    return [ 'formInput', formInputIndex, 'inputField' ] // TODO fix this union type to either be number or string
  }

const mergeDefaultValueToProps = (data, {
  defaultValue, title, propName
}) => {
  const valuePath = getFormPath(title, data)
  const inputFieldIndex = findIndex(propEq('propName', propName))(path(valuePath, data) || []) // TODO this could be undefined and this function call is invalid
  return assocPath([ ...valuePath, inputFieldIndex, 'defaultValue' ], defaultValue, data)
}

export const removeFormField =
  (data, formTitle: string, propName: string) => {
    const valuePath = getFormPath(formTitle, data)
    const inputFields = defaultTo([])(path(valuePath, data))
    const rejectedInputFields = reject((x) => safeProp('propName', x).map(equals(propName))
      .getOrElse(false), inputFields)

    return assocPath(valuePath, rejectedInputFields, data)
  }

// TODO define the type expected to be passed to this function
// TODO export and unit test
// TODO To change the logic shortly
const toFormInput = applySpec<Record<string, string>>({
  // TODO if order matters this should not be an object as the order isn't guarunteed. Try using OrderedMap or a Set.
  email: prop('emailAddress'),
  firstName: path([ 'addresses', 0, 'firstName' ]),
  lastName: path([ 'addresses', 0, 'lastName' ]),
  streetAddress1: path([ 'addresses', 0, 'street1' ]),
  streetAddress2: path([ 'addresses', 0, 'street2' ]),
  city: path([ 'addresses', 0, 'city' ]),
  postalCode: path([ 'addresses', 0, 'zip' ]),
  state: path([ 'addresses', 0, 'state' ]),
  phoneNumber: path([ 'addresses', 0, 'phone' ]),
})

// TODO type and test this function
const getApplyCouponErrorMessage = (data): string => {
  const formInput = prop('formInput', data)
  const couponIndexField = findIndex(propEq('title', TITLE_COUPONS))(formInput)
  const fieldValidation = path([ couponIndexField, 'inputField', 0, 'fieldValidation' ], formInput)
  return path([ 0, 'errorMessage' ], fieldValidation)
}

const getApplyCouponSuccessMessage = (data): string => {
  const couponIndexField = findIndex(propEq('title', TITLE_COUPONS))(prop('formInput', data))
  // TODO add types to this function input
  return  path([ couponIndexField, 'successMessage' ], prop('formInput', data))
}

export const getShippingItem = (shippingText: string, shippingInfo): CartLineItemProps => {
  const shippingAmount = defaultTo(0)(path([ 'price', 'centAmount' ], shippingInfo)) / 100
  const shippingMethod = path([ 'shippingMethodName' ], shippingInfo)
  const shipInfo = ifElse(isNil, always(''), (sInfo) => ` (${sInfo})`)(get(`shipinfo-${get(LOCAL_STORAGE_CARTID)}`))

  return {
    itemName: shippingAmount ? `${shippingMethod}${shipInfo}` : `${shippingText}${shipInfo}`,
    price: formatDisplayPrice(shippingAmount).orJust('')
  }
}

// TODO fix type
const radioButtonToForms =
  (radioButtons: readonly ShippingOption[]) => ({
    'propName': 'multipleShippingOptions',
    'radioOption': radioButtons,
    'title': null,
    'type': 'Radio'
  })

export const getShippingOptionText =
(cartTotalPrice: number, shipText: string, nameWithAmountText: string) =>
  (freeAbove: number) =>
    cartTotalPrice > freeAbove ? shipText : nameWithAmountText

const toShippingOptions =
  (shippingText: string, cart: ImmutableCart, shipId?: string) =>
    transformObject<ShippingMethod, ShippingOption>({
      defaultChecked: (shipMethod) => equals(shipMethod.id, shipId) || shipMethod.isDefault,
      text: (shippingMethod) => {
        const name = prop('name', shippingMethod)
        const shipRate = getMatchingShippingRate(shippingMethod)
        const amount = getMatchingShippingPrice(shipRate, cart)
          .chain(formatDisplayPrice)
          .getOrElse('')

        const nameWithAmountText = `${name}: ${amount}`
        const freeShippingText = `${shippingText}: ${formatDisplayPrice(0).orJust('')}`

        return shipRate
          .chain(safeProp('freeAbove'))
          .map(formatCTPriceToNumber)
          .cata(
            () => nameWithAmountText,
            getShippingOptionText(cart.subTotal, freeShippingText, nameWithAmountText)
          )
      },
      value: prop('id')
    })

export const hasDefaultShippingOption = (options: readonly ShippingOption[]) =>
  options.some(propEq('defaultChecked', true))

// Sets defaultChecked to true if is the first index from Array.map.
// Intended so avoid user advancing to payment without shipping.
const maybeSetDefaultOption = (option: ShippingOption, idx: number): ShippingOption =>
  idx === 0 ? {
    ...option,
    defaultChecked: true
  } : option

// Only sets default shipping item to first if there isn't one selected yet.
export const setDefaultShippingOption =
  (setShippingMethodId: (id: string) => void) =>
    (options: readonly ShippingOption[]): readonly ShippingOption[] => {
      const defaultedOptions = hasDefaultShippingOption(options) ? options : options.map(maybeSetDefaultOption)
      // call state setter for whatever's now defaulted
      Maybe.fromUndefined(defaultedOptions.find(propEq('defaultChecked', true)))
        .forEach(option =>
          safeProp('value', option)
            .forEach(val => setShippingMethodId(val))
        )
      return defaultedOptions
    }

export const getShippingMethodHandleSuccess =
  (setDefinedData: (data: Partial<ContentfulLoginforgetPassword>) => void,
    setShippingMethodId: (id: string) => void,
    shippingText: string, cart: ImmutableCart,
    shippingInputField: readonly (string | number)[],
    definedData: Partial<ContentfulLoginforgetPassword>,
    shippingMethodId?: string) =>
    ((shipResp: Maybe<ShippingMethodPagedQueryResponse>) => {
      const radioForm = shipResp.chain(safeProp('results'))
        .map(sm => sm.map(toShippingOptions(shippingText, cart, shippingMethodId)))
        .map(setDefaultShippingOption(setShippingMethodId))
        .map(radioButtonToForms)

      const mergedToProp = assocPath(shippingInputField, [ radioForm.orNull() ], definedData)
      setDefinedData(mergedToProp)
    })

const captureLead = (promoCode: string, locale: string, optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void,
  email?: string, trackEvent?, optedIn?: boolean, emailCheckboxHidden?: boolean) => {
  const trackLeadSuccess = () => {
    optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
    trackSubmitLeadEvent(trackEvent)
  }

  const leadHandleSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
    // Always cookie lead data and pass data to Braze
    cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
    handleBrazeTrackingEvent(value.orUndefined())

    const shouldUnsubscribe = !optedIn && !emailCheckboxHidden
    const shouldTrackLeadEvent = optedIn && !emailCheckboxHidden

    // Per ECP-1699, only track when checkbox is checked AND not hidden
    shouldTrackLeadEvent && trackLeadSuccess()

    // If the user unchecked the email opt-in checkbox, we need to unsubscribe them from emails after creating them (ECP-1667)
    shouldUnsubscribe && !!email && leadGenUnsub({ email })(() => null)(() => null)
  }

  const leadHandleError = () => {
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }

  const leadGenParams = {
    email,
    leadSource: 'exit_intent',
    locale,
    promoCode,
    source: 'checkout',
    sourceType: 'cart',
  } as LeadGenCaptureParams

  leadGenCapture(leadGenParams)(leadHandleError)(leadHandleSuccess)
}

const getShippingMethodId =
  (cart: ImmutableCart) =>
    safeProp('shippingInfo', cart)
      .chain(toMaybeOrNone)
      .chain(safeProp('shippingMethod'))
      .chain(safeProp('id'))

// TODO fix types to remove unknowns
const getMarketingEmailCheckboxValue =
  (forms?: readonly Record<string, unknown>[]): boolean =>
    safeFind(form => !isNil(path([ TITLE_EMAIL, OFFER_PROP_NAME ], form)), forms)
      .chain(form => safePath([ TITLE_EMAIL, OFFER_PROP_NAME ], form))
      .orJust(true)

const getEpsilonAbacusOptInCheckboxValue =
  (forms: readonly Record<string, unknown>[]): boolean =>
    safeFind(form => !isNil(path([ TITLE_HOW_HEAR_INFO, EPSILON_ABACUS_PROP_NAME ], form)), forms)
      .chain(form => safePath([ TITLE_HOW_HEAR_INFO, EPSILON_ABACUS_PROP_NAME ], form))
      .orJust(false)

const getApplyCouponCode = (code: string, dispatch, couponErrorMessage, couponSuccessMessage, setError?: (name: FieldName<FieldValues>, error: ErrorOption) =>  void) => {
  const showMessage =
    (message: string, type = TYPE_ERROR) =>
      () => {
        Maybe.fromUndefined(setError)
          .forEach(_setError => {
            _setError('couponCode', {
              message,
              type
            })

            message ? dispatch(clearCartError()) : set(USER_DISCOUNT_CODE, code)
            when(equals('success'), () => {
            // Need to have timeout for removing the success message CartUpdatedMessage.
              setTimeout(() => _setError('couponCode', {}), 5100)
            })(type)
          })
      }
  isEmpty(code) ? showMessage(couponErrorMessage)() :
    dispatch(IOUpdateDiscountCodeToCart([ code ], showMessage(couponErrorMessage), showMessage(couponSuccessMessage, 'success')))
}

const navigateUrl = (url: string) => {
  navigate(url)
}

export const checkShippingMethodExists =
  (cart: ImmutableCart, handleSuccess: () => void, handleFailure: () => void) => {
  // Ensures that a shippingMethod is included in cart
    safeProp('shippingInfo', cart)
      .cata(
        handleFailure,
        (shippingInfoMaybe: ImmutableCart['shippingInfo']) => shippingInfoMaybe.cata(
          handleFailure,
          shippingInfo => has('shippingMethod', shippingInfo) ?  handleSuccess() : handleFailure()
        )
      )
  }

const getPartnerContactFormdata = (partnerWithUsFormField: PartnerFormProps) => {
  const fullName = prop('name', partnerWithUsFormField)
  const firstName: string = pipe(split(' '), head)(fullName)
  const lastName = pipe(split(' '), tail, join(''))(fullName)

  return applySpec<PartnerWithUsFormSubmitBody>({
    businessType: always(BUSINESS_TYPE),
    companyName: prop('company'),
    email: prop('emailAddress'),
    firstName: () => firstName,
    lastName: () => lastName,
    numLocations: always(''),
    phone: always(PHONE),
    source: always(SOURCE)
  })(partnerWithUsFormField)
}

/** Partner with us form submission **/
const onValidPartnerContactForm = (dispatch: ThunkDispatch<ImmutableState, void, ACTION>, setPartnerFormSuccess: (isSuccess: boolean) => void) =>
  (formData: Record<string, unknown>) => {
    // TODO: fix type
    const form = pipe(getForms, path('0'))(formData) as Record<string, PartnerFormProps>
    const partnerWithUsForm = pipe<Record<string, PartnerFormProps>, readonly PartnerFormProps[], PartnerFormProps>(values, head)(form) as PartnerFormProps
    const formRequest = getPartnerContactFormdata(partnerWithUsForm)
    const handleFailure = always(setPartnerFormSuccess(false))
    const handleSuccess = () => setPartnerFormSuccess(true)
    dispatch(IOSubmitPartnerContactForm(formRequest, handleFailure, handleSuccess))
  }

export const updateShippingMethod =
  (shippingMethodIdFromCart: string, shippingMethodId: string, callback: (shippingMethodId: string) => void) => {
    const shippingHasChanged = shippingMethodId !== shippingMethodIdFromCart
    shippingHasChanged && isNotEmpty(shippingMethodId) && callback(shippingMethodId)
  }

export function validateFormAndSubmit<T, U>(emailFormValue?: U, shippingFormValue?: T) {
  return Maybe.fromUndefined(emailFormValue)
    .toValidation('emailFormValue is undefined')
    .chain(_ => isNotNil(shippingFormValue) ? Validation.Success(_) : Fail<string, U>('shippingFormValue is nil'))
}

const ContentfulFormComponent: FC<FormComponentProps> = ({ data }: FormComponentProps) => {
  const dispatch = useDispatch()
  const locale = useSelector(selectLocale)
  const cart = useSelector(selectCart)
  const [ userData, setUserData ] = useState({})
  const [ definedData, setDefinedData ] = useState(data)
  const [ placeholders, setPlaceholders ] = useState({})
  const [ isPostalReset, setIsPostalReset ] = useState(false)
  const [ postalCode, setPostalCode ] = useState('')
  const [ shippingMethodId, setShipingMethodId ] = useState('')
  const [ isFirstLoad, setIsFirstLoad ] = useState(true)
  const [ isSubmissionError, setSubmissionError ] = useState(false)
  const [ isPartnerFormSuccess, setPartnerFormSuccess ] = useState(false)
  const [ showSpinner, setShowSpinner ] = useState(false)
  const buttonUrl = path([ 'button', 'url' ], data) as string
  const submissionErrorMessage = safeProp('submissionErrorMessage', data).orUndefined()
  const successMessageTitle = safeProp('successMessageTitle', data).orUndefined()
  const couponErrorMessage = getApplyCouponErrorMessage(data)
  const couponSuccessMessage = getApplyCouponSuccessMessage(data)
  const formInputvalue = defaultTo([])(prop('formInput', definedData))

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const validationPatterns = useMemo(() => ifElse(
    equals('en-US'),
    always(validationPatternForUS),
    always(validationPatternForUK)
  )(locale), [ locale ])

  const promoCode: Maybe<string> = useSelector(selectActivePromoCode) // TODO update to new selector
  const leadData = cookies.get(COOKIE_LEAD_DATA)
  const userId = cookies.get(COOKIE_DRUPAL_UID)
  // Checking leadData and userId cookies to show/hide the marketing email opt-in checkbox
  const hideMarketingEmailCheckbox = or(!isNil(leadData), !isNil(userId))
  const fromProps = toForm(definedData)
  const isPartnerContactForm = propEq('formTheme', 'Partner Contact Us')(fromProps)
  unless(isNil, always(append({ formInput: formInputvalue })))(formInputvalue)
  const shippingForm = formInputvalue.find(form => form && form.shippingText) || {}
  const shippingText = defaultTo('Free Shipping')(prop('shippingText', shippingForm)) //TODO remove text after cms change
  const { trackEvent } = useTracking()

  /** When the user clicks next/submit this is set to true to block any other actions */
  const [ submitting, setSubmitting ] = useState(false)

  const onValidForm = useCallback((formData: Record<string, unknown>) => {
    cart.forEach(_cart => {
      setShowSpinner(true)
      setSubmissionError(false)
      setSubmitting(true)
      // TODO: fix type
      const form = getForms(formData) as CheckoutFormData['forms']
      const shippingFormValue = pipe(getForms, find(pipe(getShippingAddress, isNil, not)))(formData) // TODO fix unknown types
      const emailFormValue = find(pipe<Record<string, unknown>, unknown, boolean, boolean>(getEmail, isNil, not))(form)
      const email = getEmailFromForm(formData)
      const actions = buildAddressUpdateActions(formData, _cart.custom.isJust(), shippingMethodId)

      const navigatePage = () => {
        Maybe.fromNull(buttonUrl).forEach(navigateUrl)
      }

      const handleSuccess = () => {
        setShowSpinner(false)
        checkShippingMethodExists(_cart, navigatePage, handleFailure)
      }

      const handleFailure = () => {
        setSubmissionError(true)
        setShowSpinner(false)
        setSubmitting(false)
      }

      validateFormAndSubmit(emailFormValue, shippingFormValue)
        .cata(err => {
          handleFailure()
          logError(Error(`checkout-form: ${err}`))
        }
        , _ => {
          dispatch(IOUpdateCart(actions, handleFailure, handleSuccess))

          // TODO: fix the unknown type
          // formData is unknown
          const emailsOptedIn = getMarketingEmailCheckboxValue(prop('forms', formData))

          // Always call captureLead, even if the email checkbox is hidden because the user's already logged in, so that we can make sure we
          // have the leadData cookie set
          captureLead(promoCode.orJust(''), locale, optimizelyTrackSiteEvents,
            // TODO: fix type
            prop('email', email),
            trackEvent, emailsOptedIn, hideMarketingEmailCheckbox)

          // always fire the Epsilon Abacus opt-in value in order to capture opt-outs
          Maybe.fromNull(prop('forms', formData))
            .map(getEpsilonAbacusOptInCheckboxValue)
            .forEach(trackEpsilonAbacusOptIn(trackEvent))
        })
    })
  }, [ buttonUrl, dispatch, promoCode, hideMarketingEmailCheckbox, locale, cart, trackEvent, shippingMethodId, optimizelyTrackSiteEvents ])

  const getShippingMethods = useCallback((_cart, shippingInputField) => {
    const shipMethodId = getShippingMethodId(_cart).orUndefined()
    !submitting && commercetoolsGetShippingMethods(_cart.id)
      .pipe(fork((e: Error) => {
        logError(e)
      })(getShippingMethodHandleSuccess(setDefinedData,
        setShipingMethodId,
        shippingText,
        _cart,
        shippingInputField,
        definedData,
        shipMethodId))
      ) }, [ definedData, shippingText, submitting ])

  const onApplyCouponCode = useCallback((code: string, setError?: (name: FieldName<FieldValues>, error: ErrorOption) =>  void) => {
    getApplyCouponCode(code, dispatch, couponErrorMessage, couponSuccessMessage, setError)
  }, [ dispatch, couponErrorMessage, couponSuccessMessage ])

  const handleSuccess = (userCheckoutData: Maybe<UserCheckoutData>) => {
    setIsFirstLoad(false)
    userCheckoutData.cata(
      /* istanbul ignore next */ () => logError(Error('FormComponent: userCheckoutData is empty')),
      (userCheckoutData) => {
        const formInput = toFormInput(userCheckoutData)

        const postalCode = prop('postalCode', formInput)

        set('postalCode', postalCode)

        setUserData(formInput)
      }
    )
  }

  const shouldFetchUserInfoData = isNotNil(cookies.get(KEY_AUTHENTICATED)) && isFirstLoad

  shouldFetchUserInfoData && fetchUserInfoData()(logError)(handleSuccess)

  const onClickLink = useCallback((type: string) => {
    type === 'Login'
      && fetchUserCheckoutDataWithPopup(get(deviceIdStorageKey))(console.error)(handleSuccess)
    type !== 'Login'
      && logError(Error(`FormComponent: onClick was not of type Login - type = ${type}`)) // JDV - I don't know why this would be an error, but there was a todo to add error handling
  }, [])

  const shippingMethodIdFromCart = useSelector((state: ImmutableState) => selectCart(state).chain(getShippingMethodId)
    .getOrElse(''))

  const handleChanges = useMemo(() => {
    return ({
      // Listening to postal code and state value change for displaying the shipping information.
      fieldKeys: [ 'postalCode', 'multipleShippingOptions', 'state' ],
      onChange: (values: { readonly postalCode?: string; readonly multipleShippingOptions?: string}) => {
        // Setting the shipping info once the postal code is entered.
        !submitting && when(equals(true), () => {
          setPostalCode(propOrString('postalCode', values))
          // To identify for updating tax info in cart.
          setIsPostalReset(true)
        })(and(validationPatterns.postalCode.test(propOrString('postalCode', values)), values.postalCode !== postalCode))

        !!values.multipleShippingOptions && !submitting && setShipingMethodId(values.multipleShippingOptions)
      }
    })
  }, [ postalCode, submitting, validationPatterns.postalCode, ])

  useEffect(() => {
    !submitting && updateShippingMethod(shippingMethodIdFromCart, shippingMethodId, id => {
      dispatch(IOSetShippingMethod(id))
    })
  }, [ submitting, dispatch, shippingMethodIdFromCart, shippingMethodId ])

  useEffect(() => {
    // TODO extract this logic into a function and write a unit test
    when(isNil, () => set(deviceIdStorageKey, v4()))(get(deviceIdStorageKey))
    Maybe.fromNull(userData)
      .forEach(_userData => {
        const mergedList = listPropInputField.map((data) => assoc('defaultValue', _userData[data.propName], data))
        setDefinedData(d => Just(mergedList).map(reduce(mergeDefaultValueToProps, d))
          .getOrElse(d))
      })
  }, [ userData ])

  // This is for single shipping option that used in UK site
  useEffect(() => {
    when(equals('en-GB'), () => {
      cart.forEach(_cart => {
        const shippingInfo = _cart.shippingInfo
        when(equals(true), () => {
          const { itemName, price } = getShippingItem(shippingText, shippingInfo.just())
          // Setting the shipping info in the placeholder for rendering in the checkout form shipping option.
          isEmpty(placeholders) && setPlaceholders({ shippingInfo: `${itemName}: ${price}` })
        })(shippingInfo.isJust())
      })
    })(locale)
  }, [ locale, cart, shippingText, placeholders ])

  // This is for multiple shipping options that used in US site
  useEffect(() => {
    !submitting && isPostalReset && when(equals('en-US'), () => cart.forEach(_cart => {
      const shippingInputField = getFormPath(TITLE_SHIPPING_OPTIONS, definedData)
      Maybe.fromFalsy(postalCode).forEach(code => {  // To identify whether shipping info is updated in cart or not.
        setIsPostalReset(false)
        ifElse(equals(code), () => { // Getting list of shipping options for the entered shipping address.
          Maybe.fromNull(path(shippingInputField, definedData)).orElseRun(() => {
            getShippingMethods(_cart, shippingInputField)
          })
        },
        () => { // Setting shipping address only with postal/country code for tax calculation.
          const action = buildShippingAddressUpdateAction({
            country: LOCALE_INFO['country'],
            postalCode: code
          })
          dispatch(IOUpdateCart([ action ], undefined, () => {
            getShippingMethods(_cart, shippingInputField)  // Getting list of shipping options for the entered shipping address.
          }))
        }
        )(safePath([ 'shippingAddress', 'postalCode' ], _cart).getOrElse(''))
      })
    }))(locale)
  }, [ locale, cart, definedData, postalCode, isPostalReset, shippingText, submitting, getShippingMethods, dispatch ])

  // Handling display of marketing email checkbox for the given email id.
  useEffect(() => {
    Maybe.fromFalsy(hideMarketingEmailCheckbox).forEach(_ => setDefinedData(d => removeFormField(d, TITLE_EMAIL, OFFER_PROP_NAME)))
  }, [ hideMarketingEmailCheckbox ])

  useEffect(() => {
    cart.forEach(_cart => {
      const discountCode = getCartDiscountCode(_cart)
      !isEmpty(discountCode) && setDefinedData(
        val => mergeDefaultValueToProps(val, {
          defaultValue: discountCode,
          propName: 'couponCode',
          title: TITLE_COUPONS
        })
      )
    })
  }, [ cart ])

  const cartIsLoading = useSelector(selectCartLoading)

  return (<Form {...fromProps}
    disableSubmit={cartIsLoading}
    isLoading={cartIsLoading}
    onApplyCoupon={onApplyCouponCode}
    onChangeHandlers={handleChanges}
    onClickLink={onClickLink}
    onValidated={ifElse(equals(true), () => onValidPartnerContactForm(dispatch, setPartnerFormSuccess), always(onValidForm))(isPartnerContactForm)}
    placeholders={postalCode ? placeholders : undefined}
    showSpinner={showSpinner}
    submissionMessage={isPartnerFormSuccess ? successMessageTitle : isSubmissionError ? submissionErrorMessage : undefined}
    validationPatterns={validationPatterns}
  />)
}

export const query = graphql`#graphql
fragment form on ContentfulLoginforgetPassword{
  id
  internal {
    type
  }
  title
    formInput {
      ... on ContentfulCheckoutFormSection {
        id
        description {
          json
        }
        validatedDescription {
          json
        }
        shippingText
        successMessage
        inputField {
          ... on ContentfulForms {
            answerOptions
            maximumCharacter
            propName
            title
            placeholderText
            placeholderTextMobile
            id
            fieldValidation {
              requirement
              errorMessage
            }
            type
            checked
            defaultChecked
            deviceToDisplay
          }
        }
        title,
        titleMobile,
        referenceLink {
          ... on ContentfulLink {
            id
            linkText
            linkType
          }
        }
      }
    }
    navigationLink {
      linkText
      linkUrl
      linkType
      linkSubtext
      id
    }
    button {
      url
      type
      text
    }
    formTheme
    successMessageTitle
    successMessageDescription {
      successMessageDescription
    }
    submissionErrorMessage
  }
`
export default ContentfulFormComponent
