import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { LOCALE_INFO } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { brazeLogCustomEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import { CartCount } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import and from 'ramda/src/and'
import equals from 'ramda/src/equals'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { setGtmCustomEvent } from '../../util/analytics'
import {
  handleLinkClick, isLogo, logoLinkUrl
} from './helpers'
import {
  ContentfulGroupSectionFragment, ContentfulLinkFragment, ContentfulMenuItemsFragment
} from './query'

const imgLogoStyle = { objectPosition: 'left center' /* //used for us logo on the left side */ }

type HeaderLinkProps = {
  readonly link: ContentfulGroupSectionFragment | ContentfulLinkFragment | ContentfulMenuItemsFragment
  readonly cartItemCount?: number
  readonly trackEvent: (_data: Partial<TrackingData>) => void
};

export const HeaderLink: FC<HeaderLinkProps> = ({
  link, cartItemCount, trackEvent
}) => {
  const linkText = propOr<string, string>('', 'linkText', link)
  const linkUrlCtfl = propOr<string, string>('', 'linkUrl', link)
  const id = prop('id', link)
  const linkSubtext = propOr<string, string>('', 'linkSubtext', link)
  const linkIcon = propOr<ContentfulLinkFragment['linkIcon'], ContentfulLinkFragment['linkIcon']>(undefined, 'linkIcon', link)
  const linkImage = propOr<ContentfulLinkFragment['linkImage'], ContentfulLinkFragment['linkImage']>(undefined, 'linkImage', link)
  const linkType = propOr<string, string>('', 'linkType', link)

  const { domain } = LOCALE_INFO
  const linkUrl = isLogo(linkType) ? logoLinkUrl(linkUrlCtfl) : linkUrlCtfl

  const cartIcon = linkUrl === '/cart'
    ? <CartCount
      // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      count={cartItemCount}
      key={`cart-count-${cartItemCount}`} />
    : null

  const imageNode = linkIcon && linkIcon.fluid
    ? <Img
      alt={linkText}
      fluid={linkIcon.fluid}
      loading="eager"
      style={{
        height: '24px',
        width: '24px'
      }} />
    : null

  const children = <>
    {!cartIcon && imageNode}
    {!linkIcon && !isLogo(linkType) && linkText}
    {cartIcon}
    {linkSubtext && <p><small>{linkSubtext}</small></p>}
  </>

  return linkUrl && linkUrl.startsWith('tel:')
    ? (
      <a
        href={linkUrl}
        key={id}
        onClick={() => {
          setGtmCustomEvent({
            event: 'buttonClick',
            eventAction: 'phone',
            eventCategory: 'contact-us',
            eventLabel: linkUrl.replace('tel:', '')
          })
          brazeLogCustomEvent('click_to_call')
        }}
        style={{
          fontSize: '14px',
          textDecoration: 'inherit'
        }}>
        {children}
      </a>
    )
    : (
      <Link
        aria-label={cartIcon ? `Cart with ${cartItemCount} items` : undefined}
        key={id}
        onClick={() => isNotNil(linkUrl) && handleLinkClick(linkText, trackEvent)}
        state={{ source: 'header' }}
        style={{ textDecoration: 'inherit' }}
        to={linkUrl || ''}>
        {linkImage && linkImage.fixed && <Img
          alt={linkText}
          fixed={linkImage.fixed}
          imgStyle={{
            objectFit: 'contain',
            // eslint-disable-next-line ramda/no-redundant-and -- legacy code
            ...and(equals(domain, 'us'), isLogo(linkType)) && imgLogoStyle
          }}
          loading="eager"
          style={{
            height: '100%',
            width: '100%'
          }}
        />}
        {children}
      </Link>
    )

}
