import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeProp } from '@simplisafe/monda'
import {
  Column, HiddenAnchor, PageSection, Row
} from '@simplisafe/ss-react-components'
import { Spans } from '@simplisafe/ss-react-components/Column'
import { graphql } from 'gatsby'
import React, {
  FC, useEffect,  useLayoutEffect, useRef, useState
} from 'react'

import {
  AlarmSensorsFragment, AlarmSensorSystemFragment,
  ProductCardAccessoriesFragment, RichTextWithOptionsFragment
} from '../../../graphql'
import { PriceProvider } from '../../providers/PriceProvider/PriceProvider'
import { calculateColumns } from '../GroupSection'
import { mapPageComponentToTemplate, PageProps } from '../Page'
import LegacyBanner from './LegacyBanner'
import SimpleBannerWrapper from './SimpleBannerWrapper'

export type AlarmSensorsProps = {
  readonly data: AlarmSensorsFragment
  readonly pageContext: PageProps['pageContext']
  readonly location: PageProps['location']
}

const isAccessoriesFragment = (fragment: ProductCardAccessoriesFragment | RichTextWithOptionsFragment): fragment is ProductCardAccessoriesFragment => 'productId' in fragment

const AlarmSensorsComponent: FC<AlarmSensorsProps> = ({
  data, pageContext, location
}: AlarmSensorsProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  const systems = safeProp('systems', data)
    .map(systems => systems.filter(isNotNil))
    .orJust([])

  const skus = systems
    .map(system => safeProp('cardItems', system)
      .map(cardItems => cardItems.filter(isNotNil))
      .map(cardItems => cardItems
        .filter(isAccessoriesFragment)
        .map(cardItem => cardItem.productId)
      )
      .orJust([])
    )
    .reduce((arr, skus) => arr.concat(skus), [])
    .filter((sku): sku is string => !!sku)

  const pathSuffix = safeProp('pathname', location)
    .map(pathname => pathname.split('/'))
    .map(tokens => tokens[tokens.length - 1])
    .orJust('')
  const [ systemState, setSystemState ] = useState<string | null | undefined>(pathSuffix)
  const [ selectedSystem, setSelectedSystem ] = useState<AlarmSensorSystemFragment | null>(systems.length === 1 ? systems[0] : null)

  useEffect(() => {
    const filteredSystem = systems.length === 1 ? systems : systems.filter((s: AlarmSensorSystemFragment | null) => s && systemState === s.extensionUrl)
    setSelectedSystem(filteredSystem[0])
  }, [ systemState, systems ])

  // Remove "as unknown" usage here once calculateColumns' type signature is fixed
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const systemSpans = calculateColumns(systems.length, 'Row', '2 Columns', '2 Columns') as unknown
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const cardItemSpans = calculateColumns(systems.length, 'Row', '3 Columns', '4 Columns') as unknown

  const handleSystemClick = (idx: number) => {
    setSystemState(systems[idx].extensionUrl)
  }

  useLayoutEffect(() => {
    const anchor = anchorRef.current
    selectedSystem && anchor && anchor.scrollIntoView && anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, [ selectedSystem ])

  const systemBanners = systems.map((system, idx) => system && system.systemBanner && (
    <SimpleBannerWrapper data={system.systemBanner}
      isSelected={systemState === system.extensionUrl}
      key={system.id}
      onClick={() => handleSystemClick(idx)}
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      spans={systemSpans as Spans} />
  ))

  const cardItems = selectedSystem && selectedSystem.cardItems ?
    selectedSystem.cardItems.map(cardItem => cardItem && (
      <Column equalHeightChildren={true}
        key={cardItem.id}
        rounded='none'
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        spans={cardItemSpans as Spans}>
        <div style={{
          alignSelf: 'stretch',
          display: 'flex'
        }}>
          {cardItem && mapPageComponentToTemplate(cardItem, pageContext)}
        </div>
      </Column>
    )) : []

  return (
    <PriceProvider skus={skus}>
      {systemBanners.length > 1 && <PageSection>
        <Row equalHeightRows={true} gap='medium'>
          {systemBanners}
        </Row>
      </PageSection>}
      <HiddenAnchor id="card-item-anchor"
        ref={anchorRef}
        top={-40} />
      {selectedSystem && <PageSection>
        <LegacyBanner {...selectedSystem} />
      </PageSection>}
      <PageSection>
        <Row equalHeightRows={true} id="alarm-sensors-list">
          {cardItems}
        </Row>
      </PageSection>
    </PriceProvider>
  )
}

export default AlarmSensorsComponent

export const AlarmSensorsQuery = graphql`#graphql
  fragment alarmSensors on ContentfulAlarmSensors {
    id
    internal {
      type
    }
    title
    systems {
      ...alarmSensorSystem
    }
  }

  fragment alarmSensorSystem on ContentfulAlarmSensorSystem {
    id
    internal {
      type
    }
    extensionUrl
    systemBanner {
      ... on ContentfulBanner {
        ...contentfulBanner
      }
    }
    cardItems {
      ... on ContentfulProductCardAccessories {
        ...productCardAccessories
      }
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    legacyDiscount
    legacyPromoCode
    legacyText
    legacySubtext
    legacyStyle
    legacyIcon {
      title
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    legacyButton {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
    }
  }
`
