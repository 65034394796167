import { COOKIE_DRUPAL_UID, COOKIE_FCP_ORDER_ID } from '@lib/tracking/src/cookies'
import { get as sessionStorageGet, set as sessionStorageSet } from '@lib/utils/src/sessionStorage'
import { safePath, safeProp } from '@simplisafe/monda'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import {
  createOrder as createApiOrder,
  CreateOrderRequest,
  CreateOrderResponse,
  CreateOrderResponseWithWebappToken,
  PreactivationStatuses
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  chain, fork, FutureInstance, reject, resolve
} from 'fluture'
import { get as localStorageGet } from 'local-storage'
import { Either, Maybe } from 'monet'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import T from 'ramda/src/T'
import Cookies from 'universal-cookie'

import { getWebAppUrl } from '../../util/common'

export type ChaseOrderData = {
  readonly chaseCustomerRefNumber: string
  readonly chaseToken: string
  /** Type field is sent to the orders api endpoint. */
  readonly type: 'credit'
  readonly provider: 'chase'
}

export type AffirmOrderData = {
  readonly affirmCheckoutToken: string
  /** Type field is sent to the orders api endpoint. */
  readonly type: 'affirm'
}

export type ZuoraOrderData = {
  readonly paymentMethodId: string
  readonly token: string
  readonly type: 'credit'
  readonly provider: 'zuora'
}

export type OrderData = AffirmOrderData | ChaseOrderData | ZuoraOrderData

const cookies = new Cookies()

export const logErrorWithOrderInfo = (e: Error) => {
  const orderId = sessionStorageGet('orderId')
  const cartId = localStorageGet<string>(LOCAL_STORAGE_CARTID)
  logError(e, {
    cartId,
    orderId
  })
}

// exported for testing
export const getOrderId = (response: CreateOrderResponse) =>
  safeProp('orderNumber', response)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    .orElse(safePath([ 'custom', 'fields', 'omsId' ], response))

const forwardToPaymentConfirmation = (orderId: string) => {
  const urlPayment = '/payment-confirmation?orderId=' + orderId
  window.location.href = urlPayment
}

const forwardPreactivationFlowToWebApp = (hasPlan: boolean) => (orderId: string) => {
  const webAppUrl = getWebAppUrl()
  const hasPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel`
  const noPlanUrl = `${webAppUrl}/#/collect-monitoring?funnel&orderId=${orderId}`

  window.location.href = hasPlan ? hasPlanUrl : noPlanUrl
}

export const forwardToPaymentErrorUrl = (error: Error) => {
  window.location.href = `${window.location.origin}${window.location.pathname}?error=${encodeURIComponent(error.message)}`
}

const validatePreActivationEligibilityKey = (orderResponse: CreateOrderResponse): Either<Error, CreateOrderResponse> =>
  safeProp('preActivationEligibility', orderResponse).cata(
    () => Either.left(Error('createOrder: initPreactivationFlow - order response is missing preactivation eligibility key')),
    () => Either.right(orderResponse)
  )

// TODO this should use ts-pattern instead of cond once FE is on TypeScript 4
const checkPreActivationEligibilityStatus = (orderResponse: CreateOrderResponse): Either<Error, CreateOrderResponse> => {
  const checkEligibility = cond<string, Either<Error, CreateOrderResponse>>([
    [ equals(PreactivationStatuses.ELIGIBLE), () =>
      Either.right(orderResponse)
    ],
    [ equals(PreactivationStatuses.INELIGIBLE), () =>
      Either.left(Error('createOrder: initPreactivationFlow - order is ineligible for preactivation'))
    ],
    [ equals(PreactivationStatuses.PENDING), () =>
      Either.left(Error('createOrder: initPreactivationFlow - order is still pending for preactivation'))
    ],
    [ T, () =>
      Either.left(Error(`createOrder: initPreactivationFlow - order has invalid preactivation eligibility status: ${orderResponse.preActivationEligibility}`))
    ]
  ])

  return checkEligibility(orderResponse.preActivationEligibility)
}

const hasWebappToken = (orderResponse: CreateOrderResponse): orderResponse is CreateOrderResponseWithWebappToken => !!orderResponse.webappToken

const validateWebAppToken = (orderResponse: CreateOrderResponse): Either<Error, CreateOrderResponseWithWebappToken> =>
  hasWebappToken(orderResponse)
    ? Either.right(orderResponse)
    : Either.left(Error('createOrder: initPreactivationFlow - missing webappToken on eligible preactivation order'))

const onPreactivationOrderIdMissing = () => {
  const orderIdError = Error('createOrder: initPreactivationFlow - missing order id')
  logErrorWithOrderInfo(orderIdError)
  forwardToPaymentConfirmation('')
}

// exported for testing
export const initPreactivationFlow = (orderResponse: CreateOrderResponse, onPreactivationReady: (_webAppToken: string) => void) => {
  Either.of<Error, CreateOrderResponse>(orderResponse)
    .chain(validatePreActivationEligibilityKey)
    .chain(checkPreActivationEligibilityStatus)
    .chain(validateWebAppToken)
    .cata(
      error => {
        // Log the error
        logErrorWithOrderInfo(error)
        // Forward user to payment confirmation page
        getOrderId(orderResponse).cata(
          // We shouldn't hit this None case because we check for orderId in `createOrder`, but including this here
          // because `getOrderId` returns a Maybe
          onPreactivationOrderIdMissing,
          forwardToPaymentConfirmation
        )
      },
      _orderResponse => {
        // Fire tracking events to GTM and Optimizely.
        // Set auth token for webapp to extract user from.
        onPreactivationReady(_orderResponse.webappToken)
        // Forward user to webapp
        getOrderId(_orderResponse)
          .cata(
            // We shouldn't hit this None case because we check for orderId in `createOrder`, but including this here
            // because `getOrderId` returns a Maybe
            onPreactivationOrderIdMissing,
            forwardPreactivationFlowToWebApp(_orderResponse.hasPlan)
          )
      }
    )
}

const validateOrder = chain((response: Maybe<CreateOrderResponse>) =>
  response.cata<FutureInstance<Error, CreateOrderResponse>>(
    () => reject(Error('createOrder: order response does not have a valid body')),
    res => resolve(res)
  )
)

const trackUid = chain((orderResponse: CreateOrderResponse) => {
  safePath([ 'custom', 'fields', 'uid' ], orderResponse)
    .forEach(uid => {
      cookies.set(COOKIE_DRUPAL_UID, uid, {
        path: '/',
        sameSite: 'strict',
        secure: true
      })
    })

  return resolve(orderResponse)
})

const trackOrderId = chain((orderResponse: CreateOrderResponse) =>
  getOrderId(orderResponse)
    .cata<FutureInstance<Error, CreateOrderResponse>>(
      () => {
        return reject(Error('createOrder: missing order id'))
      },
      _orderId => {
        sessionStorageSet('orderId', _orderId)

        // For Braze - we can use the ecomm cart data for purchase info,
        // but we need the order ID for the tag as well.
        const orderData = { 'orderID': _orderId }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(orderData)

        cookies.set(COOKIE_FCP_ORDER_ID, _orderId, {
          path: '/',
          sameSite: 'strict',
          secure: true
        })

        return resolve(orderResponse)
      }
    )
)

const createOrderRequest = (cartId: string, orderData: OrderData): CreateOrderRequest => ({
  cart: { id: cartId },
  custom: {
    fields: (
      orderData.type === 'affirm'
        ? { paymentToken: orderData.affirmCheckoutToken }
        : (
          orderData.provider === 'zuora'
            ? { paymentMethodId: orderData.paymentMethodId }
            : {
              // @ts-expect-error TS(2322) FIXME: Type '{ paymentToken: string; } | { paymentMethodI... Remove this comment to see the full error message
              paymentProfileId: orderData.chaseCustomerRefNumber,
              paymentToken: orderData.chaseToken
            }
        )
    )
  },
  type: orderData.type
})

const validateOrderData = (orderData: OrderData) => {
  orderData.type === 'affirm' && !orderData.affirmCheckoutToken && logErrorWithOrderInfo(Error('createOrder: missing affirmCheckoutToken'))

  orderData.type === 'credit' && orderData.provider === 'chase' && !orderData.chaseToken && logErrorWithOrderInfo(Error('createOrder: missing chaseToken'))

  orderData.type === 'credit' && orderData.provider === 'chase' && !orderData.chaseCustomerRefNumber && logErrorWithOrderInfo(Error('createOrder: missing chaseCustomerRefNumber'))

  orderData.type === 'credit' && orderData.provider === 'zuora' && !orderData.paymentMethodId && logErrorWithOrderInfo(Error('createOrder: missing paymentMethodId'))
}

type CreateOrderProps = {
  readonly cartId: string
  readonly onPaymentComplete: () => void
  readonly onPaymentError: (_e: Error) => void
  readonly onPreactivationReady: (_webAppToken: string) => void
}

/**
 * Creates an order in Commercetools and redirects the user to either the payment confirmation page
 * or the webapp (if eligible for preactivation).
 */
const createOrder = (props: CreateOrderProps) =>
  (orderData: OrderData) => {
    validateOrderData(orderData)

    return createApiOrder(createOrderRequest(props.cartId, orderData))
      .pipe(validateOrder)
      .pipe(trackUid)
      .pipe(trackOrderId)
      .pipe(chain(orderResponse => {
        // Displays loading message for post payment flows such as preactivation.
        props.onPaymentComplete()
        return resolve(orderResponse)
      }))
      .pipe(fork((error: Error) => {
        logErrorWithOrderInfo(error)
        props.onPaymentError(error)
        forwardToPaymentErrorUrl(error)
      })((orderResponse: CreateOrderResponse) => {
        // Wait a second so user has change to read message/avoid jarring experience, and to allow
        // enough time for tracking pixels to fire.
        setTimeout(() => initPreactivationFlow(orderResponse, props.onPreactivationReady), 1000)
      }))
  }

export default createOrder
