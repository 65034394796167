// TODO: clean up this file, there may be some unused functionality here
/* eslint-disable max-lines */
import { Document } from '@contentful/rich-text-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { buildCustomFieldUpdateAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { PartnerMembershipCaptureBody, partnersMembershipFormSubmit }
  from '@simplisafe/ss-ecomm-data/partners/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  FormField, SSButton, Text
} from '@simplisafe/ss-react-components'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import {
  Form,
  Formik,
} from 'formik'
import propOr from 'ramda/src/propOr'
import React, { CSSProperties, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  boolean,
  number,
  object,
  string,
} from 'yup'

import {
  ContentfulAsset, ContentfulButton, ContentfulModal,
  ContentfulPartnerCaptureForm
} from '../../../graphql'
import { toButtonTypeValue, toButtonWidthValue } from '../../attributeMappings'
import { toButton } from '../../util/helper'
import { parseArray } from '../../util/parseContentfulValues'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import ContentfulRichText from '../ContentfulRichText'
import FluidImg from '../FluidImg'
import ModalComponent from '../ModalComponent'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'
import Description from './form-sections/Description'
import SimpleText from './form-sections/SimpleText'
import SuccessMessage from './form-sections/SuccessMessage'

type PartnerCaptureFormProps = {
  readonly closeModal: () => void
  readonly data: ContentfulPartnerCaptureForm
  readonly onSuccess?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
enum InputTypes {
  string = 'string',
  number = 'number',
  date = 'date',
  boolean = 'boolean'
}

type FormTypes = {
  readonly partnerName: string
  readonly firstName?: string
  readonly lastName?: string
  readonly memberNumber: string
}

// TODO: Refactor to use existing CTFL inputs instead of "Form Input" we created for this.
const PartnerCaptureForm = ({
  closeModal, data, onSuccess
}: PartnerCaptureFormProps) => {
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()

  const partnerFields = parseArray(prop('partnerFields', data))
  const formTitle = propOr<string, string>('', 'formTitle', data)
  const termsAndConditions = safeProp('termsAndConditionsModal', data).orUndefined()
  const continueButton = safeProp('continueButton', data).orUndefined()
  const formDescription = <p>{propOr<string, string>('', 'formDescription', data)}</p>
  const submitButton = safeProp('submitButton', data).orUndefined()
  const image =  safeProp('partnerImage', data).orUndefined()
  const promoInformation = propOr('', 'promoInformation', data)

  const schema = partnerFields.reduce((prevSchema, currentField) => {
    const types = {
      'boolean': boolean(),
      'number': number(),
      'string': string()
    }

    const minValidationNumber = prop('minRequirement', currentField)
    const maxValidationNumber = prop('maxRequirement', currentField)

    // TODO: refactor this code to fix lint and TS errors
    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, functional/no-let
    let fieldValidation = types[prop('inputType', currentField)]
      .label(currentField.label)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    prop('isEmail', currentField) && (fieldValidation.type === 'string') ? fieldValidation = fieldValidation.email() : undefined
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    prop('required', currentField) ? fieldValidation = fieldValidation.required() : undefined
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    minValidationNumber && (fieldValidation.type === 'number' || fieldValidation.type === 'string') ? fieldValidation = fieldValidation.min(minValidationNumber) : undefined
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    maxValidationNumber && (fieldValidation.type === 'number' || fieldValidation.type === 'string') ? fieldValidation = fieldValidation.max(maxValidationNumber) : undefined

    return {
      ...prevSchema,
      // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [currentField.name]: fieldValidation
    }
  }, {})

  const initialFormValues = partnerFields.reduce((prevValue, currentField) => {
    return {
      ...prevValue,
      // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [currentField.name]: ''
    }
  }, { locale })

  // TODO: add variables for reused values to avoid duplicate code
  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderPartnerField = field => ({
    'number': <SimpleText
      key={propOr<string, string>('', 'id', field)}
      label={propOr<string, string>('', 'label', field)}
      name={propOr<string, string>('', 'name', field)}
      placeholder={propOr<string, string>('', 'placeholder', field)}
      type="number"
    />,
    'string': <SimpleText
      key={propOr<string, string>('', 'id', field)}
      label={propOr<string, string>('', 'label', field)}
      name={propOr<string, string>('', 'name', field)}
      placeholder={propOr<string, string>('', 'placeholder', field)}
      type={field.isEmail ? 'email' : 'text'}
    />
  })

  const renderButton = (
    props: ContentfulButton,
    buttonType: SSButtonProps['type'],
    isSubmitting: boolean,
    onClick?: SSButtonProps['onClick'],
    styles?: CSSProperties
  ) => {
    const buttonProps = {
      ...toButton(props),
      disabled: isSubmitting,
      minWidth: toButtonWidthValue('medium'),
      onClick,
      type: toButtonTypeValue(buttonType)
    }
    return <div className="mt-8" data-component={buttonType}
      style={styles}>
      <FormField hideLabel={true} name={`${buttonType}`}>
        <SSButton
          {...buttonProps} />
      </FormField>
    </div>
  }

  const renderTermsAndConditions = (termsAndConditions: ContentfulModal) => {
    const termsAndConditionsLink =
      <a
        href="#"
        style={{
          'color': '#9D9CA0',
          'padding': '10px'
        }}
      >
        Terms and Conditions
      </a>

    const modalContentData = safeProp('modalContent', termsAndConditions).orUndefined()
    const modalContent: ReactNode = modalContentData ? <RichTextWithOptionsComponent data={modalContentData} /> : <></>

    return <ModalComponent clickTarget={termsAndConditionsLink} modalContent={modalContent} />
  }

  const renderPartnerImage = (image: ContentfulAsset, small: boolean) => {
    return <FluidImg
      alt={prop('title', image)}
      // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
      fluid={prop('fluid', image)}
      imgStyle={{ objectFit: 'contain', }}
      style={{
        'height': '64px',
        'width': small ? '220px' : '320px'
      }}
    />
  }

  const renderProcessingMessage = <div style={{
    marginBottom: '-1rem',
    padding: '10px'
  }}>
    <Text
      fontWeight='medium'
      textColor='darkOrange'
      textSize='sm'
    >
      {propOr<string, string>('Thank you. Validation in-progress.', 'submittedMessage', data)}
    </Text>
  </div>

  const successMessageDescription =
    safePath([ 'successMessageDescription', 'json' ], data)
      .map((json: Document) => <ContentfulRichText key={prop('id', data)}
        rawRichText={json} />)
      .getOrElse(<></>)

  const formatFormData = (data: FormTypes) => {
    const partnerName = getValueFromPartnerCookie('partnerName') || ''
    const partnerGroup = getValueFromPartnerCookie('partnerGroup')

    const reward = partnerGroup === 'airlines' ? 7000 : 0

    return {
      memberNumber: propOr('', 'memberNumber', data),
      membershipData: {
        firstName: propOr('', 'firstName', data),
        lastName: propOr('', 'lastName', data),
        memberNumber: propOr('', 'memberNumber', data),
        rewardValue: reward
      },
      partnerName: partnerName
    }
  }

  const updateCartMemberNumber = (partnerMemberNumber: string) => {
    const partnerMemberNumberAction = buildCustomFieldUpdateAction({
      name: 'partnerMemberNumber',
      value: partnerMemberNumber
    })
    const updateActions = [
      partnerMemberNumberAction
    ]
    dispatch(IOUpdateCart(updateActions, () => logError(Error('something went wrong trying to update partnerMemberNumber'))))
  }

  return (
    <Formik initialValues={initialFormValues}
      // @ts-expect-error TS(2322) FIXME: Type '(formData: PartnerMembershipCaptureBody, { s... Remove this comment to see the full error message
      onSubmit={
        (
          formData: PartnerMembershipCaptureBody,
          {
            setSubmitting, setStatus, setFieldError
          }
        ) => {
          const handleSuccess = () => {
            setStatus('success')
            setSubmitting(true)
            updateCartMemberNumber(formData.memberNumber)
            onSuccess && onSuccess()
          }

          const handleFailure = () => {
            const message = 'Uh oh! Looks like something is wrong! try again'
            setFieldError('memberNumber', message)
            setSubmitting(false)
          }
          setSubmitting(true)
          partnersMembershipFormSubmit(formatFormData(formData))(handleFailure)(handleSuccess)
        }
      }
      validationSchema={object().shape(schema)}>
      {({
        isSubmitting,
        status
      }) => (
        <div style={{ 'padding': '50px' }}>
          <div className="flex flex-wrap items-center justify-between">
            {image && renderPartnerImage(image, !!promoInformation)}
            {promoInformation && (
              <Text useTailwind={true}>
                <h1 className="md:ml-[9px]" style={{
                  marginBottom: 0,
                  marginTop: '15px'
                }}>{promoInformation}</h1>
              </Text>
            )}
          </div>
          <Form>
            {!status && (
              <>
                {(formTitle || formDescription) && <Description
                  formDescription={formDescription}
                  formTitle={formTitle}
                />}
                <div style={{ marginBottom: '10px' }}>
                  {partnerFields.map(field =>
                    // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
                    renderPartnerField(field)[field.inputType])}
                </div>
                {termsAndConditions && renderTermsAndConditions(termsAndConditions)}
                {isSubmitting && renderProcessingMessage}
                {submitButton && renderButton(submitButton, 'submit', isSubmitting)}
                {continueButton && renderButton(continueButton, 'button', isSubmitting, closeModal, { margin: '-13px 0 0 -22px' })}
              </>
            )}
          </Form>
          {status && (
            <SuccessMessage
              successMessageDescription={successMessageDescription}
              successMessageTitle={propOr<string, string>('', 'successMessageTitle', data)}
            />
          )}
        </div>
      )}
    </Formik>
  )
}

export default PartnerCaptureForm
