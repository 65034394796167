"use strict";
exports.__esModule = true;
exports.parseFixedImg = exports.fixedImgSchema = void 0;
var zod_1 = require("zod");
exports.fixedImgSchema = zod_1.z.object({
    base64: zod_1.z.string().optional(),
    height: zod_1.z.number()["default"](0),
    media: zod_1.z.string().optional(),
    src: zod_1.z.string(),
    srcSet: zod_1.z.string(),
    srcSetWebp: zod_1.z.string().optional(),
    srcWebp: zod_1.z.string().optional(),
    tracedSVG: zod_1.z.string().optional(),
    width: zod_1.z.number()
});
/**
 * Parse raw a raw contentful FixedObject and remove null/undefiend values.
 */
var parseFixedImg = function (fixed) {
    return exports.fixedImgSchema.parse(fixed);
};
exports.parseFixedImg = parseFixedImg;
