import { visitorIdAtAt } from '@lib/tracking/src/atat'
import { COOKIE_LEAD_DATA } from '@lib/tracking/src/cookies'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { useExperimentVariation } from '@lib/tracking/src/useExperimentation'
import { OptimizelyContext } from '@optimizely/react-sdk'
import path from '@simplisafe/ewok/ramda/path'
import type { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import {
  selectActivePromoCode, selectActivePromoDiscountTextWithOverrides,
  selectLocale, selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  leadGenCapture, LeadGenCaptureParams, LeadGenCaptureResponse
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { brazeTrackPromoView, handleBrazeTrackingEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { exists, window } from 'browser-monads-ts'
import { Maybe } from 'monet'
import React, {
  FC, useContext, useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

// import { trackSubmitLeadEvent } from '../../util/analytics'
import type { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import { PageProps } from '../Page'
import DesktopBanner from './DesktopBanner'
import MobileBannerAlt from './MobileBannerAlt'
import MobileBottomBanner from './MobileBottomBanner'
import MobileTopBanner from './MobileTopBanner'
import PromoStyler from './PromoStyler'

export type ActivePromoBannerType = 'cart' | 'none' | 'pdp-plp' | 'standard'

export type ActivePromoBannerProps = {
  /** Page data, only used to suppress mobile bottom banner for BMS LiveChat Experiment. */
  readonly pageData: PageProps['data']
  /** Determines what type of banner to show. */
  readonly type: ActivePromoBannerType
}

const cookies = new Cookies()
const COOKIE_DISMISSED = 'promobanner_isDismissed'
const COOKIE_MINIMIZED = 'promobanner_isMinimized'
const COOKIE_SUBMITTED = 'promobanner_isFormSubmitted'

const leadSourceI18N: Record<Locale, string> = {
  'en-GB': 'uk_promo_banner',
  'en-US': 'us_promo_banner'
}

const ActivePromoBannerComponent: FC<ActivePromoBannerProps> = ({ pageData, type }: ActivePromoBannerProps) => {
  const locale = useSelector(selectLocale)
  const promoCode = useSelector(selectActivePromoCode)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })
  const experimentNoBottomBannerVariation = useExperimentVariation('ECP_3597')
  const { optimizely } = useContext(OptimizelyContext)
  const variation = Maybe.fromNull(optimizely)
    .chain(_optimizely => Maybe.fromNull(_optimizely.getVariation('all___cart__checkout___live_chat', visitorIdAtAt())))
    .getOrElse('')
  const pagePath = path([ 'contentfulPage', 'pageUrl' ], pageData) || ''
  const isValidVariation = [ 'control', 'variation_1' ].includes(variation)
  const isBmsLiveChat = pagePath.includes('build-my-system') && isValidVariation

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (email, onFailure) => {

    const handleLeadCaptureFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      onFailure('We\'ve encountered an error. Please try again later.')
    }

    const handleLeadCaptureSuccess = (value: Maybe<LeadGenCaptureResponse>) => {
      setIsFormSubmitted(true)
      cookies.set(COOKIE_SUBMITTED, true)
      cookies.set(COOKIE_DISMISSED, true)
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      handleBrazeTrackingEvent(value.orUndefined())
      brazeTrackPromoView()
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      trackEvent({ event: 'submit' })
      // TODO: Check this trackEvent
      // trackSubmitLeadEvent(trackEvent)
    }

    const leadGenParams: LeadGenCaptureParams = {
      email,
      promoCode: promoCode.getOrElse(''),
      source: `${leadSourceI18N[locale]}_${getDeviceType().toLowerCase()}`
    }

    leadGenCapture(leadGenParams)(handleLeadCaptureFailure)(handleLeadCaptureSuccess)
  }

  const isMobile = !useMediaQuery('TabletAndUp')
  // Hide bottom banner on page load for BMS LiveChat Experiment.
  const [ isDismissed, setIsDismissed ] = useState(isBmsLiveChat || cookies.get(COOKIE_DISMISSED) ? true : false)
  const [ isMinimized, setIsMinimized ] = useState(cookies.get(COOKIE_MINIMIZED) ? true : false)
  const [ isFormSubmitted, setIsFormSubmitted ] = useState(cookies.get(COOKIE_SUBMITTED) ? true : false)

  const handleRedeemClick = () => {
    setIsDismissed(false)
    setIsMinimized(false)
  }

  const handleMinimizeClick = () => {
    setIsMinimized(true)
    cookies.set(COOKIE_MINIMIZED, true)
    // If BMS experiment, just dismiss it the whole thing.
    isBmsLiveChat && handleDismissClick()
  }

  const handleDismissClick = () => {
    setIsDismissed(true)
    cookies.set(COOKIE_DISMISSED, true)
  }

  const mobileBanner = experimentNoBottomBannerVariation === 'variation_1' ?
    <MobileBannerAlt
      isFormSubmitted={isFormSubmitted}
      onEmailSubmit={handleEmailSubmit}
      type={type}
    /> : <>
      <MobileTopBanner
        isFormSubmitted={isFormSubmitted}
        onRedeemClick={handleRedeemClick}
        type={type}
      />
      <MobileBottomBanner
        isDismissed={isDismissed}
        isFormSubmitted={isFormSubmitted}
        isMinimized={isMinimized}
        onDismiss={handleDismissClick}
        onEmailSubmit={handleEmailSubmit}
        onMinimize={handleMinimizeClick}
        onRedeemClick={handleRedeemClick}
        type={type}
      />
    </>

  const desktopBanner = <DesktopBanner
    isFormSubmitted={isFormSubmitted}
    onEmailSubmit={handleEmailSubmit}
    type={type}
  />

  return (
    <PromoStyler>
      {isMobile ? mobileBanner : desktopBanner}
    </PromoStyler>
  )
}

const ActivePromoBanner: FC<ActivePromoBannerProps> = ({ pageData, type }: ActivePromoBannerProps) => {
  const isActivePromo = useSelector(selectTopBannerVisible)
  const promoDiscountText = useSelector(selectActivePromoDiscountTextWithOverrides)
  const showPromoBanner = isActivePromo && promoDiscountText.isSome()

  // Checking if the window exists makes sure this isn't rendered during gatsby build, but only when the page
  // hydrates when the end user hits the site
  const isBrowser = exists(window)

  return type !== 'none' && showPromoBanner && isBrowser ? <ActivePromoBannerComponent pageData={pageData} type={type} /> : null
}

export default ActivePromoBanner
