import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Either, Maybe } from 'monet'

import { locale } from '../../commercetools/utils'
import {
  getCommerceDataFromPackageWithExtras,
  getCommerceDataFromProduct, getNameFromProductName, getPackagePrice,
  TrackEvent
} from '.'

export const trackProductDetailEvent = (productDetail: Either<Error, Product>, trackEvent: TrackEvent) => {
  productDetail.forEach(productDetail => {
    const productName = safeProp('name', productDetail)

      .chain(getNameFromProductName(locale))
      .getOrElse('')

    const ecomm = getCommerceDataFromProduct(productDetail, 'detail', 1)

    trackEvent({
      ecommerce: ecomm,
      event: 'productDetails'
    })

    trackEvent({
      ecommerce: ecomm,
      event: 'eec.details',
      eventAction: 'detail',
      eventCategory: 'eec',
      eventLabel: productName
    })
  })
}

export const trackPackageDetailEvent = (
  product: Product,
  _package: Package,
  isBms: boolean,
  trackEvent: TrackEvent,
  selectState: ImmutableState,
  hasMonitoring: boolean
) => {
  Maybe.fromUndefined(_package)
    .forEach(_package => {
      Maybe.fromUndefined(product)
        .forEach(product => {
          const trackEvents = () => {
            const packagePrice = getPackagePrice(hasMonitoring)(_package)

            const ecomm = getCommerceDataFromPackageWithExtras(_package, product, [], isBms, 'detail', selectState, packagePrice)

            const packageName = safeProp('name', product)

              .chain(getNameFromProductName(locale))
              .getOrElse('')

            trackEvent({
              ecommerce: ecomm,
              event: 'productDetails'
            })

            trackEvent({
              ecommerce: ecomm,
              event: 'eec.details',
              eventAction: 'detail',
              eventCategory: 'eec',
              eventLabel: packageName
            })
          }

          trackEvents()
        })
    })
}
