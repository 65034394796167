import { cookies } from '@lib/tracking/src/cookies'
import React, { useCallback } from 'react'

// value is string because cookies.onChangeListener doesn't auto-parse value like cookies.get does
// https://github.com/reactivestack/cookies/issues/302
const useCookieChange = (cookieName: string, onChangeCookie: (value: string) => void) => {
  const onChange = useCallback(({ name, value }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    (name === cookieName) ? onChangeCookie(value) : undefined
  }, [ cookieName, onChangeCookie ])

  React.useEffect(() => {
    cookies && cookies.addChangeListener  && cookies.addChangeListener(onChange)
    return () => cookies && cookies.removeChangeListener && cookies.removeChangeListener(onChange)
  }, [ onChange ])

  return { onChange }
}

export default useCookieChange
