/* eslint-disable max-lines -- legacy code */
import { INLINES } from '@contentful/rich-text-types'
import { useLocation } from '@reach/router'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { Column, Row } from '@simplisafe/ss-react-components'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { ColumnProps, Spans } from '@simplisafe/ss-react-components/Column'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { RowProps } from '@simplisafe/ss-react-components/Row'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import { applySpec } from 'ramda'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import split from 'ramda/src/split'
import T from 'ramda/src/T'
import React, {
  FC, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'
import { DeepPartial } from 'redux'

import { ContentfulTwoColumn } from '../../../graphql'
import {
  toColumnSpans,
  toGapValue, toMarginValue, toPaddingValue,
  toSiteColor
} from '../../attributeMappings'
import { ContentfulComponent, getMappedComponent } from '../../componentMappings'
import getJson from '../../util/getJson'
import { toFirstCharLower } from '../../util/helper'
import { renderComponentFromData } from '../../util/render'
import { getBadgeDiscount } from '../BadgeText'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'

export type TwoColumnBannerProps = {
  readonly data: DeepPartial<ContentfulTwoColumn>
}

const alignItemsValueMapper: { [key: string]: RowProps['alignItems'] } = {
  Center: 'center',
  Stretch: 'stretch'
}

export const toAlignItemsValue = (value: string) => prop(value, alignItemsValueMapper)

const hasSingleMediaItem = (columnData: readonly ContentfulComponent[]) => {
  const mediaTypes = [ 'ContentfulImage', 'ContentfulImageWithFocalPoint', 'ContentfulImageWithArtDirection' ]
  const isSingleItem = prop('length', columnData) === 1
  const interalType: string = path([ 0, 'internal', 'type' ], columnData) || ''
  return isSingleItem && mediaTypes.includes(interalType)
}

const alignSelfFn = (columnData?: ReadonlyArray<ContentfulComponent | null> | null): ColumnProps['alignSelf'] => {
  const imageTypes = [ 'ContentfulImage', 'ContentfulImageWithFocalPoint', 'ContentfulBadgeText' ]
  return Maybe.fromNull(columnData)
    // returns None if columnData has the wrong length and the following chains/filters won't apply
    .filter(columnData => columnData.length === 1)
    // get the first item from columnData and turn it into a Maybe - this also takes care of the
    // possibility of an individual item being null, which the Contentful types seem to think can
    // happen. if the result is None, the following chains/filters won't apply
    .chain(columnData => Maybe.fromNull(columnData[0]))
    // map to the internal type of the first column. if None, the following filter won't apply
    .chain(firstColData => safePath([ 'internal', 'type' ], firstColData))
    // check if the content type of the first column is contained in the image type array. returns None if it doesn't
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    .filter(contentType => imageTypes.includes(contentType))
    // if None, return undefined, otherwise return 'stretch'
    .cata(
      always(undefined),
      always('stretch')
    )
}

const renderRightSectionColoumn = (
  rightContent: readonly ContentfulComponent[],
  rightColOnTop: boolean,
  rightColumnHeight: ColumnProps['height'],
  id: string,
  columnPadding: string,
  spans: readonly [Spans, Spans]
) => rightContent.length ? (
  <Column
    alignSelf={alignSelfFn(rightContent)}
    firstRow={rightColOnTop}
    height={rightColumnHeight}
    key={`${id}-right`}
    padding={toPaddingValue(columnPadding)}
    spans={spans[1]}>
    {rightContent && rightContent.map(data =>
      renderComponentFromData(data)
    )}
  </Column>
) : <></>

const toFloatingBadge = (ContentfulTwoColumn: DeepPartial<ContentfulTwoColumn>, placeholders: Record<string, string>) => {
  const floatingBadgeRawText = getJson(path([ 'promoBadge', 'text' ], ContentfulTwoColumn))
  const options: Options = { renderNode: { [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node, placeholders), }, }

  return !isEmpty(placeholders) ? applySpec({
    backgroundColor: () => toSiteColor('brandPrimary'),
    borderColor: () => toSiteColor('brandPrimary'),
    floatingBadgeContent: () => <ContentfulRichText optionsCustom={options} rawRichText={floatingBadgeRawText} />,
    linkUrl: prop('promoBadgeUrl'),
    textColor: () => toSiteColor('neutralWhite'),
  })(ContentfulTwoColumn) : null
}

// TODO Row doesn't support all margin/padding options yet
const TwoColumnBanner: FC<TwoColumnBannerProps> = ({ data }: TwoColumnBannerProps) => {
  const {
    alignItems,
    // TODO Later needd to implment for all marigin options for both Row/Column.
    margin,
    desktopColumnRatio,
    gapSize,
    id = '',
    rowPadding,
    tabletColumnRatio
  } = data
  const height = propOr<RowProps['height'], RowProps['height']>('standard', 'height', data)
  const columnPadding = propOr<string, string>('', 'columnPadding', data)
  const rightContent = propOr<readonly ContentfulComponent[], readonly ContentfulComponent[]>([], 'rightContent', data)
  const leftContent = propOr<readonly ContentfulComponent[], readonly ContentfulComponent[]>([], 'leftContent', data)
  const mobilleColumnRatio = propOr<string, string>('', 'mobilleColumnRatio', data)
  const location = useLocation()
  const isMobile = !useMediaQuery('TabletAndUp')
  const currentUrlName = prop('pathname', location)
  const isPartnerFormWithMobile = ifElse(equals(true), T, F)(currentUrlName === '/partner-with-us' && isMobile)
  const backgroundColor = safeProp('backgroundColor', data).cata(
    always(undefined),
    pipe(toFirstCharLower, split(' '), join(''))
  )
  const spans = toColumnSpans(mobilleColumnRatio, tabletColumnRatio, desktopColumnRatio)
  // A single image or video in either column should stack on top on mobile when content is in rows.
  // Currently this just supports ContentfulImageWithFocalPoint.
  const rightColOnTop = parseInt(mobilleColumnRatio, 10) === 12
    && hasSingleMediaItem(rightContent)
    && !hasSingleMediaItem(leftContent)
  const rightColumnHeight = pathOr<ColumnProps['height'], ColumnProps['height']>('any', [ 'rightContent', 0, 'height' ], data)
  const backgroundImageData = propOr<ContentfulComponent, ContentfulComponent>({}, 'backgroundImageRef', data)
  const BackgroundImageComponent: React.ComponentType = getMappedComponent(backgroundImageData)
  // @ts-expect-error TS(2774) FIXME: This condition will always return true since this ... Remove this comment to see the full error message
  const backgroundImageProps = BackgroundImageComponent ? { data: backgroundImageData } : {}

  const [ placeholders, setPlaceholders ] = useState({})
  const displayDiscountBadge = prop('displayDiscountBadge', data)
  const isPromoTopBanner = useSelector(selectTopBannerVisible)
  const discountText = useSelector(selectPromoDiscountText)

  const handleFailure = () => {
    setPlaceholders({})
  }

  useEffect(() => {
    discountText.cata(
      handleFailure,
      text => setPlaceholders(getBadgeDiscount(text)),
    )
    // @ts-expect-error TS(2339) FIXME: Property 'val' does not exist on type 'Maybe<strin... Remove this comment to see the full error message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ discountText.val ])

  return (
    <>
      <Row
        BackgroundComponent={BackgroundImageComponent}
        alignItems={toAlignItemsValue(alignItems || '')}
        backgroundColor={backgroundColor}
        backgroundComponentProps={backgroundImageProps}
        gap={toGapValue(gapSize)}
        height={height}
        key={id}
        // Replace padding type inside Row's component
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        padding={toPaddingValue(rowPadding) as string}
      >
        {displayDiscountBadge && !isPromoTopBanner &&
          <FloatingBadge className={'bannerBadge'}
            {...toFloatingBadge(data, placeholders)} />
        }
        <Column
          alignSelf={alignSelfFn(leftContent)}
          key={`${id}-left`}
          marginLeftRight={toMarginValue(margin)}
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          padding={toPaddingValue(columnPadding) as string}
          spans={spans[0]}
        >
          {leftContent && leftContent.map(data =>
            renderComponentFromData(data)
          )}
        </Column>
        {!isPartnerFormWithMobile && renderRightSectionColoumn(rightContent, rightColOnTop, rightColumnHeight, id, columnPadding, spans)}
      </Row>
      {isPartnerFormWithMobile && renderRightSectionColoumn(rightContent, rightColOnTop, rightColumnHeight, id, columnPadding, spans)}
    </>
  )
}

export default TwoColumnBanner

export const query = graphql`#graphql
  fragment __twoColumnBannerBasic on ContentfulTwoColumn {
    ...contentfulTwoColumnInteranls
    leftContent {
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulImageWithOverlay {
        ...imageWithOverlay
      }
      ... on ContentfulImageWithArtDirection {
        ...imageWithArtDirection
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulIconWithText {
        ...iconWithText
      }
      ... on ContentfulModal {
        ...secondaryModalFragment
      }
    }
    rightContent {
      ... on ContentfulImageWithFocalPoint {
        ...imageWithFocalPoint
      }
      ... on ContentfulSmallTextSection {
        ...smallTextSectionFragment
      }
      ... on ContentfulBadgeText {
        ...badgeText
      }
      ... on ContentfulFloatingBadge {
        ...floatingBadge
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulImageWithArtDirection {
        ...imageWithArtDirection
      }
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulLoginforgetPassword {
        ...form
      }
      ... on ContentfulPopupButton {
        ... contentfulPopupButton
      }
      ... on ContentfulResponsiveContainer {
        ...responsiveContainer
      }
      ... on ContentfulPartnerWithUsForm {
        ...partnerWithUsForm
      }
    }
  }

  fragment contentfulTwoColumnFragment on ContentfulTwoColumn {
    ...__twoColumnBannerBasic
    rightContent {
      ... on ContentfulTwoColumn {
        ...__twoColumnBannerBasic
      }
    }
    backgroundColor
    backgroundImageRef {
      ... on ContentfulImageWithArtDirection {
        ...imageWithArtDirection
      }
      ... on ContentfulImage {
        ...contentfulImage
      }
    }
  }

  fragment contentfulTwoColumnInteranls on ContentfulTwoColumn {
      id
      alignItems
      columnPadding
      margin
      internal {
        type
      }
      displayDiscountBadge
      promoBadge {
        promoCode
        text {
          json
        }
      }
      promoBadgeUrl
      gapSize
      rowPadding
      title
      desktopColumnRatio
      tabletColumnRatio
      mobilleColumnRatio # typo is in contentful
      height
    }
`
