import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { CartCount } from '@simplisafe/ss-react-components'
import { Profile } from '@simplisafe/ss-react-components/icons'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { handleLinkClick } from './helpers'
import { ContentfulLinkFragment, NavItem } from './query'

type NavigationDrawerBottomItemProps = {
    readonly item: NavItem
    readonly cartItemCount: number
    readonly trackEvent: (_data: Partial<TrackingData>) => void
};
const NavigationDrawerBottomItem: FC<NavigationDrawerBottomItemProps> = ({
  item, cartItemCount, trackEvent
}) => {
  const linkText = propOr<string, string>('', 'linkText', item)
  const linkUrl = propOr<string, string>('', 'linkUrl', item)
  const id = prop('id', item)
  const linkIcon = propOr<ContentfulLinkFragment['linkIcon'], ContentfulLinkFragment['linkIcon']>({}, 'linkIcon', item)

  const isCart = linkUrl === '/cart'
  const icon = isCart ? <CartCount
    count={cartItemCount}
    key={`cart-count-${cartItemCount}`} />
    : <Profile />

  return (
    <Link
      key={id}
      onClick={() => handleLinkClick(linkText, trackEvent)}
      state={{ source: 'header' }}
      style={{ textDecoration: 'inherit' }}
      to={linkUrl}>

      {linkIcon && linkIcon.fluid && !isCart && <Img
        fluid={linkIcon.fluid}
        loading="eager"
        style={{
          height: '24px',
          width: '24px'
        }} />}
      {icon}
      {linkText}
    </Link>
  )
}

export default NavigationDrawerBottomItem
