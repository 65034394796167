import { Document } from '@contentful/rich-text-types'
import {
  Column,
  Row,
  Text
} from '@simplisafe/ss-react-components'
import React, { FC } from 'react'

import doesDocumentHaveContent from '../../../util/doesDocumentHaveContent'
import ContentfulRichText from '../../ContentfulRichText'
import { PaymentState } from '../usePayment'

type CardVerificationNoteProps = {
  readonly cardVerificationNote?: Document
  readonly paymentState: PaymentState
}

const CardVerificationNote: FC<CardVerificationNoteProps> = (props: CardVerificationNoteProps): JSX.Element => {
  return props.cardVerificationNote && doesDocumentHaveContent(props.cardVerificationNote) && props.paymentState === 'ready' ?
    (
      <Row key="payment-verification-note" padding='small' >
        <Column
          backgroundColor='neutralLightGray'
          justifySelf='center'
          padding='small'>
          <Text maxWidth={true} textColor='neutralBlack'>
            <ContentfulRichText rawRichText={props.cardVerificationNote} />
          </Text>
        </Column>
      </Row>
    ) :
    <></>
}

export default CardVerificationNote
