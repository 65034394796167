import { ToastCheckMarkAlert } from '@simplisafe/ss-react-components'
import React, { FC } from 'react'
import type { Toast } from 'react-hot-toast'
import {
  toast, ToastBar, Toaster
} from 'react-hot-toast'

import { sendGtmCustomEvent } from '../../util/analytics'

const PageToaster: FC = () => {
  return (<Toaster toastOptions={{ style: { padding: 0 } }}>
    {(t: Toast) => (
      <ToastBar toast={t}>
        {({ message }) => (
          <ToastCheckMarkAlert
            hasCloseButton={true}
            message={message}
            onCloseHandler={() => {
              sendGtmCustomEvent({
                event: 'toastAlert',
                eventAction: 'closeToastAlert',
                eventCategory: 'UTMAdditionAlert',
                eventLabel: 'closedToastAlert'
              })
              toast.dismiss(t.id)
              window.localStorage.utm_code && window.localStorage.removeItem('utm_code')
            }}
          />
        )}
      </ToastBar>
    )}
  </Toaster>)
}

export default PageToaster
