import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import {
  selectActivePromoDiscountTextWithOverrides, selectActivePromoEndTime, selectLocale, selectPromotionBanner
} from '@simplisafe/ss-ecomm-data/redux/select'
import {
  Column, Row, SimpleButton, Text
} from '@simplisafe/ss-react-components'
import * as Promo from '@simplisafe/ss-react-components/promotions'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { SimpliSafeCSSProperties } from '../../types/SimpliSafeCSSProperties'

export type MobileMinimizedLayoutProps = {
  readonly isFormSubmitted: boolean
  readonly onRedeemClick: () => void
}

const TitleMobile: FC = () => {
  const locale = useSelector(selectLocale)
  const endTime = useSelector(selectActivePromoEndTime).map(_endTime => new Date(_endTime))
    .orUndefined()
  const bannerProps = useSelector(selectPromotionBanner)
  const bannerTitle = bannerProps.chain(chainProp('saleName')).getOrElse('')
  const hasEndDateCallout = bannerProps.chain(safeProp('hasEndDateCallout')).getOrElse(false)
  const hasSaleName = bannerProps.chain(safeProp('hasSaleName')).getOrElse(false)

  return ( hasSaleName && bannerTitle ) || ( hasEndDateCallout && endTime ) ? (
    <Promo.PromoTitle
      endDate={hasEndDateCallout ? endTime : undefined}
      lineBreak={false}
      locale={locale}
      title={bannerTitle}
    />
  ) : null
}

const OfferMobile: FC = () => {
  const discountText = useSelector(selectActivePromoDiscountTextWithOverrides).getOrElse('')
  const bannerProps = useSelector(selectPromotionBanner)
  const lineItemTitle = bannerProps.chain(chainProp('freeGiftItemPrimaryText')).orUndefined()

  return discountText ? (
    <Text
      className={!lineItemTitle ? 'ml-4' : ''}
      fontWeight={!lineItemTitle ? 'medium' : 'body'}
      textSize={!lineItemTitle ? 'sm' : 'xs'}
      useTailwind={true}
    >
      <Promo.OfferText
        hasBorder={true}
        lineBreak={false}
        lineItemTitle={lineItemTitle}
        offerTitle={`${discountText} off`}
      />
    </Text>
  ) : null
}

const RedeemButton: FC<MobileMinimizedLayoutProps> = ({
  isFormSubmitted,
  onRedeemClick,
}: MobileMinimizedLayoutProps) => {
  const bannerProps = useSelector(selectPromotionBanner)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)
  const buttonBackgroundColor = bannerProps.chain(chainProp('buttonBackgroundColor')).orUndefined()
  const buttonTextColor = bannerProps.chain(chainProp('buttonTextColor')).orUndefined()
  const buttonStyles: SimpliSafeCSSProperties = {
    '--btn-accent': buttonTextColor,
    '--btn-primary': buttonBackgroundColor,
    '--btn-primary-light': buttonBackgroundColor,
  }

  return hasForm && !isFormSubmitted ? (
    <Column
      justifySelf='end' rounded='none'
      spans={[ 5 ]}>
      <Text
        textAlignment='right'
        textSize='sm'
        useTailwind={true}
      >
        <SimpleButton data-component='PromoRedeemButton' onClick={onRedeemClick}
          size='small'
          style={buttonStyles}
          variant='solid'>
          redeem
        </SimpleButton>
      </Text>
    </Column> ) : null
}

const MobileMinimizedLayout: FC<MobileMinimizedLayoutProps> = ({ isFormSubmitted, onRedeemClick }: MobileMinimizedLayoutProps) => {
  const maybeEndTime = useSelector(selectActivePromoEndTime).map(_endTime => new Date(_endTime))
  const bannerProps = useSelector(selectPromotionBanner)
  const hasForm = bannerProps.chain(safeProp('hasEmailInput')).getOrElse(false)
  const hasRedeemButton = hasForm && !isFormSubmitted
  const lineItemTitle = bannerProps.chain(chainProp('freeGiftItemPrimaryText')).orUndefined()
  const hasCountdown = bannerProps.chain(safeProp('hasCountdown')).getOrElse(false)

  const countdownLayout = maybeEndTime
    .cata<JSX.Element | null>(
      () => null,
      _endTime => (
        <Text
          inheritTextColor={true}
          textSize='xs'
          useTailwind={true}
        >
          <Promo.Countdown endTime={_endTime}/>
          <OfferMobile />
        </Text>
      )
    )

  const regularLayout =
    <Text
      className={!lineItemTitle ? 'flex flex-row justify-center items-center' : ''}
      fontWeight='medium'
      textSize='xs'
      useTailwind={true}
    >
      <TitleMobile />
      <OfferMobile />
    </Text>

  const promoContent =
    <Column rounded='none' spans={hasRedeemButton ? [ 7 ] : [ 12 ]}>
      {hasCountdown && countdownLayout ? countdownLayout : regularLayout}
    </Column>

  return (
    <Row dataComponent='MobileMinimizedLayout' inheritTextColor={true}
      rounded='none'>
      {promoContent}
      <RedeemButton
        isFormSubmitted={isFormSubmitted}
        onRedeemClick={onRedeemClick}
      />
    </Row>
  )
}

export default MobileMinimizedLayout
