import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { liftSelectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Maybe } from 'monet'
import {
  useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { getIsOnStock, getIsSellable } from './helpers'

export const useAddExtraSensors = (sku: Maybe<string>, defaultQuantity: number) => {
  const dispatch = useDispatch()
  const { Track, trackEvent } = useTracking()
  const [ showSpinner, setShowSpinner ] = useState(false)
  const [ addToCartError, setAddToCartError ] =
    useState<AddToCartErrorType>(null)
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const [ isCartUpdateSuccess, setCartUpdateSuccess ] = useState(false)

  const product = useSelector(liftSelectProduct(sku))
  const isCartable = getIsSellable(product) && getIsOnStock(product)

  const [ quantity, setQuantity ] = useState(defaultQuantity)

  const onAddToCart = useCallback(
    (quantity: number, handleSuccess: () => void) => {
      setShowSpinner(true)
      setAddToCartError(null)

      const onFailure = () => {
        setShowSpinner(false)
        setAddToCartError('recoverable')
        optimizelyTrackSiteEvents({ eventType: 'website_error' })
      }

      const onSuccess = () => {
        handleSuccess()
        setShowSpinner(false)
        optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
        trackAddToCartEvent(product, trackEvent, quantity)
      }

      const message = sku.cata(
        () => 'received null/empty sku',
        (_sku) => `no product with sku ${_sku} found in redux`
      )

      product.cata(
        () => {
          setShowSpinner(false)
          setAddToCartError('unrecoverable')
          logError(Error(`Cannot add to cart: ${message}`))
        },
        (_product) => {
          dispatch(
            IOAddToCart(
              {
                products: [
                  {
                    quantity,
                    sku: _product.masterSku,
                  },
                ],
              },
              onFailure,
              onSuccess
            )
          )
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ product, sku ]
  )

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdateSuccess(false), 5000)
    return () => clearTimeout(timer)
  }, [ isCartUpdateSuccess ])

  const addToCart = useCallback(() => {
    onAddToCart(quantity, () => {
      setCartUpdateSuccess(true)
    })
  }, [ onAddToCart, quantity ])

  return {
    Track,
    addToCart,
    addToCartError,
    defaultQuantity,
    isCartUpdateSuccess,
    isCartable,
    product,
    quantity,
    setQuantity,
    showSpinner,
    sku
  }
}
