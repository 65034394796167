import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { FloatingBadge } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe } from 'monet'
import React, { ReactNode } from 'react'

import type { FloatingBadgeFragment } from '../../../graphql'
import { toSiteColor } from '../../attributeMappings'
import { renderComponentFromData } from '../../util/render'

export type FloatingBadgeComponentProps = {
  readonly data: FloatingBadgeFragment
}

// @ts-expect-error TS(7006) FIXME: Parameter 'imgData' implicitly has an 'any' type.
const toGatsbyImage = (imgData): ReactNode => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const altText = Maybe.fromNull(imgData)
    .chain(safeProp('description'))
    .getOrElse('')

  return Maybe.fromNull(imgData)
    .chain(safeProp('fluid'))
    .cata(
      () => null,
      fluid => <Img
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        alt={altText}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        fluid={fluid as FluidObject}
        imgStyle={{ objectFit: 'contain' }}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'content' implicitly has an 'any' type.
const renderImage = (content) => {
  return safeProp('image', content).cata(
    () => null,
    imgData => toGatsbyImage(imgData)
  )
}

const routeToComponent = (content: FloatingBadgeFragment['content']) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const type = safePath([ 'internal', 'type' ], content).getOrElse('')
  return Maybe.fromNull(content)
    .cata(
      () => null,
      c => type === 'ContentfulImage' ?
        renderImage(c) :
        renderComponentFromData(c)
    )
}

const FloatingBadgeComponent = ({ data }: FloatingBadgeComponentProps) => {
  const background = safeProp('bgColor', data)
    .chain(safeProp('color'))
    .getOrElse('none')

  const childComponent = routeToComponent(prop('content', data))

  return Maybe.fromNull(childComponent)
    .cata(
      () => null,
      component => {
        return (<div style={{
          position: 'absolute',
          right: '-18px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code */}
          <FloatingBadge backgroundColor={toSiteColor(background)}>{component}</FloatingBadge>
        </div>)
      }
    )
}

export default FloatingBadgeComponent

export const FloatingBadgeQuery = graphql`#graphql
  fragment floatingBadge on ContentfulFloatingBadge {
    bgColor: backgroundColor {
      color
    }
    content {
      ... on ContentfulImage {
        id
        internal {
          type
        }
        image {
          id
          description
          fluid(maxWidth: 320) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    id
    internal {
      type
    }
  }
`
