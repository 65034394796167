import { safeProp } from '@simplisafe/monda'
import { HeaderNavigation } from '@simplisafe/ss-react-components'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import React, {
  FC, HTMLAttributes, ReactElement
} from 'react'
import { useTracking } from 'react-tracking'

import { useHeaderData } from './hooks'
import { renderNavItem } from './NavItem'
import { ContentfulHeaderFragment, NavItem } from './query'

// @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
const getContentElements = (data: Maybe<readonly NavItem[]>, cartItemCount: number, trackEvent, side: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  return data.map(map<NavItem, ReactElement | null>((item) => renderNavItem(item, true, cartItemCount, false, trackEvent)))
    .map(links => <HeaderNavigation key={`${side}-navigation`}><>{links}</></HeaderNavigation>)
}

const DesktopHeader: FC<HTMLAttributes<HTMLDivElement> & { readonly data: ContentfulHeaderFragment }> = ({ data, className }) => {
  const { cartItemCount } = useHeaderData()
  const { trackEvent } = useTracking()

  const leftContent = getContentElements(safeProp('leftContent', data), cartItemCount, trackEvent, 'left')
  const rightContent = getContentElements(safeProp('rightContent', data), cartItemCount, trackEvent, 'right')

  return (
    <div className={`${className}`}>
      {leftContent.orNull()}
      {rightContent.orNull()}
    </div>
  )
}

export default DesktopHeader
