import {
  Block, BLOCKS, Inline, Node
} from '@contentful/rich-text-types'
import { Divider } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { renderEmbeddedBlockComponent } from './embeddedEntries'


const defaultRenderBlockNode: RenderNode = {
  [BLOCKS.PARAGRAPH]:
        (_, children) =>
          children &&  <p className={classNames('paragraph')}>{children}</p>,
  [BLOCKS.HEADING_1]:
        (_, children) =>
          children && <h1 className={classNames('h1')}>{children}</h1>,
  [BLOCKS.HEADING_2]:
        (_, children) =>
          children && <h2 className={classNames('h2')}>{children}</h2>,
  [BLOCKS.HEADING_3]:
        (_, children) =>
          children && <h3 className={classNames('h3')}>{children}</h3>,
  [BLOCKS.HEADING_4]:
        (_, children) =>
          children && <h4 className={classNames('h4')}>{children}</h4>,
  [BLOCKS.HEADING_5]:
        (_, children) =>
          children && <h5 className={classNames('h5')}>{children}</h5>,
  [BLOCKS.UL_LIST]:
        (_, children) =>
          children && <ul className={classNames('ul')}>{children}</ul>,
  [BLOCKS.HR]:
        () => <Divider />,
  [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => renderEmbeddedBlockComponent(node),
}

export type NodeRenderer = (node: Block | Inline, children: ReactNode) => ReactNode;

export type RenderNode = {
  readonly [k: string]: NodeRenderer
};

export default defaultRenderBlockNode
