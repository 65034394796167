import { Cookie, useTrackingCookies } from '@lib/tracking/src/cookies'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import { get } from 'local-storage'
import React, {
  FC, useEffect, useState
} from 'react'
import { useTracking } from 'react-tracking'
import { StringParam, useQueryParam } from 'use-query-params'

import { ContentfulPaymentFormErrorMessage } from '../../../graphql'
import getJson from '../../util/getJson'
import { params } from '../../util/queryParams'

type PaymentFormErrorMessageComponentProps = {
  readonly id?: string
  readonly data: Partial<ContentfulPaymentFormErrorMessage>
}

export function handleError(error: string, trackEvent: (data: Partial<TrackingData>) => void, cookies: Cookie) {
  const vid: string = cookies.vid || ''
  const drupalUid: string = cookies.DRUPAL_UID || ''
  const atAtToken: string = cookies.atat_v2 || ''
  const cartId: string = get(LOCAL_STORAGE_CARTID) || 'unknown'

  logError(Error(`PAYMENT_FORM_ERROR: ${JSON.stringify(error)}`), {
    atAtToken,
    cartId,
    drupalUid,
    vid
  })

  trackEvent({
    event: 'paymentFormError',
    transactionId: cartId
  })
}

const PaymentFormErrorMessageComponent: FC<PaymentFormErrorMessageComponentProps> = ({ data }: PaymentFormErrorMessageComponentProps) => {
  const { Track, trackEvent } = useTracking({ appSection: 'paymentForm' })

  const [ error ] = useQueryParam(params.error, StringParam)
  const isCVVError = error && error.includes('"type":"InvalidCVV"')
  const errorMessage = isCVVError ? getJson(prop('invalidCVVMessage', data)) : getJson(prop('errorMessage', data))

  const [ scrolled, setScrolled ] = useState(false)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
    setScrolled(true)
  }

  const [ cookies ] = useTrackingCookies()

  useEffect(() => {
    error && handleError(error, trackEvent, cookies)
  }, [ error, trackEvent, cookies ])

  useEffect(() => {
    error && !scrolled && scrollToTop()
  }, [ error, scrolled ])

  error && !scrolled && optimizelyTrackSiteEvents({ eventType: 'website_error' })

  return (
    error
      ?
      <Track>
        <SmallTextSection
          dataComponent="payment-form-error"
          description={errorMessage}
          theme={'errorMessage'} />
      </Track>
      : null
  )
}
export default PaymentFormErrorMessageComponent

export const query = graphql`#graphql
    fragment paymentFormErrorMessageFragment on ContentfulPaymentFormErrorMessage {
      id
      title
      errorMessage {
        json
      }
      invalidCVVMessage {
        json
      }
      internal {
        type
      }
    }
`
