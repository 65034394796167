import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp, safeProps } from '@simplisafe/monda'
import { Column, Row } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { RowProps } from '@simplisafe/ss-react-components/Row'
import { graphql } from 'gatsby'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import props from 'ramda/src/props'
import React, { FC, useState } from 'react'

import { toColumnSpans } from '../../attributeMappings'
import { getMappedComponent } from '../../componentMappings'
import { PriceProvider } from '../../providers/PriceProvider'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import { ImageProps } from '../Media'
import ProductPageHeroContentColumn, { renderAdditionalLink } from './ContentColumn'
import type {
  AdditionalLinkData, ContentPosition, ImagePosition, ProductInformation, ProductPageHeroProps, ProductVariationData
} from './types'


const contentPositionMapper: {[key: string]: [ImagePosition, ContentPosition]} = {
  'Background - Content Left': [ 'background', 'left' ],
  'Background - Content Right': [ 'background', 'right' ],
  'Left': [ 'left', 'right' ],
  'Right': [ 'right', 'left' ]
}
export const getContentPosition = (value?: string | null) => (value && contentPositionMapper[value]) || [ 'background', 'left' ]


const ProductPageHero: FC<ProductPageHeroProps> = ({ data, location }: ProductPageHeroProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  const [ selectedVariationIndex, selectVariationIndex ] = useState(0)

  const padding = prop('padding', data)
  const height = prop('height', data)

  const [ leftSpans, rightSpans ] = toColumnSpans('12', ...props([ 'tabletColumnRatio', 'desktopColumnRatio' ], data))
  const [ imagePosition, contentPosition ] = getContentPosition(data.imagePosition)
  const isLeftContent = contentPosition === 'left'
  const isBackgroundImage = imagePosition === 'background' && isTabletUp

  const [ variationImages, variationsInfo ] = safeProps(
    [ 'imageList', 'productInformationList' ],
    data.productVariationList || {}
  )
  const productImage = prop('productImage', data)
  const imageData = variationImages
    .chain(imgs => Maybe.fromNull(imgs[selectedVariationIndex]))
    .orElse(Maybe.fromNull(productImage))
    .orUndefined()
  const ImageComponent = imageData && getMappedComponent<ImageProps>(imageData)
  const imageProps = ImageComponent ? { data: imageData } : undefined

  const additionalLinks = safeProp('additionalLinks', data).map(links =>
    links
      .filter((l): l is AdditionalLinkData => !!l)
      .map(renderAdditionalLink))
    .orJust([])

  const variationData = variationsInfo.map(variations =>
    variations
      .filter((variation): variation is ProductInformation => !!variation)
      .map(transformObject<ProductInformation, ProductVariationData>({
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Pick<ContentfulProductInformatio... Remove this comment to see the full error message
        description: variation => getDescriptionJson(variation),
        // TODO: fix type
        // @ts-expect-error TS(2322) FIXME: Type '<O extends { productInformationKey: V; }, T ... Remove this comment to see the full error message
        key: prop('productInformationKey'),
        // TODO: fix type
        // @ts-expect-error TS(2322) FIXME: Type '<O extends { productId: V; }, T extends keyo... Remove this comment to see the full error message
        productId: prop('productId')
      })))
    .orJust([])

  const productData = transformObject({
    description: product => getJson(prop('productDescription', product)),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { maxQuantity: V; }, T extends ke... Remove this comment to see the full error message
    maxQuantity: prop('maxQuantity'),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { priceDisclaimerText: V; }, T ex... Remove this comment to see the full error message
    priceDisclaimerText: prop('priceDisclaimerText'),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { priceMessage: V; }, T extends k... Remove this comment to see the full error message
    priceMessage: prop('priceMessage'),
    productId: product => path([ 'product', 'sku' ], product),
    quantityChangerLabel: product => path([ 'quantityChangerLabel', 'text', 'text' ], product),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { showQuantityChanger: V; }, T ex... Remove this comment to see the full error message
    showQuantityChanger: prop('showQuantityChanger')
  }, data)

  const productAddonData = data.productAddon ? transformObject({
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { description: V; }, T extends ke... Remove this comment to see the full error message
    description: prop('description'),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { maxQuantity: V; }, T extends ke... Remove this comment to see the full error message
    maxQuantity: prop('maxQuantity'),
    // TODO: fix type
    // @ts-expect-error TS(2322) FIXME: Type '<O extends { modal: V; }, T extends keyof O,... Remove this comment to see the full error message
    modal: prop('modal'),
    productId: addon => path([ 'product', 'sku' ], addon),
    quantityChangerLabel: addon => path([ 'quantityChangerLabel', 'text', 'text' ], addon)
  }, data.productAddon) : null

  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const productId = safeProp('productId', productData)
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<never>' is not assignable ... Remove this comment to see the full error message
  const productAddonId = Maybe.fromNull(productAddonData).chain(safeProp('productId'))
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type '<O extends { productId: V; }, T ... Remove this comment to see the full error message
  const variationProductIds = variationData.map(prop('productId')).filter(isNotNil)
  const allProductIds = new Set<string>()
  productId.forEach(id => allProductIds.add(id))
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  productAddonId.forEach(id => allProductIds.add(id))
  // TODO: fix type
  // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  variationProductIds.forEach(id => allProductIds.add(id))

  const contentColumn = (
    <ProductPageHeroContentColumn
      addToCartButtonData={prop('addToCartButton', data)}
      addToMiniCartButtonData={prop('addToMiniCartButton', data)}
      additionalLinks={additionalLinks}
      colorVariationData={variationData}
      columnSpans={isLeftContent ? leftSpans : rightSpans}
      id={prop('id', data)}
      location={location}
      onSelectVariationIndex={selectVariationIndex}
      outOfStockButtonText={prop('outOfStockButtonText', data)}
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'ProductA... Remove this comment to see the full error message
      productAddonData={productAddonData}
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'ProductD... Remove this comment to see the full error message
      productData={productData}
      selectedVariationIndex={selectedVariationIndex}
    />
  )

  const imageColumn = (
    <Column firstRow={true}
      spans={isLeftContent ? rightSpans : leftSpans}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ data?: ContentfulImageFragment | undefined... Remove this comment to see the full error message */}
      { !isBackgroundImage && ImageComponent && <ImageComponent {...imageProps} /> }
    </Column>
  )

  return (
    <PriceProvider skus={Array.from(allProductIds)}>
      <Row
        // @ts-expect-error TS(2322) FIXME: Type 'FC<ImageProps> | undefined' is not assignabl... Remove this comment to see the full error message
        BackgroundComponent={isBackgroundImage ? ImageComponent : undefined}
        backgroundColor={path([ 'productHeroBackgroundColor', 'color' ], data)}
        backgroundComponentProps={isBackgroundImage ? imageProps : undefined}
        dataComponent={ProductPageHero.name}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        height={defaultTo('standard', height) as RowProps['height']}
        padding={ifElse(equals(true), always('large'), always('none'))(isTabletUp && !padding)}
        textColor='none'
      >
        { isLeftContent ? contentColumn : imageColumn }
        { isLeftContent ? imageColumn : contentColumn }
      </Row>
    </PriceProvider>
  )
}

export const query = graphql`
  fragment productPageHero on ContentfulProductPageHeroV2 {
    productHeroBackgroundColor: backgroundColor {
      color
    }
    additionalLinks {
      ... on ContentfulButton {
        ...contentfulButtonFragment
      }
      ... on ContentfulLink {
        id
        internal {
          type
        }
        linkText
        linkItem {
          ... on ContentfulPage {
            pageUrl
          }
        }
      }
    }
    addToCartButton {
      ...contentfulButtonFragment
    }
    addToMiniCartButton {
      ...contentfulButtonFragment
    }
    desktopColumnRatio
    padding
    height
    id
    priceDisclaimerText
    priceMessage
    productImage: image {
      ... on ContentfulImage {
        ...contentfulImage
      }
    }
    productAddon {
      description
      product {
        sku
      }
      maxQuantity
      modal {
        ...modalFragment
      }
      quantityChangerLabel {
        text {
          text
        }
      }
    }
    productVariationList {
      imageList {
        ... on ContentfulImage {
          ...contentfulImage
        }
      }
      productInformationList {
        description {
          json
        }
        productId
        productInformationKey
      }
    }
    imagePosition
    internal {
      type
    }
    maxQuantity
    outOfStockButtonText
    productDescription {
      json
    }
    product {
      sku
    }
    quantityChangerLabel {
      text {
        text
      }
    }
    tabletColumnRatio
    showQuantityChanger
  }
`

export default ProductPageHero
