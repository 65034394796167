import {
  FormField,
  FormTextInput,
  Text,
} from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly companyFieldLabel: string
  readonly companyFieldPlaceholder: string
};

const Company: React.FC<Props> = ({ companyFieldLabel, companyFieldPlaceholder }: Props) => {
  return (
    <div className="my-7" data-component={'Company'}>
      <FormField
        hideLabel={true}
        label={companyFieldLabel}
        name="company"
      >
        <Text fontWeight="medium">{companyFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          id="company"
          name="company"
          placeholder={companyFieldPlaceholder}
        />
      </FormField>
    </div>
  )
}

export default Company
