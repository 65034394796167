import { Document } from '@contentful/rich-text-types'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import localizeCent from '@simplisafe/ewok/currency-utils/localizeCent'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Price, ProductPlan as SSProductPlan } from '@simplisafe/ss-react-components'
import {
  Either, Just, Maybe, None
} from 'monet'
import React, { FC } from 'react'

import { ContentfulProductPlan } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import { locale } from '../../commercetools/utils'
import { getMappedComponent } from '../../componentMappings'
import { usePriceContext } from '../../providers/PriceProvider'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import getTokenizedJson from '../../util/getTokenizedJson'
import ContentfulRichText from '../ContentfulRichText'
import type { MonitoringPlanTypeTokens } from '.'
import { getAddPlanButton, getMonitoringConfirmModal } from '.'

export type ProductPlanProps = {
  readonly item: Partial<ContentfulProductPlan>
  readonly product: Either<Error, Product>
  readonly index: MonitoringPlanTypeTokens
  readonly trackEvent: (_: TrackingData) => void
  readonly offerValue: Maybe<string>
  readonly monthsOfFreeService: string
  readonly packageSku: string
}

const ProductPlan: FC<ProductPlanProps> = ( {
  item,
  product,
  index,
  trackEvent,
  offerValue: overrideOfferText,
  monthsOfFreeService,
  packageSku
} ) => {
  // Based on the product plan type, retrieve either the service plan discount
  // offer text or regular offer text.
  const {
    getDiscountedPrice,
    getDiscountedPriceWithServicePlan,
    getDiscountedText,
    getDiscountedTextWithServicePlan,
    getPrice,
  } = usePriceContext()
  const packagePrice: Maybe<number> = getPrice(packageSku)
  const discountedPrice: Maybe<number> = getDiscountedPrice(packageSku)
  const discountPriceWithPlan: Maybe<number> = getDiscountedPriceWithServicePlan(packageSku)

  const discountAmount = packagePrice
    .map(price => price - discountedPrice.getOrElse(0))
    .map(_price => formatDisplayPrice(_price))
    .chain(_price => _price.isSome() ? _price : None<string>())
    .getOrElse('')

  const discountAmountWithPlan = packagePrice
    .map(price => price - discountPriceWithPlan.getOrElse(0))
    .map(_price => formatDisplayPrice(_price))
    .chain(_price => _price.isSome() ? _price : None<string>())
    .getOrElse('')

  const planIndexes = [ 'pro premium', 'interactive' ]

  const defaultOfferText: Maybe<string> = planIndexes.includes(index) ?
    getDiscountedTextWithServicePlan(packageSku) :
    getDiscountedText(packageSku)

  const discountAmountText = planIndexes.includes(index) ?
    discountAmountWithPlan :
    discountAmount

  const productOfferText: Maybe<string> = overrideOfferText.cata(
    () =>  defaultOfferText,
    _overrideOfferText => Just(_overrideOfferText)
  )
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulProductPlanProductOffe... Remove this comment to see the full error message
  // This retrieves the JSON for +{Discount Percentage} off your system today or Save Discount Value today
  const productOffer: Document = packageSku && getJson(prop('productOfferText', item))
  const productOfferValue = productOfferText.orUndefined()

  const keyFeatures = safeProp('keyFeatures', item).orJust([])

  const relatedInformation = prop('relatedInformation', item)
  const typeButton = path([ 'button', 'internal', 'type' ], item)
  const button = prop('button', item)
  const titlePlan = prop('title', item) || ''
  const priceRate: string = safeProp('priceRate', item).getOrElse('{price}/day')
  const isPriceRatePerDay = new RegExp(/(day)/gi).test(priceRate)

  const THEME_PRODUCT_PLAN_COMPONENT = /months? of Interactive Monitoring/i.test(titlePlan) === true ? 'fullWidthBlue' : 'fullwidth'
  const productOfferRichText = Maybe.fromNull(productOffer)
    .map(json => getTokenizedJson(json, '{{discount_amount}}', !overrideOfferText.isNone() ? overrideOfferText.some() : discountAmountText))
    .map(json => getTokenizedJson(json, '{{discount_percentage}}', !overrideOfferText.isNone() ? overrideOfferText.some() : defaultOfferText.getOrElse('')))

  const productOfferContent = productOfferValue && productOfferRichText.cata(
    () => null,
    _json => <ContentfulRichText rawRichText={_json} />
  )

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulProductPlan>' ... Remove this comment to see the full error message
  const planDescription = <ContentfulRichText rawRichText={getDescriptionJson(item)} />

  // Defines the monitoring price
  const price = product
    .toMaybe()
    .chain(_product => getPrice(_product.sku || ''))
    .chain(_price => isPriceRatePerDay ? Just(localizeCent(locale, _price)) : formatDisplayPrice(_price))
    .map(_price => priceRate.replace('{price}', _price))
    .cata(
      () => null,
      _price => <Price regularPrice={_price} />
    )

  return (
    <SSProductPlan
      button={
        typeButton !== 'ContentfulModal'
          ? button && getAddPlanButton(button)
          : button && getMonitoringConfirmModal(button, trackEvent, titlePlan, monthsOfFreeService)
      }
      key={`productplan ${index}`}
      keyFeatures={keyFeatures.map((data) => {
        const Component = data && getMappedComponent(data)
        return data
          ? {
            clickTarget: Component ? <Component data={data} /> : null,
            id: prop('id', data),
          }
          : {
            clickTarget: null,
            id: '',
          }
      })}
      planDescription={planDescription}
      price={price}
      productOfferContent={productOfferContent}
      relatedInformation={relatedInformation || ''}
      theme={THEME_PRODUCT_PLAN_COMPONENT}
      title={prop('title', item) || ''}
    />
  )
}

export default ProductPlan
