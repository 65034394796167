import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Grid } from '@simplisafe/ss-react-components'
import { GridProps, SocialLinks } from '@simplisafe/ss-react-components/Grid'
import { GridItemProps } from '@simplisafe/ss-react-components/GridItem'
import {
  Facebook, IconProps, Linkedin, Pinterest, Twitter, Youtube
} from '@simplisafe/ss-react-components/icons'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import contains from 'ramda/src/contains'
import defaultTo from 'ramda/src/defaultTo'
import head from 'ramda/src/head'
import map from 'ramda/src/map'
import split from 'ramda/src/split'
import toLower from 'ramda/src/toLower'
import React, { FC } from 'react'
import { pipe } from 'ts-functional-pipe'

import { ContentfulPressKitGrid } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'
import ContentfulRichText from '../ContentfulRichText'
import FluidImg from '../FluidImg'

type PressKitGridComponentProps = {
  readonly data: Partial<ContentfulPressKitGrid>
}

function toHoverIcon<T extends { readonly title?: string; readonly id?: string; readonly file?: { readonly url?: string } }>(icon: T) {
  const alt = prop('title', icon)
  const key = prop('id', icon)

  return (
    <img
      alt={alt}
      key={key}
      src={path([ 'file', 'url' ], icon)}
    />
  )
}

const toProps = applySpec<IconProps>({ dynamicClassName: pipe(split(' '), head, defaultTo(''), toLower) })

// @ts-expect-error TS(7006) FIXME: Parameter 'icon' implicitly has an 'any' type.
const toSvgIcon = (icon) => {

  return (pipe(prop('title'), defaultTo(''), toLower,
    cond(
      [
        // @ts-expect-error TS(7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
        [ contains('twitter'), (prop) => <Twitter {...toProps(prop)} /> ],
        // @ts-expect-error TS(7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
        [ contains('pinterest'), (prop) => <Pinterest {...toProps(prop)} /> ],
        // @ts-expect-error TS(7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
        [ contains('facebook'), (prop) => <Facebook {...toProps(prop)} /> ],
        // @ts-expect-error TS(7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
        [ contains('youtube'), (prop) => <Youtube {...toProps(prop)} /> ],
        // @ts-expect-error TS(7006) FIXME: Parameter 'prop' implicitly has an 'any' type.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
        [ contains('linkedin'), (prop) => <Linkedin {...toProps(prop)} /> ]
      ]
    )
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  )(icon))
}

const toSocialMediaIcon = applySpec<SocialLinks>({
  icon: pipe(path([ 'icon', '0' ]), toSvgIcon),
  id: prop('id'),
  linkText: prop('linkTitle'),
  linkUrl: prop('linkUrl')
})

const toGridItem = applySpec<GridItemProps>({
  hoverIcon: pipe(prop('hoverIcon'), toHoverIcon),
  hoverText: prop('hoverText'),
  id: prop('id'),
  image: prop('image'),
})

const gridProps = applySpec<GridProps>({
  content: (data: GridProps) => <ContentfulRichText rawRichText={getDescriptionJson(data)} />,
  gridContent: (data: GridProps) => {
    return (
      safeProp('gridContent', data)
        .map(gridItem => gridItem.map((content, i) => {
          const height = i !== 0 ? undefined : { height: 'auto' }
          const image = prop('image', content)
          return (
            {
              ...toGridItem(content),
              imageList: image && <FluidImg
                // TODO: fix type
                // @ts-expect-error TS(2322) FIXME: Type 'string | number | (() => string) | ((pos: nu... Remove this comment to see the full error message
                alt={prop('title', image)}
                className='styles-image'
                // TODO: fix type
                // @ts-expect-error TS(2322) FIXME: Type 'string | number | (() => string) | ((pos: nu... Remove this comment to see the full error message
                fluid={prop('fluid', image)}
                imgStyle={{
                  bottom: '0',
                  margin: 'auto',
                  maxWidth: '100%',
                  ...height
                }}
                // TODO: fix type
                // @ts-expect-error TS(2322) FIXME: Type 'string | number | (() => string) | ((pos: nu... Remove this comment to see the full error message
                key={prop('id', image)}
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            }
          )
        }))
        .orUndefined()
    )
  }


  ,
  socialLinks: pipe(prop('socialMediaIcons'), map(toSocialMediaIcon), defaultTo([])),
  title: prop('title')
})

const PressKitGridComponent: FC<PressKitGridComponentProps> =
  ({ data }: PressKitGridComponentProps) => {

    const gridDataProps = gridProps(data)
    return (
      <>
        <style>
          {
            `
              .styles-image {
                &:hover {
                  opacity: 0.35;
                }
              }
            `
          }
        </style>
        <Grid
          {...gridDataProps} />
      </>
    )
  }

//We have to integrate the filter selection to redux
export const query = graphql`
  fragment pressKitGridFragment on ContentfulPressKitGrid {
    id
    internal {
        type
    }
    description {
        json
    }
    title
    socialMediaIcons {
      linkUrl
      linkTitle
      id
      icon {
        file {
            url
            contentType
        }
        id
        title
      }
    }
    gridContent {
      hoverColor
      hoverIcon {
        file {
            contentType
            url
        }
        id
        title
      }
      hoverText
      id
      image {
        file {
            url
            contentType
        }
        id
        title
        fluid(maxWidth: 400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PressKitGridComponent
