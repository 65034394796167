import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { SSButton, TestimonialBanner } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { CompanyReview, TestimonialBannerProps } from '@simplisafe/ss-react-components/TestimonialBanner'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'
import {
  Just, Maybe, None
} from 'monet'
import { propOr } from 'ramda'
import applySpec from 'ramda/src/applySpec'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'
import React, { FC } from 'react'

import { ContentfulCompanyReviewBanner } from '../../../graphql'
import FluidImg from '../FluidImg'

type CompanyReviewBannerComponentProps = {
  readonly id: string
  readonly data: Partial<ContentfulCompanyReviewBanner>
}

// @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
const toCompanyList = (x) => (
  prop('companyIcon', x) ?
    <FluidImg
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      alt={path([ 'companyIcon', 'title' ], x)}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      fluid={path([ 'companyIcon', 'fluid' ], x)}
      imgStyle={{ objectFit: 'contain', }}
      style={{
        maxHeight: '90px',
        width: '100%',
      }}
    /> : null
)

const toCompanyReviewItem = applySpec<CompanyReview>({
  companyIcon: toCompanyList,
  companyName: prop('companyName'),
  companyReview: prop('companyReview'),
  iconSize: prop('iconSize')
})

const renderButton = (data:  Partial<ContentfulCompanyReviewBanner>) =>
  safeProp('button', data).map(button =>
    propOr<boolean, boolean>(false, 'isLinkExternal', data) ?
      <SSButton href={propOr<string, string>('#', 'url', button)} type="link">
        {propOr<string, string>('', 'text', button)}
      </SSButton> :
      <Link key="link" role="button"
        to={propOr<string, string>('', 'url', button)}>
        <SSButton type='div'>{propOr<string, string>('', 'text', button)}</SSButton>
      </Link>
  )
    .orUndefined()

const toCompanyReviewBanner = applySpec<TestimonialBannerProps>({
  companyReviewList: pipe(prop('companyReviewComponent'), map(toCompanyReviewItem)),
  sidePaddingSize: data => safeProp('sidePadding', data)
    .map(toLower)
    .orUndefined()
})

const CompanyReviewBannerComponent: FC<CompanyReviewBannerComponentProps> =
  ({ data }: CompanyReviewBannerComponentProps) => {
    const isMobile = !useMediaQuery('TabletAndUp')

    const button = renderButton(data)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const mobileMedia: Maybe<FluidObject> = safePath([ 'mediaMobile', 'fluid' ], data)
      .chain(fluid => isMobile ? Just(fluid) : None())

    const BackgroundComponent = safeProp('media', data)
      .chain(media =>
        safeProp('fluid', media)
          .map(fluid => (props: React.PropsWithChildren<{readonly [x: string]: unknown}>) =>
            <BackgroundImage
              {...props}
              alt={safeProp('title', media).getOrElse('CompanyReview')}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulFluid' is not assignab... Remove this comment to see the full error message
              fluid={mobileMedia.orJust(fluid)}
            />)
      )
      .orUndefined()

    return (
      <TestimonialBanner
        {...{
          ...toCompanyReviewBanner(data),
          buttonElement: button,
        }}
        BackgroundComponent={BackgroundComponent}
      />
    )
  }

export const query = graphql`
  fragment mediaAsset on ContentfulAsset {
    file {
    url
    }
  }
  fragment CompanyReviewBannerInformation on ContentfulCompanyReviewBanner{
      id
      internal {
        type
      }
      title
      companyReviewComponent {
          companyIcon {
            fluid(maxWidth: 511) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          companyName
          companyReview
          iconSize
      }
      button {
        url
        text
        type
        isLinkExternal
      }
      media {
        fluid(maxWidth: 1440) {
              ...GatsbyContentfulFluid_withWebp_noBase64
          }
        title
      }
      mediaMobile {
        fluid(maxWidth: 511) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
        title
      }
      sidePadding
  }
`

export default CompanyReviewBannerComponent
