import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { MeetTheSystem } from '@simplisafe/ss-react-components'
import { deviceProps, MeetTheSystemProps } from '@simplisafe/ss-react-components/MeetTheSystem'
import { ButtonItem } from '@simplisafe/ss-react-components/SSButton'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import map from 'ramda/src/map'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import pipe from 'ramda/src/pipe'
import React, { FC, ReactNode } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulMeetTheSystem as ContentfulMeetTheSystem } from '../../../graphql'
import { trackShopNowEvent } from '../../util/analytics'
import getDescriptionJson from '../../util/getDescriptionJson'
import getJson from '../../util/getJson'
import ContentfulRichText from '../ContentfulRichText'

export type MeetTheSystemComponentProps = {
  readonly data: Partial<ContentfulMeetTheSystem>
}

// @ts-expect-error TS(7006) FIXME: Parameter 'imgData' implicitly has an 'any' type.
const toGatsbyImage = (imgData): ReactNode => {
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const imgFluid = path([ 'fluid' ], imgData)
  return imgFluid && <Img
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    alt={path([ 'description' ], imgData)}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    fluid={imgFluid}
  />
}

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
const deviceList = (data) => {
  const toDeviceList = applySpec<deviceProps>({
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    content: (data: deviceProps) => <ContentfulRichText rawRichText={getJson(prop('descriptionText', data))} />,
    deviceImage: (value) => toGatsbyImage(prop('image', value)),
    id: path([ 'image', 'id' ]),
    title: path([ 'title' ])
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  return toDeviceList(data)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
const toMeetTheSystem = (trackEvent) => applySpec<MeetTheSystemProps>({
  badgeImage: (data) => toGatsbyImage(prop('badgeImage', data)),
  button: pipe(prop('button'), mergeDeepRight({
    onClickCtaButton: (_: ButtonItem) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
      trackShopNowEvent('lp-shop-now-button')(trackEvent)
    }
  })),
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulMeetTheSystem' is not ... Remove this comment to see the full error message
  content: (data: ContentfulMeetTheSystem) => <ContentfulRichText rawRichText={getDescriptionJson(data)} />,
  deviceList: pipe(prop('deviceList'), map(deviceList), defaultTo([])),
  id: prop('id'),
  title: prop('title')
})


const MeetTheSystemComponent: FC<MeetTheSystemComponentProps> =
  ({ data }: MeetTheSystemComponentProps) => {
    const { trackEvent } = useTracking()
    return (<>
      <MeetTheSystem {...toMeetTheSystem(trackEvent)(data)}></MeetTheSystem>
    </>)
  }


export const query = graphql`
  fragment meetTheSystemFragment on ContentfulMeetTheSystem {
    id
    internal {
        type
    }
    description {
      json
    }
    title
    deviceList {
      id
      descriptionText:description {
        json
      }
      title
      image {
        id
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    badgeImage {
      id
      description
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    button {
      text
      type
      id
      url
    }
  }
`

export default MeetTheSystemComponent
