import path from '@simplisafe/ewok/ramda/path'
import { safeProp } from '@simplisafe/monda'
import { BuildMySystemHeroItem } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulBmsHeroItem } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import getDescriptionJson from '../../util/getDescriptionJson'
import RichText from '../RichText'
import { SimpleBannerComponentProps } from '../SimpleBannerComponent/types'

type ContentfulBmsHeroItemProps = {
  readonly data: Partial<ContentfulBmsHeroItem>
}

const BmsHeroItem: FC<ContentfulBmsHeroItemProps> =
  ({ data }: ContentfulBmsHeroItemProps) => {
    const detailsModalContent = safeProp('detailsModalContent', data).orUndefined()
    const { trackEvent } = useTracking()
    // @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
    const trackEventBasePlanTooltip = (trackEvent, title) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- legacy code
      trackEvent({
        action: 'tooltip',
        category: 'bms',
        event: 'clickTooltip',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        label: title,
        site: 'fcp'
      })
    }
    const DetailsModalComponent = detailsModalContent ? getMappedComponent<SimpleBannerComponentProps>(detailsModalContent) : undefined

    return (
      <BuildMySystemHeroItem
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<ContentfulBmsHeroItem>' ... Remove this comment to see the full error message

        description={<RichText json={getDescriptionJson(data)} />}
        // @ts-expect-error TS(2322) FIXME: Type 'ContentfulBanner | undefined' is not assigna... Remove this comment to see the full error message
        detailsModalContent={DetailsModalComponent && <DetailsModalComponent data={detailsModalContent} id='' />}
        image={
          <Img
            alt={path([ 'icon', 'title' ], data)}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            fluid={path([ 'icon', 'fluid' ], data)}
            imgStyle={{ objectFit: 'contain' }}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        }
        onClick={() => trackEventBasePlanTooltip(trackEvent, safeProp('displayTitle', data).getOrElse(''))}
        title={safeProp('displayTitle', data).getOrElse('')}
      />
    )
  }

export default BmsHeroItem

export const query = graphql`#graphql
  fragment contentfulBmsHeroItemFragment on ContentfulBmsHeroItem {
    id
    internal {
      type
    }
    displayTitle
    description {
      json
    }
    icon {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    detailsModalContent {
      ... on ContentfulBanner {
        ...contentfulBanner
      }
    }
  }
`
