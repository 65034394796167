import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { MiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { brazeTrackCartEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import { Either, Maybe } from 'monet'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'

import { locale } from '../../commercetools/utils'
import {
  getCommerceDataFromPackageWithExtras,
  getCommerceDataFromProduct,
  getNameFromProductName,
  getPackagePrice,
  TrackEvent
} from '.'

export const trackAddToCartEvent = (
  productDetail: Either<Error, Product> | Maybe<Product>,
  trackEvent: TrackEvent,
  quantity: number,
  label?: string,
) => {
  productDetail.forEach(productDetail => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const productName = path([ 'name', locale ], productDetail)

    const ecomm = getCommerceDataFromProduct(productDetail, 'add', quantity)

    trackEvent({
      ecommerce: ecomm,
      event: 'addToCart'
    })

    trackEvent({
      ecommerce: ecomm,
      // todo add eec.add to TrackingEvent union type
      event: 'eec.add',
      // todo add eventAction param to TrackingData
      eventAction: 'addtocart',
      // todo add eventCategory param to TrackingData
      eventCategory:'eec',
      // todo add eventLabel param to TrackingData
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      eventLabel: label ? label : productName
    })

    brazeTrackCartEvent({
      eventType: 'add_to_cart',
      productTrackingData: ecomm
    })
  })
}

export const trackAddToCartPackageWithExtrasEvent = (
  pkg: Maybe<Package>,
  pkgProduct: Maybe<Product>,
  extraLineItems: readonly MiniCartLineItem[],
  isBms: boolean,
  trackEvent: TrackEvent,
  selectState: ImmutableState,
  hasMonitoring: boolean,
  label?: string,
  packagePrice?: number
) => {
  pkg.forEach(_package => {
    pkgProduct.forEach(_packageProduct => {
      const adjustedPackagePrice = packagePrice || getPackagePrice(hasMonitoring)(_package)

      const ecomm = getCommerceDataFromPackageWithExtras(_package, _packageProduct, extraLineItems, isBms, 'add', selectState, adjustedPackagePrice)

      trackEvent({
        ecommerce: ecomm,
        event: 'addToCart'
      })

      trackEvent({
        ecommerce: ecomm,
        // todo add eec.add to TrackingEvent union type
        event: 'eec.add',
        // todo add eventAction param to TrackingData
        eventAction: 'addtocart',
        // todo add eventCategory param to TrackingData
        eventCategory: 'eec',
        // todo add eventLabel param to TrackingData
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Product' is not assignable to pa... Remove this comment to see the full error message

        eventLabel: label || getNameFromProductName(locale)(_packageProduct).getOrElse('')
      })

      brazeTrackCartEvent({
        eventType: 'add_to_cart',
        productTrackingData: ecomm
      })
    })
  })
}

// todo this is convoluted to unpack and repack the Maybe; find a better way
export const trackAddToCartFloatingBannerEvent = (productDetail: Either<Error, Product>, trackEvent: TrackEvent, quantity: number) => {
  productDetail.forEach(response => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const productPrice = getPriceDiscountFloatingBanner(response)
    trackAddToCartEvent(Maybe.of({
      ...response,
      discountedPrice: Maybe.of(productPrice)
    }), trackEvent, quantity)
  })
}

const getPriceDiscountFloatingBanner = (product: Product) =>
  ifElse(isNil, always(prop('price')(product)), () =>
    prop('discountedPrice', product).fold(0)(response => response))(prop('discountedPrice', product))
