import { useEffect, useState } from 'react'

export const checkIsBrowser = () => typeof window !== 'undefined'

const useWindowScrollRender = (offset: number): boolean => {
  const isBrowser = checkIsBrowser()

  const [ y, setY ] = useState(isBrowser ? window.pageYOffset : 0)

  useEffect(() => {

    const handler = () => setY(window.pageYOffset)

    isBrowser && handler()

    isBrowser && window.addEventListener('scroll', handler, {
      capture: false,
      passive: true
    })

    return () => isBrowser ? window.removeEventListener('scroll', handler) : undefined
  }, [ y, isBrowser ])

  return isBrowser && y > offset
}

export default useWindowScrollRender
