import { safeProp } from '@simplisafe/monda'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { PaymentExperience } from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { Maybe } from 'monet'

export const getPaymentExperience = (cart: ImmutableCart): Maybe<PaymentExperience> => {
  return cart.get('custom')
    .chain(safeProp('fields'))
    .chain(safeProp('creditPaymentExperience'))
    .map((experience) => experience === 'zuora' ? 'zuora' : 'chase')
}
