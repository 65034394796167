import {
  FormField, FormTextInput, Text
} from '@simplisafe/ss-react-components'
import React from 'react'

export type Props = {
  readonly nameFieldLabel: string
  readonly nameFieldPlaceholder: string
}

const Name: React.FC<Props> = ({ nameFieldLabel, nameFieldPlaceholder }: Props) => {
  return (
    <div className="my-7" data-component={'Name'}>
      <FormField hideLabel={true} label={nameFieldLabel}
        name="name">
        <Text className="my-7 mb-1.5" fontWeight="medium">{nameFieldLabel}</Text>
        <FormTextInput
          fullWidth={true}
          id="name"
          name="name"
          placeholder={nameFieldPlaceholder}/>
      </FormField>
    </div>
  )
}

export default Name
