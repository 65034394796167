import { MAX_MESSAGE_LENGTH } from '@simplisafe/ss-ecomm-data/leads/schema/submitIdeaSchema'
import { submitIdeasFormSubmission } from '@simplisafe/ss-ecomm-data/leads/submission'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  FormField, FormTextAreaInput, FormTextInput, SSButton, Text
} from '@simplisafe/ss-react-components'
import {
  Form, Formik, FormikHelpers
} from 'formik'
import { propOr } from 'ramda'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { object, string } from 'yup'

import { SubmitIdeaFormFragment } from '../../../graphql'


type SubmitIdeaFormProps = {
  readonly data: SubmitIdeaFormFragment
}

type FormValues = {
  readonly email: string
  readonly message: string
  readonly firstName: string
  readonly lastName: string
}

const SubmitIdeaForm = ({ data }: SubmitIdeaFormProps) => {
  const locale = useSelector(selectLocale)

  const schema =
    object()
      .shape({
        email: string().email()
          .trim()
          .required()
          .label(propOr<string, string>('', 'emailLabel', data)),
        firstName: string()
          .trim()
          .required()
          .label(propOr<string, string>('', 'firstNameLabel', data)),
        lastName: string()
          .trim()
          .required()
          .label(propOr<string, string>('', 'lastNameLabel', data)),
        message: string()
          .trim()
          .required()
          .label(propOr<string, string>('', 'messageLabel', data))
          .max(MAX_MESSAGE_LENGTH)
      })

  const initialFormValues: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    message: '',
  }

  // use useState instead of Formik status for type-safety
  const [ submitStatus, setSubmitStatus ]  = useState<'error' | 'success' | undefined>()

  const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    submitIdeasFormSubmission({
      email: values.email.trim(),
      locale,
      message: values.message.trim(),
      name: `${values.firstName.trim()}_${values.lastName.trim()}`
    })(
      () => {
        setSubmitting(false)
        setSubmitStatus('error')
      }
    )(
      () => {
        setSubmitting(false)
        setSubmitStatus('success')
      }
    )
  }

  return (
    <Formik
      initialStatus={{ isError: false }}
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={schema}>
      {({ isSubmitting }) => (
        submitStatus === 'success' ?
          <Text className='mt-1'>{propOr<string, string>('', 'submitSuccessMessage', data)}</Text>
          :
          (
            <Form>
              <FormField hideLabel={true} label={propOr<string, string>('', 'firstNameLabel', data)}
                name='firstName'>
                <Text fontWeight='medium'>{propOr<string, string>('', 'firstNameLabel', data)}</Text>
                <FormTextInput fullWidth={true} id='firstName'
                  name='firstName'
                  placeholder={propOr<string, string>('', 'firstNamePlaceholder', data)} />
              </FormField>
              <FormField hideLabel={true} label={propOr<string, string>('', 'lastNameLabel', data)}
                name='lastName'>
                <Text fontWeight='medium'>{propOr<string, string>('', 'lastNameLabel', data)}</Text>
                <FormTextInput fullWidth={true} id='lastName'
                  name='lastName'
                  placeholder={propOr<string, string>('', 'lastNamePlaceholder', data)}/>
              </FormField>
              <FormField hideLabel={true} label={propOr<string, string>('', 'emailLabel', data)}
                name='email'>
                <Text fontWeight='medium'>{propOr<string, string>('', 'emailLabel', data)}</Text>
                <FormTextInput fullWidth={true} id='email'
                  name='email'
                  placeholder={propOr<string, string>('', 'emailPlaceholder', data)}/>
              </FormField>
              <FormField hideLabel={true} label={propOr<string, string>('', 'messageLabel', data)}
                name='message'>
                <Text fontWeight='medium'>{propOr<string, string>('', 'messageLabel', data)}</Text>
                <FormTextAreaInput id='message' name='message'
                  placeholder={propOr<string, string>('', 'messagePlaceholder', data)}/>
              </FormField>
              <FormField hideLabel={true}
                name='submit'>
                <SSButton disabled={isSubmitting}
                  minWidth='small' type='submit'>{propOr<string, string>('', 'submitButtonLabel', data)}</SSButton>
              </FormField>
              { submitStatus === 'error' && <Text className='mt-4' textColor='errorRed'>{propOr<string, string>('', 'submitErrorMessage', data)}</Text> }
            </Form>
          )
      )}
    </Formik>
  )
}

export default SubmitIdeaForm
