"use strict";
exports.__esModule = true;
exports.jsonSchema = void 0;
var zod_1 = require("zod");
var schema_1 = require("./schema");
var jsonSchema = function (typename) { return zod_1.z.object({
    __typename: zod_1.z.literal(typename)["default"](typename),
    json: schema_1.documentSchema
}); };
exports.jsonSchema = jsonSchema;
