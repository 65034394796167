import { Maybe } from 'monet'
import React, { FC } from 'react'

import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import CountryRedirectModal from '../CountryRedirectModal'



const CountryRedirectModals: FC<{ readonly countryRedirectModals: Maybe<SmallTextSectionRedirect>; readonly hidePopups: boolean }> = ({ countryRedirectModals, hidePopups }) => {
  return (<>
    {hidePopups ? <></> :
      countryRedirectModals?.map((data: SmallTextSectionRedirect) => (<CountryRedirectModal data={data} data-testid="country-redirect-modal"
        key='CountryRedirectModal' />)).orNull()
    }
  </>)
}

export default CountryRedirectModals
