import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import transformObject from '@simplisafe/ewok/transformObject'
import { safeProp } from '@simplisafe/monda'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { CardItemSmallBanner, SSButton } from '@simplisafe/ss-react-components'
import { CardItemSmallBannerProps } from '@simplisafe/ss-react-components/CardItemSmallBanner'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { graphql, Link } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ContentfulCardItemSmallBannerFragment, GatsbyContentfulFluid_NoBase64Fragment as GatsbyContentfulFluidNoBase64Fragment } from '../../../graphql'
import { usePriceContext } from '../../providers/PriceProvider'
import { priceDefault } from '../../providers/PriceProvider/formatter'
import getJson from '../../util/getJson'
import { nullToUndefined, toFirstCharLower } from '../../util/helper'
import FluidImg from '../FluidImg'

type CardItemSmallBannerComponentProps = {
  readonly id?: string
  readonly data: ContentfulCardItemSmallBannerFragment
}

const toButtonProps = applySpec<SSButtonProps>({
  color: pipe(prop('type'), defaultTo('primaryOutline'), toFirstCharLower, split(' '), join('')),
  href: path([ 'button', 'url' ]),
})

const toImageProps = (data: ContentfulCardItemSmallBannerFragment) => {
  const fluid: GatsbyContentfulFluidNoBase64Fragment | null | undefined = path([ 'image', 0, 'fluid' ], data)
  return fluid && <FluidImg alt={path([ 'image', 0, 'file', 'url' ], data)} fluid={nullToUndefined(fluid)}/>
}

const CardItemSmallBannerComponent: FC<CardItemSmallBannerComponentProps> =
  ({ data }: CardItemSmallBannerComponentProps) => {
    const skuID =  safeProp('productId', data)
    const isPromoTopBanner = useSelector(selectTopBannerVisible)
    // For US, when the top banner promo is displayed then priority should be given to discount without plan.
    const isNoPlanDiscount = isPromoTopBanner && equals('en-US', process.env.LOCALE)

    const {
      getDiscountedText, getDiscountedTextWithServicePlan, getFormattedPrice
    } = usePriceContext()
    const price = getFormattedPrice(skuID.orJust(''))(priceDefault, true, !isNoPlanDiscount)

    const discountedText = getDiscountedText(skuID.orJust(''))
    const discountedTextWithServicePlan = getDiscountedTextWithServicePlan(skuID.orJust(''))

    const placeholderText = isNoPlanDiscount ? discountedText : discountedTextWithServicePlan

    const tagBackgroundColor: string | undefined = path([ 'promotionalTagging', 'tagBackgroundColor' ], data) || undefined
    const taggingText = getJson(path([ 'promotionalTagging', 'taggingText' ], data))
    const tagTextColor: string | undefined = path([ 'promotionalTagging', 'textColor' ], data) || undefined

    const buttonProps = toButtonProps(data)
    const url = path([ 'button', 'url' ], data)
    const isInternalLink = url && url.startsWith('/')
    const ssButton = <SSButton {...buttonProps} type={isInternalLink ? 'div' : 'link'}>{path([ 'button', 'text' ], data)}</SSButton>
    const button = url && url.startsWith('/') ? <Link to={url}>{ssButton}</Link> : ssButton

    const props = transformObject<ContentfulCardItemSmallBannerFragment, CardItemSmallBannerProps>({
      additionalInfo: path([ 'additionalInfo', 'json' ]),
      bannerImage: toImageProps,
      button: () => button,
      offerTag: () => isPromoTopBanner ? {
        placeholderText: placeholderText.orJust(''),
        tagBackgroundColor,
        tagTextColor,
        taggingText
      } : undefined,
      price: () => price,
      productDescription: path([ 'productDescription', 'json' ]),
      productHighlights: path([ 'productHighlights', 'json' ]),
      // different to propOr because data.title is string | null | undefined
      title: (_data) => prop('title', _data) || ''
    })(data)

    return <CardItemSmallBanner {...props} />
  }

export default CardItemSmallBannerComponent

export const query = graphql`#graphql
    fragment promotionalTagging on ContentfulPromotionalTagging {
      id
      tagBackgroundColor
      textColor
      borderColor
      taggingText {
        json
      }
      tagBackgroundColor
    }
    fragment contentfulCardItemSmallBanner on ContentfulProductCard {
      id
      internal {
        type
      }
      textPosition
      spaceId
      additionalInfo {
        json
      }
      productDescription {
        json
      }
      productHighlights {
        json
      }
      title
      button {
        text
        url
        type
      }
      image {
        file {
          url
          fileName
        }
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      productId
      promotionalTagging {
        ...promotionalTagging
      }
    }
`
