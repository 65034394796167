import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { selectCrimesByCategory, selectGeoLocationPlace } from '@simplisafe/ss-ecomm-data/redux/select'
import { IOSearchLocation } from '@simplisafe/ss-ecomm-data/ukPoliceData/policedata'
import { LocationBanner, Text } from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { LocationBannerProps, SquareSection } from '@simplisafe/ss-react-components/LocationBanner'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'
import trim from 'ramda/src/trim'
import unless from 'ramda/src/unless'
import React, {
  FC, ReactNode,
  useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContentfulCrimeLocationBannerWithAddressField } from '../../../graphql'
import getJson from '../../util/getJson'
import { getPreviousDates } from '../../util/helper'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { getContentType } from '../ContentfulRichText/embeddedEntries'
import FluidImg from '../FluidImg'


export type LocationBannerComponentProps = {
  readonly data: Partial<ContentfulCrimeLocationBannerWithAddressField>
}

function getImage<T extends keyof ContentfulCrimeLocationBannerWithAddressField, U extends keyof T>(firstPath: T, secondPath: U, data: Partial<ContentfulCrimeLocationBannerWithAddressField>) {
  return ifElse(
    equals('image/svg+xml'),
    always(<img alt={path([ firstPath, secondPath, 'title' ], data)}
      src={path([ firstPath, secondPath, 'file', 'url' ], data)} />),
    always(path([ firstPath, secondPath, 'fluid' ], data) && <FluidImg alt={path([ firstPath, secondPath, 'title' ], data)}
      fluid={path([ firstPath, secondPath, 'fluid' ], data)} />)
  )(pathOr('', [ firstPath, secondPath, 'file', 'contentType' ], data))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'useMyLocation' implicitly has an 'any' ... Remove this comment to see the full error message
const toLocationBannerData = (data: Partial<ContentfulCrimeLocationBannerWithAddressField>, useMyLocation, isTabletUp: boolean) => {
  const toLocationLink = applySpec({
    // @ts-expect-error TS(2345) FIXME: Argument of type '"linkIcon"' is not assignable to... Remove this comment to see the full error message
    icon: always(getImage('locationLink', 'linkIcon', data)),
    onClick: () => useMyLocation,
    text: prop('linkText')
  })

  const getDisclaimerClassName = (otherDeviceVisibility: string, isTabletUp: boolean) => {
    return classNames(
      { ['hideMobile']: isEmpty(otherDeviceVisibility) },
      { ['showMobile']: !isEmpty(otherDeviceVisibility) && !isTabletUp && equals(otherDeviceVisibility, 'Mobile') }
    )
  }

  const disclaimerOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]:
        (__: unknown, children: ReactNode) =>
          <div className="rc-w-full rc-h-full"><small
            className={classNames(getDisclaimerClassName('', isTabletUp))} style={{
              backgroundColor: 'var(--neutral-medium)',
              color: 'var(--neutral-black)',
              margin: 0,
            }} ><Text textAlignment='center' textSize="xs">{children}</Text></small></div>,
    }
  }
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const disclaimerContentText = getJson(path([ 'disclaimerTextDesc' ], data))
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const contentText = getJson(path([ 'description' ], data))

  const toLocationBanner = applySpec<LocationBannerProps>({
    BackgroundComponent: pipe(prop('backdropImage'), unless(isNil, always(BackgroundImage))),
    backgroundComponentProps: { fluid: path([ 'backdropImage', 'fluid' ]), },
    content: () => <ContentfulRichText rawRichText={contentText} />,
    disclaimerContent: () => <ContentfulRichText optionsCustom={disclaimerOptions} rawRichText={disclaimerContentText} />,
    enterLocationText: prop('enterLocationText'),
    locationLink: pipe(prop('locationLink'), toLocationLink),
    placeholder: path([ 'searchBox', 'placeholderText' ]),
    title: prop('title'),
  })

  return toLocationBanner(data)
}

const ContentfulLocationBannerComponent: FC<LocationBannerComponentProps> =
  ({ data }: LocationBannerComponentProps) => {
    const dispatch = useDispatch()
    const myLocation = useSelector(selectGeoLocationPlace)
    const [ isSubmitAction, setSubmitAction ] = useState(false)
    const [ searchLocation, setSearchLocation ] = useState(myLocation)
    const crimesData = useSelector(selectCrimesByCategory(searchLocation))
    const isCrimesData = !isNil(crimesData) && !isEmpty(crimesData)
    const isTabletUp = useMediaQuery('TabletAndUp')
    const searchResultOptions = {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const isPlaceHolder = pipe(getContentType, equals('placeholder'))(node)
          //TODO need content to be updated
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
          const data = crimesData as ReadonlyArray<SquareSection>
          return isPlaceHolder && data && (
            <div className={classNames('flex flex-row justify-center my-0 -mx-4',
              { 'flex-col': !isTabletUp, }
            )}>
              {
                data.map(({
                  title, count, color
                }) => (
                  <div className={classNames('py-0 px-4 text-center w-full', 'neutralWhiteTextColor', `${color}BackgroundColor`)}
                    key={title}>
                    <p>{title}</p>
                    <p>{count}</p>
                  </div>
                ))
              }
            </div>
          )
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          const isPlaceHolder = pipe(getContentType, equals('placeholder'))(node)
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
          const location = searchLocation as string
          return isPlaceHolder && location && (
            <span>{location}</span>
          )
        }
      } as RenderNode
    }
    const searchResultSection = path([ 'searchResultSection' ], data)
    const noResultSection = path([ 'noResultSection' ], data)
    const searchResultsContent = isCrimesData ? <ContentfulRichText optionsCustom={searchResultOptions} rawRichText={getJson(searchResultSection)} /> : null
    const noResultsContent = searchLocation && !isNil(crimesData) && isEmpty(crimesData) && <ContentfulRichText optionsCustom={searchResultOptions} rawRichText={getJson(noResultSection)} />


    const useMyLocation = () => {
      ifElse(isNil, always(''), (loc: Geolocation) => loc.getCurrentPosition(pos => {
        setSubmitAction(false)
        IOSearchLocation(dispatch)(`${pos.coords.longitude},${pos.coords.latitude}`, []).run()
      })
      )(navigator.geolocation)
    }

    const onSubmit = useCallback((place: string) => {
      const location = toLower(trim(defaultTo('', place)))
      setSearchLocation(location)
      // To get the list of dates YYYY-MM for getting crime data for last 12 months.
      // Here passing prevMonth as 2 for taking the last but the previous month to start the search.
      const dates = getPreviousDates(12, 2)
      location && IOSearchLocation(dispatch)(location, dates).run()
    }, [ dispatch ])

    const onClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault()
      unless(pipe(isNil, isEmpty), onSubmit)(searchLocation)
    }, [ searchLocation, onSubmit ])

    const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSubmitAction(true)
      setSearchLocation(event.target.value)
    }, [])

    useEffect(() => {
      !isSubmitAction && setSearchLocation(myLocation)
    }, [ myLocation, isSubmitAction ])

    return (<LocationBanner {...toLocationBannerData(data, useMyLocation, isTabletUp)
    }
    noResultsContent={noResultsContent}
    onChange={handleOnChange}
    onSubmit={onClick}
    response={{
      location: searchLocation,
      theft: crimesData
    }}
    searchButton={
      {
        // @ts-expect-error TS(2345) FIXME: Argument of type '"icon"' is not assignable to par... Remove this comment to see the full error message
        children: getImage('searchButton', 'icon', data),
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        type: path([ 'searchButton', 'type' ], data)
      }
    }
    searchResultsContent={searchResultsContent}
    />)
  }

export const query = graphql`#graphql
fragment locationBanner on ContentfulCrimeLocationBannerWithAddressField{
  id
  internal {
    type
  }
  title
  backdropImage {
    fluid(maxWidth: 1100) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
    description: descriptionText {
      json
    }
    disclaimerTextDesc: disclaimerText {
      json
    }
    enterLocationText
    id
    searchBox {
        placeholderText
    }
    searchButton {
        icon {
        title
        file{
            url
            contentType
        }
        fluid(maxWidth: 30) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        type
    }
    locationLink {
        linkIcon {
        title
        file{
            url
            contentType
        }
        fluid(maxWidth: 30) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        linkText
    }
    searchResultSection {
        json
    }
    noResultSection {
        json
    }
  }
`
export default ContentfulLocationBannerComponent
