import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  Column,
  RichText,
  Row
} from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { PerfectSystemBannerFragment } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'
import { renderComponentFromData } from '../../util/render'
import FluidImg from '../FluidImg'

export type PerfectSystemBannerComponentProps = {
  readonly data: Partial<PerfectSystemBannerFragment>
}

export const toNumber =
  (n: number | undefined): number =>
    n === undefined ? NaN : n

const PerfectSystemBannerComponent: FC<PerfectSystemBannerComponentProps> =
  ({ data }: PerfectSystemBannerComponentProps) => {
    const findYourSystemComponent = prop('findYourSystemComponent', data)

    return (
      <Row inheritTextColor key={prop('id', data)}
        padding='small'>
        <Column spans={[ 0, 5, 6 ]}>
          <FluidImg
            alt={path([ 'media', 'image', 'title', ], data)}
            // @ts-expect-error TS(2322) FIXME: Type 'GatsbyContentfulFluid_WithWebp_NoBase64Fragm... Remove this comment to see the full error message
            fluid={path([ 'media', 'image', 'fluid', ], data)}
            style={{ height: '100%' }}/>
        </Column>
        <Column
          spans={[ 12, 7, 6 ]}>
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<PerfectSystemBannerFragm... Remove this comment to see the full error message */}
          <RichText json={getDescriptionJson(data)}/>
          { findYourSystemComponent && renderComponentFromData(findYourSystemComponent) }
        </Column>
      </Row>
    )
  }


export default PerfectSystemBannerComponent

export const perfectSystemFragment = graphql`#graphql
  fragment perfectSystemBanner on ContentfulPrefectSystemBanner {
    id
    internal {
      type
    }
    title
    description {
      json
    }
    media: image  {
      id
      image {
        id
        title
        description
        fluid(cropFocus: RIGHT, resizingBehavior: FILL, maxWidth: 1440) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    findYourSystemComponent {
      ...quoteWizard
    }
  }
`
