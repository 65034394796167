import React, { FC } from 'react'

import FluidImg from '../FluidImg'
import { getImageStyle, getImageWrapperStyle } from './helpers'
import { ReviewCardImageProps } from './types'

const ReviewCardImage: FC<ReviewCardImageProps> = ({
  title, icon, iconSize, iconPosition
}) => {
  return (
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    <div className={getImageWrapperStyle(iconPosition, iconSize)}>
      <FluidImg
        alt={title}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulFluid> | undefined' is not a... Remove this comment to see the full error message
        fluid={icon?.fluid}
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        style={getImageStyle(iconPosition)}
      />
    </div>
  )
}

export default ReviewCardImage
