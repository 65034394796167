import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { GridContentBlock } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import lensIndex from 'ramda/src/lensIndex'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import set from 'ramda/src/set'
import T from 'ramda/src/T'
import toLower from 'ramda/src/toLower'
import React, { FC, ReactNode } from 'react'

import {  ContentfulComparisonBannerFragment } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'
import ContentfulRichText from '../ContentfulRichText'

type ContentfulComparisonBannerProps = {
  readonly id: string
  readonly data: ContentfulComparisonBannerFragment
}

// @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
const toHeaderLogo = (x) => (
  <Img
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    alt={path([ 'headerLogo', 'title' ], x)}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    fluid={path([ 'headerLogo', 'fluid' ], x)}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    key={path([ 'headerLogo', 'id' ], x)}
    style={{ width: '100%' }}
  />
)

const simplisafeIndex = 1

const textColumn = (value: string) => ({
  type: 'text',
  value
})

const toHeaderData = (headerData: ReadonlyArray<string>, imgUrl: ReactNode) => {
  const getHeaderRow = cond([
    [ equals('Simplisafe'), always({
      type: 'icon',
      value: imgUrl
    }) ],
    [ isEmpty, always({}) ],
    [ T, textColumn ]
  ]
  )
  return map(getHeaderRow, headerData)
}

const toRowData = (rowData: ReadonlyArray<string>, ssIndex: number) => {
  const getRowData = cond([
    [ pipe(toLower, equals('yes')), always({
      type: 'icon',
      // TODO can we validate these icon names somehow? or at least don't break the site if it's wrong
      value: 'CheckMarkGrayCircle'
    }) ],
    [ pipe(toLower, equals('no')), always({}) ],
    [ T, textColumn ]
  ]
  )
  const row = map(getRowData, rowData)
  const ssLens = lensIndex(ssIndex)
  const rowResult = set(ssLens, {
    type: 'icon',
    value: 'CheckMarkBlueCircle'
  }, row)
  return { row: rowResult }
}

const ContentfulComparisonBanner: FC<ContentfulComparisonBannerProps> = (({ data }: ContentfulComparisonBannerProps) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulComparisonBannerFragme... Remove this comment to see the full error message
  const description = getDescriptionJson(data)
  const headerLogo = pipe(toHeaderLogo)(data)
  const tableData = path([ 'table', 'tableData' ], data)
  const title = prop('title', data)

  const [ headerData, ...rowData ] = tableData ? tableData : []

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<string>[]' is not assignab... Remove this comment to see the full error message
  const headerArray = headerData ? toHeaderData(headerData, headerLogo) : []
  const rowArray = rowData.map((item) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<Maybe<string>[]>' is not a... Remove this comment to see the full error message
    return toRowData(item, simplisafeIndex)
  })
  const table = {
    header: headerArray,
    rows: rowArray
  }
  return (
    <GridContentBlock
      content={<ContentfulRichText rawRichText={description} />}
      iconPopup={false}
      tableList={table}
      title={title || ''} />
  )
})

export default ContentfulComparisonBanner

export const contentfulComparisonFragment = graphql`#graphql
  fragment contentfulComparisonBanner on ContentfulComparisonBanner {
      id
      internal {
          type
      }
      title
      headerLogo{
          file{
            url
          }
          fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
        id
        }
        description{
        json
        }
        table{
          tableData
        }
  }
`
