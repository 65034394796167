import { TrackingData } from '@simplisafe/ecomm-ts-types'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import applySpec from 'ramda/src/applySpec'
import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import toLower from 'ramda/src/toLower'

import { setGtmCustomEvent } from '../../util/analytics'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import { NavItem } from './query'

export const logo = 'logo'

export const isLogo = pipe(toLower, (t) => t.includes(logo))

export const logoLinkUrl = (linkUrl: string) => {
  // If a partner cookie is set and it includes partner page URL, overwrite homepage URL to use partner page URL instead
  const defaultLogoLinkUrl = '/'
  const partnerPageUrl = getValueFromPartnerCookie('partnerUrl')
  const replaceLogoUrl = linkUrl === defaultLogoLinkUrl && isNotNil(partnerPageUrl)
  const logoLinkFinal = replaceLogoUrl ? partnerPageUrl : linkUrl
  return logoLinkFinal || defaultLogoLinkUrl
}

export const handleLinkClick = (
  linktext: string,
  trackEvent: (_data: Partial<TrackingData>) => void
) => {
  setGtmCustomEvent({
    event: 'linkClick',
    eventAction: 'click',
    eventCategory: 'navigation',
    eventLabel: linktext,
  })
  // TODO add event type to ecomm-ts-types
  trackEvent({ event: 'linkClick' })
}


export const linkToListItem = applySpec<{ readonly title: string; readonly href: string }>({
  href: prop('linkUrl'),
  label: prop('linkText'),
  title: prop('linkText')
})


export const flattenInternalType = (item: NavItem): NavItem => {
  const internalType = path<'internal', 'type', NavItem['internal']['type']>([ 'internal', 'type' ], item)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  return {
    ...item,
    internalType
  } as NavItem
}
