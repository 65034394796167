import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { Carousel } from '@simplisafe/ss-react-components'
import { CarouselProps } from '@simplisafe/ss-react-components/Carousel'
import { PaginationPosition } from '@simplisafe/ss-react-components/CarouselContainer'
import { HeroSlideProps, PreloadLink } from '@simplisafe/ss-react-components/HeroSlide'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import cond from 'ramda/src/cond'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import has from 'ramda/src/has'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import last from 'ramda/src/last'
import map from 'ramda/src/map'
import split from 'ramda/src/split'
import toLower from 'ramda/src/toLower'
import unless from 'ramda/src/unless'
import React, { FC } from 'react'
import { pipe } from 'ts-functional-pipe'

import {
  CarouselFeaturesInformationFragment, ContentfulAsset, ContentfulBanner
} from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'


type CarouselFeaturesComponentProps = {
  readonly data: CarouselFeaturesInformationFragment
}

type AssetWithFluid = ContentfulAsset & {
  readonly fluid: FluidObject
}

const getContent = (keyDescription: string) => {
  return applySpec({
    description: ifElse(
      has(keyDescription),
      path([ keyDescription, 'json' ]),
      getDescriptionJson
    ),
    title: prop('title')
  })
}

const getImage = (banner: ContentfulBanner): Maybe<AssetWithFluid> => safePath([ 'media', '0' ], banner)
  .filter(img => !!prop('fluid', img))

const toDisplayMode = cond([
  [ equals('Center-Left'), always('left') ],
  [ equals('Center-Right'), always('right') ],
  [ equals('Top-Center'), always('top') ],
  [ equals('Top-Left'), always('top-left') ],
])

const toPaginationPosition = cond<string, PaginationPosition>([
  [ equals('Left'), always('below-text') ],
  [ equals('Center'), always('bottom-center') ],
  [ equals('Right'), always('bottom-right-to-center') ],
])

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
const getHeroSlide = (keyDescription: string, data) => {

  const toContent = getContent(keyDescription)
  return applySpec<HeroSlideProps>({
    backgroundColor: pipe(prop('backgroundColor'), unless(isNil, split(' ')), unless(isNil, last), unless(isNil, toLower), defaultTo('gray')),
    children: (banner: ContentfulBanner) => getImage(banner)
      // use image from the first slide if the current slide doesn't have one (not sure why, this was existing behavior)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
      .orElse(getImage(safePath([ 'banner', '0' ], data).orUndefined()))
      .map(img => (
        <Img
          alt={prop('description', img)}
          fluid={prop('fluid', img)}
          key={prop('id', img)}
          style={{ width: '100%' }}
          title={prop('title', img)}
        />
      ))
      .orUndefined(),
    childrenPosition: pipe(prop('mediaPosition'), unless(isNil, toLower), defaultTo('center')),
    content: pipe(unless(isNil, toContent), defaultTo({})),
    displayMode: pipe(prop('textPosition'), unless(isNil, toDisplayMode), defaultTo('top-left')),
    preload: () => None<PreloadLink>(),
    // TECH DEBT: We should change the way that the colors are passed to the HeroSlide.
    textColor: pipe(path([ 'textColor', 'color' ]), unless(isNil, ifElse(equals('neutralWhite'), always('light'), always('dark'))), defaultTo('dark')),
  })
}

const toCarouselFeaturesData = (data: CarouselFeaturesInformationFragment) => {
  const toCarouselFeatures = applySpec<CarouselProps>({
    mediatype: always('image'),
    slideData: pipe(prop('banner'), map(getHeroSlide('description', data))),
  })

  return toCarouselFeatures(data)
}

const isNumberCarousel = pipe(prop('carouselIndicatorType'), defaultTo(''), equals('Chevron with Number'))

const CarouselFeaturesComponent: FC<CarouselFeaturesComponentProps> =
  ({ data }: CarouselFeaturesComponentProps) => {
    const numberCarouselIndicatorType =
      // TODO: fix type
      isNumberCarousel(data)
    const isMobile = !useMediaQuery('TabletAndUp')
    const paginationAlignment = isMobile ? safeProp('paginationPositionMobile', data) : safeProp( 'paginationPosition', data)
    return (
      <Carousel
        {...toCarouselFeaturesData(data)}
        carouselContainerData={{
          arrows: numberCarouselIndicatorType,
          autoplay: !numberCarouselIndicatorType,
          dotsColor: 'blue',

          enableDots: !numberCarouselIndicatorType,
          paginationPosition: paginationAlignment.map(toPaginationPosition).orUndefined(),
          playButton: false
        }}
        enableInlineArrow={numberCarouselIndicatorType}
        slideMobileData={safeProp('banner', data)
          .map(val => val.map(getHeroSlide('description', data)))
          .orUndefined()}
      />
    )
  }

export const query = graphql`
  fragment carouselFeaturesInformation on ContentfulCarouselFeatures{
      id
      internal {
        type
      }
      title
      descriptionMobile {
        json
      }
      backgroundColor
      carouselIndicatorType
      paginationPosition
      paginationPositionMobile
      banner {
        title
        backgroundColor
        textColor {
          color
        }
        description {
          json
        }
        textPosition
        mediaPosition
        media {
          description
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          id
          title
        }
      }
    }
`

export default CarouselFeaturesComponent
