import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { SimpleFooter } from '@simplisafe/ss-react-components'
import React, { FC } from 'react'

import { SmallTextSectionFragmentFragment } from '../../../graphql'
import getJson from '../../util/getJson'

type SimpleFooterComponentProps = {
  readonly id?: string
  readonly data: SmallTextSectionFragmentFragment
}


const SimpleFooterComponent: FC<SimpleFooterComponentProps> =
  ({ data }: SimpleFooterComponentProps) =>
    safeProp('description', data)
      .map(getJson)
      .map(json => <SimpleFooter description={json}
        key={prop('id', data)}/>)
      .orNull()

export default SimpleFooterComponent
