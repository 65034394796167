import {
  FormField,
  FormTextInput,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type LastNameProps = {
  readonly label: string
  readonly placeholder: string
}

const LastName: React.FC<LastNameProps> = (props: LastNameProps) => {
  return (
    <div data-component={'lastName'}>
      <FormField hideLabel={true} name='lastName'>
        <Text fontWeight='medium'>{props.label}</Text>
        <FormTextInput
          fullWidth={true}
          name='lastName'
          placeholder={props.placeholder}
          style={{ width: '100%' }}
        />
      </FormField>
    </div>
  )
}

export default LastName
