import { SSButton, Text } from '@simplisafe/ss-react-components'
import React from 'react'

type SubmissionProps = {
  readonly isMobile: boolean
  readonly disableSubmit: boolean
  readonly showErrorMessage: boolean
  readonly submitButtonLabel: string
}

const Submission: React.FC<SubmissionProps> = (props: SubmissionProps) => {
  return (
    <div style={{ marginLeft: '0.5625rem' }}>
      <Text
        className="mt-8"
        data-component={'Submission'}
        useTailwind
      >
        <SSButton
          disabled={props.disableSubmit}
          minWidth={'auto'}
          type='submit'>
          {props.submitButtonLabel}
        </SSButton>
      </Text>
    </div>
  )
}

export default Submission
