import log from '@simplisafe/ewok/log'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart'
import { buildCustomFieldUpdateAction, } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import {
  fetchPaymentExperience,
  PaymentExperience,
  PaymentExperienceRequest
} from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { Maybe } from 'monet'
import equals from 'ramda/src/equals'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import {
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'

import { getPaymentExperience } from '../../../util/zuora'

export type UserIdentification = {
  readonly id?: string
  readonly email?: string
}

export type UsePaymentExperienceResult = {
  readonly creditPaymentExperience?: PaymentExperience
}

/**
 * Hook for fetching the credit payment experience for a given user based on email and/or user id, and then updating
 * the cart to store the experience as a custom field.
 *
 * Currently, falls back to Chase upon receiving an error while fetching the payment experience, though once Zuora
 * is fully live this should fall back to Zuora as the default.
 *
 * @param user The user information for selecting a payment experience.
 * @param isCartReady The cart 'ready' state, to prevent update attempts while the cart is already being updated.
 */
export const usePaymentExperience = (user: UserIdentification, isCartReady: boolean): UsePaymentExperienceResult => {
  const cart = useSelector(selectCart)
  const dispatch = useDispatch()
  const [ creditPaymentExperience, setCreditPaymentExperience ] = useState<PaymentExperience>()

  const cartPaymentExperience = cart.toMaybe()
    .chain(getPaymentExperience)
    .orUndefined()

  // Fetch the payment experience for the user when either email or id changes
  useEffect(() => {
    const email = Maybe.fromEmpty(user.email).orUndefined()
    const userId = Maybe.fromEmpty(user.id).orUndefined()
    const shouldFetch = !isNil(email) || !isNil(userId)

    const fetch = () => {
      const request: PaymentExperienceRequest = {
        email,
        userId
      }

      fetchPaymentExperience(request)(() => {
        setCreditPaymentExperience('chase')
      })(response => {
        response.forEach(response => {
          setCreditPaymentExperience(response.experience)
          log(`Got payment experience of ${response.experience} for email ${user.email} and userId ${user.id}`)
        })
      })
    }

    when(equals(true), fetch, shouldFetch)
  }, [ user ])

  // Set the creditPaymentExperience field on the cart when the experience changes
  useEffect(() => {
    const experienceUpdated = !isNil(creditPaymentExperience) && !equals(cartPaymentExperience, creditPaymentExperience)
    const shouldUpdate = isCartReady && experienceUpdated

    const update = () => {
      const experienceUpdateAction = buildCustomFieldUpdateAction({
        name: 'creditPaymentExperience',
        value: creditPaymentExperience
      })

      const handleUxUpdateError = () => logError(Error('Failed to update payment experience'))

      dispatch(IOUpdateCart([ experienceUpdateAction ], handleUxUpdateError))
    }

    when(equals(true), update, shouldUpdate)
  }, [ cartPaymentExperience, creditPaymentExperience, dispatch, isCartReady ])

  return { creditPaymentExperience }
}
