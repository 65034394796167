import { COOKIE_CHAT_OPENED } from '@lib/tracking/src/cookies'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { useLocation } from '@reach/router'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import { window } from 'browser-monads-ts'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import {
  useCallback, useEffect, useRef, useState
} from 'react'
import { useTracking } from 'react-tracking'
import Cookies from 'universal-cookie'

import { SprChatOnEventParameter } from '../types/sprChat'
import useLiveChatClientExists from './useLiveChatClientExists'
import { Status } from './useScript'

const cookies = new Cookies()

const useLiveChatTracking = (status: Status) => {
  const location = useLocation()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking()

  const { pathname } = location

  // State to track if an agent was assigned in the chat. Determines which close event fires.
  const [ liveChatAgentAssigned, setLiveChatAgentAssigned ] = useState<boolean>(false)
  // Keep track of the current state value for usage within useCallback.
  const stateRef = useRef(false)
  stateRef.current = liveChatAgentAssigned

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleAgentAssigned = useCallback(() => {
    setLiveChatAgentAssigned(true)
    trackEvent({
      appSection: 'liveChat',
      event: 'agentEvent',
      pagePath: pathname,
    })
  }, [ pathname, trackEvent ])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosedPostEngagement = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'closeWidgetPostEngagement',
      pagePath: pathname,
    })
  }, [ pathname, trackEvent ] )

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosedWithoutEngagement = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'closeWidgetWithoutEngaging',
      pagePath: pathname,
    })
  }, [ pathname, trackEvent ])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowClosed = useCallback(() => {
    stateRef.current ? handleConversationWindowClosedPostEngagement() : handleConversationWindowClosedWithoutEngagement()
  }, [ handleConversationWindowClosedPostEngagement, handleConversationWindowClosedWithoutEngagement, stateRef ])

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleConversationWindowOpened = useCallback(() => {
    optimizelyTrackSiteEvents({ eventType: 'click_sprinklr_live_chat' })
    trackEvent({
      appSection: 'liveChat',
      event: 'openEvent',
      pagePath: pathname,
    })
    cookies.set(COOKIE_CHAT_OPENED, 'true', cookiesOption)
  }, [ optimizelyTrackSiteEvents, pathname, trackEvent ])

  const handleTriggerVisible = useCallback(() => {
    trackEvent({
      appSection: 'liveChat',
      event: 'impression',
      pagePath: pathname,
    })
  }, [ pathname, trackEvent ] )

  // Unable to test 3rd party livechat event handlers
  /* istanbul ignore next */
  const handleEvent = useCallback((event: SprChatOnEventParameter) => {
    const { eventType } = event
    cond([
      [ equals('AGENT_ASSIGNED'), handleAgentAssigned ],
      [ equals('CONVERSATION_WINDOW_CLOSED'), handleConversationWindowClosed ],
      [ equals('CONVERSATION_WINDOW_OPENED'), handleConversationWindowOpened ],
    ])(eventType)
  }, [ handleAgentAssigned, handleConversationWindowClosed, handleConversationWindowOpened ] )

  const clientExists = useLiveChatClientExists(status)

  useEffect(() => {
    const runSideEffect = () => {
      handleTriggerVisible()
      window.sprChat && window.sprChat('onEvent', handleEvent)
    }

    clientExists && runSideEffect()
  }, [ clientExists, handleEvent, handleTriggerVisible ])
}

export default useLiveChatTracking
