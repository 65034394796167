import { ResponsiveContainer } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { ResponsiveContainerFragment } from '../../../graphql'
import { ContentfulComponent } from '../../componentMappings'
import { renderComponentFromData } from '../../util/render'

export type ResponsiveContainerProps = {
  readonly data: ResponsiveContainerFragment
}

const ResponsiveContainerComponent: React.FC<ResponsiveContainerProps> = ({ data }: ResponsiveContainerProps) => {
  const content = propOr<readonly ContentfulComponent[], readonly ContentfulComponent[]>([], 'showContent', data)

  return (
    <ResponsiveContainer
      desktop={propOr<boolean, boolean>(false, 'showOnDesktop', data)}
      mobile={propOr<boolean, boolean>(false, 'showOnMobile', data)}
      tablet={propOr<boolean, boolean>(false, 'showOnTablet', data)}
    >
      <>{content && content.map((data) => renderComponentFromData(data))}</>
    </ResponsiveContainer>
  )
}

export default ResponsiveContainerComponent

export const ResponsiveContainerQuery = graphql`
  #graphql
  fragment responsiveContainer on ContentfulResponsiveContainer {
    id
    entryTitle
    showContent: content {
      ... on ContentfulImage {
        ...contentfulImage
      }
      ... on ContentfulGetaQuoteForm {
        ...getAQuoteForm
      }
      ... on ContentfulGroupSection {
        ...nonCyclicalGroupSectionFragment
      }
    }
    showOnMobile: mobile
    showOnTablet: tablet
    showOnDesktop: desktop
    internal {
      type
    }
  }
`
