import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import path from '@simplisafe/ewok/ramda/path'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { selectPromoDiscountText, selectPromoWithMonitoringDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { selectDisplayMonitoringDiscount, selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { Divider } from '@simplisafe/ss-react-components'
import { HeroBannerContainer } from '@simplisafe/ss-react-components/banners'
import { HeightTypes } from '@simplisafe/ss-react-components/Row'
import { Maybe } from 'monet'
import { applySpec } from 'ramda'
import always from 'ramda/src/always'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
// eslint-disable-next-line no-restricted-imports
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import React, {
  FC, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'

import { ContentfulHeroBanner, ContentfulHeroBannerColumn } from '../../../graphql'
import { SiteColor, toSiteColor } from '../../attributeMappings'
import getJson from '../../util/getJson'
import { getBadgeDiscount } from '../BadgeText'
import ContentfulRichText, { Options } from '../ContentfulRichText'
import { spanWrap } from '../ContentfulRichText/common'
import { renderEmbeddedEntry } from '../ContentfulRichText/embeddedEntries'
import HeroColumn from './HeroColumn'

export type HeroBannerProps = {
  readonly data: ContentfulHeroBanner
}

const toFloatingBadge = (data: ContentfulHeroBanner, placeholders: Record<string, string | null>) => {
  const promoBadge = prop('promoBadge', data)
  const floatingBadgeRawText = getJson(path([ 'promoBadge', 'text' ], data))
  const options: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => renderEmbeddedEntry(node, placeholders),
      [BLOCKS.HR]: () => <Divider color="neutralWhite" />,
      [BLOCKS.HEADING_3]:
        (__: unknown, text) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const header3 = map(spanWrap, text as string)

          return (
            <h3 className={'h3'}>{header3}</h3>
          )
        },
    },
  }

  return !isEmpty(placeholders) && isNotNil(promoBadge) ? applySpec({
    backgroundColor: always('complementaryBlue100'),
    borderColor: always('complementaryBlue100'),
    floatingBadgeContent: () => <ContentfulRichText optionsCustom={options} rawRichText={floatingBadgeRawText} />,
    linkUrl: prop('promoBadgeUrl'),
    textColor: () => toSiteColor('neutralWhite'),
  })(data) : null
}

const HeroBannerComponent: FC<HeroBannerProps> =
  ({ data }: HeroBannerProps) => {
    const columns = propOr<readonly ContentfulHeroBannerColumn[], readonly ContentfulHeroBannerColumn[]>([], 'bannerColumns', data)
    const backgroundColors = columns.map(col => prop<SiteColor>('backgroundColor', col)).filter(c => !!c)
    const isFullWidth = prop('isFullWidth', data)
    const isPromoTopBanner = useSelector(selectTopBannerVisible)

    const [ placeholders, setPlaceholders ] = useState<Record<string, string | null>>({})
    const displayMonitoringDiscount = useSelector(selectDisplayMonitoringDiscount)
    const discountTextWithMonitoring: Maybe<string> = useSelector(selectPromoWithMonitoringDiscountText)
    const discountTextWithoutMonitoring: Maybe<string> = useSelector(selectPromoDiscountText)
    const discountText = displayMonitoringDiscount ? discountTextWithMonitoring : discountTextWithoutMonitoring

    useEffect(() => {
      discountText.cata(
        () => setPlaceholders({}),
        text => setPlaceholders(getBadgeDiscount(text)),
      )
    }, [ discountText ])

    const floatingPromo = !isPromoTopBanner ? toFloatingBadge(data, placeholders) : null

    return (
      columns.length ? (
        <HeroBannerContainer
          backgroundColors={backgroundColors}
          // @ts-expect-error TS(2322) FIXME: Type 'FloatingBadgeProps | null' is not assignable... Remove this comment to see the full error message
          floatingPromoBadgeProps={floatingPromo}
          heightType={prop<HeightTypes>('heightType', data)}
          // @ts-expect-error TS(2322) FIXME: Type 'Maybe<boolean> | undefined' is not assignabl... Remove this comment to see the full error message
          isFullWidth={isFullWidth}>
          {columns?.map((col, index) => (
            <HeroColumn
              colsLength={columns.length}
              column={col}
              key={index} />
          ))}
        </HeroBannerContainer>
      ) : null
    )
  }

export default HeroBannerComponent
