import { Span, Spans } from '@simplisafe/ss-react-components/Column'
// eslint-disable-next-line no-restricted-imports
import prop from 'ramda/src/prop'

import { ContentfulHeroBannerColumn } from '../../../graphql'

export const calculateSpans = (data: ContentfulHeroBannerColumn, colsLength: number): Spans => {
  const mobileColSpan = prop<Span>('mobileColumnSpan', data)
  const tabletColSpan = prop<Span>('tabletColumnSpan', data)
  const desktopColSpan = prop<Span>('desktopColumnSpan', data)
  const tabletUpSpanSize = colsLength > 1 ? 6 : 12

  return [ mobileColSpan || 12, tabletColSpan || 12, desktopColSpan || tabletUpSpanSize ]
}
