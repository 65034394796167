import { Text } from '@simplisafe/ss-react-components'
import { Column } from '@simplisafe/ss-react-components'
import classNames from 'classnames'
import BackgroundImage from 'gatsby-background-image'
import path from 'ramda/src/path'
// eslint-disable-next-line no-restricted-imports
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import { SiteColor } from '../../attributeMappings'
import ContentfulRichText from '../ContentfulRichText'
import ModalComponent from '../ModalComponent'
import { calculateSpans } from './helpers'
import { useHeroColumnImage } from './hooks'
import PromoTag from './PromoTag'
import { HeroColumnObject } from './types'

const colorTranslations: Record<string, string> = {
  'brandPrimary': 'var(--primary-100)',
  'complementaryBlue100': 'var(--complementary-blue-100)',
  'neutralBlack': 'var(--neutral-black)',
  'neutralLight100': 'var(--neutral-light-100)',
  'neutralWhite': 'var(--white)'
}

const HeroColumn: FC<HeroColumnObject> = ({
  column,
  colsLength
}) => {
  const description = <ContentfulRichText rawRichText={path([ 'description', 'json' ], column)} />
  const quoteWizardModal = prop('quoteWizardModal', column)
  const descriptionMobile = <ContentfulRichText rawRichText={path([ 'descriptionMobile', 'json' ], column)} />
  const additionalLinkText = path<string>([ 'additionalLink', 'linkText' ], column)
  const additionalLinkUrl = path<string>([ 'additionalLink', 'linkUrl' ], column)
  const verticalAlign = prop('contentVerticalAlignment', column)
  const textAlignment = prop<'center' | 'left' | 'right'>('textAlignment', column)

  const sidePadding = propOr<string, string>('', 'sidePadding', column)
  const verticalPadding = propOr<string, string>('', 'verticalPadding', column)
  const linkColor = prop<string>('linkColor', column)
  const textColor = prop<string>('textColor', column)
  const backgroundColor = prop<SiteColor>('backgroundColor', column)

  const { images } = useHeroColumnImage(column)

  const promoTagProps = prop('promotionalTag', column)

  const containerClassNames = classNames('flex items-center w-full h-full bg-center bg-no-repeat bg-cover', {
    'p-8 md:p-16 lg:p-16': !verticalPadding && !sidePadding,
    'px-4': sidePadding === 'small',
    'px-4 md:px-8': sidePadding === 'medium',
    'px-4 md:px-8 lg:px-16': sidePadding === 'large',
    'px-8 md:px-16 lg:px-32': sidePadding === 'extraLarge',
    'py-4': verticalPadding === 'small',
    'py-4 md:py-8': verticalPadding === 'medium',
    'py-4 md:py-8 lg:py-16': verticalPadding === 'large',
    'py-8 md:py-16 lg:py-32': verticalPadding === 'extraLarge'
  })

  const linkColorReplacement = linkColor && colorTranslations[linkColor] ? { '--prose-links-color': `${colorTranslations[linkColor]}`, } : {}

  const textColorReplacement = textColor && colorTranslations[textColor] ? {
    '--prose-body-color': `${colorTranslations[textColor]}`,
    '--prose-headings-color': `${colorTranslations[textColor]}`,
    color: `${colorTranslations[textColor]}`
  } : {}

  return (
    <Column
      className='!rc-overflow-y-hidden rc-h-full'
      rounded='none'
      spans={calculateSpans(column, colsLength)}>
      <BackgroundImage
        className={containerClassNames}
        fluid={images}
        style={{
          ...textColorReplacement,
          ...linkColorReplacement,
          backgroundColor: backgroundColor ? colorTranslations[backgroundColor] : 'inherit'
        }}>
        <Text
          className={classNames('w-full', {
            'self-baseline':  verticalAlign === 'baseline',
            'self-center':  verticalAlign === 'center',
            'self-end': verticalAlign === 'end',
            'self-start': verticalAlign === 'start',
            'self-stretch': verticalAlign === 'stretch'
          })}
          textAlignment={textAlignment}
          useTailwind>
          {
            promoTagProps && <PromoTag data={promoTagProps} />
          }
          <div className='md:hidden'>{descriptionMobile}</div>
          <div className='hidden md:!block'>{description}</div>
          {/* Both following elements will be removed when we be able to embed them inside of the text box */}
          {quoteWizardModal && <ModalComponent data={quoteWizardModal} style={{ content: { padding: '32px' } }}/>}
          {additionalLinkUrl && <div style={{ marginTop: '16px' }}><a href={additionalLinkUrl}>{additionalLinkText}</a></div>}
        </Text>
      </BackgroundImage>
    </Column>
  )
}

export default HeroColumn
