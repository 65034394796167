import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { TestimonialCard } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { TestimonialCardFragment } from '../../../graphql'

export type TestimonialCardProps = {
  readonly data: TestimonialCardFragment
}

const TestimonialCardComponent: FC<TestimonialCardProps> = ({ data }: TestimonialCardProps) => {
  const quote = prop('quote', data)
  const company = prop('company', data)
  const subtext = safeProp('subtext', data).orUndefined()
  const noBackground = prop('noBackground', data)

  return quote && company ? (
    <TestimonialCard company={company}
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      noBackground={noBackground}
      quote={quote}
      subtext={subtext} />
  ) : null
}

export default TestimonialCardComponent

export const TestimonialCardQuery = graphql`#graphql
  fragment testimonialCard on ContentfulTestimonialCard {
    internal {
      type
    }
    noBackground
    company
    quote
    subtext
  }
`
