import { PhoneCTA } from '@simplisafe/ss-react-components'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { PhoneCtaFragment } from '../../../graphql'

export type PhoneCTAComponentProps = {
  readonly data: PhoneCtaFragment
}

const PhoneCTAComponent: FC<PhoneCTAComponentProps> = ({ data }: PhoneCTAComponentProps) => {

  const {
    id,
    phone,
    text,
  } = data

  return phone && text ? (
    <PhoneCTA
      key={id}
      phone={phone}
      text={text}
    />
  ) : null
}

export default PhoneCTAComponent

export const PhoneCTAQuery = graphql`#graphql
  fragment phoneCTA on ContentfulPhoneCta {
    id
    internal {
      type
    }
    phone
    text
  }
`
