import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { BlogArticlePreview } from '@simplisafe/ss-react-components'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import concat from 'ramda/src/concat'
import React, { FC } from 'react'

import { ContentfulBlogArticleList } from '../../../graphql'
import getJson from '../../util/getJson'
import ContentfulRichText from '../ContentfulRichText'

type BlogArticleListComponentProps = {
  readonly data: Partial<ContentfulBlogArticleList>
}

const prependWithSlash = (str: string) => str.startsWith('/') ? str : concat('/', str)

const BlogArticleListComponent: FC<BlogArticleListComponentProps> = ({ data }: BlogArticleListComponentProps) => {

  const linkText = prop('blogPreviewLinkText', data)

  return (
    <>
      {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        prop('blogPages', data).map(blogPage => {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          const url = prependWithSlash(prop('pageUrl', blogPage))

          const overviewUrl = (
            <Link to={url}>
              {linkText}
            </Link>
          )

          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          return prop('pageComponents', blogPage)
            .filter(pageComponent => path([ 'internal', 'type' ], pageComponent) === 'ContentfulBlogArticle')
            .map(blogArticle => {
              const title = (
                <Link to={url}>
                  {/* title does not exist on the blogArticle type */}
                  {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
                  {prop('title', blogArticle)}
                </Link>
              )

              // TODO previewDescription does not exist
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              const json = getJson(prop('previewDescription', blogArticle))
              const previewDescription = <ContentfulRichText rawRichText={json} />

              const previewImage = (
                <Link to={url}>
                  <Img
                    // TODO previewImage does not exist
                    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    alt={path([ 'previewImage', 'title' ], blogArticle)}
                    className='w-full rounded-base'
                    // TODO previewImage does not exist
                    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    fluid={path([ 'previewImage', 'fluid' ], blogArticle)}
                  />
                </Link>
              )

              // TODO lastPostedText does not exist
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.

              const lastPostedText = <ContentfulRichText rawRichText={getJson(prop('lastPostedText', blogArticle))} />

              return (
                <BlogArticlePreview
                  key={prop('id', blogArticle)}
                  lastPostedText={lastPostedText}
                  overviewUrl={overviewUrl}
                  previewDescription={previewDescription}
                  previewImage={previewImage}
                  title={title}
                />
              )
            })
        })
      }
    </>
  )
}

export default BlogArticleListComponent

export const query = graphql`#graphql
  fragment contentfulBlogArticleList on ContentfulBlogArticleList {
      blogPages {
        pageComponents {
          ... on ContentfulBlogArticle {
            id
            internal {
              type
            }
            lastPostedText {
                json
            }
            previewImage {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              id
              title
            }
            previewDescription {
                json
            }   
            title    
          }
        }
        pageUrl
      }
      blogPreviewLinkText
      id
      internal {
        type
      }
    }
`
