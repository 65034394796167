import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { Carousel, OverlayBanner } from '@simplisafe/ss-react-components'
import { CarouselProps } from '@simplisafe/ss-react-components/Carousel'
import { HeroSlideProps, PreloadLink } from '@simplisafe/ss-react-components/HeroSlide'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { OverlayBannerProps } from '@simplisafe/ss-react-components/OverlayBanner'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import multiply from 'ramda/src/multiply'
import pipe from 'ramda/src/pipe'
import when from 'ramda/src/when'
import React, { FC } from 'react'

import { ContentfulAsset, OverlayBannerFragment } from '../../../graphql'
import getDescriptionJson from '../../util/getDescriptionJson'

const referAFriendBannerType = 'overlay'

type OverlayBannerComponentProps = {
  readonly id: string
  readonly data: OverlayBannerFragment
}

const getImage = (banner: OverlayBannerFragment, media: string): Maybe<ContentfulAsset> => safePath([ media, '0' ], banner)
  .filter(img => !!prop('fluid', img))

const getBackgroundImage = (banner: OverlayBannerFragment, media: string) => getImage(banner, media)
  .map(() => BackgroundImage)
  .orUndefined()

const getBackgroundImageProps = (banner: OverlayBannerFragment, media: string) => getImage(banner, media)
  .map(img => ({
    alt: prop('description', img),
    fluid: prop('fluid', img),
    title: prop('title', img)
  }))
  .orUndefined()

// @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
const toOverlayBanner = (x) => applySpec<OverlayBannerProps>({
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  BackgroundComponent: () => getBackgroundImage(x, 'mediaOverlay'),
  additionalContent: path([ 'additionalContent', '0', 'description', 'json' ]),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  backgroundComponentProps: () => getBackgroundImageProps(x, 'mediaOverlay'),
  descriptionContent: getDescriptionJson,
  title: path([ 'title' ]),
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
})(x)

const toAdditionalContent = applySpec({ additionalDescription: getDescriptionJson })

// @ts-expect-error TS(7006) FIXME: Parameter 'isMobile' implicitly has an 'any' type.
const toCarouselData = (data: OverlayBannerFragment, isMobile, isDesktop): CarouselProps => {
  const getTitle = prop('title', data)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'OverlayBannerFragment' is not as... Remove this comment to see the full error message
  const getDescription = getDescriptionJson(data)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  const bgImage = ifElse(equals(true), always('mediaOverlayMobile'), always(ifElse(equals(true), always('mediaOverlay'), always('mediaOverlayTablet'))(isDesktop)))(isMobile)
  const toHeroSlide = applySpec<HeroSlideProps>({
    BackgroundComponent: () => getBackgroundImage(data, bgImage),
    additionalContent: toAdditionalContent,
    backgroundComponentProps: () => getBackgroundImageProps(data, bgImage),
    content: {
      description: always(getDescription),
      title: always(getTitle)
    },
    paginationPosition: always('vertical'),
    preload: () => None<PreloadLink>(),
    textColor: always('dark')
  })

  const toCarousel = applySpec<CarouselProps>({
    carouselContainerData: {
      autoPlaySpeed: pipe(prop('autoPlaySpeed'), when(isNil, always(4)), multiply(1000)),
      paginationPosition: pipe(prop('additionalContentPosition'), equals('Right-Center'), always('vertical')),
      playButton: F
    },
    slideData: pipe(prop('additionalContent'), map(toHeroSlide)),
  })

  return toCarousel(data)
}
const OverlayBannerComponent: FC<OverlayBannerComponentProps> =
  ({ data }: OverlayBannerComponentProps) => {
    const bannerType = safeProp('bannerType', data).getOrElse('')
      .toLocaleLowerCase()
    const isMobile = !useMediaQuery('TabletAndUp')
    const isDesktop = useMediaQuery('DesktopAndUp')

    return bannerType === referAFriendBannerType ?
      (
        <OverlayBanner
          {...toOverlayBanner(data)}
        />
      ) : (
        // TODO why is an overlay banner sometimes a carousel instead of those components just being carousels in contentful?
        <Carousel
          {...toCarouselData(data, isMobile, isDesktop)}
        />
      )
  }

export const query = graphql`#graphql
fragment OverlayBanner on ContentfulAdditionalInfoBanner{
  id
  internal {
    type
  }
  bannerType
  autoPlaySpeed
  additionalContentPosition
  additionalContent {
    ... on ContentfulSmallTextSection {
      id
      description {
        json
      }
    }
  }
  description {
    json
  }
  displayPagination
  title
  textPosition
  mediaOverlay: media {
    description
    id
    fluid(maxWidth: 1440) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    title
  }
  mediaOverlayTablet: mediaTablet {
    description
    id
    fluid(maxWidth: 1200) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    title
  }
  mediaOverlayMobile: mediaMobile {
    description
    id
    fluid(maxWidth: 768) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
    title
  }
}`

export default OverlayBannerComponent
