import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import defaultWhen from '@simplisafe/ewok/ramda-adjunct/defaultWhen'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import __ from 'ramda/src/__'
import complement from 'ramda/src/complement'
import contains from 'ramda/src/contains'
import isNil from 'ramda/src/isNil'
import React, { FC, ReactNode } from 'react'

import { ImageWithFocalPointFragment } from '../../../graphql'
import { setFocus, transformFluid } from './focalPoint'

export type ImageWithFocalPointProps = {
  /** Renders a background image if children are passed */
  readonly children?: ReactNode
  /** If rendering a background image for a Row, it needs to accept a class name for styling */
  readonly className?: string
  readonly data: ImageWithFocalPointFragment
  readonly roundedCorners?: boolean
}

const ImageWithFocalPoint: FC<ImageWithFocalPointProps> = ({
  children, className, data, roundedCorners = true
}: ImageWithFocalPointProps) => {
  const title = path([ 'desktopImage', 'title' ], data)
  const description = path([ 'desktopImage', 'description' ], data)
  const styles = roundedCorners ? { borderRadius: '10px' } : {}

  const fillDimension = defaultWhen<string>(
    // TODO have this default to an empty string
    complement(contains(__, [ 'height', 'width' ])),
    'height',
    prop('fillDimension', data)
  )

  const fluidMobile = path([ 'mobileImage', 'fluid' ], data)
  const fluidDesktop = path([ 'desktopImage', 'fluid' ], data)
  const height = path([ 'desktopImage', 'file', 'details', 'image', 'height' ], data)
  const mobileImageHeight = path([ 'mobileImageHeight' ], data)
  const width = path([ 'desktopImage', 'file', 'details', 'image', 'height' ], data)
  const xFocus = setFocus(path([ 'focalPoint', 'focalPoint', 'x' ], data), width)
  const yFocus = setFocus(path([ 'focalPoint', 'focalPoint', 'y' ], data), height)

  const sources = !isNil(fluidMobile) && !isNil(fluidDesktop) ? [
    // @ts-expect-error TS(2345) FIXME: Argument of type 'GatsbyContentfulFluid_WithWebp_N... Remove this comment to see the full error message
    transformFluid(fluidMobile, xFocus, yFocus),
    {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'GatsbyContentfulFluid_WithWebp_N... Remove this comment to see the full error message
      ...transformFluid(fluidDesktop, xFocus, yFocus),
      media: '(min-width: 768px)'
    }
  ] : undefined

  const isMobile = !useMediaQuery('TabletAndUp')
  const getHeight = isMobile && mobileImageHeight ? { minHeight: mobileImageHeight + 'px' } : { minHeight: '100%' }

  return children ? (
    <BackgroundImage
      alt={description}
      className={className}
      fluid={sources}
      style={styles}
      title={title}
    >
      {children}
    </BackgroundImage>
  ) : sources ? (
    <Img
      alt={description}
      fluid={sources}
      style={{
        [fillDimension]: '100%',
        ...getHeight,
        ...styles
        // TODO use objectPosition: [right, left, center] to allow positioning of image for mobile
      }}
      title={title}
    />
  ) : null
}

export default ImageWithFocalPoint

export const imageWithFocalPointFragment = graphql`#graphql
  fragment imageWithFocalPoint on ContentfulImageWithFocalPoint {
    id
    internal {
      type
    }
    focalPoint {
      focalPoint {
        x
        y
      }
    }
    mobileImageHeight
    fillDimension
    mobileImage: image {
      id
      title
      description
      fluid(cropFocus: CENTER, resizingBehavior: CROP, maxWidth: 768) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopImage: image {
      id
      title
      description
      file {
        details {
          image {
             height
            width
          }
        }
      }
      fluid(cropFocus: CENTER, resizingBehavior: CROP, maxWidth: 1440) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
