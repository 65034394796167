/* eslint-disable max-lines -- legacy code */
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import isLeftValue from '@simplisafe/ewok/monet-utils/isLeftValue'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  safeFind, safePath, safeProp
} from '@simplisafe/monda'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import { setMiniCartLineItem } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { selectMiniCartLineItems, selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  CartUpdatedMessage, Modal, OfferTag
} from '@simplisafe/ss-react-components'
import { CardItem } from '@simplisafe/ss-react-components'
import { CartItemDialog } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import pathOr from 'ramda/src/pathOr'
import split from 'ramda/src/split'
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { pipe } from 'ts-functional-pipe'

import {
  ContentfulAccessoriesCardVariation, ContentfulAsset, ContentfulProductCardAccessories
} from '../../../graphql'
import { renderOutOfStockMessage } from '../../commercetools/outOfStock'
import AddToCartError, { AddToCartErrorType } from '../../errorComponents/AddToCartError'
import { usePriceContext } from '../../providers/PriceProvider'
import { pricePerUnit } from '../../providers/PriceProvider/formatter'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getDescriptionJson from '../../util/getDescriptionJson'
import {
  leadingSlashIt, nullToUndefined, toFirstCharLower
} from '../../util/helper'
import { verifyButtonColor } from '../../util/verifyButtonColor'
import FluidImg from '../FluidImg'

type AccessoriesCardProps = {
    readonly children?: ReactNode
    readonly data: Partial<ContentfulProductCardAccessories>
}

type VariationState = {
  readonly [x: string]: {
    readonly image?: ContentfulAsset
    readonly productId?: string | null
  }
}

const getTitle = (data: Partial<ContentfulProductCardAccessories>) => {
  const url: string = path([ 'pageLink', 'pageUrl' ], data) || ''
  const title: string | undefined = path([ 'productName', 'linkText' ], data)
  return !isEmpty(url) ?
    <Link to={leadingSlashIt(url)}>
      {title}
    </Link> :
    title
}

// TODO: create component to take a ContentfulAsset and render a Gatsby Image
export const toFormImg = (image?: ContentfulAsset) => image
  ? (<Img
    alt={prop('title', image)}
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    fluid={prop('fluid', image)}
    key={prop('id', image)}
    style={{ height: '100%' }}
  />)
  : null

const getGatsbyImage = (data: Partial<ContentfulProductCardAccessories>): ReactNode => {
  const imageTitle = path([ 'image', 0, 'title' ], data)
  const imageData = path([ 'image', 0, 'fluid' ], data)
  return <FluidImg
    alt={imageTitle}
    // @ts-expect-error TS(2322) FIXME: Type 'ContentfulFluid | undefined' is not assignab... Remove this comment to see the full error message
    fluid={imageData}
    style= {{
      height: '100%',
      width: '100%'
    }}
  />
}

const AccessoriesCardComponent: FC<AccessoriesCardProps> = ({ data }: AccessoriesCardProps) => {
  const dispatch = useDispatch()

  const [ isAddToCardModalVisible, setAddToCartModalVisibility ] = useState(false)
  const [ showSpinner, setShowSpinner ] = useState(true)

  const defaultQuantity = safeProp('defaultQuantity', data).orJust(0)
  const maxQuantity = safeProp('maximumQuantity', data).orUndefined()
  const [ selectedQuantity, setSelectedQuantity ] = useState(1)

  const [ addToCartError, setAddToCartError ] = useState<AddToCartErrorType>(null)
  const { Track, trackEvent } = useTracking()
  const [ isCartUpdated, setCartUpdated ] = useState(false)

  // TODO: this eslint warning will go away once we upgrade to TS 4 and the Monda 3
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const cartUpdatedText: Maybe<string> = safePath([ 'cartUpdatedText', 'text', 'text' ], data)
  const productLimitMessage: string | undefined = safeProp('productAvailability', data).orUndefined()

  const defaultColor: string = safeProp('defaultColor', data).getOrElse('')

  const defaultVariation: VariationState = useMemo(() => ({
    [defaultColor]: {
      image: path([ 'image', 0 ], data),
      productId: prop('productId', data)
    }
  }), [ data, defaultColor ])

  const [ variations, setVariations ] = useState<VariationState>(defaultVariation)
  const [ selectedColor, setSelectedColor ] = useState(defaultColor)
  const selectedVariation = variations[selectedColor]

  const skuID = safeProp('productId', selectedVariation)

  const product = useSelector(selectProduct(selectedVariation.productId || ''))

  // TODO description doesn't always exist on this, it will need some type of type guard
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulGroupSectionContentful... Remove this comment to see the full error message
  const addToCartModalContent = getDescriptionJson(path([ 'popupButton', 'popupContent' ], data))

  // Some accessory cards add to the BMS mini cart instead of directly to cart
  const shouldAddToMiniCart = Maybe.fromFalsy(prop('addToMiniCart', data))
  const miniCartLineItems = useSelector(selectMiniCartLineItems)
  // The quantity of the product in the minicart, or 0 if the sku isn't in the minicart
  const miniCartQuantity = product.toMaybe()
    .chain(_product => safeFind(lineItem => lineItem.sku === _product.sku, miniCartLineItems))
    .chain(safeProp('quantity'))
    .orJust(0)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  useMemo(() => {
    const newVariations = safeProp('variations', data).orJust([])
      .reduce((obj: VariationState, variation: ContentfulAccessoriesCardVariation | null | undefined) => (variation && variation.color ? {
        ...obj,
        [variation.color]: {
          image: nullToUndefined(prop('image', variation)),
          productId: prop('productId', variation)
        }
      } : obj), defaultVariation)
    setVariations(newVariations)
  }, [ data, defaultVariation ])

  const addToCartButtonClick = useCallback((quantity: number) => {
    setSelectedQuantity(quantity)
    setAddToCartError(null)

    const handleSuccess = () => {
      setShowSpinner(false)
      doesDocumentHaveContent(addToCartModalContent) && setAddToCartModalVisibility(true)
      setCartUpdated(true)
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(product, trackEvent, quantity)
    }
    const handleFailure = () => {
      setShowSpinner(false)
      setAddToCartError('recoverable')
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const message = skuID.cata(
      () => 'received null/empty sku',
      sku => `no product with sku ${sku} found in redux`
    )

    product.cata(
      () => {
        setAddToCartError('unrecoverable')
        setShowSpinner(false)
        logError(Error(`Cannot add to cart: ${message}`))
      },
      _product => {
        dispatch(IOAddToCart({
          products: [ {
            quantity,
            sku: _product.masterSku
          } ]
        }, handleFailure, handleSuccess))
      }
    )
  }, [ dispatch, product, skuID, trackEvent, addToCartModalContent, optimizelyTrackSiteEvents ])

  const onMiniCartQuantityChange = useCallback((quantity: number) => {
    product.cata(
      () => { setAddToCartError('unrecoverable') },
      _product => {
        setAddToCartError(null)
        setCartUpdated(true)

        dispatch(setMiniCartLineItem({
          ..._product,
          maxQuantity,
          quantity
        }))
      }
    )
  }, [ dispatch, maxQuantity, product ])

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdated(false), 3000)
    return () => clearTimeout(timer)
  }, [ isCartUpdated ])

  const cartItemDialogProps = {
    doContinueShopping: () => setAddToCartModalVisibility(false),
    productDescription: addToCartModalContent,
    productImage: getGatsbyImage(data),
    quantity: selectedQuantity,
    title: pathOr<string, string>('', [ 'productName', 'linkText' ], data)
  }

  const { getFormattedPrice } = usePriceContext()
  const price = getFormattedPrice(skuID.orJust(''))(pricePerUnit)

  const initialButtonProps = {
    children: path([ 'popupButton', 'buttonText' ], data),
    // TODO: replace this pipe with ts-functional-pipe
    color: pipe(path([ 'popupButton', 'buttonType' ]), defaultTo(''), toFirstCharLower, split(' '), join(''), verifyButtonColor)(data),
    showSpinner: showSpinner
  }

  const outOfStockButtonText = prop('outOfStockButtonText', data)
  const [ isSellable, setIsSellable ] = useState(true)
  const [ isOnStock, setIsOnStock ] = useState(true)

  useEffect(() => {
    product.cata(
      () => {
        setIsSellable(true)
        setIsOnStock(true)
      },
      p => {
        prop('isSellable', p) ? setIsSellable(true) : setIsSellable(false)
        prop('isOnStock', p) ? setIsOnStock(true) : setIsOnStock(false)
      }
    )
  }, [ product ])

  const outOfStockButtonProps = {
    ...initialButtonProps,
    children: outOfStockButtonText ? outOfStockButtonText : prop('children', initialButtonProps),
    disabled: true,
  }

  return (
    <Track>
      <CardItem
        buttonProps={!isSellable || !isOnStock ? outOfStockButtonProps : initialButtonProps}
        cartUpdatedText={cartUpdatedText.map(text => <CartUpdatedMessage
          isVisible={isCartUpdated}
          key={text}
          message={text}/>)
          .orUndefined()}
        defaultQuantity={shouldAddToMiniCart.cata(always(defaultQuantity), always(miniCartQuantity))}
        description={pathOr('', [ 'productDescription', 'productDescription' ], data)}
        errorMessage={addToCartError && <AddToCartError errorType={addToCartError}
          textAlign='center' />}
        imageTag={
          safeProp('productTag', data)
            .map(tagData =>
              <OfferTag
                className={''}
                key={prop('id', tagData)}
                tagBackgroundColor={safeProp('tagBackgroundColor', tagData).orUndefined()}
                taggingText={pathOr<string, string>('', [ 'taggingText', 'json' ], tagData)}
                theme={'autoWidth'} />)
            .orUndefined()
        }
        img={selectedVariation && selectedVariation.image ? toFormImg(selectedVariation.image) : null}
        isCartable={isOnStock && isSellable ? true : false}
        key={prop('id', data)}
        maxQuantity={maxQuantity}
        minQuantity={shouldAddToMiniCart.cata(always(1), always(0))}
        onButtonClick={addToCartButtonClick}
        onQuantityChange={shouldAddToMiniCart.map(always(onMiniCartQuantityChange)).orUndefined()}
        onVariationClick={(color: string) => setSelectedColor(color)}
        outOfStockMessage={isSellable && renderOutOfStockMessage({ product: product })}
        price={price}
        productLimitMessage={productLimitMessage}
        selectedVariation={selectedColor}
        showButton={isLeftValue(shouldAddToMiniCart)}
        title={getTitle(data)}
        variations={Object.keys(variations)}
      />
      <Modal
        appElementId='___gatsby'
        isOpen={isAddToCardModalVisible}
        key={`CartItemModal${prop('id', data)}`}
        onRequestClose={() => {
          setAddToCartModalVisibility(false)
          setShowSpinner(false)
        }}>
        <CartItemDialog {...cartItemDialogProps}
          price={price} />
      </Modal>
    </Track>
  )
}

export default AccessoriesCardComponent
