import prop from '@simplisafe/ewok/ramda/prop'
import escapeRegExp from '@simplisafe/ewok/ramda-adjunct/escapeRegExp'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import { safeProp } from '@simplisafe/monda'
import { LOCALE as locale, Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { Price } from '@simplisafe/ss-react-components'
import {
  Either, Just, Maybe, None
} from 'monet'
import ifElse from 'ramda/src/ifElse'
import multiply from 'ramda/src/multiply'
import replace from 'ramda/src/replace'
import rTest from 'ramda/src/test'
import React from 'react'
import { pipe } from 'ts-functional-pipe'

import { findFirstJust } from '../util/helper'
import { percentStringToNumber } from './utils'

export type PriceValue = {
  readonly centAmount: number
  readonly currencyCode: string
  readonly fractionDigits: number
}

export type CommerceToolsPrice = {
  readonly value: PriceValue
}

export const safeIsNotNan = (n: number) => isNaN(n) ? None<number>() : Just(n)

/***
 ** Returns the discounted price of a product if it has one, or the regular price otherwise.*
 **/

export const getProductFinalPrice = (product: Package | Product) =>
  safeProp('discountedPrice', product)
    .chain(v => Maybe.isInstance(v) ? v : None<number>())
    .orJust(prop('price', product))

/***
 ** Returns the discounted price with a service of a package if it has one, or the regular price otherwise.*
 **/

export const getProductFinalPriceWithService = (product: Package) =>
  safeProp('discountedPriceWithServicePlan', product)
    .chain(v => Maybe.isInstance(v) ? v : None<number>())
    .orJust(prop('price', product))

/**
 * Takes a number and formats it based on locale and additional options
 * @param addtlOptions see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters for available fields
 */
export const formatDisplayPrice = (x: number, addtlOptions?: Record<string, unknown>): Maybe<string> =>
  localizedDisplayPrice(locale, addtlOptions)(x)

// TODO This is something that should either be handled by Contentful or some reasonable i18n library.
// It was added here temporarily as a quick fix, but shouldn't be added to or modeled after.
const localePerUnitPriceTemplate: Partial<Record<Locale, string>> = { 'en-US': '{price} ea' }


/***
 ** Returns the default per-unit price template for the current locale.*
 ** TODO This is something that should either be handled by Contentful or some reasonable i18n library.*
 ** It was added here temporarily as a quick fix, but shouldn't be added to or modeled after.*
 **/

export const getPerUnitPriceTemplate = () => {
  const fallbackLocale: Locale = 'en-US'
  return prop(locale, localePerUnitPriceTemplate) || prop(fallbackLocale, localePerUnitPriceTemplate)
}


const renderPriceError = () => {
  // TODO display an error message to the user, and handle items that do not have a price without displaying an error
  return <></>
}

export const renderRawPrice =
    (price: Maybe<number>) =>
      price
        .chain(safeIsNotNan)
        .chain(Maybe.fromNull)
        .chain(formatDisplayPrice)
        .cata(
          () => null,
          val => <Price regularPrice={val} />
        )

export const renderPriceNumber =
  (price: Maybe<number>) =>
    price
      .chain(safeIsNotNan)
      .chain(Maybe.fromNull)
      .cata(
        () => 0,
        val => val,
      )

// Returns true if a string contains the phrase 'custom-home-security-system' (case-insensitive), so different pricing information can be displayed.
export const isBmsSku = rTest(/custom-home-security-system/i)

/** Takes a number and converts it into a localized percentage (some countries place the % sign on the left)
 * @example
 * formatPercentage(25) // => '25%'
*/
export const formatPercentage = (num: number) =>
  new Intl.NumberFormat(locale, { style: 'percent' })
    // the number has to be converted to a fraction, so 25 becomes 0.25
    // we also need to add a 0 before numebers less than 10, so 5 becomes 0.05
    .format(Number(`0.${('0' + num).slice(-2)}`))

export function formatDiscountText(product: Package | Product, displayOffText = false): string | undefined {
  const offText = displayOffText ? ' Off' : ''
  return product['@@type'] === 'package'
    ? findFirstJust([
      // Todo: Make this "{x} Off" text translatable via Contentful/Commerce Tools, etc.
      product.relativeDiscountWithServicePlan.map(val => `${formatPercentage(val)}${offText}`),
      product.absoluteDiscountWithServicePlan.chain(formatDisplayPrice).map(val => `${val}${offText}`),
      product.relativeDiscount.map(val => `${formatPercentage(val)}${offText}`),
      product.absoluteDiscount.chain(formatDisplayPrice).map(val => `${val}${offText}`)
    ]).getOrElse('')
    : ''
}

export function formatDiscountNoPlanText(product: Package | Product, displayOffText = false): string | undefined {
  const offText = displayOffText ? ' Off' : ''
  return product['@@type'] === 'package'
    ? findFirstJust([
      // Todo: Make this "{x} Off" text translatable via Contentful/Commerce Tools, etc.
      product.relativeDiscount.map(val => `${formatPercentage(val)}${offText}`),
      product.absoluteDiscount.chain(formatDisplayPrice).map(val => `${val}${offText}`),
      product.relativeDiscountWithServicePlan.map(val => `${formatPercentage(val)}${offText}`),
      product.absoluteDiscountWithServicePlan.chain(formatDisplayPrice).map(val => `${val}${offText}`)
    ]).getOrElse('')
    : ''
}

export function formatDiscountDifferenceText(product: Package | Product, displayYouSaveText = false): string | undefined {
  const youSaveText = displayYouSaveText ? 'You Save ' : ''
  const price: number = product.price
  const discountedPrice: Maybe<number> = product.discountedPrice.map(val => Math.round(price - val))
  const discountedPriceWithServicePlan: Maybe<number> = product.discountedPriceWithServicePlan.map(val => Math.round(price - val))
  return product['@@type'] === 'package'
    ? findFirstJust([
      discountedPrice
        .chain(formatDisplayPrice)
        .map(replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
        .map(val => `${youSaveText}${val}`),
      discountedPriceWithServicePlan
        .chain(formatDisplayPrice)
        .map(replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
        .map(val => `${youSaveText}${val}`)
    ]).getOrElse('')
    : ''
}

/**
 * Renders price details for a product or package, including strikethrough pricing and discount text.
 *
 * @param isServiceDiscount - Send as false if disount without service plan has to be picked up.
 */
export function renderPrice<T extends Package | Product>(product: Either<Error, T>, isServiceDiscount = true) {
  return product.cata(
    renderPriceError,
    value => {
      const sku: Maybe<string> =
        safeProp('sku', value)

      /** The regular price of an item with no discounts */
      const regularPrice = formatDisplayPrice(prop('price', value)).orJust('')

      /** The formatted string of the price after discounts */
      const formattedDiscountedPrice =
        safeProp('discountedPrice', value)
          .chain(price => Maybe.isInstance(price) ? price : None<number>())
          .chain(safeIsNotNan)
          .chain(formatDisplayPrice)
          .orUndefined()

      /** The formatted string of the price after service plan discounts */
      const formattedDiscountedPriceWithService = isServiceDiscount ?
        safeProp('discountedPriceWithServicePlan', value)
          .chain(price => Maybe.isInstance(price) ? price : None<number>())
          .chain(safeIsNotNan)
          .chain(formatDisplayPrice)
          .orUndefined() : undefined

      // Todo: Make this "{x} Off" text translatable via Contentful/Commerce Tools, etc.
      const discountedPriceText = formatDiscountText(value, true)

      const getPrice = ifElse(
        isBmsSku,
        () =>
          isNotEmpty(discountedPriceText)
            ? <Price
              discountedPrice={discountedPriceText}
              regularPrice={undefined} />
            : null,
        () => <Price
          discountedPrice={formattedDiscountedPriceWithService || formattedDiscountedPrice || undefined}
          regularPrice={regularPrice}/>
      )

      // TODO it's not clear what getPrice does when you pass it no data, but you have to do it
      return sku.cata(() => getPrice(''), getPrice)
    }
  )
}

export function toMaybeOrNone<T>(maybe: Maybe<T>) {
  return Maybe.isInstance(maybe)
    ? maybe
    : None<T>()
}

/**
 * Returns a Price component with the product pricing formatted using a template, e.g. '{price} per month'
 */
export function renderPriceWithTemplate<T extends Package | Product>({
  product, showDiscountedPrice = true, template = '', templateVar = '{price}', customFormatPrice
}: {
  readonly product: Either<Error, T>
  readonly showDiscountedPrice?: boolean
  readonly template?: string
  readonly templateVar?: string
  readonly customFormatPrice?: (price: number) => string
}) {
  return product.cata(
    renderPriceError,
    v => {
      const getTemplatedPrice = ifElse<number, string, string>(
        price => !!(price && template && templateVar && template.includes(templateVar)),
        price => template.replace(new RegExp(escapeRegExp(templateVar), 'g'), customFormatPrice ? customFormatPrice(price) : formatDisplayPrice(price).orJust('')),
        price => customFormatPrice ? customFormatPrice(price) :  formatDisplayPrice(price).orJust('') // if we're missing a template or if it's invalid, just return the price
      )

      const regularPrice = safeProp('price', v).chain(safeIsNotNan)
        .map(getTemplatedPrice)
        .orUndefined()

      const discountedPrice = safeProp('discountedPrice', v)
        .chain(toMaybeOrNone)
        .chain(safeIsNotNan)
        .map(getTemplatedPrice)
        .orUndefined()

      return (
        <Price
          discountedPrice={showDiscountedPrice ? discountedPrice : undefined}
          regularPrice={regularPrice} />)
    }
  )
}

export const renderTotalPrice =
    (product: Either<Error,
      Product>) =>
      (quantity: number) =>
        product.cata(
          renderPriceError,
          pipe(
            getProductFinalPrice,
            multiply(quantity),
            formatDisplayPrice,
            p => <Price regularPrice={p.orJust('')} />
          )
        )

/**  @deprecated */
export const calculatePercentPrice = (x: string, totalPriceVal: number) => totalPriceVal - percentStringToNumber(x) * totalPriceVal / 100
