import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import Img, { FixedObject, GatsbyImageFixedProps } from 'gatsby-image'
import all from 'ramda/src/all'
import React from 'react'

const isFixed = (fixed: FixedObject | Partial<FixedObject> | undefined): fixed is FixedObject => {
  return fixed && all(isNotNil, [ fixed.src, fixed.srcSet, fixed.width, fixed.height ]) || false
}

// eslint-disable-next-line @typescript-eslint/sort-type-union-intersection-members -- legacy code
type FixedImgProps = {
  readonly fixed?: Partial<FixedObject>
} & Omit<GatsbyImageFixedProps, 'fixed'>

/**
 * This component should be used instead of directly using Gatsby's Img component
 * for fixed images from Contentful.
 *
 * Contentful data can always be undefined, and this can make working with objects like FixedObject difficult.
 * This component makes it easy by checking if fixed is defined and that it has all the required keys before
 * trying to render Img.
 */
const FixedImg = ({ fixed, ...rest }: FixedImgProps) => {

  return isFixed(fixed)
    ? <Img fixed={fixed}
      {...rest}/>
    : null
}

export default FixedImg
