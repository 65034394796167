import { safeProp } from '@simplisafe/monda'
import { HeaderNavigation, HeaderNavigationDrawer } from '@simplisafe/ss-react-components'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import propOr from 'ramda/src/propOr'
import React, {
  FC, HTMLAttributes, ReactElement
} from 'react'
import { useTracking } from 'react-tracking'

import { setGtmCustomEvent } from '../../util/analytics'
import { HeaderDropdownItem } from './HeaderDropdown'
import { isLogo, logo } from './helpers'
import { useHeaderData } from './hooks'
import NavigationDrawerBottomItem from './NavigationDrawerBottomItem'
import { renderNavItem } from './NavItem'
import { ContentfulHeaderFragment, NavItem } from './query'

const MobileOrTabletHeader: FC<HTMLAttributes<HTMLDivElement> & { readonly data: ContentfulHeaderFragment }> = ({ data, className }) => {
  const {
    cartItemCount,
    mobileOrTabletRightSideContentFieldName,
  } = useHeaderData()
  const { trackEvent } = useTracking()

  const mobileLogo = safeProp(mobileOrTabletRightSideContentFieldName, data)
    .chain(_data => {
      const logo = _data.find(navItem => {
        const linkType = propOr<string, string>('', 'linkType', navItem)
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
        return isLogo(linkType as string)
      })
      // @ts-expect-error TS(2345) FIXME: Argument of type 'NavItem | undefined' is not assi... Remove this comment to see the full error message
      return Maybe.fromNull(logo).chain(_logo => Maybe.fromNull(renderNavItem(logo, true, cartItemCount, true, trackEvent)))
    })

  const drawerTopContent = safeProp('mobileDrawerTopContent', data)
    .map(map((item) => <HeaderDropdownItem isMobile={true} item={item}
      key={item.id} showImage={true}
      trackEvent={trackEvent} />))

  const drawerCenterContent =
    safeProp('mobileDrawerCenterContent', data).map(map<NavItem, ReactElement | null>(navItem => renderNavItem(navItem, false, cartItemCount, true, trackEvent)))

  const drawerBottomContent =
    safeProp('mobileDrawerBottomContent', data)
      .map(map<NavItem, ReactElement>(navItem => <NavigationDrawerBottomItem cartItemCount={cartItemCount} item={navItem}
        key={navItem.id} trackEvent={trackEvent} />))

  const headerContents = safeProp(mobileOrTabletRightSideContentFieldName, data)
    .map(x => x.reduce((acc: readonly ReactElement[], item, i) => {
      const showHamburgerMenu = drawerTopContent.isJust() || drawerCenterContent.isJust() || drawerBottomContent.isJust()

      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      const contentGroup = item?.['contentsToGroup']

      /* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.*/ /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- legacy code, legacy code */
      const navItems = !!contentGroup ? contentGroup.map(item => renderNavItem(item, true, cartItemCount, true, trackEvent)) : renderNavItem(item, true, cartItemCount, true, trackEvent)
      const linkType = propOr<string, string>('', 'linkType', item)

      const hamburgerMenu = showHamburgerMenu && i === 0
        ? <HeaderNavigationDrawer bottom={<>{drawerBottomContent.orNull()}</>}
          display={true}
          header={mobileLogo.orUndefined()}
          key={'mobile-navigation-drawer'}
          middle={<>{drawerCenterContent.orNull()}</>}
          onHamburgerClick={() => setGtmCustomEvent({
            event: 'buttonClick',
            eventAction: 'open',
            eventCategory: 'navigation',
            eventLabel: 'hamburger'
          })}
          top={<>{drawerTopContent.orNull()}</>} />
        : null

      return [
        ...acc,
        <HeaderNavigation key={`header-nagivation=${item.id}`}
          navigationType={isLogo(linkType) ? `${logo}-${i}` : linkType}>
          <>
            {hamburgerMenu}
            {navItems}
          </>
        </HeaderNavigation> ]
    }, []))
    .orNull()

  return <div className={className}>{headerContents}</div>
}

export { MobileOrTabletHeader }

