import prop from '@simplisafe/ewok/ramda/prop'
import {
  Column, Row, SSInput
} from '@simplisafe/ss-react-components'
import { PatentTable } from '@simplisafe/ss-react-components'
import { PatentTableCell, PatentTableRow } from '@simplisafe/ss-react-components/PatentTable'
import type { RichTextProps } from '@simplisafe/ss-react-components/RichText'
import { graphql } from 'gatsby'
import applySpec from 'ramda/src/applySpec'
import pathOr from 'ramda/src/pathOr'
// TODO replace with ts-functional-pipe
import React, { FC, useState } from 'react'

import {
  ContentfulLegal,
  ContentfulTableCell,
  ContentfulTableRow
} from '../../../graphql'
import useScrollToHash from '../../hooks/useScrollToHash'
import getDescriptionJson from '../../util/getDescriptionJson'
import { renderComponentFromData } from '../../util/render'
import { PageProps } from '../Page'
import RichText from '../RichText'
import SubmitIdeaForm from '../SubmitIdeaForm'

type ContentfulLegalContainerProps = {
  readonly id: string
  readonly data: Partial<ContentfulLegal>
  readonly pageContext?: PageProps['pageContext']
}

const toLegalContainerData = (data: Partial<ContentfulLegal>): RichTextProps => {
  const toData = applySpec<RichTextProps>({ json: getDescriptionJson })
  return toData(data)
}

const toFormContent = applySpec<PatentTableCell>({
  colspan: (x: ContentfulTableCell) => prop('colspan', x),
  content: (x: ContentfulTableCell) => renderComponentFromData(prop('content', x))
})

const toGetPatentTableProps = (tableData: readonly ContentfulTableRow[]): readonly PatentTableRow[] => {
  return tableData && tableData.map(data => {
    const { cell } = data
    return { cell: cell?.map(toFormContent) ?? [] }
  })
}

const LegalContainer: FC<ContentfulLegalContainerProps> =
  ({ data }: ContentfulLegalContainerProps) => {
    const props = toLegalContainerData(data)
    const patentTableProps = toGetPatentTableProps(pathOr<readonly ContentfulTableRow[], readonly ContentfulTableRow[]>([], [ 'table', 'row' ], data))
    const submissionCheckbox = prop('submissionCheckbox', data)
    const submissionForm = prop('submissionFormV2', data)
    const [ isShowForm, setShowForm ] = useState(false)

    useScrollToHash()

    return (
      <>
        <Row>
          <Column spans={[ 12, 12 ]}>
            <RichText {...props} />
          </Column>
        </Row>
        {patentTableProps && <PatentTable row={patentTableProps} />}
        {submissionCheckbox && <SSInput
          checked={isShowForm}
          defaultChecked={!!prop('defaultChecked', submissionCheckbox)}
          id={prop('id', submissionCheckbox)}
          label={<p>{prop('placeholderText', submissionCheckbox)}</p>}
          name={prop('propName', submissionCheckbox) || ''}
          onChange={() => {
            setShowForm(!isShowForm)
          }}
          // TODO fix type
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          type={prop('type', submissionCheckbox)}
        />}
        {submissionForm && isShowForm &&
          <SubmitIdeaForm data={submissionForm} />
        }
      </>
    )
  }

export default LegalContainer

export const query = graphql`#graphql
  fragment contentfulLegalFragment on ContentfulLegal {
    id
    internal {
      type
    }
    description {
      json
    }
    table {
      row {
        cell {
          colspan
          content {
            ... on ContentfulImage {
              ...contentfulImage
            }
            ... on ContentfulRichTextWithOptions {
              ...richTextWithOptions
            }
          }
        }
      }
    }
    submissionCheckbox{
      ... on ContentfulForms {
        answerOptions
        maximumCharacter
        propName
        title
        placeholderText
        placeholderTextMobile
        id
        fieldValidation {
          requirement
          errorMessage
        }
        type
        checked
        defaultChecked
        deviceToDisplay
      }
    }
    submissionFormV2 {
      ... on ContentfulSubmitIdeaForm {
        ...submitIdeaForm
      }
    }
  }
`
