import type { Document } from '@contentful/rich-text-types'
import { Column, RichText } from '@simplisafe/ss-react-components'
import type { Spans } from '@simplisafe/ss-react-components/Column'
import React, { FC } from 'react'

import { toListStyleValue, toPaddingValue } from '../../attributeMappings'

export type TextColumnProps = {
    readonly listStyle?: string
    readonly text?: Document
    readonly textAlignment?: 'center' | 'left' | 'right'
    readonly textColor?: string
    readonly id?: string
    readonly padding?: 'Large' | 'Medium' | 'Small'
    readonly spans: Spans
    readonly columnCount?: number
}

const TextColumn: FC<TextColumnProps> =
    ({
      listStyle,
      padding,
      text,
      textAlignment,
      textColor,
      id,
      spans,
      columnCount = 1
    }: TextColumnProps) => {
      return (
        <>
          <Column
            key={id}
            padding={toPaddingValue(padding)}
            rounded={'none'}
            spans={spans}>
            <RichText
              json={text}
              key={id}
              listStyle={toListStyleValue(listStyle)}
              style={{ columnCount:columnCount }}
              textAlignment={textAlignment}
              textColor={textColor}
            />
          </Column>
        </>
      )
    }


export default TextColumn
