import { FormField, SSButton } from '@simplisafe/ss-react-components'
import React from 'react'

type Props = {
  readonly submitButtonLabel: string
  readonly submitButtonDisabled: boolean
}

const Submission: React.FC<Props> = ({ submitButtonDisabled, submitButtonLabel }: Props) => {
  return (
    <div data-component={'Submission'}>
      <FormField hideLabel={true} label={submitButtonLabel}
        name="submit">
        <SSButton disabled={submitButtonDisabled} minWidth="full"
          type="submit">
          {submitButtonLabel}
        </SSButton>
      </FormField>
    </div>
  )
}

export default Submission
