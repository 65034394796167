import {
  FormField,
  FormSelect,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type StateProps = {
  readonly label: string
  readonly placeholder: string
  readonly options: readonly string[]
}

const State: React.FC<StateProps> = (props: StateProps) => {
  return (
    <div data-component={'State'}>
      <FormField hideLabel={true} name='state'>
        <Text fontWeight='medium'>{props.label}</Text>
        <FormSelect
          className={'!my-0'}
          fullWidth={true}
          name="state"
          options={props.options}
          placeholder={props.placeholder}
        />
      </FormField>
    </div>
  )
}

export default State
