import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { graphql } from 'gatsby'
import React, { FC } from 'react'

import {  RichTextWithOptionsFragment } from '../../../graphql'
import doesDocumentHaveContent from '../../util/doesDocumentHaveContent'
import getJson from '../../util/getJson'
import RichText, { RichTextProps } from '../RichText'

export type RichTextWithOptionsComponentProps = {
  readonly data: RichTextWithOptionsFragment
}

const textAlignMapper: {[key: string]: RichTextProps['textAlignment']} = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
}
// @ts-expect-error TS(7006) FIXME: Parameter 'contentfulTextAlignValue' implicitly ha... Remove this comment to see the full error message
const toTextAlignment = contentfulTextAlignValue =>
  contentfulTextAlignValue ? textAlignMapper[contentfulTextAlignValue] : undefined

const listStyleMapper: {[key: string]: RichTextProps['listStyle']} = {
  Checkmark: 'checkmark',
  Disc: 'disc',
  RedX: 'redx',
  Square: 'square',
}
// @ts-expect-error TS(7006) FIXME: Parameter 'contentfulListStyleValue' implicitly ha... Remove this comment to see the full error message
const toListStyle = contentfulListStyleValue =>
  contentfulListStyleValue && contentfulListStyleValue !== 'standard' ? listStyleMapper[contentfulListStyleValue] : undefined

const toMaxWidth = (contentfulMaxWidth: boolean | null | undefined): boolean => contentfulMaxWidth ? true : false

const textSizeMapper: {[key: string]: RichTextProps['textSize']} = {
  'md': 'md',
  'sm': 'sm',
  'xs': 'xs',
}

const toTextSize = (value?: string | null) => value ? textSizeMapper[value] : undefined

const paddingMapper: { [key: string]: RichTextProps['padding'] } = {
  'large': 'large',
  'medium': 'medium',
  'small': 'small'
}

const toPadding = (value?: string | null) => value ? paddingMapper[value] : undefined

const marginMapper: { [key: string]: RichTextProps['margin'] } = { 'auto': 'auto' }

const toMargin = (value?: string | null) => value ? marginMapper[value] : undefined

const RichTextWithOptionsComponent: FC<RichTextWithOptionsComponentProps> =
    ({ data }: RichTextWithOptionsComponentProps) => {
      const text = getJson(prop('richText', data))
      return doesDocumentHaveContent(text) ? (

        <RichText
          json={text}
          listStyle={toListStyle(prop('listStyle', data))}
          margin={toMargin(prop('margin', data))}
          maxWidth={toMaxWidth(prop('hasMaxWidth', data))}
          padding={toPadding(prop('padding', data))}
          textAlignment={toTextAlignment(prop('textAlignment', data))}
          textColor={path([ 'textColor', 'color' ], data)}
          textSize={toTextSize(prop('textSize', data))}
        />
      ) : null
    }

export default RichTextWithOptionsComponent

export const query = graphql`#graphql
  fragment richTextWithOptions on ContentfulRichTextWithOptions {
    id
    internal {
      type
    }
    listStyle
    hasMaxWidth
    containerMargin
    richText {
      json
    }
    textAlignment
    textColor {
      color
    }
    textSize
    padding
    margin
  }`
