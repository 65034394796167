import { getLocale } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import type { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { Just, Maybe } from 'monet'

import { safeIsNotNan } from '../../commercetools/price'

export type OrderTracking = {
  readonly orderId: string
  readonly cartTotal: Maybe<number>
  readonly currency: CurrencyCode
  readonly lineItems: readonly LineItem[]
}

const liveChatOrderTracking = (
  data: OrderTracking,
) => {
  const locale = getLocale()

  const cartTotal: Maybe<number> = prop('cartTotal', data) || Just(0)
  const lineItems: readonly LineItem[] = prop('lineItems', data) || []
  const currency: string = prop('currency', data) || ''
  const orderId: string = prop('orderId', data) || ''

  const orderTotal =
  (total: Maybe<number>) =>
    total
      .chain(safeIsNotNan)
      .chain(Maybe.fromNull)
      .getOrElse(0)

  const _orderTotal = orderTotal(cartTotal).toString()

  const filterProductType = (item: LineItem) => item.productType === 'sensor'
  const products = lineItems.filter(filterProductType).map(product => product.name[locale])

  window.sprChat && window.sprChat('updateConversationContext', {
    context: {
      '_c_62600286c8257b12f6ca54b9': [ _orderTotal ],
      '_c_626002a3c8257b12f6ca67ca': [ currency ],
      '_c_626002bdc8257b12f6ca77ee': [ orderId ],
      '_c_626002d5c8257b12f6ca8713': products
    }
  })
}

export default liveChatOrderTracking
