import { Document } from '@contentful/rich-text-types'
import { safeProp } from '@simplisafe/monda'
import type { PartnerWithUsFormSubmitBody } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsForm'
import { PartnerWithUsFormSchema } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsFormSchema'
import { partnerWithUsFormSubmit } from '@simplisafe/ss-ecomm-data/commercetools/partnerWithUsFormSubmission'
import { startPartnerWithUsContactFetch } from '@simplisafe/ss-ecomm-data/deprecated/partnerWithUs/actions'
import { selectPartnerWithUsSubmit } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import {
  Form, Formik, FormikHelpers
} from 'formik'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PartnerWithUsFormFragment } from '../../../graphql'
import getJson from '../../util/getJson'
import Company from './form-sections/Company'
import Email from './form-sections/Email'
import Name from './form-sections/Name'
import Submission from './form-sections/Submission'
import SuccessMessage from './form-sections/SuccessMessage'
import Title from './form-sections/Title'

export type FormValues = {
  readonly name: string
  readonly company: string
  readonly email: string
}

const initialValues: FormValues = {
  company: '',
  email: '',
  name: ''
}

type PartnerWithUsProps = {
  readonly data: PartnerWithUsFormFragment
}

const PartnerWithUsForm = ({ data }: PartnerWithUsProps) => {

  const dispatch = useDispatch()

  const isSubmittedSuccessfully = useSelector(selectPartnerWithUsSubmit)

  const partnerWithUsFormSuccessMessageDescription = safeProp('successMessageDescription', data)
    .map(getJson)
    .map((json: Document) => <SmallTextSection description={json} key="successMessage"
      verticalPadding={'small'} />)
    .orUndefined()

  const onSubmit = async (formData: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const nameParts = formData.name.split(' ')
    const firstName = nameParts[0]
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : ' '

    const submitFormValues: PartnerWithUsFormSubmitBody = {
      companyName: formData.company,
      email: formData.email,
      firstName: firstName,
      lastName: lastName,
      source: propOr<string, string>('Partner with us form', 'source', data)
    }
    setSubmitting(true)

    const handleSuccess = () => {
      setStatus({ message: 'success' })
      dispatch(startPartnerWithUsContactFetch())
      setSubmitting(false)
    }

    const handleFailure = (error: Error) => {
      setSubmitting(false)
      logError(error)
    }

    partnerWithUsFormSubmit(submitFormValues)(handleFailure)(handleSuccess)
  }

  return (
    <div
      className="bg-neutral-100 m-0 pt-1 px-8 pb-8"
      data-component={'PartnerWithUsForm'}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px', }}
    >
      <Formik initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PartnerWithUsFormSchema}>
        {({ isSubmitting }) => (
          <Form>
            {!isSubmittedSuccessfully && (
              <>
                <Title formTitle={propOr<string, string>('', 'formTitle', data)} />
                <Name
                  nameFieldLabel={propOr<string, string>('', 'nameFieldLabel', data)}
                  nameFieldPlaceholder={propOr<string, string>('', 'nameFieldPlaceholder', data)}
                />
                <Company
                  companyFieldLabel={propOr<string, string>('', 'companyFieldLabel', data)}
                  companyFieldPlaceholder={propOr<string, string>('', 'companyFieldPlaceholder', data)}
                />
                <Email
                  emailFieldLabel={propOr<string, string>('', 'emailFieldLabel', data)}
                  emailFieldPlaceholder={propOr<string, string>('', 'emailFieldPlaceholder', data)}
                />
                <Submission submitButtonDisabled={isSubmitting} submitButtonLabel={propOr<string, string>('', 'submitButtonLabel', data)} />
              </>
            )}
            {isSubmittedSuccessfully && (
              <SuccessMessage
                successMessageDescription={partnerWithUsFormSuccessMessageDescription}
                successMessageTitle={propOr<string, string>('', 'successMessageTitle', data)}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PartnerWithUsForm
