import path from '@simplisafe/ewok/ramda/path'
import { safePath, safeProp } from '@simplisafe/monda'
import { selectLocale, } from '@simplisafe/ss-ecomm-data/redux/select'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import {
  PageBody, PageMasthead, PageSection, PageWrapper
} from '@simplisafe/ss-react-components'
import { useMediaQuery } from '@simplisafe/ss-react-components/hooks'
import { Maybe } from 'monet'
import defaultTo from 'ramda/src/defaultTo'
import map from 'ramda/src/map'
import React, {
  FC, useContext, useEffect
} from 'react'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import {
  ContentfulBannerTopPage,
  ContentfulFooterFragment,
  ContentfulHeroBanner,
  ContentfulModalPromoPopup,
  PageAnchorNavigationFragment,
} from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import ErrorBoundary from '../../errorComponents/ErrorBoundary'
import useApplyPromoCode from '../../hooks/useApplyPromoCode'
import useAwinTracking from '../../hooks/useAwinTracking'
import useEnableLiveChat from '../../hooks/useEnableLiveChat'
import useOptimizelyParams from '../../hooks/useOptimizelyParams'
import useUtmCode from '../../hooks/useUtmCode'
import useUtmContent from '../../hooks/useUtmContent'
import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import { PageTitleContext } from '../../tracking/pageTitleContext'
import { trackEventIsMobile } from '../../util/analytics'
import { devParams } from '../../util/queryParams'
import SEO from '../../util/seo'
import AuthenticationComponent from '../AuthenticationComponent'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import DefaultBannerComponent from '../DefaultBannerComponent'
import FloatingPromoWidgetComponent from '../FloatingPromoWidgetComponent'
import FooterComponent from '../FooterComponent'
import GlobalPromotionalComponent from '../GlobalPromotionalComponent'
import HeaderComponent from '../Header'
import { ContentfulHeaderFragment } from '../Header/query'
import HeaderPartnerComponent from '../HeaderPartnerComponent'
import HeaderProgressBarComponent from '../HeaderProgressBarComponent'
import HeroBannerComponent from '../HeroBannerComponent'
import LiveChat from '../LiveChat'
import ModalExitIntentComponent from '../ModalExitIntentComponent'
import ModalPromoPopupComponent from '../ModalPromoPopupComponent'
import PageAnchorNavigationComponent from '../PageAnchorNavigationComponent'
import PopupWizard from '../PopupWizard'
import SimpleFooterComponent from '../SimpleFooterComponent'
import TopPageBanner from '../TopPageBanner'
import TrustpilotReviewBanner from '../TrustpilotBanner'
import CountryRedirectModals from './CountryRedirectModals'
import { getPageLayoutComponents } from './helpers'
import { usePartnerShopRedirect } from './hooks'
import PageToaster from './PageToaster'
import PromoBanner from './PromoBanner'
import {
  MappedComponentProps, PageComponent, PageLayoutComponent, PageProps
} from './types'

export const isBrowser = typeof window !== 'undefined'
export const getWindow = () => window

// NOTE: we need to continue importing getMappedComponent into this file for now.
// Somehow GroupSection was unable to dynamically render ContentfulModal using getMappedComponent.
// If we move this function back into helpers, be sure to check the choose monitoring page can
// still render the 'Skip this step' modal to proceed without monitoring.
const mapPageComponentToTemplate = (pageComponent: PageComponent, pageContext?: PageProps['pageContext'], location?: PageProps['location']) => {
  const Component = getMappedComponent<MappedComponentProps>(pageComponent)
  return Component
    ? <ErrorBoundary key={pageComponent.id}>
      <Component
        data={pageComponent}
        key={pageComponent.id}
        location={location}
        pageContext={pageContext}
      />
    </ErrorBoundary>
    : null
}

const Page: FC<PageProps> = ({
  data, pageContext, location
}) => {
  useEffect(() => {
    configureNewRelic()
    isBrowser && getWindow().scrollTo(0, 0)
  }, [])

  usePartnerShopRedirect(location)

  const liveChatStatus = useEnableLiveChat(data)

  const liveChatId = liveChatStatus && liveChatStatus.liveChatId
  const liveChatEnabled = liveChatStatus && liveChatStatus.liveChatEnabled
  const locale = useSelector(selectLocale)

  const isTabletUp = useMediaQuery('TabletAndUp')
  const [ hidePopups ] = useQueryParam(devParams.hidePopUps, BooleanParam)
  // this is the value from contentful that we will no longer be using
  const globalPromoCode = defaultTo('')(path([ 'contentfulPage', 'pageLayout', 'promoCode' ], data))
  const pagePath = path([ 'contentfulPage', 'pageUrl' ], data) || ''
  const utmCode = useUtmCode(true, Maybe.fromFalsy(pagePath))
  useUtmContent()
  useAwinTracking(locale === 'en-GB')

  useApplyPromoCode(Maybe.fromNull(utmCode))
  useOptimizelyParams()

  // pageTitle is passed to our SEO component below, and also set via the setPageTitle context hook so it can be passed
  // to tracking endpoints like AT-AT (see InjectPageTracking).
  const pageTitle: string = path([ 'contentfulPage', 'seoDetails', 'metaTitle' ], data) || ''
  const setPageTitle = useContext(PageTitleContext)
  const { trackEvent } = useTracking()

  useEffect(() => {
    setPageTitle(pageTitle)
  }, [ pageTitle, setPageTitle ])

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [ isTabletUp, trackEvent ])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const components: readonly PageLayoutComponent[] = safePath([ 'contentfulPage', 'pageLayout', 'components' ], data).orJust([])
  const allComps = getPageLayoutComponents(components)

  /* eslint-disable @typescript-eslint/no-unsafe-assignment*/
  const pageComponents: Maybe<readonly PageComponent[]> = safePath([ 'contentfulPage', 'pageComponents' ], data).chain(x => Maybe.fromNull(x))
  const pageHeroBanner: Maybe<ContentfulHeroBanner> = safePath([ 'contentfulPage', 'pageHeroBanner' ], data).chain(x => Maybe.fromNull(x))
  const belowComponents: readonly PageLayoutComponent[] = safePath([ 'contentfulPage', 'pageLayout', 'belowComponents' ], data).orJust([])
  const countryRedirectModal: Maybe<SmallTextSectionRedirect> = safeProp('contentfulSmallTextSection', data)
  const belowPageLayoutCompoments = belowComponents.map((component: PageComponent) => mapPageComponentToTemplate(component))
  const anchoredNav: PageAnchorNavigationFragment = safePath([ 'contentfulPage', 'anchoredNavigation' ], data).orNull()
  const productId: Maybe<string> = safePath([ 'contentfulPage', 'productId' ], data)
  const pageUrl: string = safePath([ 'contentfulPage', 'pageUrl' ], data).getOrElse('')
  const pageSchema = safePath([ 'contentfulPage', 'seoDetails', 'pageSchema' ], data).orUndefined()
  const pageTopBanner: ContentfulBannerTopPage = safePath([ 'contentfulPage', 'pageTopBanner' ], data).orUndefined()
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */

  useEffect(() => {
    toast.remove('promoToast')
  }, [ pageUrl ])

  const renderPageComponents = () => (
    pageContext &&
    <ErrorBoundary>
      <PageBody>
        {pageComponents
          .cata(
            () => null,
            map((comp: PageComponent) =>
              <React.Fragment key={comp.id}>
                <PageSection>{mapPageComponentToTemplate(comp, pageContext, location)}</PageSection>
              </React.Fragment>
            )
          )
        }
      </PageBody>
    </ErrorBoundary>
  )

  return (
    <PageWrapper key={pageContext.id}>
      {
        anchoredNav && <PageAnchorNavigationComponent data={anchoredNav} key='PageAnchorNavigationComponent' />
      }
      <CountryRedirectModals countryRedirectModals={countryRedirectModal || undefined} hidePopups={!!hidePopups} />
      <PageToaster />
      <GlobalPromotionalComponent data={{ globalPromoCode }} key='GlobalPromotionalComponent'
        pageContext={pageContext} />
      {pageTopBanner && <TopPageBanner data={pageTopBanner} />}
      <PromoBanner pageData={data} />
      {
        /* eslint-disable @typescript-eslint/consistent-type-assertions*/
        allComps.contentfulHeaderVariationContainer.map(data => Maybe.fromNull(<ContentfulVariationContainerComponent data={data} key={data.id}
          renderVariant={(variant) => variant.internal.type === 'ContentfulHeader' ? <HeaderComponent data={variant as ContentfulHeaderFragment} /> : null} />)).orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      { allComps.contentfulHeader.map(data => <HeaderComponent data={data} key={data.id} />).orNull() }
      { allComps.headerProgressBar.map(data => <HeaderProgressBarComponent data={data} key={data.id} />).orNull() }
      { allComps.headerPartner.map(data => <HeaderPartnerComponent data={data} key={data.id} />).orNull() }
      {
        allComps.modalExitIntent.map(data => (
          <ModalExitIntentComponent data={data} key={data.id}
            location={location} pageContext={pageContext} />
        )).orNull()
      }
      {
        allComps.modalPromoPopup.map(data => (
          <ModalPromoPopupComponent data={data} key={data.id} />
        )).orNull()
      }
      {
        allComps.contentfulModalPromoPopupVariationContainer.map(data => Maybe.fromNull(<ContentfulVariationContainerComponent data={data} key={data.id}
          // Assert type since TypeScript doesn't infer type from variant.internal.type check
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          renderVariant={(variant) => <ModalPromoPopupComponent data={variant as ContentfulModalPromoPopup}/>}/>)).orNull()
      }
      {
        pageHeroBanner.map(data => data?.internal?.type === 'ContentfulVariationContainer'
        /* eslint-disable @typescript-eslint/consistent-type-assertions */
          ? <ContentfulVariationContainerComponent data={data} renderVariant={(variant) => <HeroBannerComponent data={variant as ContentfulHeroBanner} key='hero-banner' />} />
          : <HeroBannerComponent data={data as ContentfulHeroBanner} key='hero-banner' />).orNull()
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      }
      <div key='TrustpilotReviewBanner-wrapper'>
        {allComps.trustpilotBanner.map(data => <TrustpilotReviewBanner data={data}
          key={data.id} />).orNull()}
      </div>
      {
        allComps.contentfulDefaultBanner.cata(
          () => null,
          data => <PageMasthead key={data.id}>
            <DefaultBannerComponent data={data} />
          </PageMasthead>
        )
      }
      {
        allComps.contentfulFloatingPromoWidget.map(data => <div key="floating-promo-widget"><FloatingPromoWidgetComponent data={data}
          key={data.id} liveChatAppId={liveChatId} /></div>).orNull()
      }
      {
        allComps.authentication.cata(
          () => renderPageComponents(),
          data => (
            <AuthenticationComponent
              data={data}
              key='AuthenticationComponent'
              location={location}
              pageContext={pageContext}
            >
              {renderPageComponents()}
            </AuthenticationComponent>
          )
        )
      }
      {
        allComps.popupWizard.map(data =>
          <ErrorBoundary key={data.id}>
            <PopupWizard data={data} />
          </ErrorBoundary>
        ).orNull()
      }

      {
        // I think I have this logic correct, but it was hard to tell from the original code
        // If simplefooter is Just, render it, otherwise if contentfulFooter is Just, render that, otherwise render null
        <div key='Footer-wrapper'>
          {allComps.simpleFooter
            .cata(() =>
              allComps.contentfulFooter
                .map((data: ContentfulFooterFragment) => (
                  <div key='FooterComponent-wrapper'>
                    <FooterComponent data={data}
                      id={data.id}
                      key={data.id} />
                  </div>
                )).orNull(),
            (simpleFooter) => (
              <div key='SimpleFooter-wrapper'>
                <SimpleFooterComponent data={simpleFooter}
                  id={simpleFooter.id}
                  key={simpleFooter.id} />
              </div>
            ))}
        </div>
      }
      {belowPageLayoutCompoments}
      <SEO canonicalLink={path([ 'contentfulPage', 'seoDetails', 'canonicalLinks' ], data)}
        key='page-seo'
        lang={path([ 'contentfulPage', 'node_locale' ], data)}
        metaDescription={path([ 'contentfulPage', 'seoDetails', 'metaDescription', 'metaDescription' ], data)}
        metaKeyword={path([ 'contentfulPage', 'seoDetails', 'keywords', 'keywords' ], data)}
        metaTitle={pageTitle}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        pageSchema={pageSchema}
        pageUrl={pageUrl}
        productId={productId}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        seoDetails={safePath([ 'contentfulPage', 'seoDetails' ], data).getOrElse({})}
      />
      {liveChatEnabled && <LiveChat appId={liveChatId} />}
    </PageWrapper>
  )
}

export {
  Page as default, PageProps, PageComponent, mapPageComponentToTemplate
}
