import {
  FormField,
  FormRadio,
  Text
} from '@simplisafe/ss-react-components'
import React from 'react'

type SubjectFieldProps = {
  readonly subjectDisclaimer?: string
  readonly subjectFieldLabel: string
  readonly subjectOptions: readonly { readonly text?: string; readonly value?: string }[]
}

const Subject: React.FC<SubjectFieldProps> = (props: SubjectFieldProps) => {
  return (
    <FormField hideLabel={true} label={props.subjectFieldLabel}
      name='subject'>
      <Text fontWeight='medium'>{props.subjectFieldLabel}</Text>
      {props.subjectOptions.map((option, idx) => {
        return (
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          <FormRadio key={idx} label={option.text}
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            name='subject' value={option.value} />
        )
      })}
      <Text
        fontWeight='medium'
        textColor='darkOrange'
        textSize='xs'
      >
        {props.subjectDisclaimer}</Text>
    </FormField>
  )
}

export default Subject
