import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import {
  clearCartError, IOAddDiscountCodeToCart, IORemoveDiscountCodeFromCart, LOCAL_STORAGE_CARTID
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { PromoCode } from '@simplisafe/ss-ecomm-data/promotions'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { navigate } from 'gatsby'
import { get } from 'local-storage'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import pluck from 'ramda/src/pluck'
import reduce from 'ramda/src/reduce'
import unless from 'ramda/src/unless'
import when from 'ramda/src/when'
import {
  FC, useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isDiscountApplied } from '../../commercetools/utils'
import useUtmCode from '../../hooks/useUtmCode'
import { isValidFromToDate } from '../../util/helper'
import { PageProps } from '../Page'

type GlobalPromotionalComponentProps = {
  readonly data: {
    readonly globalPromoCode: string
  }
  readonly pageContext: PageProps['pageContext']
}

// TODO all of this logic needs to move to ecomm-data
type DiscountResult = {
  readonly promoCode: string
  readonly result: PromoCode
  readonly isValidPromo: boolean
}

export const USER_DISCOUNT_CODE = 'userDiscountCode'

/**
 * This is to handle the checkout/payment redirection to cart when the cart is empty.
 *
 * @param url
 * @param lineItems
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
const validateCheckout = (url, lineItems) => {
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  when(equals(true), () => isEmpty(lineItems) && typeof window !== 'undefined' && navigate('/cart'))((/(checkout|payment-page)/).test(url))
}

/**
 * This component is a handler for applying global promotion to the cart and hadnles the empty
 * checkout/payment redirection as well.
 *
 * TODO Once this approach is tested and works fine in the env this controls the no of dicount-codes API call
 * which currently happens in the respective components.
 */
const GlobalPromotionalComponent: FC<GlobalPromotionalComponentProps> =
  ({ data, pageContext }: GlobalPromotionalComponentProps) => {
    const dispatch = useDispatch()
    const url = defaultTo('')(prop('url', pageContext))

    const utmCode = useUtmCode()

    const globalPromoCode = utmCode || prop('globalPromoCode', data)// EX: '25MAY21UK,MONITORING20'
    const totalPromoCount = globalPromoCode.split(',').length

    const cart = useSelector(selectCart)

    /**
     * TODO all of this logic needs to move to ecomm-data
     *
     * something like useSelector(selectActivePromo)
    */
    const [ discountCodes, setDiscountCodes ] = useState<readonly DiscountResult[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
    const [ isPromoVerified, setIsPromoVerified ] = useState(false)
    const [ isPromoApplied, setIsPromoApplied ] = useState(false)

    // TODO all of this logic needs to move to ecomm-data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
    const handleDiscountResult = useCallback((result = {}, promoCode = '') => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
      const validUntil = prop('validUntil', result)
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, ramda/always-simplification -- legacy code, legacy code */
      const isValidPromo = ifElse(isEmpty, always(false), () => validUntil ? isValidFromToDate(result) : true)(result)
      const discount = {
        isValidPromo,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        promoCode,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
        result
      }

      // Log an error when there is no valid promo code in Commercetools for the
      // value supplied from Contentful. This will be used to provide alerting
      // so we know if a promo code expired when it shoudn't have.
      !isValidPromo && logError(Error(`GlobalPromotionalComponent: No valid promo code found in Commercetools for ${promoCode} as supplied by CMS`))

      utmCode ? setDiscountCodes(prevDiscounts => ([
        ...prevDiscounts,
        {
          isValidPromo: true,
          promoCode: utmCode,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
          result
        }
      ]))
        : setDiscountCodes(prevDiscounts => ([
          ...prevDiscounts,
          discount
        ]))

    }, [ utmCode ])

    // useEffect(() => {
    //   !isPromoVerified && unless(isEmpty, promoCodes => {
    //     setIsPromoVerified(true)
    //     promoCodes.split(',').map(
    //       // promoCode => getCartDiscountByCode(locale, encodeURIComponent(`code="${promoCode}"`))(handleDiscountResult)(result => handleDiscountResult(result, promoCode))
    //     )
    //   })(globalPromoCode)
    // }, [ globalPromoCode, isPromoVerified, handleDiscountResult ])

    useEffect(() => {
      // Storing the valid discount results in the state that will be used by Promotional Banner & Roundel without making API call.
      isPromoVerified && when(equals(totalPromoCount), () => {
        const validDiscounts = discountCodes.filter(discount => discount.isValidPromo)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
        const promoCodes = pluck('promoCode', validDiscounts)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- legacy code
        const results = pluck('result', validDiscounts)

        // dispatch(setActivePromotionAction({
        //   // This 'promoCode' value is used in all leads capture API call.
        //   promoCode: head(promoCodes),
        //   promoCodes,
        //   // TODO replace the inners of this with PromoCode instead of DiscountCode
        //   // @ts-ignore
        //   results
        // }))
      })(discountCodes.length)
    }, [ globalPromoCode, discountCodes, isPromoVerified, totalPromoCount, dispatch ])

    useEffect(() => {
      const cartId = get(LOCAL_STORAGE_CARTID)

      /**
         * 1) discountCodes - To identify whether the promo banner is valid with the configured dates.
         * 2) isDiscountApplied()
         *      - To identify whether the promo banner discount is already applied to the cart or not.
         *      - If not cart will be created or updated with this promo banner discount
         * 3) isDiscountApplied(cart, get(USER_DISCOUNT_CODE), ['obj', 'code'])
         *      -  To ensure that user entered discount is not available in cart before auto applying the global discount codes.
         */
      isNil(cartId) ?
        validateCheckout(url, []) :
        cart.cata(
          () => undefined,
          () => undefined,
          () => undefined,
          _cart => {
            const lineItems = _cart.lineItems

            validateCheckout(url, lineItems)

            const isPaymentPage = (/(payment-page)/).test(url)

            const isPromoOrDiscountApplied = isPromoApplied || isDiscountApplied(cart, get(USER_DISCOUNT_CODE), [ 'obj', 'code' ])

            /**
             * If the page is 'payment-page' then remove the discounts which is not in 'MatchesCart' state.
             * In all other pages look for the valid discount codes and apply to the cart to enable auto promotion.
             */
            ifElse(
              equals(true),
              () => dispatch(IORemoveDiscountCodeFromCart()),
              () => when(equals(false), () => {
                const validDiscountCodes = discountCodes.filter(discount => discount.isValidPromo)
                const discountList: readonly string[] = reduce((acc: readonly string[], discount: DiscountResult) => {
                  // @ts-expect-error TS(2322) FIXME: Type 'defaultTo_general_11<string, string | boolea... Remove this comment to see the full error message
                  const discountId: string = defaultTo('')(path([ 'result', 'id' ], discount))

                  return ifElse(
                    equals(false),
                    always([ ...acc, discount.promoCode ]),
                    always(acc)
                  )(isDiscountApplied(cart, discountId))

                }, [])(validDiscountCodes)

                // Making Add Discount to Cart call only when all promo codes are validated and filtered.
                discountCodes.length === totalPromoCount && unless(isEmpty,
                  list => {
                    setIsPromoApplied(true)
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
                    dispatch(IOAddDiscountCodeToCart(list, () => dispatch(clearCartError())))
                  })(discountList)
              })(isPromoOrDiscountApplied)
            )(isPaymentPage)
          })
    }, [ url, discountCodes, cart, isPromoApplied, totalPromoCount, dispatch ])

    return null
  }

export default GlobalPromotionalComponent
