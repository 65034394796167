import * as Promo from '@simplisafe/ss-react-components/promotions'
import React, { FC } from 'react'

import { LeadCaptureFormPropsOnSubmit } from '../LeadCaptureForm'
import type { ActivePromoBannerType } from '.'
import CartLayout from './CartLayout'
import MobileAltLayout from './MobileAltLayout'

export type MobileBannerAltProps = {
  readonly isFormSubmitted: boolean
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
  readonly type: ActivePromoBannerType
}

const MobileBannerAlt: FC<MobileBannerAltProps> = ({
  isFormSubmitted,
  onEmailSubmit,
  type,
}: MobileBannerAltProps) => {

  return (
    <Promo.PromoBanner data-component='MobileBannerAlt'
      position='top'
      role='banner'>
      {type === 'cart' ?
        <CartLayout /> :
        <MobileAltLayout
          isFormSubmitted={isFormSubmitted}
          onEmailSubmit={onEmailSubmit}
          type={type}
        />
      }
    </Promo.PromoBanner>
  )
}

export default MobileBannerAlt
