import { getChatAppId } from '@lib/tracking/src/cookies'
import prop from '@simplisafe/ewok/ramda/prop'
import { safeProp } from '@simplisafe/monda'
import { Text } from '@simplisafe/ss-react-components'
import { SmallTextSection } from '@simplisafe/ss-react-components'
import { window } from 'browser-monads-ts'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { MouseEvent } from 'react'

import { LiveChatTriggerFragment } from '../../../graphql'
import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import getJson from '../../util/getJson'
import LiveChat from '.'

export type LiveChatTriggerProps = {
  readonly data: LiveChatTriggerFragment
}

const LiveChatTrigger = ({ data }:  LiveChatTriggerProps) => {
  const disclaimer = propOr<string, string>('', 'disclaimerText', data)
  const chatIdCookie: string = getChatAppId()
  const chatId: string = chatIdCookie || '610bd23d07ea1e23ca931dd8_app_979960'
  const hasAvailableAgents: boolean = useLiveChatAvailability(chatId)
  const clickTagetData = hasAvailableAgents ? 'clickTarget' : 'clickTargetDisabled'

  const clickTarget =
  safeProp(clickTagetData, data)
    .map(getJson)
    .map(json => <SmallTextSection description={json}
      key={prop('id', data)}/>)
    .orNull()

  const onClick = (e: MouseEvent) => {
    e.preventDefault()
    // so the build doesn't complain about missing type for window.sprChat
    hasAvailableAgents && window.sprChat && window['sprChat'].call(window['sprChat'], 'open')
  }

  return (
    <>
      <LiveChat appId={chatId} />
      <span onClick={(e) => onClick(e)}>
        {clickTarget}
      </span>
      {!hasAvailableAgents &&
        <Text display='inline-flex'
          fontWeight='medium'
          textColor='neutralDarkGray'
        >
          {disclaimer}
        </Text>
      }
    </>
  )
}

export const liveChatTrigger = graphql`
  fragment liveChatTrigger on ContentfulLiveChatTrigger {
    id
    internal {
      type
    }
    clickTarget {
      json
    }
    clickTargetDisabled {
      json
    }
    disclaimerText
  }
`

export default LiveChatTrigger
