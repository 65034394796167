import { currencyCode as localeCurrencyCode } from '@lib/utils'
import findFirstRight from '@simplisafe/ewok/monet-utils/findFirstRight'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import isString from '@simplisafe/ewok/ramda-adjunct/isString'
import { safePath, safeProp } from '@simplisafe/monda'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  CurrencyCode, LOCALE, Locale
} from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import {
  Just, Maybe, None
} from 'monet'
import head from 'ramda/src/head'
import split from 'ramda/src/split'
import subtract from 'ramda/src/subtract'
import { pipe } from 'ts-functional-pipe'

import {
  calculatePercentPrice, formatDisplayPrice, formatPercentage
} from './price'

/** @deprecated - usage should be replaced with selectLocale selector (or getLocale from package/utils/locale if not in a React component) */
export const locale: Locale = LOCALE

/** @deprecated - usage should be replaced with using currencyCode from packages/utils/locale directly */
export const currencyCode: CurrencyCode = localeCurrencyCode

// TODO everything below this point should be coming from ss-ecomm-data (or else cleaned up)

export const getProductName =
  (product: Product): Maybe<string> =>

    safePath([ 'name', locale ], product)

export const getProductLocaleName =  getProductName

/**
 * @deprecated use packageToDiscountDisplayText from @simplisafe/ss-ecomm-data/commercetools/discounts
 */
export const getDiscountValue =
  (x: Record<string, number> | string): string =>
  {
    const getDiscountString = (x: Record<string, number>) =>

      Maybe.fromNull(prop(currencyCode, x))
        .chain(formatDisplayPrice)
        .orJust('')

    return isString(x) ? x : getDiscountString(x)
  }


/** To check whether given discount id is already applied to cart or not. */
export const isDiscountApplied = (cart: RemoteData<ImmutableCart>, discountId: string, attrbutePath = [ 'id' ]) => {
  // @ts-expect-error TS(7034) FIXME: Variable 'emptyValue' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const emptyValue = []
  const cartDiscounts = cart
    .chain(safeProp('discountCodes'))
    // TODO something is broken somewhere. This type should always be Maybe<DiscountCodeInfo[]> but it's sometimes undefined
    // @ts-expect-error TS(7005) FIXME: Variable 'emptyValue' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    .map(discountCodes => Maybe.isInstance(discountCodes) ? discountCodes.orJust(emptyValue) : emptyValue)
    // @ts-expect-error TS(7005) FIXME: Variable 'emptyValue' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    .orJust(emptyValue)

  // TODO path doesn't support ... spreading because the keys might not match the object
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return !!cartDiscounts.find(disc => path([ 'discountCode', ...attrbutePath ], disc) === discountId)
}

export const percentStringToNumber = pipe(
  split('%'),
  head,
  Number
)

export type PackageDiscountKey = 'discountedPrice' | 'discountedPriceWithServicePlan'

export const subtractAbsoluteDiscount =
(totalPrice: number) =>
  (x: Record<string, number>) =>

    safeProp(currencyCode, x)
      .map(subtract(totalPrice))


/**
  * TODO the front-end shouldn't be doing this calculation
  *
  * It should rely on the data coming from commercetools
  *
  * @deprecated
 */
export const calculateDiscountValue =
      (totalPrice: number) =>
        (x: Record<string, number> | string) =>
          isNotEmpty(x) ?
            (
              isString(x) ?

                Just(Number(calculatePercentPrice(x, totalPrice).toFixed(2)))
                : subtractAbsoluteDiscount(totalPrice)(x)
            )
            : None<number>()

const liftSubtract = (b: Maybe<number>) =>
  (a: number) =>
    b.map(n => a - n).getOrElse(a)

const formatPackageExtraDiscountValue = (_package: Package) => {
  const extraAbsoluteDiscounts =
           _package.absoluteDiscountWithServicePlan
             .map(liftSubtract(_package.absoluteDiscount))
             .chain(formatDisplayPrice)

  const formattedRelativeDiscount =
          _package.relativeDiscountWithServicePlan
            .map(liftSubtract(_package.relativeDiscount))
            .map(n => `${formatPercentage(n)} `)

  return findFirstRight(
    [
      extraAbsoluteDiscounts,
      formattedRelativeDiscount
    ])
}

const formatProductExtraDiscountValue = (product: Product) =>
  product.discountedPrice
    .chain(formatDisplayPrice)

/**
 * This takes a Package or Product and returns a textual difference between
 * promo discount with service and promo discount without (eg 35% - 25% == "10%").
 *
 * Janky because the caller uses the deprecated @simplisafe/ss-ecomm-data/redux/select/selectPackageDiscounts
 * and because this should live in ecomm-data as a selector to begin with.
 *
 * See @simplisafe/ss-ecomm-data/redux/select/selectPackagesDiscountDisplayText for example.
 *
 * @deprecated move this logic into ecomm-data
 */
export const getExtraDiscountValue =
  (_package: Maybe<Package | Product>): string | undefined => {
    return _package
      .chain(
        val => val['@@type'] === 'package'
          ? formatPackageExtraDiscountValue(val)
          : formatProductExtraDiscountValue(val)
      )
      .orUndefined()
  }
