import { fetchAtAtVisitorId, visitorIdAtAt } from '@lib/tracking/src/atat'
import {
  COOKIE_LEAD_DATA,
  cookies,
  getLeadData,
} from '@lib/tracking/src/cookies'
import { useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { useExperimentVariation } from '@lib/tracking/src/useExperimentation'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import { safePath, safeProp } from '@simplisafe/monda'
import { LeadGenCreateParams, leadGenRecommendations } from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import { selectActivePromoCode, selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { brazeTrackQuoteWizardSubmission, handleBrazeTrackingEvent } from '@simplisafe/ss-ecomm-data/tracking/braze'
import {
  Column, LoadingSpinner, Text
} from '@simplisafe/ss-react-components'
import { WizardInitial } from '@simplisafe/ss-react-components'
import { Link } from 'gatsby'
import { set } from 'local-storage'
import { None } from 'monet'
import propOr from 'ramda/src/propOr'
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import styled from 'styled-components'

import { locale } from '../../commercetools/utils'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import useCookieChange from '../../hooks/useCookieChange'
import { trackEventCompletedQuoteWizard, trackSubmitLeadEvent } from '../../util/analytics'
import ButtonComponent from '../ButtonComponent'
import QuoteWizardComponent, { QuoteWizardProps, toRichText } from '../QuoteWizardComponent'
import RichText from '../RichText'
import { appendVIDtoButtonUrl } from './lib'
import { buildRecommendationResponseData } from './quoteWizardRecommendationTrackingData'

const KEY_QW_RECOMMENDATIONS_SUBMITTED = 'qw_recommendations_submitted'
const KEY_RETURN_TO_QW = 'ss_return_to_qw'
const POP_UP_STORAGE_KEY = 'popupWizardOpened'

export type QuoteWizardWrapperProps = {
  readonly handleEmailSubmit?: QuoteWizardProps['handleEmailSubmit']
  readonly handlePhoneSubmit?: QuoteWizardProps['handlePhoneSubmit']
  readonly data: QuoteWizardProps['data']
  readonly includePhone?: QuoteWizardProps['includePhone']
  readonly type?: QuoteWizardProps['type']
  readonly finalTabDescription?: QuoteWizardProps['finalTabDescription']
  readonly finalTabContents?: QuoteWizardProps['finalTabContents']
  readonly firstTab?: QuoteWizardProps['firstTab']
  readonly showTitle?: QuoteWizardProps['showTitle']
  readonly columnType?: 'none' | 'shadowbox'
}

const StyledText = styled(Text)`
  --prose-body-color: var(--white);
  --prose-headings-color: var(--white);
  --prose-links-color: var(--white);

  --btn-outline-accent: var(--white);
  --btn-outline-primary: var(--neutral-black);
  --btn-outline-primary-light: var(--white);
  --btn-outline-accent-light: var(--neutral-black);

  --btn-link-accent: var(--white);
  /* This line is because the component uses RichText, so paragraphs takes neutral black instead of white */
  p { color: #fff; }
  text-align: center;
`

const QuoteWizardWrapper: FC<QuoteWizardWrapperProps> =
  ({
    columnType = 'shadowbox',
    data,
    type = 'embedded',
    includePhone = false
  }: QuoteWizardWrapperProps) => {
    const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    const leadDataCookie = getLeadData()

    const [ emailAddress, setEmailAddress ] = useState<string>(propOr<string, string>('', 'email', leadDataCookie))
    const [ vid, setVid ] = useState<string | undefined>(visitorIdAtAt())
    const [ attributeHash, setAttributeHash ] = useState(None())
    const [ isLoading, setIsLoading ] = useState(false)
    const { Track, trackEvent } = useTracking<TrackingData>({
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ action: string; appSection: "q... Remove this comment to see the full error message
      action: 'quoteWizard',
      appSection: 'quoteWizard',
      wizardType: type
    })

    const promoCode = useSelector(selectActivePromoCode)

    const siteLocale = useSelector(selectLocale)
    const { handleHidePopup } = useContext(HidePopupWizard)
    useEffect(() => {
      !vid &&
      fetchAtAtVisitorId().then(setVid)
        .catch()
    }, [ vid ])


    const storedAttributeHash: string | undefined = cookies.get<string>(KEY_RETURN_TO_QW)

    const {
      newUserHelpText,
      newUserSkipQuizButton,
      newUserStartQuizButton,
      returningUserHelpText,
      returningUserRestartQuizButton,
      returningUserSeeResultsButton
    } = data.firstTab || {}

    const variation = useExperimentVariation(propOr('control', 'experimentKey', newUserStartQuizButton))


    // TODO: fix type
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const newUserSkipQuizButtonUrl = prop('url', newUserSkipQuizButton || {})

    const handleEmailSubmitFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
    const handleEmailSubmitSuccess = (responses: Record<string, unknown>) => (value) => {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call -- legacy code, legacy code */
      setAttributeHash(value.map(prop('attributeHash')))
      cookies.set(KEY_QW_RECOMMENDATIONS_SUBMITTED, true, cookiesOption)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- legacy code
      const attributeHash = value.map(prop('attributeHash')).orUndefined()
      cookies.set(KEY_RETURN_TO_QW, attributeHash, cookiesOption)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- legacy code
      cookies.set(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call -- legacy code
      handleBrazeTrackingEvent(value.orUndefined())
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
      const wizardWithRecsResponseData = buildRecommendationResponseData(siteLocale, attributeHash, responses)

      brazeTrackQuoteWizardSubmission(wizardWithRecsResponseData, true)
      trackEventCompletedQuoteWizard(trackEvent)
      optimizelyTrackSiteEvents({ eventType: 'quote_wizard_complete_fs' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      setIsLoading(false)
      trackSubmitLeadEvent(trackEvent)
    }

    const handleEmailSubmit = (responses: Record<string, string | unknown>) => {
      const leadGenParams: LeadGenCreateParams = {
        format: type,
        leadPromoOffer: promoCode.getOrElse('NO_CODE'),
        leadSourceVersion: {},  // TODO pull in from page context

        locale: locale,
        // @ts-expect-error TS(2322) FIXME: Type 'Record<string, unknown>' is not assignable t... Remove this comment to see the full error message
        responses,
        showPhoneField: includePhone,
        visitorId: vid || 'NO_VISITOR_ID'
      }

      const emailAddress = safeProp('email', responses).getOrElse('')
      setEmailAddress(
        typeof emailAddress === 'string'
          ? emailAddress : ''
      )

      setIsLoading(true)
      leadGenRecommendations(leadGenParams)(handleEmailSubmitFailure)(handleEmailSubmitSuccess(responses))
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
    useCookieChange(COOKIE_LEAD_DATA, data => setEmailAddress(propOr('', 'email', JSON.parse(data))))
    const onClickButton = () => {
      handleHidePopup(false)
      set(POP_UP_STORAGE_KEY, true)
      variation && optimizelyTrackSiteEvents({ eventType: 'impacted_22146810067' })
    }

    const isNewUser = !storedAttributeHash

    /**
      * `useMemo` allows us to avoid inconsistencies between the number of steps for a new user and a returning one.
      * Without the use of "useMemo," a bug occurs because when a new user submits their email, a cookie is set,
      * and it becomes a returning user, so an initial step is added, and an inconsistency occurs.
      */
    const showNewUsersFirstTab = useMemo(
      () => !!(isNewUser && newUserHelpText && newUserSkipQuizButton && newUserSkipQuizButtonUrl && newUserStartQuizButton)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , []
    )
    const showReturningUsersFirstTab = useMemo(
      () => !!(!isNewUser && returningUserHelpText && returningUserRestartQuizButton && returningUserSeeResultsButton),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const firstTab = showNewUsersFirstTab || showReturningUsersFirstTab ?
      <WizardInitial
        isNewUser={isNewUser}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
        newUserHelpText={newUserHelpText}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulButton> | undefined' is not ... Remove this comment to see the full error message
        newUserSkipQuizButton={<ButtonComponent data={newUserSkipQuizButton} />}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulButton> | undefined' is not ... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        newUserStartQuizButton={<ButtonComponent data={variation === 'variation_1' ? appendVIDtoButtonUrl(newUserStartQuizButton.variations[1], vid) : newUserStartQuizButton.variations[0]} onClick={onClickButton}
          style={{ width:'100%' }}
        />}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
        returningUserHelpText={returningUserHelpText}
        // @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulButton> | undefined' is not ... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        returningUserRestartQuizButton={<ButtonComponent data={variation === 'variation_1' ? appendVIDtoButtonUrl(returningUserRestartQuizButton.variations[1], vid) : returningUserRestartQuizButton.variations[0]} style={{ width:'100%' }}
        />}
        returningUserSeeResultsButton={
          <Link
            style={{
              display: 'block',
              textDecoration: 'none'
            }}
            to={`/product/system/${storedAttributeHash}`}
          >
            {/* @ts-expect-error TS(2322) FIXME: Type 'Maybe<ContentfulButton> | undefined' is not ... Remove this comment to see the full error message */}
            <ButtonComponent data={returningUserSeeResultsButton} />
          </Link>
        }
      /> : null

    const loading = <div key="loading"
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <LoadingSpinner />
      <p className="m1_t" style={{ marginBottom: 0 }}>Building your System</p>
    </div>

    const finalTabDescription = isLoading ? [ loading ] : toRichText('finalTabDescription', data, emailAddress)
    const finalTabContents = isLoading ? [ loading ]
      : safeProp('finalTabContents', data)
        .map(components => components.map(componentData => {
          const component = componentData

          const key = prop('id', component)

          return path([ 'internal', 'type' ], component) === 'ContentfulRichText' && attributeHash.isSome()
            ? (
              <Text textAlignment='center' useTailwind>
                <Link
                  key={key}
                  to={`/product/system/${attributeHash.just()}`}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code */}
                  {safePath([ 'richText', 'json' ], component).map(json => <RichText json={json}
                    key={key} />)}
                </Link>
              </Text>
            )
            : <div key={key} />
        })
        )
        .getOrElse([])
    const renderQuoteWizard = () => (<QuoteWizardComponent
      data={data}
      defaultEmail={emailAddress}
      finalTabContents={finalTabContents}
      finalTabDescription={finalTabDescription}
      firstTab={firstTab}
      handleEmailSubmit={handleEmailSubmit}
      includePhone={includePhone}
      type={type}
    />)

    return (columnType === 'none' ? renderQuoteWizard() :
      <Track>
        <Column
          backgroundColor='neutralBlack'
          padding='medium'>
          <StyledText useTailwind={true}>{renderQuoteWizard()}</StyledText>
        </Column>
      </Track>
    )
  }

export default QuoteWizardWrapper
