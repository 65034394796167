import path from '@simplisafe/ewok/ramda/path'
import { safePath } from '@simplisafe/monda'
import React, { FC } from 'react'

import { ContentfulBannerTopPage } from '../../../graphql'
import { toPromoBannerStyleValue } from '../../attributeMappings'
import { getValueFromPartnerCookie } from '../../util/partnerCookie'
import ActivePromoBanner from '../ActivePromoBanner'
import PartnerBanner from '../PartnerBanner'
import { usePromoBannerData } from './hooks'
import { PageProps } from './types'

const PromoBanner: FC<{ readonly pageData: PageProps['data'] }> = ({ pageData }) => {
  const {
    isActivePromoLoading,
    isPartnerBannerLoading,
    partnerBanner,
    monthsOfServiceDisplay,
    discountText,
    lineItemText
  } = usePromoBannerData()

  const partnerName = getValueFromPartnerCookie('partnerName')

  const promoBannerStyle = toPromoBannerStyleValue(path([ 'contentfulPage', 'pageLayout', 'promoBannerStyle' ], pageData)) || 'none'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const pageTopBanner: ContentfulBannerTopPage = safePath([ 'contentfulPage', 'pageTopBanner' ], pageData).orUndefined()

  return (<>
    {
      !pageTopBanner && !(isPartnerBannerLoading || isActivePromoLoading) && partnerBanner.map(data =>
        promoBannerStyle !== 'cart' && promoBannerStyle !== 'none' && <PartnerBanner
          backgroundColor={data.backgroundColor.orUndefined()}
          discountSecondaryText={data.discountSecondaryText.orUndefined()}
          discountText={discountText.orSome('15% off')}
          displayEmailInputField={data.displayEmailInputField.orUndefined()}
          lineItemText={lineItemText.orSome('free SimpliCam')}
          linkPath={data.linkPath.orUndefined()}
          logoDescription={data.logoDescription.orUndefined()}
          logoUrl={data.logoUrl.orUndefined()}
          monitoringText={`${monthsOfServiceDisplay} of monitoring`}
          partnerName={partnerName || undefined}
          primaryOfferText={data.primaryOfferText.orUndefined()}
          primaryTextColor={data.primaryTextColor.orUndefined()}
          secondaryOfferText={data.secondaryOfferText.orUndefined()}
          secondaryOfferTextMobile={data.secondaryOfferTextMobile.orUndefined()}
          secondaryTextColor={data.secondaryTextColor.orUndefined()}
          showCta={promoBannerStyle !== 'pdp-plp'}
        ></PartnerBanner>
      ).orSome(
        <ActivePromoBanner
          pageData={pageData}
          type={promoBannerStyle}
        />
      )
    }
  </>)
}

export default PromoBanner
