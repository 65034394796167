/* eslint-disable max-lines */ // TODO split this file up
import {
  COOKIE_MONITORING_PLAN,
  cookies,
  getMonitoringGiftItems,
  getNonMonitoringGiftItems,
} from '@lib/tracking/src/cookies'
import { useOptimizelyAffirm, useOptimizelyTrackSiteEvents } from '@lib/tracking/src/optimizely'
import { useLocation } from '@reach/router'
import log from '@simplisafe/ewok/log'
import { EitherWithVal } from '@simplisafe/ewok/monet-utils/types'
import path from '@simplisafe/ewok/ramda/path'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotEmpty from '@simplisafe/ewok/ramda-adjunct/isNotEmpty'
import transformObject from '@simplisafe/ewok/transformObject'
import { safePath, safeProp } from '@simplisafe/monda'
import { IOAddBmsToCart } from '@simplisafe/ss-ecomm-data/cart'
import { ProductBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { getLocalizedString } from '@simplisafe/ss-ecomm-data/commercetools/products'
import {
  initializeMiniCart, MiniCartLineItem, setMiniCartLineItem
} from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { PackageProduct } from '@simplisafe/ss-ecomm-data/packages/commercetools'
import { GiftItemDTO } from '@simplisafe/ss-ecomm-data/prices/service'
import {
  selectActivePromoOverrideDiscountText,
  selectCustomSystemDiscountedPrice, selectCustomSystemTotalPrice, selectLocale, selectMiniCartLineItems, selectPackage,
  selectProduct, selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import {
  Price, SSInput, Text
} from '@simplisafe/ss-react-components'
import { AffirmPromoMessage } from '@simplisafe/ss-react-components'
import { CartSummary } from '@simplisafe/ss-react-components'
import type { AdditionalOptionItemsProps } from '@simplisafe/ss-react-components/AdditionalOptionItems/types'
import { CartLineItemProps } from '@simplisafe/ss-react-components/CartLineItem'
import { CartSummaryprops } from '@simplisafe/ss-react-components/CartSummary'
import { MiniCartOptionsProps } from '@simplisafe/ss-react-components/MiniCartOptions'
import { SSButtonProps } from '@simplisafe/ss-react-components/SSButton'
import { window } from 'browser-monads-ts'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import {
  Either,
  List, Maybe, None
} from 'monet'
import always from 'ramda/src/always'
import applySpec from 'ramda/src/applySpec'
import contains from 'ramda/src/contains'
import equals from 'ramda/src/equals'
import filter from 'ramda/src/filter'
import has from 'ramda/src/has'
import head from 'ramda/src/head'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import propOr from 'ramda/src/propOr'
import split from 'ramda/src/split'
import T from 'ramda/src/T'
import trim from 'ramda/src/trim'
import without from 'ramda/src/without'
import React, {
  FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulBmsMiniCartFragment, RichTextWithOptionsFragment } from '../../../graphql'
import {
  componentsNotInStock, renderCoreComponentsNotInStockMsg, systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice, getProductFinalPrice } from '../../commercetools/price'
import { currencyCode, locale } from '../../commercetools/utils'
import usePriceVariation from '../../hooks/usePriceVariation'
import useRequestPrice from '../../hooks/useRequestPrice'
import type { AffirmClient } from '../../types/affirm'
import { trackAddToCartEvent, trackAddToCartPackageWithExtrasEvent } from '../../util/analytics/addToCart'
import getJson from '../../util/getJson'
import { toButton } from '../../util/helper'
import { giftCookieOptions } from '../BmsSensorGroup/BmsSensor'
import { getNameWithUSFallback } from '../CartDetailsComponent/transformLineItem'
import ContentfulRichText from '../ContentfulRichText'
import {
  getAddToCartRedirectUrl,
  getRichTextPriceOptionsTitle,
  maybeAddMonitoringToProducts,
  noPlanKey,
  ss2UpgradeKey,
  toAdditionalOptionItems,
  withPlanKey
} from '../ItemContainerComponent/OldVersion'
import RichTextWithOptionsComponent from '../RichTextWithOptionsComponent'

const GTM_ADD_TO_CART_LABEL = 'build my system'

type CartSummaryComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly id: string
  readonly data: ContentfulBmsMiniCartFragment
}

// TODO move this to ecomm-data
export type DiscountValues = {
  readonly absoluteDiscount: Maybe<number>
  readonly relativeDiscount: Maybe<number>
  readonly formattedDiscountString: Maybe<string>
}

type RadioOnChange = (_: string) => void

type PartialCartSummaryprops = Omit<CartSummaryprops, 'buttonOnClick' | 'cartLineItem' | 'price'>

export type CartLineItemPropsValues = 'itemName' | 'price' | 'quantity' | 'quantityItem'

const toAdditionalOptionItemsProps = (onChange: RadioOnChange, _package: Package | undefined, discountText: string | undefined, discountTextWithMonitoring: string | undefined, planPrice: number) => {
  return transformObject<ContentfulBmsMiniCartFragment, AdditionalOptionItemsProps>({
    // @ts-expect-error TS(2322) FIXME: Type '(v1: any) => map_mixed_11<AdditionalOptionIt... Remove this comment to see the full error message
    additionalOptionItems:
      // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
      pipe(value => prop('priceOptions', value), map(value => toAdditionalOptionItems(_package, discountText, discountTextWithMonitoring, planPrice)(value))),
    onClick: () => onChange,
    priceOptionsTitle: value => safeProp('priceOptionsTitle', value).getOrElse(''),
    richTextPriceOptionsTitle: getRichTextPriceOptionsTitle(_package)
  })
}

const toMiniCartOptionsProps = (onChange: RadioOnChange, _package: Package | undefined, discountText: string | undefined, discountTextWithMonitoring: string | undefined, planPrice: number) =>
  transformObject<ContentfulBmsMiniCartFragment, MiniCartOptionsProps>({ additionalOptionItemsProps: toAdditionalOptionItemsProps(onChange, _package, discountText, discountTextWithMonitoring, planPrice) })


const getBadgeImage = (data: Partial<ContentfulBmsMiniCartFragment>): ReactNode => {
  const imageTitle = path([ 'satisfactionGuaranteeBadgeAndDescription', 'guaranteeBadge', 'image', 'title' ], data)
  const imageData = path([ 'satisfactionGuaranteeBadgeAndDescription', 'guaranteeBadge', 'image', 'fluid' ], data)
  return (
    imageData ?
      <Img
        alt={imageTitle}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        fluid={imageData}
        imgStyle={{
          height: '100%',
          objectFit: 'contain',
          width: '100%'
        }}
      /> : null
  )
}

const toRichTextWithOptionsComponent = (data: RichTextWithOptionsFragment) =>
  <RichTextWithOptionsComponent data={data} />

/** TODO badgeText data will eventually be replaced by a child ReactNode */
const toCartSummary = (onChange: RadioOnChange, showSpinner: boolean, discountText: string | undefined, discountTextWithMonitoring: string | undefined, planPrice: number) => (
  transformObject<ContentfulBmsMiniCartFragment, PartialCartSummaryprops>({
    badgeImage: value => getBadgeImage(value),
    badgeText: value => path([ 'satisfactionGuaranteeBadgeAndDescription', 'refundContactInformation', 'richText', 'json' ], value),
    buttonProps: (value: ContentfulBmsMiniCartFragment & { readonly children?: ReactNode }): SSButtonProps => (
      safeProp('button', value).cata(always({ children: value.children }), el => ({
        ...toButton(el),
        showSpinner
      }))
    ),
    freeShippingText: value => prop('freeShippingText', value),
    miniCartOptions: toMiniCartOptionsProps(onChange, undefined, discountText, discountTextWithMonitoring, planPrice),
    title: value => propOr<string, string>('', 'title', value),
    totalOrderText: value => path([ 'totalOrderText', 'json' ], value)
  })
)

const multipliedPrice = (x: MiniCartLineItem) => x.quantity * getProductFinalPrice(x)
const minQuantityValue = 0

const lineItemProp: {
  readonly itemName: (_: MiniCartLineItem) => string
  readonly price: (_: MiniCartLineItem) => string
} = {
  itemName: getNameWithUSFallback,
  price: pipe(multipliedPrice, p => formatDisplayPrice(p).orJust('')),
}

type OnQuantityChange = (_: MiniCartLineItem, __: number) => void;

const getQuantity = (lineItem: MiniCartLineItem, onQuantityChange: OnQuantityChange) => {
  const checked = prop('checked', lineItem)
  return (!checked ? {
    defaultValue: prop('quantity', lineItem),
    max: prop('maxQuantity', lineItem),
    min: minQuantityValue,
    onChange: (quantity: number) => onQuantityChange(lineItem, quantity)
  } : undefined)
}

const getQuantityItem = (lineItem: MiniCartLineItem, onQuantityChange: OnQuantityChange) => {
  const checked = prop('checked', lineItem)
  return (checked ? <SSInput
    defaultChecked={checked}
    disabled={false}
    id={'checkBoxCartSummery'}
    label={''}
    name={'checkBoxCartSummery'}
    onChange={() => onQuantityChange(lineItem, 0)}
    placeholder={'checkbox'}
    type={'checkbox'}
    value={''}
  /> : null)
}
const toCartLineItem = (onQuantityChange: OnQuantityChange) =>
  transformObject<MiniCartLineItem, Pick<CartLineItemProps, CartLineItemPropsValues>>({
    ...lineItemProp,
    quantity: lineItem => getQuantity(lineItem, onQuantityChange),
    quantityItem: lineItem => getQuantityItem(lineItem, onQuantityChange)
  })

// TODO figure out what the actual type for this should be
// I think this is a PackageProduct, but that doesn't have a name or price
// this should somehow be refactored to pull the name from the product store (package products only have sku and quantity)
type Input = { readonly name: Record<string, string>; readonly quantity: number }

export const toBaseCartLineItem = (product: PackageProductWithQuantity) => applySpec<CartLineItemProps>({
  ...lineItemProp,
  itemName: (x: Input) => safeProp('name', x)
    .map(getLocalizedString(locale))
    .map(productName => `${x.quantity} ${productName}`)
    .getOrElse('')
})(product)

type PackageProductWithQuantity = PackageProduct & {
  readonly quantity?: number
}

/**
 * I'm going to add some extra comments here, becuase it's somewhat complicated and super FP.
 *
 * Each product we want to show in the cart summary has a potentional to error, so it's an array of Either<Error, PackageProductWithQuantity>.
 * We really don't want to show errors for each line, because if there is an error with something in the cart the entire cart has something wrong.
 * So, instead of an array or Eithers, we want 1 Either with an array of products, or 1 error.
 *
 * We can flip our functors around using sequence, so Array<Either> become Either<Array>.
 *
 * https://mostly-adequate.gitbook.io/mostly-adequate-guide/ch12
 *
 * Ramda has a sequence function, but it doesn't work with typescript or Monet, so we can't use that.
 * Monet's List has a sequence function: https://github.com/monet/monet.js/blob/master/docs/LIST.md#sequence
 *
 * First we take in the array, convert it to List, sequence it into an array, and convert it back to an array.
 * Instead of being able to just use .map, I am using .bimap so I can declare the type of Left.
 */
// eslint-disable-next-line functional/prefer-readonly-type
function sequenceProducts<T>(_products: Either<Error, T>[]): Either<Error, T[]> {
  return List.fromArray(_products)
    .sequence(Either)
    .bimap(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (e) => e as Error,
      // eslint-disable-next-line functional/prefer-readonly-type, @typescript-eslint/consistent-type-assertions
      l => l.toArray() as T[])
}

const modProduct =
  (select: ImmutableState) =>
    (_package: Package) =>
      safeProp('products', _package)
        .map(packageProducts => packageProducts.filter(packageProduct => !!prop('isViewable', packageProduct)))
        .map(packageProducts =>
          packageProducts.map(packageProduct => {
            // TODO extract this function.
            const { sku, quantity } = packageProduct
            const _product = selectProduct(sku)(select)

            return _product.map(p => ({
              ...p,
              quantity
            }))
          })
        )
        .toEither(Error('The package does not contain any products'))

const additionalLineItem = applySpec<CartLineItemProps>({
  isFreeItem: T,
  itemName: identity,
})

const toProductBody = applySpec<ProductBody>({
  quantity: prop('quantity'),
  sku: prop('sku')
})

const renderPrice = (price?: string, discountedPrice?: string) =>
  <Price
    discountedPrice={discountedPrice}
    key={`total-price-${price}`}
    regularPrice={price} />

type CartSummaryPriceProps = {
  readonly miniCartOptions: MiniCartOptionsProps
  readonly price: JSX.Element
}

export const getTotalFormattedPrice = (price: Maybe<number>) =>
  price
    .chain(formatDisplayPrice)
    .orUndefined()

const CartSummaryComponent: FC<CartSummaryComponentProps> = (({
  // allows passing in a different value for affirmClient in unit tests
  affirmClient = prop('affirm', window),
  data
}: CartSummaryComponentProps) => {
  // TODO this should never be a thing. use a real selector from ecomm-data, don't grab the entire raw redux state.
  const select = useSelector((state: ImmutableState) => state)

  const dispatch = useDispatch()

  const addSensorsText = safeProp('addSensorsText', data).getOrElse('')

  const skuBaseProduct = pathOr<string, string>('', [ 'defaultBundle', 'bmsBaseProduct' ], data)

  // Get core components products (Base Station, Entry and Keypad) and return
  // an array of core components that are currently out of stock
  const coreComponetsProducts = useSelector(selectProducts(systemCoreComponents))
  const coreComponetsNotInStockList = useMemo(() => componentsNotInStock(coreComponetsProducts), [ coreComponetsProducts ])
  const areCoreComponentsNotInStock = coreComponetsNotInStockList.length > 0

  const shippingEstimate = getJson(prop('shippingEstimateText', data))
  // TODO: if this needs to be built for UK and when it is build, locale condition needs to be removed
  const siteLocale = useSelector(selectLocale)
  const showShippingEstimate = shippingEstimate && siteLocale === 'en-US' && !areCoreComponentsNotInStock

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const defaultBundleData = safePath([ 'defaultBundle', 'otherItem' ], data).getOrElse([])
  // System components that will be filtered from custom packages line items, etc. Window Decals
  const systemComponents = path([ 'defaultBundle', 'systemComponents' ], data) || []

  const basePackage: EitherWithVal<Error, Package> = useSelector(selectPackage(skuBaseProduct))
  const basePackageProduct = useSelector(selectProduct(skuBaseProduct))

  const itemList = useSelector(selectMiniCartLineItems)
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const miniCartProductLinked: Maybe<Pick<MiniCartLineItem, 'maxQuantity' | 'quantity' | 'sku'>> = safePath([ 'state', 'miniCartItem' ], location)

  const miniCartProductLinkedSku =
    miniCartProductLinked.chain(safeProp('sku'))

  const productLinked =
    useSelector((state: ImmutableState) =>
      miniCartProductLinkedSku.chain(sku => selectProduct(sku)(state).toMaybe()))

  const [ list, setList ] = useState(None<readonly CartLineItemProps[]>())

  const [ isSubmissionError, setIsSubmissionError ] = useState<boolean>(false)

  // TODO: There might be a better solution other than hard coding the SS2 upgrade package
  const planKey = skuBaseProduct === 'simplisafe-customer-upgrade-system-en-US' ? ss2UpgradeKey : withPlanKey
  const [ radioKey, setRadioKey ] = useState(planKey)
  cookies.set(COOKIE_MONITORING_PLAN, radioKey === withPlanKey, giftCookieOptions)

  const { trackEvent } = useTracking()

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const {
    optimizelyAffirmLearnMore, optimizelyAffirmVariation, optimizelyReady
  } = useOptimizelyAffirm()

  const dependentProduct = prop('dependentProduct', data) || []

  const initialServicePlanSku = safeProp('priceOptions', data)
    // TS is not happy with .chain(safeHead), unclear why
    .chain(priceOptions => Maybe.fromNull(head(priceOptions)))
    .chain(safeProp('productSku'))

  const [ servicePlanSku, setServicePlanSku ] = useState(initialServicePlanSku)
  const planProduct = usePriceVariation(servicePlanSku.getOrElse(''))
  const planPrice = planProduct.cata(
    () => 0,
    value => prop('price', value)
  )

  const overrideToggleTextMaybe =
    useSelector(selectActivePromoOverrideDiscountText)
      .chain(safeProp('toggleBox'))
      .chain(val => val)
  const hasOverrideToggleText = !overrideToggleTextMaybe.isNone()

  const convertCartLineItemToProductGTM = (cartItem: CartLineItemProps) => {
    if (!prop('itemName', cartItem)) {
      return null
    } else {
      const itemNameArray = split(' ', prop('itemName', cartItem))
      const prodName = trim(itemNameArray.filter(x => equals(NaN)(Number(x))).join(' '))
      const quantity = trim(itemNameArray.filter(x => !equals(NaN)(Number(x))).join(' '))
      return {
        name: prodName,
        price: safeProp('price', cartItem).cata<number | undefined>(always(undefined), p => Number(p)),
        quantity: equals('', quantity) ? 1 : Number(quantity)
      }
    }
  }

  const getAllProductGTMFromCartLineItem = (cartLineItem: readonly CartLineItemProps[]) => {
    const productList = map(convertCartLineItemToProductGTM)(cartLineItem)
    return { products: productList }
  }

  const getEcommerceDataFromCart = (cartLineItem: readonly CartLineItemProps[]) => {
    const products = getAllProductGTMFromCartLineItem(cartLineItem)
    return {
      currencyCode: currencyCode,
      detail: products
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  const trackEcommerceData = (trackEvent, items: readonly CartLineItemProps[]) => {
    const ecommerceData = getEcommerceDataFromCart(items)
    //TODO: We must review the types of the trackEvent. In this case, 'ecc.details' does not match to event as it has a lot of specific strings defining the type itself.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    trackEvent({
      ecommerce: ecommerceData,
      event: 'eec.details',
      eventAction: 'detail',
      eventCategory: 'eec',
      eventLabel: 'build my system'
    })
  }

  useEffect(() => {
    // this isn't actually firing, at least not on local; cartLineItem is a Right claiming it can't find the package SKU
    cartLineItem.forEach((items) => trackEcommerceData(trackEvent, items))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (radioKey: string, servicePlanSku?: string) => {
    setRadioKey(radioKey)
    setServicePlanSku(Maybe.fromNull(servicePlanSku))
    cookies.set(COOKIE_MONITORING_PLAN, radioKey === withPlanKey, giftCookieOptions)
  }

  const componentFilter = systemComponents
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    .map((component) => Object.values(component)[0])

  const monitoringGiftItem: GiftItemDTO = getMonitoringGiftItems()
  const nonMonitoringGiftItem: GiftItemDTO = getNonMonitoringGiftItems()

  const baseProductList = useMemo(() =>
    basePackage
      .chain(modProduct(select))
      .chain(s => sequenceProducts<PackageProductWithQuantity>(s))
      .map(products => products
        .filter(product => !componentFilter.includes(propOr<string, string>('', 'sku', product)))
        .map(toBaseCartLineItem))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ basePackage ])

  const applicableGiftItem = useMemo(() => {
    // first need to ensure at least one gift item exists
    return (monitoringGiftItem || nonMonitoringGiftItem)
      // then check if the monitoring option is selected
      ? (radioKey === withPlanKey
        // then check that a gift item exists for that plan before returning its title
        ? monitoringGiftItem ? monitoringGiftItem.title : null
        : nonMonitoringGiftItem ? nonMonitoringGiftItem.title : null)
      : null
  }, [ monitoringGiftItem, nonMonitoringGiftItem, radioKey ])

  const additionalBaseLineItem = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const appendedBaseLineItems = applicableGiftItem ? [ applicableGiftItem, ...defaultBundleData ] : [ ...defaultBundleData ]

    return Maybe.fromUndefined(appendedBaseLineItems).map(x => map(additionalLineItem, x))
  }, [ defaultBundleData, applicableGiftItem ])

  const buttonUrl = path([ 'button', 'url' ], data)
  const [ showSpinner, setShowSpinner ] = useState(true)

  const onQuantityChange = useCallback((product: MiniCartLineItem, quantity: number) => {
    const action = setMiniCartLineItem({
      ...product,
      quantity
    })
    dispatch(action)
  }, [ dispatch ])

  const navigateUrl = useCallback((url: string, sku: string) => {
    void navigate(url, { state: { packageSku: sku } })
  }, [])

  const servicePlanProduct = useSelector((state: ImmutableState) => {
    return servicePlanSku.chain(id => selectProduct(id)(state).toMaybe())
  })

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<string>[]' is not assignab... Remove this comment to see the full error message
  const totalPrice = useSelector(selectCustomSystemTotalPrice(skuBaseProduct, true, undefined, componentFilter))
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Maybe<string>[]' is not assignab... Remove this comment to see the full error message
  const discountedPrice = useSelector(selectCustomSystemDiscountedPrice(skuBaseProduct, radioKey, true, componentFilter))

  const onButtonClick = useCallback(() => {
    const urlNavigate = buttonUrl && getAddToCartRedirectUrl(siteLocale)(buttonUrl, radioKey)

    const handleSuccess = () => {
      setIsSubmissionError(false)
      setShowSpinner(false)

      const trackedPrice = discountedPrice.map(price => parseFloat(price.toFixed(2))).getOrElse(0)

      trackAddToCartPackageWithExtrasEvent(
        basePackage.toMaybe(),
        basePackageProduct.toMaybe(),
        itemList,
        true,
        trackEvent,
        select,
        radioKey === withPlanKey,
        GTM_ADD_TO_CART_LABEL,
        trackedPrice
      )
      trackAddToCartEvent(servicePlanProduct, trackEvent, 1, GTM_ADD_TO_CART_LABEL)
      dispatch(initializeMiniCart({}))
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      urlNavigate && navigateUrl(urlNavigate, skuBaseProduct)
    }
    const handleFailure = () => {
      setShowSpinner(false)
      setIsSubmissionError(true)
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }
    const productServices = filter<MiniCartLineItem>(propEq('productType', 'service'))(itemList)

    const listProduct = ifElse<readonly MiniCartLineItem[], readonly MiniCartLineItem[], readonly MiniCartLineItem[]>(
      isNotEmpty,
      i => [ ...without(i, itemList), ...i ],
      always(itemList)
    )(productServices)

    const finalProducts = maybeAddMonitoringToProducts(siteLocale)(listProduct.map(toProductBody), radioKey)

    skuBaseProduct && dispatch(IOAddBmsToCart({
      package: {
        quantity: 1,
        sku: skuBaseProduct
      },
      products: finalProducts
    }, handleFailure, handleSuccess))
  },
  // with this many dependencies, *maybe* this callback is doing too much
  [
    basePackage,
    basePackageProduct,
    buttonUrl,
    discountedPrice,
    dispatch,
    skuBaseProduct,
    itemList,
    radioKey,
    select,
    servicePlanProduct,
    setIsSubmissionError,
    siteLocale,
    trackEvent,
    navigateUrl,
    optimizelyTrackSiteEvents
  ])

  useEffect(() => {
    const result =
      Maybe
        .fromUndefined(itemList)
        .map(log)
        .map(lineItems => map(toCartLineItem(onQuantityChange), lineItems))

    setList(result)

    // TODO extract functions into a file outside the main component to keep the complexity down and lines under 300
  }, [ itemList, onQuantityChange ])

  useEffect(() => {
    productLinked
      .forEach(product => {
        dispatch(setMiniCartLineItem({
          ...product,
          maxQuantity: miniCartProductLinked.chain(safeProp('maxQuantity')).orUndefined(),
          quantity: miniCartProductLinked.chain(safeProp('quantity')).orJust(1)
        }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // @ts-expect-error TS(7006) FIXME: Parameter 'trackEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  const trackBmsPlanToggleEvent = (trackEvent, plan: string) => {
    // If we type trackEvent, it raises an error because some of the provided
    // attribute values doesn't match with the type of their corresponding fields
    // In this case, "onChange"
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    trackEvent({
      action: 'bms',
      category: 'monitoring-toggle',
      event: 'onChange',
      label: ifElse(equals(noPlanKey), always('no-plan'), always('add-plan'))(plan)
    })
  }
  const mounted = useRef(false)
  useEffect(() => {
    !mounted.current ? mounted.current = true : trackBmsPlanToggleEvent(trackEvent, radioKey)
  }, [ trackEvent, radioKey ])

  const { getDiscountedText, getDiscountedTextWithServicePlan } = useRequestPrice(skuBaseProduct)

  const promoDiscountText = getDiscountedText.orUndefined()
  const promoWithMonitoringDiscountText = hasOverrideToggleText ? overrideToggleTextMaybe.some() : getDiscountedTextWithServicePlan.orUndefined()

  const price = useMemo(() =>
    basePackage.map(
      (_basePackage): CartSummaryPriceProps => {
        const formattedTotalPrice: string | undefined = getTotalFormattedPrice(totalPrice)
        const formattedDiscountedPrice: string | undefined = getTotalFormattedPrice(discountedPrice)

        return ({
          miniCartOptions: toMiniCartOptionsProps(onChange, _basePackage, promoDiscountText, promoWithMonitoringDiscountText, planPrice)(data),
          price: renderPrice(formattedTotalPrice,
            equals(totalPrice, discountedPrice)
              ? undefined
              : formattedDiscountedPrice)
        })
      })
  , [ basePackage, totalPrice, discountedPrice, promoDiscountText, promoWithMonitoringDiscountText, planPrice, data ])

  const cartLineItem =
    baseProductList
      .map(_list => _list.concat(additionalBaseLineItem.getOrElse([]), list.getOrElse([])))

  const submissionError = safeProp('addToCartSubmissionErrorMessage', data).cata<JSX.Element | undefined>(
    always(undefined),
    toRichTextWithOptionsComponent
  )

  const affirmMessage = basePackage.cata<ReactElement | null>(
    () => null,
    _package => (
      discountedPrice.map(price =>
        optimizelyReady && <AffirmPromoMessage
          affirmClient={affirmClient}
          className={optimizelyAffirmVariation === 'variation_1' ? 'affirm-as-low-as-test' : 'affirm-as-low-as'}
          key={price}
          onLearnMoreClick={optimizelyAffirmLearnMore}
          pageType='product'
          price={price}
          textAlignment='right'
        /> || <></>
      )
        .orNull()
    )
  )
  const isInvalidCartItems = (itemList: readonly MiniCartLineItem[]) => {
    const isOnlyDependentProduct = itemList.find(item => item && !contains(applySpec({ sku: always(item.masterSku) })(), dependentProduct)) || {}

    return !has('sku', isOnlyDependentProduct) || isEmpty(
      itemList
        .filter((item: MiniCartLineItem) => prop('productType', item) !== 'service')
        .filter((item: MiniCartLineItem) => propOr<string, string>('', 'sku', item)
          .toUpperCase() !== 'SSYS3')
    )
  }

  const render =
    (_price: CartSummaryPriceProps) =>
      (_cartLineItem: readonly CartLineItemProps[]) => {
        return (
          <CartSummary
            {...toCartSummary(onChange, showSpinner, promoDiscountText, promoWithMonitoringDiscountText, planPrice)(data)}
            addSensorsText={addSensorsText}
            buttonAfter={<>
              {affirmMessage}
              {showShippingEstimate &&
                <Text textAlignment='right' textSize='xs'
                  useTailwind>
                  <ContentfulRichText
                    rawRichText={shippingEstimate}
                  />
                </Text>}

            </>}
            // Only show error message if state variable was toggled to true during the onButtonClick callback.
            buttonMessage={isSubmissionError && submissionError}
            buttonOnClick={onButtonClick}
            cartLineItem={_cartLineItem}
            isDisabled={isInvalidCartItems(itemList)}
            outOfStockMsg={areCoreComponentsNotInStock && renderCoreComponentsNotInStockMsg(coreComponetsNotInStockList)}
            {..._price}
          />
        )
      }

  return (
    <>
      {
        cartLineItem
          // .ap is a way to call a function with two Eithers. The function only runs if both values are Right, othwerwise it returns a Left with the first error it finds.
          .ap(price.map(render))
          .cata(
            // If there is an error with cartLineItems, set error message as the cartMessage prop.
            () => {
              return <CartSummary
                {...toCartSummary(onChange, showSpinner, promoDiscountText, promoWithMonitoringDiscountText, planPrice)(data)}
                addSensorsText={addSensorsText}
                buttonOnClick={onButtonClick}
                cartLineItem={[]}
                cartMessage={safeProp('cartItemsRetrievalErrorMessage', data).map(toRichTextWithOptionsComponent)
                  .orUndefined()}
                isDisabled={isInvalidCartItems(itemList)}
                price={null}
                totalOrderText={undefined}
              />
            },
            element => <>{element}</>) // if we don't have any errors, we render the element.
      }
    </>
  )
})

export const CartSummaryFragment = graphql`#graphql
fragment contentfulBmsMiniCart on ContentfulBmsMiniCart {
    id
    internal {
        type
    }
    title
    priceOptionsTitle
    addSensorsText
    freeShippingText
    defaultBundle {
      bmsBaseProduct
      systemComponents {
        sku
      }
      otherItem
    }
    toggleBoxesHeadline {
        json
    }
    priceOptions {
        description {
            json
        }
        productSku
        detailsModal {
          ... on ContentfulModal {
            ...modalFragment
          }
        }
        titleProductName
        title
        titleDiscountText {
          json
        }
        productType
        productName {
          json
        }
    }
    satisfactionGuaranteeSection {
        id
        title
        description {
            json
        }
    }
    dependentProduct{
      sku
    }
    satisfactionGuaranteeBadgeAndDescription {
      title
      guaranteeBadge {
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      refundContactInformation {
        title
        richText {
          json
        }
      }
    }
    button {
      text
      type
      url
    }
    totalOrderText {
      json
    }
    shippingEstimateText {
      json
    }
    cartItemsRetrievalErrorMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
    addToCartSubmissionErrorMessage {
      ... on ContentfulRichTextWithOptions {
        ...richTextWithOptions
      }
    }
}
`

export default CartSummaryComponent
