import { RichText } from '@simplisafe/ss-react-components'
import React, { FC, HTMLAttributes } from 'react'

import { getImageCardPadding } from './helpers'
import ReviewCardImage from './ReviewCardImage'
import { ReviewCardProps } from './types'

export const getTextPositionClasses = (textPosition: string ): string => {
  return textPosition === 'Right' ? 'absolute' : ''
}

const ReviewCard: FC<HTMLAttributes<HTMLDivElement> & ReviewCardProps> = ({
  title, text, icon, iconSize, iconPosition, textPosition, className
}) => {
  return (
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    <div className={`${className} ${getImageCardPadding(iconPosition)} neutralLightTanBackgroundColor flex flex-col justify-center text-center items-center rounded-lg`}>
      <ReviewCardImage icon={icon} iconPosition={iconPosition}
        iconSize={iconSize} title={title}/>
      {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code */}
      <RichText className={getTextPositionClasses(textPosition)} json={text?.json}/>
    </div>
  )
}

export default ReviewCard
