import { useExperiment } from '@optimizely/react-sdk'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safeFind, safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import { Maybe, None } from 'monet'
import propEq from 'ramda/src/propEq'
import { FC, ReactElement } from 'react'

import { VariationContainerFragment } from '../../../graphql'
import { mapPageComponentToTemplate, PageProps } from '../Page'

type Variant = NonNullable<NonNullable<VariationContainerFragment['variations']>[number]>

export type ContentfulVariationContainerComponentProps = {
  readonly data: VariationContainerFragment
  // Custom render function for contexts that don't use getMappedComponent
  readonly renderVariant?: (variant: Variant) => ReactElement | null
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

const ContentfulVariationContainerComponent: FC<ContentfulVariationContainerComponentProps> = ({
  data, location, pageContext, renderVariant,
}: ContentfulVariationContainerComponentProps) => {
  const variants = (prop('variations', data) || []).filter(isNotNil)
  const experimentKey = prop('experimentKey', data) || ''

  // Get the mapping of variation name to Contentful entry id
  // https://www.contentful.com/help/optimizely-app/#using-optimizely-to-pick-the-right-variation
  // TODO switch to safePath once frontend is on TS 4 and has updated monda version
  const variantEntryMapping = safeProp('meta', data)
    .chain(safeProp('internal'))
    .chain(safeProp('content'))
    .cata<Record<string, string>>(
      () => ({}),
      content => JSON.parse(content)
    )

  const [ variation, clientReady, didTimeout ] = useExperiment(experimentKey)
  const optimizelyReady = clientReady || didTimeout

  const getVariantForId = (entryId: string) => safeFind<Variant>(propEq('contentful_id', entryId))(variants)

  const activeVariantEntryId = Maybe.fromNull(variation)
    .chain(_variation => safeProp(_variation, variantEntryMapping))
  const activeVariant = activeVariantEntryId.chain(getVariantForId)
  const controlVariant = safeProp('control', variantEntryMapping).chain(getVariantForId)
  const selectedVariant = activeVariant.catchMap(() => variation === null ? controlVariant : None())

  const renderCustomOrMappedVariant = (variant: Variant) => renderVariant ? renderVariant(variant) : mapPageComponentToTemplate(variant, pageContext, location)

  return optimizelyReady ?
    selectedVariant.chain((variant) => Maybe.fromNull(renderCustomOrMappedVariant(variant))).orNull() :
    null
}

export const query = graphql`#graphql
  fragment variationContainer on ContentfulVariationContainer {
    id
    internal {
      type
    }
    experimentId
    experimentKey
    experimentTitle
    meta {
      internal {
        content
      }
    }
    # Note: Each fragment included under variations here needs to also request the contentful_id field in order to be mapped to a variation
    variations {
      ... on ContentfulBmsSensors {
        contentful_id
        ...bmsSensors
      }
      ... on ContentfulExpandableMonitoringPlan {
        contentful_id
        ...expandableMonitoringPlanFragment
      }
      ... on ContentfulPrefectSystemBanner {
        contentful_id
        ...perfectSystemBanner
      }
      ... on ContentfulProductPageHeroV2 {
        contentful_id
        ...productPageHero
      }
      ... on ContentfulSmallTextSection {
        contentful_id
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        contentful_id
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulHeader {
        contentful_id
        ...contentfulHeaderFragment
      }
      ... on ContentfulModalPromoPopup {
        contentful_id
        ...modalPromoPopup
      } 
      ... on ContentfulGroupSection {
        contentful_id
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulHeroBanner {
        contentful_id
        ...heroBannerFragment
      }
      ... on ContentfulProductCard {
        contentful_id
        ...productCard
      }
    }
  }
  `
export default ContentfulVariationContainerComponent
